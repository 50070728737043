/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
* npm run build
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import classnames from "classnames";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
//import Moment from 'moment';
// reactstrap components
import { Card,
  Alert,
  Modal,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Form,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination, 
  PaginationItem, 
  PaginationLink ,
  FormFeedback,

  Button,} from "reactstrap";
  import ReactToPrint from 'react-to-print';
  import { Link , useLocation} from 'react-router-dom';
// core components
import ReactDatetime from "react-datetime";
import Header from "components/Headers/Header.jsx";
import Footer from "components/Footers/AdminFooter.jsx";
import { textChangeRangeIsUnchanged } from "typescript";
import axios ,{post} from 'axios'; 
import moment from 'moment';
// mapTypeId={google.maps.MapTypeId.ROADMAP}
// const MapWrapper = withScriptjs(
//   withGoogleMap(props => (
//     <GoogleMap
//       defaultZoom={12}
//       defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
//       defaultOptions={{
//         scrollwheel: false,
//         styles: [
//           {
//             featureType: "administrative",
//             elementType: "labels.text.fill",
//             stylers: [{ color: "#444444" }]
//           },
//           {
//             featureType: "landscape",
//             elementType: "all",
//             stylers: [{ color: "#f2f2f2" }]
//           },
//           {
//             featureType: "poi",
//             elementType: "all",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "road",
//             elementType: "all",
//             stylers: [{ saturation: -100 }, { lightness: 45 }]
//           },
//           {
//             featureType: "road.highway",
//             elementType: "all",
//             stylers: [{ visibility: "simplified" }]
//           },
//           {
//             featureType: "road.arterial",
//             elementType: "labels.icon",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "transit",
//             elementType: "all",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "water",
//             elementType: "all",
//             stylers: [{ color: "#5e72e4" }, { visibility: "on" }]
//           }
//         ]
//       }}
//     >
//       <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
//     </GoogleMap>
//   ))
// );

class Orders extends React.Component {

  constructor(props){
    super(props);
    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
    if(event.keyCode === 27) {
      //Do whatever when esc is pressed
    }
  }

  state = {
    rotpendinglist:[],
    attachmentsetID:0,
    selectedFilename1:"",
    selectedFilename2:"",
    selectedFilename3:"",
    selectedFilename4:"",
    selectedFilename5:"",
    selectedFilename6:"",
    selectedFilename7:"",
    selectedFilename8:"",
    selectedFilename9:"",
    selectedFilename10:"",
    selectedFileexistname1full:null,
    selectedFileexistname2full:null,
    selectedFileexistname3full:null,
    selectedFileexistname4full:null,
    selectedFileexistname5full:null,
    selectedFileexistname6full:null,
    selectedFileexistname7full:null,
    selectedFileexistname8full:null,
    selectedFileexistname9full:null,
    selectedFileexistname10full:null,
    percentupload1:0,
    percentupload2:0,
    percentupload3:0,
    percentupload4:0,
    percentupload5:0,
    percentupload6:0,
    percentupload7:0,
    percentupload8:0,
    percentupload9:0,
    percentupload10:0,
    tabs: 2,
    tabsimportexport:1,
    detailpopout:false,
    detailorderpopout:false,
    detailprocesspopout:false,
    detailpendinglist:[],
    detailorderlist:[],
    completelist:[],
    Billtoparty:sessionStorage.getItem('Billtoparty'),
    Createdbyaccount:sessionStorage.getItem('Ref'),
    loadingmodal:false,
    loadingmodalorder:false,
    loadingmodalprocess:false,
    loading:false,
    shipper:'',
    bookingno:'',
    pol:'',
    pod:'',
    shipperorder:'',
    bookingnoorder:'',
    polorder:'',
    podorder:'',
    rotnumbermodalpending:'',
    rotnumbermodalordertab:'',
    consignmentnoprocesstab:'',
    statusprocesstab:'',
    containernoprocesstab:'',
    requiredateprocesstab:'',
    closingdateprocesstab:'',
    typeprocesstab:'',
    color1:'dark',
    color2:'dark',
    color3:'dark',
    color4:'dark',
    color5:'dark',
    color6:'dark',
    color1text:'whitesmoke',
    color2text:'whitesmoke',
    color3text:'whitesmoke',
    color4text:'whitesmoke',
    color5text:'whitesmoke',
    color6text:'whitesmoke',
    buttoncolor1:'white',
    buttoncolor2:'primary',
    buttoncolor3:'white',
    userlist:[],
    currentcompletedpagenumber:1,
    searchtextvalue:'',
    printexport:'',
    printbilltoparty:'',
    printbookingno:'',
    printclosing:'',
    printcommodity:'',
    printcontainer1:'',
    printcontainer2:'',
    printcontainer3:'',
    printcontainer4:'',
    printcontainer5:'',
    printcontainer6:'',
    printcontainer7:'',
    printcontainer8:'',
    printeta:'',
    printforwardingagent:'',
    printpic:'',
    printpickup:'',
    printpod:'',
    printpol:'',
    printremark:'',
    printrequiredate:'',
    printrequiretime:'',
    printshipper:'',
    printshippingaddresss:'',
    printshippingliner:'',
    printsize1:'',
    printsize2:'',
    printsize3:'',
    printsize4:'',
    printtype1:'',
    printtype2:'',
    printtype3:'',
    printtype4:'',
    printvolume1:'',
    printvolume2:'',
    printvolume3:'',
    printvolume4:'',
    printvoyage:'',
    editpendingrotpopout:false,
    editorderrotnumber:'',
    isopendropdownshipper:false,
    isopendropdownshipmenttype:false,
    isopendropdownpod:false,
    isopendropdownpol:false,
    shipmenttypeadropdowntext:'',
    isopendropdownoperator:false,
    operatoreadropdowntext:'',
    isopendropdownpickup:false,
    isopendropdownreturnyard:false,
    pickupdropdowntext:'',
    returnyarddropdowntext:'',
    type2dropdowntext:'',
    isopendropdownforwardingagent:false,
    forwardingagentdropdowntext:'',
    quotationtabledatafinal:[],
    operatorlistdata:[],
    yardlist:[],
    typelist:[],
    typelistfirstone:[],
    Billtoparty:sessionStorage.getItem('Billtoparty'),
    deliveryaddress:'',
    areatextbox:'',
    poltext:'',
    podtext:'',
    disableddropdownpol:true,
    disableddropdownpod:true,
    volumenumberinput:0,
    volumenumberinput1:0,
    volumenumberinput2:0,
    volumenumberinput3:0,
    volumenumberinput4:0,
    volumenumberinput5:0,
    volumenumberinput6:0,
    volumenumberinput7:0,
    volumenumberinput8:0,
    volumenumberinput9:0,
    type1dropdowntext:'',
    type1dropdowntext1:'',
    type1dropdowntext2:'',
    type1dropdowntext3:'',
    type1dropdowntext4:'',
    type1dropdowntext5:'',
    type1dropdowntext6:'',
    type1dropdowntext7:'',
    type1dropdowntext8:'',
    type1dropdowntext9:'',
    isopendropdowntype:false,
    isopendropdowntype1:false,
    isopendropdowntype2:false,
    isopendropdowntype3:false,
    isopendropdowntype4:false,
    isopendropdowntype5:false,
    isopendropdowntype6:false,
    isopendropdowntype7:false,
    isopendropdowntype8:false,
    isopendropdowntype9:false,
    volumeshowrow:0,
    rowvolume1:false,
    rowvolume2:false,
    rowvolume3:false,
    rowvolume4:false,
    rowvolume5:false,
    rowvolume6:false,
    rowvolume7:false,
    rowvolume8:false,
    minusbuttonshow:false,
    forwardingagentlist:[],
    aftersubmitstatus:0,
    picvalue:'',
    contactvalue:'',
    bookingno:'',
    Vsleta:'',
    closingday:'',
    emptytest:[],
    submitbuttonclick:0,
    picvalidation:false,
    contactvalidation:false,
    podtextvalidation:false,
    poltextvalidation:false,
    bookingnovalidation:false,
    vsletavalidation:false,
    closingvalidation:false,
    volumenumber1validation:false,
    volumenumber2validation:false,
    volumenumber3validation:false,
    volumenumber4validation:false,
    volumenumber5validation:false,
    volumenumber6validation:false,
    volumenumber7validation:false,
    volumenumber8validation:false,
    volumenumber9validation:false,
    volumenumber10validation:false,
    containervalidation:false,
    requestdatevalidation:false,
    vesselnamevalidation:false,
    vesselvoyagevalidation:false,
    commodityvalidation:false,
    areadropdowntext:'',
    commodity:'',
    vesselname:'',
    vesselvoyage:'',
    errorsubmit:false,
    samedate:false,
    alerterrormessage:'',
    attachmentquantity:1,
    selectedFile1: null,
    selectedFile2: null,
    selectedFile3: null,
    selectedFile4: null,
    selectedFile5: null,
    selectedFile6: null,
    selectedFile7: null,
    selectedFile8: null,
    selectedFile9: null,
    selectedFile10: null,
    selectedFileexistname1: null,
    selectedFileexistname2: null,
    selectedFileexistname3: null,
    selectedFileexistname4: null,
    selectedFileexistname5: null,
    selectedFileexistname6: null,
    selectedFileexistname7: null,
    selectedFileexistname8: null,
    selectedFileexistname9: null,
    selectedFileexistname10: null,
    
    container0:'',
    container1:'',
    container2:'',
    container3:'',
    container4:'',
    container5:'',
    container6:'',
    container7:'',
    requestdate0:'',
    vsltoshow:'',
    closingdateshow:'',
    numberrowaftersubmit:0,
    cancelattachmentbutton1:false,
    cancelattachmentbutton2:false,
    cancelattachmentbutton3:false,
    cancelattachmentbutton4:false,
    cancelattachmentbutton5:false,
    cancelattachmentbutton6:false,
    cancelattachmentbutton7:false,
    cancelattachmentbutton8:false,
    cancelattachmentbutton9:false,
    cancelattachmentbutton10:false,
    requestdateerrorinvalid:false,
    popoutemail:false,
    attachmentlist:[],
    processlist:[],
    loadingprocesslist:false,
    detailpopoutattachment:false,
    //linkurl:"https://crm.mewahtrans.com.my/api/mewahapicode.php?",
    //linkurl:"https://crm.mewahtrans.com.my/api/webbaseservice.php?",
    linkurl:"https://demostaging.mewahtrans.com.my/api/mewahapicode.php?",
    //imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
   // linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    imageurllink:"https://crm.mewahtrans.com.my/api/img/profile_img/",
    processtotal:0,
  };

  // asdftoggleModal = state => {
  //   this.setState({
  //     [state]: !this.state[state]
  //   });
  // };
  
  renderaddinvolumerow(){
    // console.log("checktbevalue0: " + this.state.checktbe0)
    // console.log("checktbevalue1: " + this.state.checktbe1)
    // console.log("checktbevalue2: " + this.state.checktbe2)
    // console.log("checktbevalue3: " + this.state.checktbe3)
    // console.log("checktbevalue4: " + this.state.checktbe4)
    // console.log("checktbevalue5: " + this.state.checktbe5)
    // console.log("requestdate11: " + this.state.requestdate0)
    // console.log("requestdatedisable: " + this.state.requestdatedisable0)
    let consignmentnorow=[]
    let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
                    Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
                    Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
                    Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
                    Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 
  
                   
  
  
    for(let i=0;i<numberrow;i++){
      var numbershow = i+1
      if(this.state['consignmentno'+i] != null){
        var consignmentnotoshow = this.state['consignmentno'+i]
      }else{
        var consignmentnotoshow = this.state.editorderrotnumber + '-' + (i+1)
        this.setState({['consignmentno' + i] : consignmentnotoshow})
        console.log("consignmentno: " + "consignmentno" + i)
        console.log("consignmentnoshow: " + consignmentnotoshow)
      }
      
      consignmentnorow.push(
        <Row>
  
        <Col md="2">
          
            <FormGroup>
            <Input
                id="Consignmentnoinput2"
                placeholder="Consignment No"
                type="text"
                disabled
                value= {consignmentnotoshow}
                
              />
            </FormGroup>
          </Col>
          <Col md="3">
  
            <FormGroup>
            <Input
                id="Containernoinput2"
                placeholder="Container No"
                className="textcolorblack"
                value={this.state["container" + i]}
                onChange = {(event) => this.setState({ ["container" + i]: event.target.value })}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
            <Input
                id="Sealnoinput2"
                placeholder="Seal No"
                className="textcolorblack"
                type="text"
                value={this.state["Seal" + i]}
                onChange = {(e) => this.setState({ ["Seal" + i]: e.target.value })}
              />
            </FormGroup>
            </Col>
            <Col md="2">
            <FormGroup>
              {this.state["requestdatedisable"+i]==true?
                  <Input
                  id="requestdatetbe"
                  placeholder="TBC"
                  type="text"
                  disabled={true}
                  value={"TBC"}
                  />
                   
                
              :
                    <InputGroup  className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Request Date"
                      }}
                      closeOnSelect={true}
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      value={this.state["requestdate" + i]}
                      onChange = {(e) => this.setState({ ["requestdate" + i]: e })}
                     
                    />
                  </InputGroup> 
              }
          
              
            </FormGroup>
            </Col>
            <Col md="1">
            <FormGroup>
              
              <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                <input
                  className="custom-control-input"
                  id={"customCheck"+i}
                  type="checkbox"
                  checked={this.state["checktbe" + i]}
                  onChange={(e)=>this.checktbefunction(i)}
                />
                <label className="custom-control-label" htmlFor={"customCheck"+i}>
                  TBC
                </label>
              </div>
            </FormGroup>
            </Col>
            <Col md="1">
            <FormGroup>
              
              <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                <input
                  className="custom-control-input"
                  id={"customChecksideloader"+i}
                  type="checkbox"
                  checked={this.state["checksideloader" + i]}
                  onChange={(e)=>this.checksideloaderfunction(i)}
                />
                <label className="custom-control-label" htmlFor={"customChecksideloader"+i}>
                SL
                </label>
              </div>
            </FormGroup>
            </Col>
        </Row>
      )  
    }
    
      return consignmentnorow
      
  }

  toggleModalwithfunctiondetailpopout = (rotnumber,openclosetruefalse,completeorpendingtab) =>{
    this.setState({detailpopout:openclosetruefalse})
    //function call
    if(completeorpendingtab == "complete"){
      this.showmodalapifunctioncalldetailcompletetab(rotnumber);
    }else if(completeorpendingtab == "pending"){
      this.showmodalapifunctioncalldetail(rotnumber);
    }
    
  }

  toggleModalwithfunctionpopoutemail = (openclosetruefalse) =>{
    this.setState({popoutemail:openclosetruefalse})   
    
  }

  toggleModalwithfunctiondetailorderpopout = (rotnumber,openclosetruefalse) =>{
    this.setState({detailorderpopout:openclosetruefalse})
    //function call
    this.showmodalapifunctioncalldetailordertab(rotnumber);
  }

  toggleModalwithfunctiondetailorderpopoutattachment = (rotnumber,openclosetruefalse) =>{
    this.setState({detailpopoutattachment:openclosetruefalse})
    //function call
    this.setState({attachmentrotshowtitlw:rotnumber})
   // this.showmodalapifunctioncalldetailordertab(rotnumber);
  }

  toggleModalwitheditorderpopout = (rotnumber,openclosetruefalse) =>{
    this.setState({editpendingrotpopout:openclosetruefalse})
    //function call
    this.setState({volumeshowrow:0})
    this.setState({editorderrotnumber:rotnumber})
    this.setState({selectedFileexistname1:null})
    this.setState({selectedFileexistname2:null})
    this.setState({selectedFileexistname3:null})
    this.setState({selectedFileexistname4:null})
    this.setState({selectedFileexistname5:null})
    this.setState({selectedFileexistname6:null})
    this.setState({selectedFileexistname7:null})
    this.setState({selectedFileexistname8:null})
    this.setState({selectedFileexistname9:null})
    this.setState({selectedFileexistname10:null})
    this.setState({
      picvalidation:false,
      contactvalidation:false,
      podtextvalidation:false,
      poltextvalidation:false,
      bookingnovalidation:false,
      vsletavalidation:false,
      closingvalidation:false,
      volumenumber1validation:false,
      volumenumber2validation:false,
      volumenumber3validation:false,
      volumenumber4validation:false,
      volumenumber5validation:false,
      volumenumber6validation:false,
      volumenumber7validation:false,
      volumenumber8validation:false,
      volumenumber9validation:false,
      volumenumber10validation:false,
      containervalidation:false,
      requestdatevalidation:false,
      vesselnamevalidation:false,
      vesselvoyagevalidation:false,
      commodityvalidation:false,
      errorsubmit:false,
      attachmentquantity:0,
      rowvolume1:false,
    rowvolume2:false,
    rowvolume3:false,
    rowvolume4:false,
    rowvolume5:false,
    rowvolume6:false,
    rowvolume7:false,
    rowvolume8:false,
    })
    this.getoperatorlistapi();
    this.getpickupyardreturnyardlist();
    this.getforwardingagentlist();
    this.getdetailquotationtableapi();
    this.getdetailofeditrot(rotnumber);
    this.getattachmentlistofeditrot(rotnumber);
   
    //this.editpendingorderfunction(rotnumber);
  }

  getdetailofeditrot = (rotnumber) =>{

    //this.setState({loadingmodaleditorder:true})
    console.log(this.state.Billtoparty)
    console.log("ROtnumber: " + rotnumber);
  
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("rotnumber",rotnumber);
    let link = this.state.linkurl +'mode=admin-getdetailroteditrot'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
          this.setState({
            cancelattachmentbutton1:false,
            cancelattachmentbutton2:false,
            cancelattachmentbutton3:false,
            cancelattachmentbutton4:false,
            cancelattachmentbutton5:false,
            cancelattachmentbutton6:false,
            cancelattachmentbutton7:false,
            cancelattachmentbutton8:false,
            cancelattachmentbutton9:false,
            cancelattachmentbutton10:false,
          })
            console.log(json)
            var dateStringeta = moment.unix(json.user[0].Vsleta-28800);
            var dateStringClosingday = moment.unix(json.user[0].Closingday-28800);
            var totalvolumetext = json.user[0].totalvolume
            var volumesplit = totalvolumetext.split(',')
            var firstvolumetemp = 0
            var firsttypetemp = ''
            var firstvolumetemparray = ''
            var secondvolumetemp = 0
            var secondtypetemp = ''
            var secondvolumetemparray = ''
            var thirdvolumetemp = 0
            var thirdtypetemp = ''
            var thirdvolumetemparray = ''
            var fourthvolumetemp = 0
            var fourthtypetemp = ''
            var fourthvolumetemparray = ''
            var fifthvolumetemp = 0
            var fifthtypetemp = ''
            var fifthvolumetemparray = ''
            var sixvolumetemp = 0
            var sixtypetemp = ''
            var sixvolumetemparray = ''
            var sevenvolumetemp = 0
            var seventypetemp = ''
            var sevenvolumetemparray = ''
            var eightvolumetemp = 0
            var eighttypetemp = ''
            var eightvolumetemparray = ''
            var ninevolumetemp = 0
            var ninetypetemp = ''
            var ninevolumetemparray = ''
            var tenvolumetemp = 0
            var tentypetemp = ''
            var tenvolumetemparray = ''

            if(volumesplit[0] != null){
              firstvolumetemparray = volumesplit[0].split('x ')
              firstvolumetemp = firstvolumetemparray[0]
              firsttypetemp = firstvolumetemparray[1]
            }
            if(volumesplit[1] != null){
              secondvolumetemparray = volumesplit[1].split('x ')
              secondvolumetemp = secondvolumetemparray[0]
              secondtypetemp = secondvolumetemparray[1]
            }

            if(volumesplit[2] != null){
              this.addvolumebuttonclicked()
              thirdvolumetemparray = volumesplit[2].split('x ')
              thirdvolumetemp = thirdvolumetemparray[0]
              thirdtypetemp = thirdvolumetemparray[1]
            }

            if(volumesplit[3] != null){
              this.addvolumebuttonclicked()
              fourthvolumetemparray = volumesplit[3].split('x ')
              fourthvolumetemp = fourthvolumetemparray[0]
              fourthtypetemp = fourthvolumetemparray[1]
            }

            if(volumesplit[4] != null){
              this.addvolumebuttonclicked()
              fifthvolumetemparray = volumesplit[4].split('x ')
              fifthvolumetemp = fifthvolumetemparray[0]
              fifthtypetemp = fifthvolumetemparray[1]
            }
            if(volumesplit[5] != null){
              this.addvolumebuttonclicked()
              sixvolumetemparray = volumesplit[5].split('x ')
              sixvolumetemp = sixvolumetemparray[0]
              sixtypetemp = sixvolumetemparray[1]
            }
            if(volumesplit[6] != null){
              this.addvolumebuttonclicked()
              sevenvolumetemparray = volumesplit[6].split('x ')
              sevenvolumetemp = sevenvolumetemparray[0]
              seventypetemp = sevenvolumetemparray[1]
            }
            if(volumesplit[7] != null){
              this.addvolumebuttonclicked()
              eightvolumetemparray = volumesplit[7].split('x ')
              eightvolumetemp = eightvolumetemparray[0]
              eighttypetemp = eightvolumetemparray[1]
            }
            if(volumesplit[8] != null){
              this.addvolumebuttonclicked()
              ninevolumetemparray = volumesplit[8].split('x ')
              ninevolumetemp = ninevolumetemparray[0]
              ninetypetemp = ninevolumetemparray[1]
            }
            if(volumesplit[9] != null){
              this.addvolumebuttonclicked()
              tenvolumetemparray = volumesplit[9].split('x ')
              tenvolumetemp = tenvolumetemparray[0]
              tentypetemp = tenvolumetemparray[1]
            }
            console.log('firstvolumetemp: ' +firstvolumetemp)
            console.log('firsttypetemp: ' + firsttypetemp)
            this.setState({
              areadropdowntext:json.user[0].Shipper,
              deliveryaddress:json.user[0].Deliveryaddress,
              areatextbox:json.user[0].Area,
              picvalue:json.user[0].Contactperson,
              contactvalue:json.user[0].Contactnumber,
              shipmenttypeadropdowntext:json.user[0].Shipmenttype,
              poltext:json.user[0].POL,
              podtext:json.user[0].POD,
              bookingno:json.user[0].Bookingno,
              operatoreadropdowntext:json.user[0].Operator,
              pickupdropdowntext:json.user[0].Pickupyard,
              returnyarddropdowntext:json.user[0].returnyard,
              Vsleta:dateStringeta,
              closingday:dateStringClosingday,
              vesselname:json.user[0].Vesselname,
              vesselvoyage:json.user[0].Vesselvoyage,
              commodity:json.user[0].Commodity,
              forwardingagentdropdowntext:json.user[0].Forwardingagent,
              remark:json.user[0].Remark,
              volumenumberinput: firstvolumetemp,
              type1dropdowntext: firsttypetemp,
              volumenumberinput1: secondvolumetemp,
              type1dropdowntext1: secondtypetemp,
              volumenumberinput2: thirdvolumetemp,
              type1dropdowntext2: thirdtypetemp,
              volumenumberinput3: fourthvolumetemp,
              type1dropdowntext3: fourthtypetemp,
              volumenumberinput4: fifthvolumetemp,
              type1dropdowntext4: fifthtypetemp,
              volumenumberinput5: sixvolumetemp,
              type1dropdowntext5: sixtypetemp,
              volumenumberinput6: sevenvolumetemp,
              type1dropdowntext6: seventypetemp,
              volumenumberinput7: eightvolumetemp,
              type1dropdowntext7: eighttypetemp,
              volumenumberinput8: ninevolumetemp,
              type1dropdowntext8: ninetypetemp,
              volumenumberinput9: tenvolumetemp,
              type1dropdowntext9: tentypetemp,

            })

             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              this.state['consignmentno' + i] = json.user[i].Rotnumber + '-' + (i+1)            
            }
             for(let i=0;i<lengthemployee;i++){
               for(let j=0;j<lengthemployee;j++){
                if(json.user[i].Consignmentno == this.state['consignmentno' + j]){
                  this.state['container'+j] = json.user[i].Containerno
                  this.state['Seal'+j] = json.user[i].Sealno
                  if(json.user[i].sideloader == 1){
                    this.state['checksideloader'+j] = true
                  }else{
                    this.state['checksideloader'+j] = false
                  }

                 
                  if(json.user[i].Requestdate-28800 == 946684800-28800){
                  this.state['requestdate'+j] = 'TBC'
                  this.state['requestdatedisable'+j] = true
                  this.state['checktbe' + j] = true
                  }else{
                  this.state['requestdate'+j] = moment.unix(json.user[i].Requestdate-28800)
                  this.state['requestdatedisable'+j] = false
                  this.state['checktbe' + j] = false
                  }
               }
               }
           
              }
             // this.getattachmentlistofeditrot(rotnumber);
             // this.setState({loadingmodaleditorder:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  getattachmentlistofeditrot = (rotnumber) =>{
    console.log(this.state.Billtoparty)
    console.log("ROtnumber: " + rotnumber);
  
    var urlencoded = new URLSearchParams();
    urlencoded.append("rotnumber",rotnumber);
    let link = this.state.linkurl +'mode=admin-getattachmentlistofeditrot'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){      
         console.log("attachment1: " + json.user[0].attachmenturl1)
         console.log("attachment2: " + json.user[0].attachmenturl2)
         console.log("attachment3: " + json.user[0].attachmenturl3)
      if(json.user[0].attachmenturl1 != ''){
        var splitext = json.user[0].attachmenturl1.split("/")
        console.log(splitext[0])
        console.log(splitext[1])
        console.log(splitext[2])
        console.log(splitext[3])
        console.log(splitext[4])
        console.log(splitext[5])
        console.log(splitext[6])        
           this.state.selectedFileexistname1 = splitext[6]
           this.state.selectedFileexistname1full = json.user[0].attachmenturl1
      }
      if(json.user[0].attachmenturl2 != ''){
        var splitext = json.user[0].attachmenturl2.split("/")
           this.state.selectedFileexistname2 = splitext[6]
           this.state.selectedFileexistname2full = json.user[0].attachmenturl2
      }
      if(json.user[0].attachmenturl3 != ''){
        var splitext = json.user[0].attachmenturl3.split("/")
        this.state.selectedFileexistname3 = splitext[6]
        this.state.selectedFileexistname3full = json.user[0].attachmenturl3
      }
      if(json.user[0].attachmenturl4 != ''){
        var splitext = json.user[0].attachmenturl4.split("/")
           this.state.selectedFileexistname4 = splitext[6]
           this.state.selectedFileexistname4full = json.user[0].attachmenturl4
      }
      if(json.user[0].attachmenturl5 != ''){
        var splitext = json.user[0].attachmenturl5.split("/")
           this.state.selectedFileexistname5 = splitext[6]
           this.state.selectedFileexistname5full = json.user[0].attachmenturl5
      }
      if(json.user[0].attachmenturl6 != ''){
        var splitext = json.user[0].attachmenturl6.split("/")
           this.state.selectedFileexistname6 = splitext[6]
           this.state.selectedFileexistname6full = json.user[0].attachmenturl6
      }
      if(json.user[0].attachmenturl7 != ''){
        var splitext = json.user[0].attachmenturl7.split("/")
           this.state.selectedFileexistname7 = splitext[6]
           this.state.selectedFileexistname7full = json.user[0].attachmenturl7
      }
      if(json.user[0].attachmenturl8 != ''){
        var splitext = json.user[0].attachmenturl8.split("/")
           this.state.selectedFileexistname8 = splitext[6]
           this.state.selectedFileexistname8full = json.user[0].attachmenturl8
      }
      if(json.user[0].attachmenturl9 != ''){
        var splitext = json.user[0].attachmenturl9.split("/")
        this.state.selectedFileexistname9 = splitext[6]
        this.state.selectedFileexistname9full = json.user[0].attachmenturl9
      }
      if(json.user[0].attachmenturl10 != ''){
        var splitext = json.user[0].attachmenturl10.split("/")
           this.state.selectedFileexistname10 = splitext[6]
           this.state.selectedFileexistname10full = json.user[0].attachmenturl10
      }


           
            
      }
      else{
          //this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  getattachmentno = () =>{
    
  }

  getprocesslistcall = (consignmentno,status) =>{
     
    console.log("consignmentno: " + consignmentno);
    this.setState({loadingprocesslist:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("consignmentno",consignmentno);
    urlencoded.append("status",status);
    let link = this.state.linkurl +'mode=admin-getprocesslist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      //console.log(json.success)
      console.log(json)
      if(json.success == 1){      
        this.setState({processlist:[]});   
        var lengthemployee = Object.keys(json.user).length;
        var timestampshowword = ""
        var timestampshowword2 = ""
        this.setState({processtotal:Object.keys(json.user).length})
        for(let i=0;i<lengthemployee;i++){

          if(json.user[i].activitydatecreatenewtime == 0 || json.user[i].activitydatecreatenewtime == 946684800 ){
            timestampshowword = "----/-----/-----"
            timestampshowword2 = "--:--:--"
          }else{
            timestampshowword = moment(json.user[i].activitydatecreatenewtime*1000).format('MM/DD/YYYY')
            timestampshowword2 = moment(json.user[i].activitydatecreatenewtimetime*1000).format('HH:mm:ss')
          }

          this.setState({processlist:[...this.state.processlist,
           { 
            no:i+1,
            activitydatecreatenewtime:timestampshowword,
            activitydatecreatenewtimetime:timestampshowword2,
            orderstatusdescription:json.user[i].orderstatusdescription,  
            deliverymethod: json.user[i].deliverymethod, 
           },            
         ]}) 
         }

        this.setState({loadingprocesslist:false})
           
            
      }
      else{
        this.setState({processlist:[]})
        this.setState({loadingprocesslist:false})
       // console.log(json)
      }
    
     })
  }

  
  renderprocesslist(){
    return this.state.processlist.map((data, index) => {
      const {no,activitydatecreatenewtime,orderstatusdescription,deliverymethod,activitydatecreatenewtimetime} = data //destructuring
      var rotlength = Object.keys(this.state.processlist).length;
      var deliveryword = ""
      var color = "success"
      var color1text ="gray"
      if(activitydatecreatenewtime =="----/-----/-----"){
        color1text ="gray"
      }else{
        color1text ="dark"
      }
      if(activitydatecreatenewtime =="----/-----/-----"){
        if(deliverymethod == "0"){
          deliveryword = "Truck In"
        }else if(deliverymethod == "1"){
          deliveryword = "Truck Out"
        }else if(deliverymethod == "2"){
          deliveryword = "Staging In"
        }else if(deliverymethod == "3"){
          deliveryword = "Staging Out"
        }
        color="dark"
      }else{
        if(deliverymethod == "0"){
          deliveryword = "Truck In"
          color = "warning"
        }else if(deliverymethod == "1"){
          deliveryword = "Truck Out"
          color = "primary"
        }else if(deliverymethod == "2"){
          deliveryword = "Staging In"
          color = "danger"
        }else if(deliverymethod == "3"){
          deliveryword = "Staging Out"
          color = "success"
        }

        if(orderstatusdescription == "Order Created"){
          color = "warning"
        }
      }
     
      if(this.state.loadingprocesslist == true){
        return(
          <Row >
                <Col md="12" >
              <img 
              className="img-center mt-5"
              width="120"
              height="120"
              alt="..."
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
            </Col>
              </Row>
        )
      }else{
        return (
          <>            

          <Row>
            <div>
            <h5 style={{color:color1text}}>{activitydatecreatenewtime}</h5>
            <span style={{color:color1text,fontSize:12,marginTop:-10}}>{activitydatecreatenewtimetime}</span>
            </div>
              <div>
              <Button style={{alignSelf: 'stretch',height:20,borderRadius:20}} color={color} disabled size="lg" type="button">           
                  </Button>  
                  {no == this.state.processtotal ?
              null
            :             
            <h5 style={{color:color1text,textAlign:'center',fontSize:20}}>|</h5>
            
            }
              </div>
                  
                  <div>
                  <h4 style={{color:color1text}}>{deliveryword}  &nbsp;&nbsp;</h4>
                  <h5 style={{color:color1text}}>{orderstatusdescription}  &nbsp;&nbsp;</h5>
                 
                  </div>
              </Row>
             
             
                          </>
        )
      }
     
    })
  }

  toggleModalwithfunctiondetailprocesspopout = (rotnumber,openclosetruefalse,consignmentno,status,containerno,requiredate,closingdate,type,activitydatecreatenewtimetoshow,activitystagingindatetoshow,activtiytruckindatetoshow,activitystagingoutdatetoshow,activitytruckoutdatetoshow,activitytransferbilldatetoshow) =>{
    this.setState({detailprocesspopout:openclosetruefalse})
    this.setState({
      statusprocesstab:status,
      containernoprocesstab:containerno,
      requiredateprocesstab:requiredate,
      closingdateprocesstab:closingdate,
      typeprocesstab:type,
      consignmentnoprocesstab:consignmentno,
      activitydatecreatenewtime:activitydatecreatenewtimetoshow,
      activitydatestagingin:activitystagingindatetoshow,
      activitydatetruckin:activtiytruckindatetoshow,
    activtiydatestagingout:activitystagingoutdatetoshow,
    activtiydatetruckout:activitytruckoutdatetoshow,
    activtiydatecompleted:activitytransferbilldatetoshow,
    })

    if(status =='Order Created'){
      this.setState({
        color1:'warning',
        color2:'dark',
        color3:'dark',
        color4:'dark',
        color5:'dark',
        color6:'dark',
        color1text:'black',
        color2text:'gray',
        color3text:'gray',
        color4text:'gray',
        color5text:'gray',
        color6text:'gray',
      })
    }else if(status =='Staging In'){
      this.setState({
        color1:'warning',
        color2:'yellow',
        color3:'dark',
        color4:'dark',
        color5:'dark',
        color6:'dark',
        color1text:'black',
        color2text:'black',
        color3text:'gray',
        color4text:'gray',
        color5text:'gray',
        color6text:'gray',
      })
    }else if(status =='Trucked In'){
      this.setState({
        color1:'warning',
        color2:'yellow',
        color3:'success',
        color4:'dark',
        color5:'dark',
        color6:'dark',
        color1text:'black',
        color2text:'black',
        color3text:'black',
        color4text:'gray',
        color5text:'gray',
        color6text:'gray',
      })
    }else if(status =='Staging Out'){
      this.setState({
        color1:'warning',
        color2:'yellow',
        color3:'success',
        color4:'success',
        color5:'dark',
        color6:'dark',
        color1text:'black',
        color2text:'black',
        color3text:'black',
        color4text:'black',
        color5text:'gray',
        color6text:'gray',
      })
    }else if(status =='Trucked Out'){
      this.setState({
        color1:'warning',
        color2:'yellow',
        color3:'success',
        color4:'success',
        color5:'success',
        color6:'dark',
        color1text:'black',
        color2text:'black',
        color3text:'black',
        color4text:'black',
        color5text:'black',
        color6text:'gray',
      })
    }else if(status =='Completed'){
      this.setState({
        color1:'warning',
        color2:'yellow',
        color3:'success',
        color4:'success',
        color5:'success',
        color6:'success',
        color1text:'black',
        color2text:'black',
        color3text:'black',
        color4text:'black',
        color5text:'black',
        color6text:'black',
      })
    }
    //function call
    this.getprocesslistcall(consignmentno,status);
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
 
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  toggledropdownarea =  state => {
    this.setState({
      [state]:!this.state[state]
    });
   
  };

  handleChange=(event,stateid)=> {
    if(stateid === "picvalue"){
      this.setState({picvalue: event});
    } else if(stateid == "contactvalue"){
      this.setState({contactvalue: event});
    }else if(stateid == "poltext"){
      this.setState({poltext: event});
    }else if(stateid == "podtext"){
      this.setState({podtext: event});
    }else if(stateid == "bookingno"){
      this.setState({bookingno: event});
    }else if(stateid == "volumenumberinput"){
      this.setState({volumenumberinput: event});
      
    }else if(stateid == "volumenumberinput1"){
      this.setState({volumenumberinput1: event});
      console.log("volumenumberinput1: "+ this.state.volumenumberinput1)
    }else if(stateid == "volumenumberinput2"){
      this.setState({volumenumberinput2: event});
      console.log("volumenumber2value2: "+ this.state.volumenumberinput2)
    }else if(stateid == "volumenumberinput3"){
      this.setState({volumenumberinput3: event});
    }else if(stateid == "volumenumberinput4"){
      this.setState({volumenumberinput4: event});
    }else if(stateid == "volumenumberinput5"){
      this.setState({volumenumberinput5: event});
    }else if(stateid == "volumenumberinput6"){
      this.setState({volumenumberinput6: event});
    }else if(stateid == "volumenumberinput7"){
      this.setState({volumenumberinput7: event});
    }else if(stateid == "volumenumberinput8"){
      this.setState({volumenumberinput8: event});
    }else if(stateid == "volumenumberinput9"){
      this.setState({volumenumberinput9: event});
    }
    
    
  }

  setdatedefaultincomplete = () =>{

    var timestampnow = new Date().getTime()
    var militimestampnow = timestampnow 
    var last30daytimestamp = militimestampnow - (86400000*30)
    this.setState({startDate:last30daytimestamp})
    this.setState({endDate:militimestampnow})
  }

  refreshallthing =(numberrow) =>{

    for(let i=0;i<numberrow;i++){
          
        this.setState({["container" + i] :''})
        this.setState({["requestdatedisable"+i] :false})
        this.setState({["requestdate" + i] :new Date().getTime()})
        
     
        this.setState({["Seal" +i] :''})
       
      
        this.setState({["checktbe"+i] :false})
       
      console.log("numberrow: "+numberrow);
      console.log("container: "+this.state["container" + i]);
      console.log("requestdate: "+this.state["requestdate" + i]);
      console.log("checktbe: "+this.state["checktbe" + i]);
    }

    
    this.setState({tabs: 1,
    exampleModal: false,
    editpopuout:false,
    isopendropdownshipper:false,
    isopendropdownshipmenttype:false,
    isopendropdownpod:false,
    isopendropdownpol:false,
    shipmenttypeadropdowntext:'',
    isopendropdownoperator:false,
    operatoreadropdowntext:'',
    isopendropdownpickup:false,
    isopendropdownreturnyard:false,
    pickupdropdowntext:'',
    returnyarddropdowntext:'',
    type2dropdowntext:'',
    isopendropdownforwardingagent:false,
    forwardingagentdropdowntext:'',
  
    Billtoparty:sessionStorage.getItem('Billtoparty'),
    deliveryaddress:'',
    areatextbox:'',
    poltext:'',
    podtext:'',
    disableddropdownpol:true,
    disableddropdownpod:true,
    volumenumberinput:0,
    volumenumberinput1:0,
    volumenumberinput2:0,
    volumenumberinput3:0,
    volumenumberinput4:0,
    volumenumberinput5:0,
    volumenumberinput6:0,
    volumenumberinput7:0,
    volumenumberinput8:0,
    volumenumberinput9:0,
    type1dropdowntext:'',
    type1dropdowntext1:'',
    type1dropdowntext2:'',
    type1dropdowntext3:'',
    type1dropdowntext4:'',
    type1dropdowntext5:'',
    type1dropdowntext6:'',
    type1dropdowntext7:'',
    type1dropdowntext8:'',
    type1dropdowntext9:'',
    isopendropdowntype:false,
    isopendropdowntype1:false,
    isopendropdowntype2:false,
    isopendropdowntype3:false,
    isopendropdowntype4:false,
    isopendropdowntype5:false,
    isopendropdowntype6:false,
    isopendropdowntype7:false,
    isopendropdowntype8:false,
    isopendropdowntype9:false,
    volumeshowrow:0,
    rowvolume1:false,
    rowvolume2:false,
    rowvolume3:false,
    rowvolume4:false,
    rowvolume5:false,
    rowvolume6:false,
    rowvolume7:false,
    rowvolume8:false,
    minusbuttonshow:false,
    picvalue:'',
    contactvalue:'',
    bookingno:'',
    Vsleta:'',
    closingday:'',
    submitbuttonclick:0,
    picvalidation:false,
    contactvalidation:false,
    podtextvalidation:false,
    poltextvalidation:false,
    bookingnovalidation:false,
    vsletavalidation:false,
    closingvalidation:false,
    volumenumber1validation:false,
    volumenumber2validation:false,
    volumenumber3validation:false,
    volumenumber4validation:false,
    volumenumber5validation:false,
    volumenumber6validation:false,
    volumenumber7validation:false,
    volumenumber8validation:false,
    volumenumber9validation:false,
    volumenumber10validation:false,
    containervalidation:false,
    requestdatevalidation:false,
    vesselnamevalidation:false,
    vesselvoyagevalidation:false,
    commodityvalidation:false,
    areadropdowntext:'',
    commodity:'',
    vesselname:'',
    vesselvoyage:'',
    errorsubmit:false,
    aftersubmitstatus:0,
  })
  }
  
  submitnewrotforpending =(rotnumber) =>{ 
    if(window.confirm("Ready to submit this Rot?")){
    let errorornot = false;
    
    let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
    Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
    Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
    Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
    Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 
  
    console.log("File:" + this.state.attachmentlist)


      for(let i=0;i<numberrow;i++){
        console.log("Requestdate: " + this.state["requestdate"+i])
        console.log("requestdatedisable: " + this.state["requestdatedisable"+i])
        console.log("checktbe: " + this.state["checktbe"+i])
       
      }

    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }else{
      this.setState({volumenumber1validation:false});     
    }

    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.volumenumberinput1 == 0 && this.state.type1dropdowntext1 != ''){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }else{
      this.setState({volumenumber2validation:false});
    }

    if(this.state.type1dropdowntext == '' && this.state.volumenumberinput1 != 0){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }
    if(this.state.rowvolume1==true){
      if(this.state.volumenumberinput == 0){
        this.setState({volumenumber1validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput1 == 0){
        this.setState({volumenumber2validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput2 == 0){
        this.setState({volumenumber3validation:true});
        errorornot=true;
      }

      if(this.state.type1dropdowntext == ''){
        this.setState({type1validation:true});
        this.setState({type1validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type1validation:false});
        this.setState({type1validationmessage:""});
      }
  
      if(this.state.type1dropdowntext1 == ''){
        this.setState({type2validation:true});
        this.setState({type2validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type2validation:false});
        this.setState({type2validationmessage:""});
      }

      if(this.state.type1dropdowntext2 == ''){
        this.setState({type3validation:true});
        this.setState({type3validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type3validation:false});
        this.setState({type3validationmessage:""});
      }
    }

    if(this.state.rowvolume2==true){
      if(this.state.volumenumberinput == 0){
        this.setState({volumenumber1validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput1 == 0){
        this.setState({volumenumber2validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput2 == 0){
        this.setState({volumenumber3validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput3 == 0){
        this.setState({volumenumber4validation:true});
        errorornot=true;
      }
      if(this.state.type1dropdowntext == ''){
        this.setState({type1validation:true});
        this.setState({type1validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type1validation:false});
        this.setState({type1validationmessage:""});
      }
  
      if(this.state.type1dropdowntext1 == ''){
        this.setState({type2validation:true});
        this.setState({type2validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type2validation:false});
        this.setState({type2validationmessage:""});
      }

      if(this.state.type1dropdowntext2 == ''){
        this.setState({type3validation:true});
        this.setState({type3validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type3validation:false});
        this.setState({type3validationmessage:""});
      }

      if(this.state.type1dropdowntext3 == ''){
        this.setState({type4validation:true});
        this.setState({type4validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type4validation:false});
        this.setState({type4validationmessage:""});
      }
    }

    if(this.state.rowvolume3==true){
      if(this.state.volumenumberinput == 0){
        this.setState({volumenumber1validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput1 == 0){
        this.setState({volumenumber2validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput2 == 0){
        this.setState({volumenumber3validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput3 == 0){
        this.setState({volumenumber4validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput4 == 0){
        this.setState({volumenumber5validation:true});
        errorornot=true;
      }



      if(this.state.type1dropdowntext == ''){
        this.setState({type1validation:true});
        this.setState({type1validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type1validation:false});
        this.setState({type1validationmessage:""});
      }
  
      if(this.state.type1dropdowntext1 == ''){
        this.setState({type2validation:true});
        this.setState({type2validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type2validation:false});
        this.setState({type2validationmessage:""});
      }

      if(this.state.type1dropdowntext2 == ''){
        this.setState({type3validation:true});
        this.setState({type3validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type3validation:false});
        this.setState({type3validationmessage:""});
      }

      if(this.state.type1dropdowntext3 == ''){
        this.setState({type4validation:true});
        this.setState({type4validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type4validation:false});
        this.setState({type4validationmessage:""});
      }
      if(this.state.type1dropdowntext4 == ''){
        this.setState({type5validation:true});
        this.setState({type5validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type5validation:false});
        this.setState({type5validationmessage:""});
      }
    }

    if(this.state.rowvolume4==true){
      if(this.state.volumenumberinput == 0){
        this.setState({volumenumber1validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput1 == 0){
        this.setState({volumenumber2validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput2 == 0){
        this.setState({volumenumber3validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput3 == 0){
        this.setState({volumenumber4validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput4 == 0){
        this.setState({volumenumber5validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput5 == 0){
        this.setState({volumenumber6validation:true});
        errorornot=true;
      }



      if(this.state.type1dropdowntext == ''){
        this.setState({type1validation:true});
        this.setState({type1validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type1validation:false});
        this.setState({type1validationmessage:""});
      }
  
      if(this.state.type1dropdowntext1 == ''){
        this.setState({type2validation:true});
        this.setState({type2validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type2validation:false});
        this.setState({type2validationmessage:""});
      }

      if(this.state.type1dropdowntext2 == ''){
        this.setState({type3validation:true});
        this.setState({type3validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type3validation:false});
        this.setState({type3validationmessage:""});
      }

      if(this.state.type1dropdowntext3 == ''){
        this.setState({type4validation:true});
        this.setState({type4validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type4validation:false});
        this.setState({type4validationmessage:""});
      }
      if(this.state.type1dropdowntext4 == ''){
        this.setState({type5validation:true});
        this.setState({type5validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type5validation:false});
        this.setState({type5validationmessage:""});
      }
      if(this.state.type1dropdowntext5 == ''){
        this.setState({type6validation:true});
        this.setState({type6validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type6validation:false});
        this.setState({type6validationmessage:""});
      }
    }

    if(this.state.rowvolume5==true){
      if(this.state.volumenumberinput == 0){
        this.setState({volumenumber1validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput1 == 0){
        this.setState({volumenumber2validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput2 == 0){
        this.setState({volumenumber3validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput3 == 0){
        this.setState({volumenumber4validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput4 == 0){
        this.setState({volumenumber5validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput5 == 0){
        this.setState({volumenumber6validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput6 == 0){
        this.setState({volumenumber7validation:true});
        errorornot=true;
      }

      if(this.state.type1dropdowntext == ''){
        this.setState({type1validation:true});
        this.setState({type1validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type1validation:false});
        this.setState({type1validationmessage:""});
      }
  
      if(this.state.type1dropdowntext1 == ''){
        this.setState({type2validation:true});
        this.setState({type2validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type2validation:false});
        this.setState({type2validationmessage:""});
      }

      if(this.state.type1dropdowntext2 == ''){
        this.setState({type3validation:true});
        this.setState({type3validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type3validation:false});
        this.setState({type3validationmessage:""});
      }

      if(this.state.type1dropdowntext3 == ''){
        this.setState({type4validation:true});
        this.setState({type4validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type4validation:false});
        this.setState({type4validationmessage:""});
      }
      if(this.state.type1dropdowntext4 == ''){
        this.setState({type5validation:true});
        this.setState({type5validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type5validation:false});
        this.setState({type5validationmessage:""});
      }
      if(this.state.type1dropdowntext5 == ''){
        this.setState({type6validation:true});
        this.setState({type6validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type6validation:false});
        this.setState({type6validationmessage:""});
      }
      if(this.state.type1dropdowntext6 == ''){
        this.setState({type7validation:true});
        this.setState({type7validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type7validation:false});
        this.setState({type7validationmessage:""});
      }
    }

    if(this.state.rowvolume6==true){
      if(this.state.volumenumberinput == 0){
        this.setState({volumenumber1validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput1 == 0){
        this.setState({volumenumber2validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput2 == 0){
        this.setState({volumenumber3validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput3 == 0){
        this.setState({volumenumber4validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput4 == 0){
        this.setState({volumenumber5validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput5 == 0){
        this.setState({volumenumber6validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput6 == 0){
        this.setState({volumenumber7validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput7 == 0){
        this.setState({volumenumber8validation:true});
        errorornot=true;
      }


      if(this.state.type1dropdowntext == ''){
        this.setState({type1validation:true});
        this.setState({type1validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type1validation:false});
        this.setState({type1validationmessage:""});
        
      }
  
      if(this.state.type1dropdowntext1 == ''){
        this.setState({type2validation:true});
        this.setState({type2validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type2validation:false});
        this.setState({type2validationmessage:""});
      }

      if(this.state.type1dropdowntext2 == ''){
        this.setState({type3validation:true});
        this.setState({type3validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type3validation:false});
        this.setState({type3validationmessage:""});
      }

      if(this.state.type1dropdowntext3 == ''){
        this.setState({type4validation:true});
        this.setState({type4validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type4validation:false});
        this.setState({type4validationmessage:""});
      }
      if(this.state.type1dropdowntext4 == ''){
        this.setState({type5validation:true});
        this.setState({type5validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type5validation:false});
        this.setState({type5validationmessage:""});
      }
      if(this.state.type1dropdowntext5 == ''){
        this.setState({type6validation:true});
        this.setState({type6validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type6validation:false});
        this.setState({type6validationmessage:""});
      }
      if(this.state.type1dropdowntext6 == ''){
        this.setState({type7validation:true});
        this.setState({type7validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type7validation:false});
        this.setState({type7validationmessage:""});
      }
      if(this.state.type1dropdowntext7 == ''){
        this.setState({type8validation:true});
        this.setState({type8validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type8validation:false});
        this.setState({type8validationmessage:""});
      }
    }

    if(this.state.rowvolume7==true){
      if(this.state.volumenumberinput == 0){
        this.setState({volumenumber1validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput1 == 0){
        this.setState({volumenumber2validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput2 == 0){
        this.setState({volumenumber3validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput3 == 0){
        this.setState({volumenumber4validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput4 == 0){
        this.setState({volumenumber5validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput5 == 0){
        this.setState({volumenumber6validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput6 == 0){
        this.setState({volumenumber7validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput7 == 0){
        this.setState({volumenumber8validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput8 == 0){
        this.setState({volumenumber9validation:true});
        errorornot=true;
      }


      
      if(this.state.type1dropdowntext == ''){
        this.setState({type1validation:true});
        this.setState({type1validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type1validation:false});
        this.setState({type1validationmessage:""});
      }
  
      if(this.state.type1dropdowntext1 == ''){
        this.setState({type2validation:true});
        this.setState({type2validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type2validation:false});
        this.setState({type2validationmessage:""});
      }

      if(this.state.type1dropdowntext2 == ''){
        this.setState({type3validation:true});
        this.setState({type3validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type3validation:false});
        this.setState({type3validationmessage:""});
      }

      if(this.state.type1dropdowntext3 == ''){
        this.setState({type4validation:true});
        this.setState({type4validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type4validation:false});
        this.setState({type4validationmessage:""});
      }
      if(this.state.type1dropdowntext4 == ''){
        this.setState({type5validation:true});
        this.setState({type5validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type5validation:false});
        this.setState({type5validationmessage:""});
      }
      if(this.state.type1dropdowntext5 == ''){
        this.setState({type6validation:true});
        this.setState({type6validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type6validation:false});
        this.setState({type6validationmessage:""});
      }
      if(this.state.type1dropdowntext6 == ''){
        this.setState({type7validation:true});
        this.setState({type7validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type7validation:false});
        this.setState({type7validationmessage:""});
      }
      if(this.state.type1dropdowntext7 == ''){
        this.setState({type8validation:true});
        this.setState({type8validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type8validation:false});
        this.setState({type8validationmessage:""});
      }
      if(this.state.type1dropdowntext8 == ''){
        this.setState({type9validation:true});
        this.setState({type9validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type9validation:false});
        this.setState({type9validationmessage:""});
      }
    }

    if(this.state.rowvolume8==true){
      if(this.state.volumenumberinput == 0){
        this.setState({volumenumber1validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput1 == 0){
        this.setState({volumenumber2validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput2 == 0){
        this.setState({volumenumber3validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput3 == 0){
        this.setState({volumenumber4validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput4 == 0){
        this.setState({volumenumber5validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput5 == 0){
        this.setState({volumenumber6validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput6 == 0){
        this.setState({volumenumber7validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput7 == 0){
        this.setState({volumenumber8validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput8 == 0){
        this.setState({volumenumber9validation:true});
        errorornot=true;
      }
      if(this.state.volumenumberinput9 == 0){
        this.setState({volumenumber10validation:true});
        errorornot=true;
      }



      if(this.state.type1dropdowntext == ''){
        this.setState({type1validation:true});
        this.setState({type1validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type1validation:false});
        this.setState({type1validationmessage:""});
      }
  
      if(this.state.type1dropdowntext1 == ''){
        this.setState({type2validation:true});
        this.setState({type2validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type2validation:false});
        this.setState({type2validationmessage:""});
      }

      if(this.state.type1dropdowntext2 == ''){
        this.setState({type3validation:true});
        this.setState({type3validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type3validation:false});
        this.setState({type3validationmessage:""});
      }

      if(this.state.type1dropdowntext3 == ''){
        this.setState({type4validation:true});
        this.setState({type4validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type4validation:false});
        this.setState({type4validationmessage:""});
      }
      if(this.state.type1dropdowntext4 == ''){
        this.setState({type5validation:true});
        this.setState({type5validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type5validation:false});
        this.setState({type5validationmessage:""});
      }
      if(this.state.type1dropdowntext5 == ''){
        this.setState({type6validation:true});
        this.setState({type6validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type6validation:false});
        this.setState({type6validationmessage:""});
      }
      if(this.state.type1dropdowntext6 == ''){
        this.setState({type7validation:true});
        this.setState({type7validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type7validation:false});
        this.setState({type7validationmessage:""});
      }
      if(this.state.type1dropdowntext7 == ''){
        this.setState({type8validation:true});
        this.setState({type8validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type8validation:false});
        this.setState({type8validationmessage:""});
      }
      if(this.state.type1dropdowntext8 == ''){
        this.setState({type9validation:true});
        this.setState({type9validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type9validation:false});
        this.setState({type9validationmessage:""});
      }
      if(this.state.type1dropdowntext9 == ''){
        this.setState({type10validation:true});
        this.setState({type10validationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({type10validation:false});
        this.setState({type10validationmessage:""});
      }
    }


    
  if(this.state.percentupload1 == 100 || this.state.percentupload1 == 0){     
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})      
  }

  if(this.state.percentupload2 == 100 || this.state.percentupload2 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload3 == 100 || this.state.percentupload3 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload4 == 100 || this.state.percentupload4 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload5 == 100 || this.state.percentupload5 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload6 == 100 || this.state.percentupload6 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload7 == 100 || this.state.percentupload7 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload8 == 100 || this.state.percentupload8 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload9 == 100 || this.state.percentupload9 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload10 == 100 || this.state.percentupload10 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }




    if(this.state.areadropdowntext ==''){
      this.setState({shippervalidation:true});
      this.setState({shippervalidationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({shippervalidation:false});
      this.setState({shippervalidationmessage:""});
    }

    if(this.state.operatoreadropdowntext ==''){
      this.setState({operationvalidation:true});
      this.setState({operationvalidationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({operationvalidation:false});
      this.setState({operationvalidationmessage:""});
    }

    if(this.state.pickupdropdowntext ==''){
      this.setState({pickupvalidation:true});
      this.setState({pickupvalidationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({pickupvalidation:false});
      this.setState({pickupvalidationmessage:""});
    }

    if(this.state.shipmenttypeadropdowntext =="Import"){
      if(this.state.returnyarddropdowntext ==''){
        this.setState({returnvalidation:true});
        this.setState({returnvalidationmessage:"Please fill in the detail"});
        errorornot=true;
      }else{
        this.setState({returnvalidation:false});
        this.setState({returnvalidationmessage:""});
      }
    }
  
    if(this.state.forwardingagentdropdowntext ==''){
      this.setState({forwardingvalidation:true});
      this.setState({forwardingvalidationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({forwardingvalidation:false});
      this.setState({forwardingvalidationmessage:""});
    }

    

    if(this.state.shipmenttypeadropdowntext ==''){
      this.setState({shipmenttypevalidation:true});
      this.setState({shipmenttypevalidationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({shipmenttypevalidation:false});
      this.setState({shipmenttypevalidationmessage:""});
    }

    if(this.state.podtext ==''){
      this.setState({podtextvalidation:true});
      this.setState({podtextvalidationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({podtextvalidation:false});
      this.setState({podtextvalidationmessage:""});
    }

    if(this.state.poltext ==''){
      this.setState({poltextvalidation:true});
      this.setState({poltextvalidationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({poltextvalidation:false});
      this.setState({poltextvalidationmessage:""});
    }

    if(this.state.poltext ==''){
      this.setState({poltextvalidation:true});
      this.setState({poltextvalidationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({poltextvalidation:false});
      this.setState({poltextvalidationmessage:""});
    }

    if(this.state.podtext==''){
      this.setState({podtextvalidation:true});
      errorornot=true;
    }else{
      this.setState({podtextvalidation:false});
    }

    if(this.state.poltext==''){
      this.setState({poltextvalidation:true});
      errorornot=true;
    }else{
      this.setState({poltextvalidation:false});
    }



    if(this.state.picvalue==''){
      this.setState({picvalidation:true});
      errorornot=true;
    }else{
      this.setState({picvalidation:false});
    }

    if(this.state.contactvalue==''){
      this.setState({contactvalidation:true});
      errorornot=true;
    }else{
      this.setState({contactvalidation:false});
    }
    
    if(this.state.bookingno==''){
      this.setState({bookingnovalidation:true});
      errorornot=true;
    }else{
      this.setState({bookingnovalidation:false});
    }
    
    if(this.state.Vsleta==''){
      this.setState({vsletavalidation:true});
      errorornot=true;
    }else{
      this.setState({vsletavalidation:false});
    }
    
    if(this.state.closingday==''){
      this.setState({closingvalidation:true});
      errorornot=true;
    }else{
      this.setState({closingvalidation:false});
    }
    
    if(this.state.vesselname==''){
      this.setState({vesselnamevalidation:true});
      errorornot=true;
    }else{
      this.setState({vesselnamevalidation:false});
    }
    
    if(this.state.vesselvoyage==''){
      this.setState({vesselvoyagevalidation:true});
      errorornot=true;
    }else{
      this.setState({vesselvoyagevalidation:false});
    }
    
    
    

    if(errorornot == true){
      this.setState({alerterrormessage:'Something wrong, Please fill in all details'})
    }

    if(this.state.shipmenttypeadropdowntext=="Import"){
      //CONTAINER NUMBER MUST FILL
     
      for(let i=0;i<numberrow;i++){
        console.log("container:" + this.state["container" + i])
        if(this.state["container" + i] =='' || this.state["container" + i] == null ){
          errorornot = true
          this.setState({alerterrormessage:'Please fill in Container No'})
        }
        if(this.state["requestdate" + i] == ''|| this.state["requestdate" + i] == null){
          errorornot = true
          this.setState({alerterrormessage:'Request Date must fill in'})
          this.setState({requestdateerrorinvalid:true});
        }   
        if(this.state["requestdate" + i] < this.state.Vsleta){
          errorornot = true
          this.setState({alerterrormessage:'Request Date cannot before ETA Date'})
        } 
      }
    }else if(this.state.shipmenttypeadropdowntext=="Export"){
      //REQUEST DATE CANNOT AFTER CLOSING DATE, if(requestdate > closingday)false
      for(let i=0;i<numberrow;i++){
        if(this.state["requestdate" + i] > this.state.closingday){
          errorornot = true
          this.setState({alerterrormessage:'Request Date cannot be after Closing Date'})
        }  
       
        if(this.state["requestdate" + i] == ''|| this.state["requestdate" + i] == null){
          errorornot = true
          this.setState({alerterrormessage:'Request Date must fill in'})
          this.setState({requestdateerrorinvalid:true});
        } 
        if(this.state["requestdate" + i] > this.state.Vsleta){
          errorornot = true
          this.setState({alerterrormessage:'Request Date must after ETA Date'})
        } 
      }
    }

    

    if( errorornot==true){
      window.scrollTo(0, 0)
      this.setState({errorsubmit:true})
    }else{
    console.log("clicked")
    this.setState({aftersubmitstatus:3})
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("shipper",this.state.areadropdowntext );
    urlencoded.append("deliveryaddress", this.state.deliveryaddress);
    urlencoded.append("areatextbox", this.state.areatextbox);
    urlencoded.append("picvalue", this.state.picvalue);
    urlencoded.append("contact", this.state.contactvalue);


    for(let i=0;i<10;i++){
      console.log("Filename"+(i+1),this.state.attachmentlist[i])
      if(this.state.attachmentlist[i] != null){
        urlencoded.append("Filename"+(i+1), this.state.attachmentlist[i]);
        console.log("inumber:"+i);
      }else{
        urlencoded.append("Filename"+(i+1), "");
      }     
    }

    
   


    //shipment type problem
    urlencoded.append("shipmenttypedropdowntext", this.state.shipmenttypeadropdowntext);
    urlencoded.append("poltext", this.state.poltext);
    urlencoded.append("podtext", this.state.podtext);
    urlencoded.append("bookingno", this.state.bookingno);

    var vsletapassup = new Date(this.state.Vsleta).getTime()/1000;
    var vsldate = new Date(this.state.Vsleta).getDate();
    var vsldatemonth = new Date(this.state.Vsleta).getMonth() + 1;
    var vslfullyear = new Date(this.state.Vsleta).getFullYear();
    var vsltotal = vsldate + "/" + vsldatemonth + "/" + vslfullyear;
    console.log("this.state.Vsleta" + this.state.Vsleta)
    console.log("vslfullyear: " + vslfullyear)
    console.log("vsldate: " + vsldate)
    console.log("vsldatemonth: " + vsldatemonth)
    console.log("vsltotal: " + vsltotal)
    this.setState({vsltoshow:vsltotal});
    urlencoded.append("Vsleta", vsletapassup);

    var closingdate = new Date(this.state.closingday).getDate();
    var closingmonth = new Date(this.state.closingday).getMonth() + 1;
    var closingfullyear = new Date(this.state.closingday).getFullYear();
    var closingtotal = closingdate + "/" + closingmonth + "/" + closingfullyear;
    this.setState({closingdateshow:closingtotal});

    var closingdaypasssup = new Date(this.state.closingday).getTime()/1000;


    console.log("closingfullyear: " + closingfullyear)
    console.log("closingdate: " + closingdate)
    console.log("closingmonth: " + closingmonth)
    console.log("closingtotal: " + closingtotal)

    console.log("this.state.closingday: " + this.state.closingday)
    console.log("closingdateshow: " + this.state.closingdateshow)
    console.log("closingdaypasssup: " + closingdaypasssup)

    
    urlencoded.append("closingday", closingdaypasssup);
    urlencoded.append("operatoreadropdowntext",this.state.operatoreadropdowntext);
    urlencoded.append("pickupdropdowntext", this.state.pickupdropdowntext);
    if(this.state.shipmenttypeadropdowntext =="Export"){
      urlencoded.append("returnyarddropdowntext", "");
    }else if(this.state.shipmenttypeadropdowntext =="Import"){
      urlencoded.append("returnyarddropdowntext", this.state.returnyarddropdowntext);
    }
    //till here, need to add volume and check volume key in problem
    if(this.state.volumenumberinput != 0) {
      if(this.state.type1dropdowntext == "None"){
      
      } else{
        urlencoded.append("volumenumberinput", this.state.volumenumberinput);
        urlencoded.append("type1dropdowntext",this.state.type1dropdowntext );
      }
    }else{
      //alert
    }
    console.log("volumenumber1value1: "+ this.state.volumenumberinput)
  
    if(this.state.volumenumberinput1 != 0) {
      if(this.state.type1dropdowntext1 == "None"){
      
      } else{
      urlencoded.append("volumenumberinput1", this.state.volumenumberinput1);
      urlencoded.append("type1dropdowntext1", this.state.type1dropdowntext1);
      }
    }else{
      urlencoded.append("volumenumberinput1", 0);
    }
    console.log("volumenumber2value2: "+ this.state.volumenumberinput1)

    if(this.state.volumenumberinput2 != 0 && this.state.rowvolume1 == true) {
      if(this.state.type1dropdowntext2 == "None"){
      
      } else{
      urlencoded.append("volumenumberinput2", this.state.volumenumberinput2);
      urlencoded.append("type1dropdowntext2",this.state.type1dropdowntext2 );
      }
      console.log("consoled"+ this.state.volumenumberinput2)
    }else{
      urlencoded.append("volumenumberinput2", 0);
    }

    if(this.state.volumenumberinput3 != 0 && this.state.rowvolume2 == true) {
      if(this.state.type1dropdowntext3 == "None"){
      
      } else{
      urlencoded.append("volumenumberinput3", this.state.volumenumberinput3);
      urlencoded.append("type1dropdowntext3",this.state.type1dropdowntext3 );
      }
    }else{
      urlencoded.append("volumenumberinput3", 0);
    }

    if(this.state.volumenumberinput4 != 0 && this.state.rowvolume3 == true) {
      if(this.state.type1dropdowntext4 == "None"){
      
      } else{
      urlencoded.append("volumenumberinput4", this.state.volumenumberinput4);
      urlencoded.append("type1dropdowntext4",this.state.type1dropdowntext4 );
      }
    }else{
      urlencoded.append("volumenumberinput4", 0);
    }

    if(this.state.volumenumberinput5 != 0 && this.state.rowvolume4 == true) {
      if(this.state.type1dropdowntext5 == "None"){
      
      } else{
      urlencoded.append("volumenumberinput5", this.state.volumenumberinput5);
      urlencoded.append("type1dropdowntext5",this.state.type1dropdowntext5 );
      }
    }else{
      urlencoded.append("volumenumberinput5", 0);
    }

    if(this.state.volumenumberinput6 != 0 && this.state.rowvolume5 == true) {
      if(this.state.type1dropdowntext6 == "None"){          
      } else{
        urlencoded.append("volumenumberinput6", this.state.volumenumberinput6);
        urlencoded.append("type1dropdowntext6",this.state.type1dropdowntext6 );
      }
    }else{
      urlencoded.append("volumenumberinput6", 0);
    }

    if(this.state.volumenumberinput7 != 0 && this.state.rowvolume6 == true) {
      if(this.state.type1dropdowntext7 == "None"){          
      } else{
        urlencoded.append("volumenumberinput7", this.state.volumenumberinput7);
        urlencoded.append("type1dropdowntext7",this.state.type1dropdowntext7 );
      }
    }else{
      urlencoded.append("volumenumberinput7", 0);
    }

    if(this.state.volumenumberinput8 != 0 && this.state.rowvolume7 == true) {
      if(this.state.type1dropdowntext8 == "None"){          
      } else{
        urlencoded.append("volumenumberinput8", this.state.volumenumberinput8);
        urlencoded.append("type1dropdowntext8",this.state.type1dropdowntext8 );
      }
    }else{
      urlencoded.append("volumenumberinput8", 0);
    }

    if(this.state.volumenumberinput9 != 0 && this.state.rowvolume8 == true) {
      if(this.state.type1dropdowntext9 == "None"){          
      } else{
        urlencoded.append("volumenumberinput9", this.state.volumenumberinput9);
        urlencoded.append("type1dropdowntext9",this.state.type1dropdowntext9 );
      }
    }else{
      urlencoded.append("volumenumberinput9", 0);
    }
  
    
    let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
    Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
    Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
    Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
    Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 
    
    urlencoded.append("totalnumberofjob", numberrow);
    for (let i=0;i<numberrow;i++){
      urlencoded.append("consigment"+i,this.state['consignmentno'+i]);
      urlencoded.append("container"+i, this.state["container" +i]);
      if(this.state["checksideloader" +i] == null){
        urlencoded.append("checksideloader"+i, 0);
      }else{
        if(this.state["checksideloader" +i] == true){
          urlencoded.append("checksideloader"+i, 1);
        }else{
          urlencoded.append("checksideloader"+i, 0);
        }
        
      }
      if(this.state["Seal" +i] == null){
        urlencoded.append("Seal"+i, " ");
      }else{
        urlencoded.append("Seal"+i, this.state["Seal" +i]);
      }
      
      if(this.state["container" +i] == null){
        urlencoded.append("container"+i, " ");
      }else{
        urlencoded.append("container"+i, this.state["container" +i]);
      }
      // var requestdatedate = new Date(this.state["requestdate" +i]).getDate();
      // var requestdatemonth = new Date(this.state["requestdate" +i]).getMonth() + 1;
      // var requestdateyear = new Date(this.state["requestdate" +i]).getFullYear();
      // var requestdatetotal = requestdatedate + "/" + requestdatemonth + "/" + requestdateyear;

      // this.setState({["requestdatetoshow" + i]:requestdatetotal})
       var requestdate = new Date( this.state["requestdate" +i]).getTime()/1000;

      // console.log(i);
      // console.log(this.state["container" +i]);
      // console.log(this.state["Seal" +i]);
      // console.log("requestdatestate: " + this.state["requestdate" +i]);
      // console.log("requestdate: " + requestdate)

      if(this.state["requestdate" +i] == 946684800 || this.state["requestdate" +i] == 'TBC'){
       // requestdatetotal ='TBC'
        urlencoded.append("requestdate"+i, 946684800);
      }else{
        urlencoded.append("requestdate"+i,requestdate);
      }
     
      //urlencoded.append("requestdate"+i,requestdate);

      
    }
    
    urlencoded.append("vesselname", this.state.vesselname);
    urlencoded.append("vesselvoyage", this.state.vesselvoyage);
    urlencoded.append("commodity", this.state.commodity);
    urlencoded.append("forwardingagentdropdowntext", this.state.forwardingagentdropdowntext);

    console.log("Remark: " + this.state.remark)
    urlencoded.append("remark", this.state.remark);
    if(this.state.remark == null || this.state.remark == ''){
      urlencoded.append("remark", '-');
    }else{
      urlencoded.append("remark", this.state.remark);
    }


    urlencoded.append("rotnumber", rotnumber);
    urlencoded.append("createdby",this.state.Createdbyaccount)
    ////////////
    //file upload

    let link = this.state.linkurl +'mode=admin-updaterotapi-version2'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
           // console.log(json)
            //success
            //console.log("rotnumberfeedback: " + json.rotnumber)
            this.setState({rotnumbercomeback:json.rotnumber})
            this.setState({aftersubmitstatus:1})
            this.setState({numberrowaftersubmit:numberrow})
           // this.uploadfilefrom1to10edit(json.rotnumber)
            
             
              this.setState({loading:false})  
            }        
               
         else{
           //fail submit
          this.setState({aftersubmitstatus:2})
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })


     
    }
  }

  }

  // toggleNas1 = (e, state, index) => {
  //   e.preventDefault();
  //   this.setState({
  //     [state]: index
  //   });
  //   if(state == "tabsimportexport" ){
  //     this.getorderlistapi("Import");
  //   }else if(index ==1){
  //     this.getorderlistapi("Import");
  //   }
  // };

  buttonclicktoswitchtab = (e,tabid,tabsimportexport) =>{
    e.preventDefault();
    this.setState({tabs:tabid})
    this.setState({tabsimportexport:tabsimportexport})
    this.setState({searchtextvalue:''})
    this.setState({currentcompletedpagenumber:1})
    if(tabid === 1 && tabsimportexport === 1){
      this.getpendingorderlistapi("Import");
    }else if(tabid === 1 && tabsimportexport === 2){
      this.getpendingorderlistapi("Export");
    }else if(tabid === 2 && tabsimportexport === 1){
      this.getorderlistapi("Import");
    }else if(tabid === 2 && tabsimportexport === 2){
      this.getorderlistapi("Export");
    }else if(tabid === 3 && tabsimportexport === 1){
      this.getcompletedorderapiwithdaterange("Import");
    }else if(tabid === 3 && tabsimportexport === 2){
      this.getcompletedorderapiwithdaterange("Export");
    }
    //console.log("tabid:"+tabid)
    //console.log("tabsimportexport:"+tabsimportexport)
  }

  componentDidMount(){
   // this.getpendingorderlistapi("Import");
   //var location = useLocation();
   const searchParams = new URLSearchParams(this.props.location.search);
   const searchTextValue = searchParams.get("searchtextvalue");
   const importorexport = searchParams.get("importorexport");

  


   document.addEventListener('keyup', (e) => {
    if (e.keyCode === 27){
      e.preventDefault();
      if(window.location.pathname.includes('orders')){
        this.cancelledsearchbuttonclick()
      }
     
    }else if(e.keyCode === 13){
      e.preventDefault();
      if(window.location.pathname.includes('orders')){
        this.searchfunctionstart()
      }
      
    }
    },false);
   this.setdatedefaultincomplete();
    this.getcreatedbywho();
    
    
    this.setState({tabsimportexport:1})
    this.setState({attachmentsetID:moment().unix()}) ;   

   if(searchTextValue == "" || searchTextValue == null){
     this.getorderlistapi("Import");
   }else{
      var searchtext123 = searchParams.get("searchtextvalue").split("-")[0]
     this.setState({searchtextvalue:searchtext123})
       this.searchfunctionstartrotorderlist(searchParams.get("importorexport"),searchtext123);
      
   }
  }

  getdetailquotationtableapi =()=>{
    this.setState({loadingmodaleditorder:true})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-getquotationlistfinal'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({quotationtabledatafinal:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

          
               this.setState({quotationtabledatafinal:[...this.state.quotationtabledatafinal,
                { no:i+1,
                  shippername:json.user[i].Shipper,
                  area:json.user[i].Area,
                  address:json.user[i].Address,
                  title:json.user[i].Shipper,
                  picvalue:json.user[i].Personincharge,
                  contactvalue:json.user[i].Contact,
                },            
              ]})
              
              
              this.setState({aftersubmitstatus:0})
               //console.log(this.state.quotationtabledatafinal)
             

             
              }
              this.setState({loadingmodaleditorder:false})  
              
            }        
               
         else{
          this.setState({loadingmodaleditorder:false}) 
          this.setState({aftersubmitstatus:0})
        console.log(json)
      }
    
     })

    
       

   
  }

  getpendingorderlistapi = (importexport) =>{
    this.setState({loading:true})
    var countrow = 0;
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("importexport", importexport);
    let link = this.state.linkurl + 'mode=admin-getrotpending'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({rotpendinglist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              var tempcreatedbywho = "" ; 
              var tempcreatedbywhoprofileurl = "";
              if(this.state.userlist != []){
                for ( let k=0;k<this.state.userlist.length;k++){
                  if(this.state.userlist[k].ref === json.user[i].Createdby){
                    tempcreatedbywho =  this.state.userlist[k].name 
                    tempcreatedbywhoprofileurl = this.state.userlist[k].profilepictureurl
                  }
                }   
              }
              countrow = countrow + 1 
              this.setState({["expandpendingimport" + countrow]:false})
              //status need do
               this.setState({rotpendinglist:[...this.state.rotpendinglist,
                { no:countrow,
                  rotno:json.user[i].Rotnumber,
                  shippername:json.user[i].Shipper.slice(0,32),
                  totalvolume:json.user[i].totalvolume.slice(0,20),
                  bookingno:json.user[i].Bookingno,
                  status:"Pending",
                  remark:json.user[i].Remark,
                  importexport:json.user[i].importexport,
                  createdby:tempcreatedbywho,
                  createdbywhoref:tempcreatedbywhoprofileurl,
                },            
              ]})
              
              
             
               console.log(this.state.rotpendinglist)
             
              
             
              }
              this.getcancelledorderlistapi(importexport)
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({rotpendinglist:[]});  
          this.getcancelledorderlistapi(importexport)
          //window.confirm('No related pending data')
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  getcancelledorderlistapi = (importexport) =>{
    this.setState({loading:true})
    var countrow = 0;
    if(this.state.rotpendinglist != null){
      countrow = this.state.rotpendinglist.length
    }
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("importexport", importexport);
    let link = this.state.linkurl + 'mode=admin-getrotcancelled'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            //this.setState({rotpendinglist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              var tempcreatedbywho = "" ; 
              var tempcreatedbywhoprofileurl ="";
              if(this.state.userlist != []){
                for ( let k=0;k<this.state.userlist.length;k++){
                  if(this.state.userlist[k].ref === json.user[i].Createdby){
                    tempcreatedbywho =  this.state.userlist[k].name 
                    tempcreatedbywhoprofileurl = this.state.userlist[k].profilepictureurl
                  }
                }   
              }
              countrow = countrow + 1 
              this.setState({["expandpendingimport" + countrow]:false})
              //status need do
               this.setState({rotpendinglist:[...this.state.rotpendinglist,
                { no:countrow,
                  rotno:json.user[i].Rotnumber,
                  shippername:json.user[i].Shipper.slice(0,32),
                  totalvolume:json.user[i].totalvolume.slice(0,20),
                  bookingno:json.user[i].Bookingno,
                  status:"Cancelled",
                  remark:json.user[i].Remark,
                  importexport:json.user[i].importexport,
                  createdby:tempcreatedbywho,
                  createdbywhoref:tempcreatedbywhoprofileurl,
                },            
              ]})
              
              
             
               console.log(this.state.rotpendinglist)
             

             
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  completedbuttonclicked = () =>{
    this.setState({buttoncolor1:"white"})
    this.setState({buttoncolor2:"white"})
    this.setdatedefaultincomplete()
    this.setState({buttoncolor3:"primary"})
    this.setState({tabs:3})
    this.setState({tabsimportexport:1})
    this.setState({searchtextvalue:''})
    this.setState({currentcompletedpagenumber:1})
    
      this.getcompletedorderapiwithdaterange("Import");
      
  }

  orderbuttonclicked = () =>{
    this.setState({buttoncolor1:"white"})
    this.setState({buttoncolor2:"primary"})
    this.setState({buttoncolor3:"white"})
    this.setState({tabs:2})
    this.setState({tabsimportexport:1})
    this.setState({searchtextvalue:''})
    this.setState({currentcompletedpagenumber:1})
    this.getorderlistapi("Import");
  
  }

  pendingorderbuttonclicked = (importexport) =>{
    this.setState({buttoncolor1:"primary"})
    this.setState({buttoncolor2:"white"})
    this.setState({buttoncolor3:"white"})
    
    this.setState({tabs:1})   
    this.setState({tabsimportexport:1})
    this.setState({searchtextvalue:''})
    this.setState({currentcompletedpagenumber:1})
    this.getpendingorderlistapi(importexport);
    
  }

  getorderlistapi = (importexport) =>{
    this.setState({loading:true})
    var countrow = 0;
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("importexport", importexport);
    let link = this.state.linkurl + 'mode=admin-getrotorderlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({rotpendinglist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             var indexofvalue = -1

                                 

             for(let i=0;i<lengthemployee;i++){
              
              //status need do
              if(this.state.rotpendinglist.some(item => item.rotno === json.user[i].Rotnumber)){             
                  //update counttotal here,
                  var filterobj = this.state.rotpendinglist.find(function(item, j){
                    if(item.rotno === json.user[i].Rotnumber){
                      indexofvalue = j;
                      //return j;
                    }
                  });
                  //var index = this.state.rotpendinglist.indexOf(item => item.rotno === json.user[i].Rotnumber)
                  this.state.rotpendinglist[indexofvalue].counttotal =   this.state.rotpendinglist[indexofvalue].counttotal + 1
                  if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
                    this.state.rotpendinglist[indexofvalue].countcompleted =   this.state.rotpendinglist[indexofvalue].countcompleted + 1
                  }
                console.log(json.user[i].Rotnumber)
                console.log(this.state.rotpendinglist)
                console.log(indexofvalue)
                //console.log(filterobj)
                console.log(this.state.rotpendinglist[0].counttotal)
              // }else if(rot have in all job then){
              //   add counttotal,
               }else{
                var tempcreatedbywho = "" ; 
                var tempcreatedbywhoprofileurl = "";
                if(this.state.userlist != []){
                  for ( let k=0;k<this.state.userlist.length;k++){
                    if(this.state.userlist[k].ref === json.user[i].Createdby){
                      tempcreatedbywho =  this.state.userlist[k].name 
                      tempcreatedbywhoprofileurl = this.state.userlist[k].profilepictureurl
                    }
                  }   
                }
                countrow = countrow + 1 
                this.setState({["expand" + countrow]:false})
                if(json.user[i].Activitydatetruckin-28800 == 946684800-28800){
                  this.setState({rotpendinglist:[...this.state.rotpendinglist,
                    { no:countrow,
                      rotno:json.user[i].Rotnumber,
                      shippername:json.user[i].Shipper.slice(0,32),
                      totalvolume:json.user[i].totalvolume,
                      bookingno:json.user[i].Bookingno,
                      status:1,
                      remark:json.user[i].Remark,
                      counttotal:1,
                      countcompleted:0,      
                      closingdate:json.user[i].Closingday-28800,    
                      createdby:tempcreatedbywho,
                      createdbywhoref:tempcreatedbywhoprofileurl,
                    },            
                  ]})
                }else{
                  this.setState({rotpendinglist:[...this.state.rotpendinglist,
                    { no:countrow,
                      rotno:json.user[i].Rotnumber,
                      shippername:json.user[i].Shipper.slice(0,32),
                      totalvolume:json.user[i].totalvolume,
                      bookingno:json.user[i].Bookingno,
                      status:1,
                      remark:json.user[i].Remark,
                      counttotal:1,
                      countcompleted:1,      
                      closingdate:json.user[i].Closingday-28800,    
                      createdby:tempcreatedbywho,
                      createdbywhoref:tempcreatedbywhoprofileurl,
                    },            
                  ]})
                }
               }

              //then check bill, and history, 
             // add counttotal, add countcompleted,
             
              
              
             
               console.log(this.state.rotpendinglist)
             

             
              }
              this.getorderlistfrombillandhistory(importexport)
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }
  
  functionselectshipmenttype =(shippername2)=>{
       
    var data = this.state.quotationtabledatafinal.filter(item => item.shippername === shippername2)
   
    this.setState({type1dropdowntext:''})
    this.setState({type1dropdowntext1:''})
    this.setState({type1dropdowntext2:''})
    this.setState({type1dropdowntext3:''})
    this.setState({type1dropdowntext4:''})
    this.setState({type1dropdowntext5:''})
    this.setState({type1dropdowntext6:''})
    this.setState({type1dropdowntext7:''})
    this.setState({type1dropdowntext8:''})
    this.setState({type1dropdowntext9:''})
    
    if(data[0] != null){
      
      this.setState({
        areadropdowntext:shippername2,
        deliveryaddress:data[0].address,
        areatextbox:data[0].area,
        picvalue:data[0].picvalue,
        contactvalue:data[0].contactvalue,
      })

      if(data[0].picvalue == ""){
        this.setState({picvalue:"-"});
      }
      if(data[0].contactvalue ==""){
        this.setState({contactvalue:"-"});
      }
    }

    if(shippername2 ==""){
      this.setState({
        areadropdowntext:"",
        deliveryaddress:"",
        areatextbox:"",
        picvalue:"",
        contactvalue:"",
        
      })
    }
   
    console.log(shippername2)
    console.log(this.state.poltext)
    console.log(this.state.podtext)

    if(this.state.shipmenttypeadropdowntext =='Export'){
     this.getcontainertypelist(this.state.poltext,shippername2,this.state.shipmenttypeadropdowntext)
    } else if(this.state.shipmenttypeadropdowntext =='Import'){
     this.getcontainertypelist(this.state.podtext,shippername2,this.state.shipmenttypeadropdowntext)
    } else{

    }
  }

  getorderlistfrombillandhistory =(importexport)=>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("importexport", importexport);
    let link = this.state.linkurl + 'mode=admin-getorderlistfrombillandhistory'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            //this.setState({rotpendinglist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             var indexofvalue = -1
             for(let i=0;i<lengthemployee;i++){
              
              //status need do
              if(this.state.rotpendinglist.some(item => item.rotno === json.user[i].Rotnumber)){             
                  //update counttotal here,
                  var filterobj = this.state.rotpendinglist.find(function(item, j){
                    if(item.rotno === json.user[i].Rotnumber){
                      indexofvalue = j;
                      //return j;
                    }
                  });
                  //var index = this.state.rotpendinglist.indexOf(item => item.rotno === json.user[i].Rotnumber)
                  this.state.rotpendinglist[indexofvalue].counttotal =   this.state.rotpendinglist[indexofvalue].counttotal + 1
                  this.state.rotpendinglist[indexofvalue].countcompleted =   this.state.rotpendinglist[indexofvalue].countcompleted + 1
                // console.log(json.user[i].Rotnumber)
                // console.log(this.state.rotpendinglist)
                // console.log(indexofvalue)
                // console.log(filterobj)
              //  console.log(this.state.rotpendinglist[0].counttotal)
               // console.log(this.state.rotpendinglist[0].countcompleted)
              // }else if(rot have in all job then){
              //   add counttotal,
               }

              //then check bill, and history, 
             // add counttotal, add countcompleted,
             
              
              
             
               //console.log(this.state.rotpendinglist)
             

             
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  // getcompletedorderapi = (importexport) =>{
    
  //   this.setState({loading:true})
  //   var countrow = 0;
  //   var urlencoded = new URLSearchParams();
  //   urlencoded.append("billtoparty", this.state.Billtoparty);
  //   urlencoded.append("importexport", importexport);
  //   let link = 'https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getrotordercompletedlist'
  //   fetch(link, {
  //     method: 'POST', 
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body:urlencoded
  //   })
  //   .then(response => response.json())
  //   .then(json => {
  //     console.log(json.success)
  //     if(json.success == 1){
        
  //           console.log(json)
  //           this.setState({completelist:[]});   
  //            var lengthemployee = Object.keys(json.user).length;
  //            for(let i=0;i<lengthemployee;i++){
  //             var tempcreatedbywho = "" ; 
  //             if(this.state.userlist != []){
  //               for ( let k=0;k<this.state.userlist.length;k++){
  //                 if(this.state.userlist[k].ref === json.user[i].Createdby){
  //                   tempcreatedbywho =  this.state.userlist[k].name 
  //                 }
  //               }   
  //             }
  //             countrow = countrow + 1 
  //             this.setState({["expandcompletedimport" + countrow]:false})
  //             //status need doasdf
  //              this.setState({completelist:[...this.state.completelist,
  //               { no:countrow,
  //                 rotno:json.user[i].Rotnumber,
  //                 shippername:json.user[i].Shipper,
  //                 totalvolume:json.user[i].totalvolume,
  //                 bookingno:json.user[i].Bookingno,
  //                 status:"Completed",
  //                 remark:json.user[i].Remark,
  //                 createdby:tempcreatedbywho,
  //                 createdbywhoref:json.user[i].Createdby,
  //                 nocolumn:1,
  //               },            
  //             ]})
              
              
             
  //              console.log(this.state.completelist)
             

             
  //             }
  //             //this.setState({loading:false})  111
  //            this.getbillingorderapi(importexport);
  //           }        
               
  //        else{
  //         this.setState({loading:false}) 
  //       console.log(json)
  //     }
    
  //    })
  // }  
  // searchbuttondaterangeclick = () =>{
  //   this.getcompletedorderapiwithdaterange()
  // }

  getcompletedorderapiwithdaterange = (importexport) =>{
    
    this.setState({currentcompletedpagenumber:1})
    this.setState({loading:true})
    var datestart = new Date(this.state.startDate).getDate()
    var twodigitdate = ("0" + datestart).slice(-2);
    var monthstart = new Date(this.state.startDate).getMonth() + 1
    var twodigitmonth = ("0" + monthstart).slice(-2);
    var yearstart = new Date(this.state.startDate).getFullYear()
    var totaldatestart = yearstart + "-" + twodigitmonth + "-" + twodigitdate + "T00:00:00+08:00"
    var starttimetopass = new Date(totaldatestart).getTime()
    var starttimetopassnotmilli = starttimetopass/1000
    console.log("starttime: " + starttimetopassnotmilli)

    var dateend = new Date(this.state.endDate).getDate()
    var twodigitdateend = ("0" + dateend).slice(-2);
    var monthend = new Date(this.state.endDate).getMonth() + 1
    var twodigitmonthend = ("0" + monthend).slice(-2);
    var yearend = new Date(this.state.endDate).getFullYear()
    var totaldateend = yearend + "-" + twodigitmonthend + "-" + twodigitdateend + "T23:59:59+08:00"
    var endtimetopass = new Date(totaldateend).getTime()
    var endtimetopassnotmilli = endtimetopass/1000
    console.log("endtime: " + endtimetopassnotmilli)
    this.setState({completelist:[]});   
    var countrow = 0;
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("startDate", starttimetopassnotmilli);
    urlencoded.append("endDate", endtimetopassnotmilli);
    urlencoded.append("importexport", importexport);
    let link = this.state.linkurl + 'mode=admin-getrotordercompletedlistsearch'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              var tempcreatedbywho = "" ; 
              var tempcreatedbywhoprofileurl = "";
              if(this.state.userlist != []){
                for ( let k=0;k<this.state.userlist.length;k++){
                  if(this.state.userlist[k].ref === json.user[i].Createdby){
                    tempcreatedbywho =  this.state.userlist[k].name 
                    tempcreatedbywhoprofileurl = this.state.userlist[k].profilepictureurl
                  }
                }   
              }
              countrow = countrow + 1 
              this.setState({["expandcompletedimport" + countrow]:false})
              //status need doasdf
               this.setState({completelist:[...this.state.completelist,
                { no:countrow,
                  rotno:json.user[i].Rotnumber,
                  shippername:json.user[i].Shipper.slice(0,32),
                  totalvolume:json.user[i].totalvolume.slice(0,20),
                  bookingno:json.user[i].Bookingno,
                  status:"Completed",
                  remark:json.user[i].Remark,
                  createdby:tempcreatedbywho,
                  createdbywhoref:tempcreatedbywhoprofileurl,
                  nocolumn:1,
                },            
              ]})
              
              
             
               console.log(this.state.completelist)
             

             
              }
              //this.setState({loading:false})  111
             this.getbillingorderapiwithdaterange(importexport);
            }        
               
         else{
          this.setState({loading:false}) 
          this.getbillingorderapiwithdaterange(importexport);
        console.log(json)
      }
    
     })
  } 

  // getbillingorderapi = (importexport) =>{
  //   this.setState({loading:true})
  //   var countrow = this.state.completelist.length;
  //   var urlencoded = new URLSearchParams();
  //   urlencoded.append("billtoparty", this.state.Billtoparty);
  //   urlencoded.append("importexport", importexport);
  //   let link = 'https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getbillingorderapi'
  //   fetch(link, {
  //     method: 'POST', 
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body:urlencoded
  //   })
  //   .then(response => response.json())
  //   .then(json => {
  //     console.log(json.success)
  //     if(json.success == 1){
        
  //           console.log(json)
  //           //this.setState({completelist:[]});   
  //            var lengthemployee = Object.keys(json.user).length;
  //            for(let i=0;i<lengthemployee;i++){
  //             var tempcreatedbywho = "" ; 
  //             if(this.state.userlist != []){
  //               for ( let k=0;k<this.state.userlist.length;k++){
  //                 if(this.state.userlist[k].ref === json.user[i].Createdby){
  //                   tempcreatedbywho =  this.state.userlist[k].name 
  //                 }
  //               }   
  //             }
  //             countrow = countrow + 1 
  //             this.setState({["expandcompletedimport" + countrow]:false})
  //             //status need do
  //              this.setState({completelist:[...this.state.completelist,
  //               { no:countrow,
  //                 rotno:json.user[i].Rotnumber,
  //                 shippername:json.user[i].Shipper,
  //                 totalvolume:json.user[i].totalvolume,
  //                 bookingno:json.user[i].Bookingno,
  //                 status:"Billing",
  //                 remark:json.user[i].Remark,
  //                 createdby:tempcreatedbywho,
  //                 createdbywhoref:json.user[i].Createdby,
  //                 nocolumn:1,
  //               },            
  //             ]})
              
              
             
  //              //console.log(this.state.completelist)
              

             
  //             }
              
  //             console.log(this.state.completelist.sort((a,b)=>b.rotno -a.rotno))
  //             //after sort reinput number
  //             for(let x=0;x<this.state.completelist.length;x++){
  //               this.state.completelist[x].nocolumn = x + 1;
  //             }
  //             this.setState({loading:false})  
  //           }        
               
  //        else{
  //         this.setState({loading:false}) 
  //       console.log(json)
  //     }
    
  //    })
  // }

  getbillingorderapiwithdaterange = (importexport) =>{
    this.setState({loading:true})
    var countrow = this.state.completelist.length;
    var datestart = new Date(this.state.startDate).getDate()
    var twodigitdate = ("0" + datestart).slice(-2);
    var monthstart = new Date(this.state.startDate).getMonth() + 1
    var twodigitmonth = ("0" + monthstart).slice(-2);
    var yearstart = new Date(this.state.startDate).getFullYear()
    var totaldatestart = yearstart + "-" + twodigitmonth + "-" + twodigitdate + "T00:00:00+08:00"
    var starttimetopass = new Date(totaldatestart).getTime()
    var starttimetopassnotmilli = starttimetopass/1000
    console.log("starttime: " + starttimetopassnotmilli)

    var dateend = new Date(this.state.endDate).getDate()
    var twodigitdateend = ("0" + dateend).slice(-2);
    var monthend = new Date(this.state.endDate).getMonth() + 1
    var twodigitmonthend = ("0" + monthend).slice(-2);
    var yearend = new Date(this.state.endDate).getFullYear()
    var totaldateend = yearend + "-" + twodigitmonthend + "-" + twodigitdateend + "T23:59:59+08:00"
    var endtimetopass = new Date(totaldateend).getTime()
    var endtimetopassnotmilli = endtimetopass/1000
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("startDate", starttimetopassnotmilli);
    urlencoded.append("endDate", endtimetopassnotmilli);
    urlencoded.append("importexport", importexport);
    let link = this.state.linkurl + 'mode=admin-getbillingorderapiwithdaterange'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            //this.setState({completelist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              var tempcreatedbywho = "" ; 
              var tempcreatedbywhoprofileurl = "";
              if(this.state.userlist != []){
                for ( let k=0;k<this.state.userlist.length;k++){
                  if(this.state.userlist[k].ref === json.user[i].Createdby){
                    tempcreatedbywho =  this.state.userlist[k].name 
                    tempcreatedbywhoprofileurl = this.state.userlist[k].profilepictureurl
                  }
                }   
              }
              countrow = countrow + 1 
              this.setState({["expandcompletedimport" + countrow]:false})
              //status need do
               this.setState({completelist:[...this.state.completelist,
                { no:countrow,
                  rotno:json.user[i].Rotnumber,
                  shippername:json.user[i].Shipper.slice(0,32),
                  totalvolume:json.user[i].totalvolume.slice(0,20),
                  bookingno:json.user[i].Bookingno,
                  status:"Billing",
                  remark:json.user[i].Remark,
                  createdby:tempcreatedbywho,
                  createdbywhoref:tempcreatedbywhoprofileurl,
                  nocolumn:1,
                },            
              ]})
              
              
             
               //console.log(this.state.completelist)
              

             
              }
              
              console.log(this.state.completelist.sort((a,b)=>b.rotno -a.rotno))
              //after sort reinput number
              for(let x=0;x<this.state.completelist.length;x++){
                this.state.completelist[x].nocolumn = x + 1;
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  showmodalapifunctioncalldetail = (rotnumber,no) =>{
    this.setState({["loadingmodalpending" + no]:true})
    console.log(rotnumber)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("rotnumber", rotnumber);
    let link = this.state.linkurl + 'mode=admin-getrotdetailpendingdata'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
            this.setState({rotnumbermodalpending:rotnumber})
            console.log(json)
            this.setState({["detailpendinglist"+no]:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

              this.setState({
                shipper:json.user[i].Shipper,
                bookingno:json.user[i].bookingno,
                pol:json.user[i].POL,
                pod:json.user[i].POD,
              })
              //status need do
               this.setState({["detailpendinglist"+no]:[...this.state["detailpendinglist"+no],
                { no:i+1,
                  consignmentno:json.user[i].Consignmentno,
                  containerno:json.user[i].Containerno,
                  requiredate:json.user[i].Requestdate-28800,
                  closedate:json.user[i].Closingday-28800,
                  type:json.user[i].Type,
                },            
              ]})
              
              
             
               console.log(this.state["detailpendinglist"+no])
             

             
              }
              this.setState({["loadingmodalpending" + no]:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  showmodalapifunctioncalldetailcancelled = (rotnumber,no) =>{
    this.setState({["loadingmodalpending" + no]:true})
    console.log(rotnumber)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("rotnumber", rotnumber);
    let link = this.state.linkurl + 'mode=admin-getrotdetailpendingdatacancelled'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
            this.setState({rotnumbermodalpending:rotnumber})
            console.log(json)
            this.setState({["detailpendinglist"+no]:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

              this.setState({
                shipper:json.user[i].Shipper,
                bookingno:json.user[i].bookingno,
                pol:json.user[i].POL,
                pod:json.user[i].POD,
              })
              //status need do
               this.setState({["detailpendinglist"+no]:[...this.state["detailpendinglist"+no],
                { no:i+1,
                  consignmentno:json.user[i].Consignmentno,
                  containerno:json.user[i].Containerno,
                  requiredate:json.user[i].Requestdate-28800,
                  closedate:json.user[i].Closingday-28800,
                  type:json.user[i].Type,
                },            
              ]})
              
              
             
               console.log(this.state["detailpendinglist"+no])
             

             
              }
              this.setState({["loadingmodalpending" + no]:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  showmodalapifunctioncalldetailcompletetab = (rotnumber,no) =>{
    this.setState({["loadingmodalcompleted" + no]:true})
    console.log(rotnumber)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("rotnumber", rotnumber);
    let link = this.state.linkurl + 'mode=admin-getrotdetailpendingdatacompletetab'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        this.setState({rotnumbermodalpending:rotnumber})
            console.log(json)
            this.setState({["detailcompletedlist" + no]:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

              this.setState({
                shipper:json.user[i].Shipper,
                bookingno:json.user[i].bookingno,
                pol:json.user[i].POL,
                pod:json.user[i].POD,
              })
              //status need do
               this.setState({["detailcompletedlist" + no]:[...this.state["detailcompletedlist" + no],
                { no:i+1,
                  consignmentno:json.user[i].Consignmentno,
                  containerno:json.user[i].Containerno,
                  requiredate:json.user[i].Requestdate-28800,
                  closedate:json.user[i].Closingday-28800,
                  type:json.user[i].Type,
                },            
              ]})
              
              
             
               console.log(this.state["detailcompletedlist" + no])
             

             
              }
              
              this.setState({["loadingmodalcompleted" + no]:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  showmodalapifunctioncalldetailbillingtab = (rotnumber,no) =>{
    this.setState({["loadingmodalcompleted" + no]:true})
    console.log(rotnumber)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("rotnumber", rotnumber);
    let link = this.state.linkurl + 'mode=admin-getrotdetailbillingdatacompletetab'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        this.setState({rotnumbermodalpending:rotnumber})
            console.log(json)
            
            this.setState({["detailcompletedlist" + no]:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

              this.setState({
                shipper:json.user[i].Shipper,
                bookingno:json.user[i].bookingno,
                pol:json.user[i].POL,
                pod:json.user[i].POD,
              })
              //status need do
               this.setState({["detailcompletedlist" + no]:[...this.state["detailcompletedlist" + no],
                { no:i+1,
                  consignmentno:json.user[i].Consignmentno,
                  containerno:json.user[i].Containerno,
                  requiredate:json.user[i].Requestdate-28800,
                  closedate:json.user[i].Closingday-28800,
                  type:json.user[i].Type,
                },            
              ]})
              
              
             
               console.log(this.state["detailcompletedlist" + no])
             

             
              }
              this.setState({["loadingmodalcompleted" + no]:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  showmodalapifunctioncalldetailordertab = (rotnumber,rownumber) =>{
    this.setState({["loadingmodalorder" + rownumber]:true})
    console.log(rotnumber)
    console.log("I Am Called")
    var totalvolumevar = ''
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("rotnumber", rotnumber);
    let link = this.state.linkurl + 'mode=admin-getrotdetailpendingdataordertab'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        this.setState({rotnumbermodalordertab:rotnumber})
            console.log(json)
            this.setState({["detailorderlist"+rownumber]:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

              this.setState({
                shipperorder:json.user[i].Shipper,
                bookingnoorder:json.user[i].Bookingno,
                polorder:json.user[i].POL,
                podorder:json.user[i].POD,
              })
             
              if(json.user[i].totalvolume.includes(',')){
                totalvolumevar = json.user[i].totalvolume.split(',')
                if(totalvolumevar[0] != null){
                  this.setState({["volume"+rownumber+1]:totalvolumevar[0]});
                }
                if(totalvolumevar[1] != null){
                  this.setState({["volume"+rownumber+2]:totalvolumevar[1]});
                }
                if(totalvolumevar[2] != null){
                  this.setState({["volume"+rownumber+3]:totalvolumevar[2]});
                }
                if(totalvolumevar[3] != null){
                  this.setState({["volume"+rownumber+4]:totalvolumevar[3]});
                }  
                console.log("volume1: " + "volume"+rownumber+1)           
                console.log("volume1value" + totalvolumevar[0])   
                console.log("volume2: " + "volume"+rownumber+2)           
                console.log("volume2value" + totalvolumevar[1])   
                console.log("volume3: " + "volume"+rownumber+3)           
                console.log("volume3value" + totalvolumevar[2])   
                console.log("volume4: " + "volume"+rownumber+4)           
                console.log("volume4value" + totalvolumevar[3])   
              }else{
                this.setState({["volume"+rownumber+1]:json.user[i].totalvolume});
              }


              this.setState({["container" +rownumber+ i]:json.user[i].Containerno})
              this.setState({["checksideloader" +rownumber+ i]:json.user[i].sideloader})

              //status need do
               this.setState({["detailorderlist"+rownumber]:[...this.state["detailorderlist"+rownumber],
                { no:i+1,
                  consignmentno:json.user[i].Consignmentno,
                  containerno:json.user[i].Containerno,
                  requiredate:json.user[i].Requestdate-28800,
                  closedate:json.user[i].Closingday-28800,
                  type:json.user[i].Type,
                  checksideloader:json.user[i].sideloader,
                  activitystagingindate:json.user[i].Activitydatestaging-28800,
                  activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                  activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                  activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                  activitytransferbilldate:json.user[i].transferbilldate-28800,
                  activitytransferhistory:json.user[i].transferhistorydate-28800,
                  activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                  shipper:json.user[i].Shipper,
                  importexport:json.user[i].importexport,
                  shippingline:json.user[i].Operator,
                  forwardingagent:json.user[i].Forwardingagent,
                  pol:json.user[i].POL,
                  pod:json.user[i].POD,
                  vesselvoyage:json.user[i].Vesselname,
                  voyagename:json.user[i].Vesselvoyage,
                  eta:json.user[i].Vsleta-28800,
                  closingday:json.user[i].Closingday-28800,
                  pickupyard:json.user[i].Pickupyard,
                  pic:json.user[i].Contactperson,
                  shippperaddress:json.user[i].Deliveryaddress,
                  remark:json.user[i].Remark,
                  billingto:json.user[i].Billtoparty,
                  commodity:json.user[i].Commodity,
                  bookingno:json.user[i].Bookingno,
                  contactpic:json.user[i].Contactnumber,
                },            
              ]})
              
              
             
               console.log(this.state["detailorderlist"+rownumber])
             

             
              }
              this.showmodalapifunctioncalldetailordertabbillingandhistory(rotnumber,rownumber)
              //this.setState({loadingmodalorder:false})  
            }        
               
         else{

          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  showmodalapifunctioncalldetailordertabbillingandhistory = (rotnumber,rownumber) =>{
    this.setState({["loadingmodalorder" + rownumber]:true})
    console.log(rotnumber)
    console.log("I Am Called")
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("rotnumber", rotnumber);
    let link = this.state.linkurl + 'mode=admin-getrotdetailpendingdataordertabbillingandhistory'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.successbill)
      console.log(json.successhistory)
      if(json.successbill == 1 || json.successhistory == 1){
        this.setState({rotnumbermodalordertab:rotnumber})
            console.log(json)
            //this.setState({detailorderlist:[]});   111111
             var lengthemployee = Object.keys(json.user).length;
             var lengthofjob = Object.keys(this.state["detailorderlist"+rownumber]).length;
             for(let i=0;i<lengthemployee;i++){

              // this.setState({
              //   shipperorder:json.user[i].Shipper,
              //   bookingnoorder:json.user[i].Bookingno,
              //   polorder:json.user[i].POL,
              //   podorder:json.user[i].POD,
              // })
              //status need do
              var numbershow = lengthofjob + i 
              this.setState({["container" +rownumber+ (numbershow.toString())]:json.user[i].Containerno})
              this.setState({["checksideloader" +rownumber+ (numbershow.toString())]:json.user[i].sideloader})



               this.setState({["detailorderlist"+rownumber]:[...this.state["detailorderlist"+rownumber],
                { no:lengthofjob + i+1,
                  consignmentno:json.user[i].Consignmentno,
                  containerno:json.user[i].Containerno,
                  checksideloader:json.user[i].sideloader,
                  requiredate:json.user[i].Requestdate-28800,
                  closedate:json.user[i].Closingday-28800,
                  type:json.user[i].Type,
                  activitystagingindate:json.user[i].Activitydatestaging-28800,
                  activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                  activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                  activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                  activitytransferbilldate:json.user[i].transferbilldate-28800,
                  activitytransferhistory:json.user[i].transferhistorydate-28800,
                  activitydatecreatenewtime:json.user[i].Activitydatecreatnew-28800,
                  shipper:json.user[i].Shipper,
                  importexport:json.user[i].importexport,
                  shippingline:json.user[i].Operator,
                  forwardingagent:json.user[i].Forwardingagent,
                  pol:json.user[i].POL,
                  pod:json.user[i].POD,
                  vesselvoyage:json.user[i].Vesselname,
                  voyagename:json.user[i].Vesselvoyage,
                  eta:json.user[i].Vsleta-28800,
                  closingday:json.user[i].Closingday-28800,
                  pickupyard:json.user[i].Pickupyard,
                  pic:json.user[i].Contactperson,
                  shippperaddress:json.user[i].Deliveryaddress,
                  remark:json.user[i].Remark,
                  billingto:json.user[i].Billtoparty,
                  commodity:json.user[i].Commodity,
                  bookingno:json.user[i].Bookingno,
                  contactpic:json.user[i].Contactnumber,
                },            
              ]})
              
              
             
               console.log(this.state["detailorderlist"+rownumber])
             

             
              }
              this.setState({["loadingmodalorder" + rownumber]:false})
            }        
               
         else{
          this.setState({loading:false}) 
          this.setState({["loadingmodalorder" + rownumber]:false})
        console.log(json)
      }
      this.setState({["loadingmodalorder" + rownumber]:false})
     })
     this.setState({["loadingmodalorder" + rownumber]:false})
  }







  getcreatedbywho = () =>{    
    
    //this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    let link = this.state.linkurl + 'mode=admin-getcreatedbywhoname'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              this.setState({userlist:[...this.state.userlist,{
                name:json.user[i].Name,
                ref:json.user[i].Ref,
                profilepictureurl:json.user[i].Profilepictureurl,
              }]})
                     
            }   
           // this.setState({loading:false})
          }     
               
         else{
          
         // this.setState({loading:false}) 
       console.log(json)
       // return "Null"
      }
    
     })

    // console.log(this.state.quotationtabledatafinal)
  }

  searchfunctionstartrotorderlist = (importexport,searchtext) =>{
    // if(this.state.searchtextvalue.length < 3){
    //  window.confirm('Please insert more than 3 word in Search box Order')
    // }else{
     this.setState({buttoncolor1:"white"})
     this.setState({buttoncolor2:"primary"})
   
     this.setState({loading:true})
     this.setState({tabs:2})
     this.setState({rotpendinglist:[]})
     var urlencoded = new URLSearchParams();
     var countrow = 0;
     if(searchtext == "" || searchtext == null){
      urlencoded.append("searchvalue",this.state.searchtextvalue);
     }else{
      urlencoded.append("searchvalue",searchtext);
     }
     //urlencoded.append("searchvalue",this.state.searchtextvalue);
     urlencoded.append("importexport",importexport);
     urlencoded.append("billtoparty", this.state.Billtoparty);
     let link = this.state.linkurl + 'mode=admin-getrotsearchlistorder'
     console.log("SEarchfunctionstartstart")
     console.log(searchtext)
     fetch(link, {
       method: 'POST', 
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/x-www-form-urlencoded',
       },
       body:urlencoded
     })
     .then(response => response.json())
     .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({rotpendinglist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             var indexofvalue = -1

                                 

             for(let i=0;i<lengthemployee;i++){
              
              //status need do
              if(this.state.rotpendinglist.some(item => item.rotno === json.user[i].Rotnumber)){             
                  //update counttotal here,
                  var filterobj = this.state.rotpendinglist.find(function(item, j){
                    if(item.rotno === json.user[i].Rotnumber){
                      indexofvalue = j;
                      //return j;
                    }
                  });
                  //var index = this.state.rotpendinglist.indexOf(item => item.rotno === json.user[i].Rotnumber)
                 console.log("truckintime"+json.user[i].Activitydatetruckin-28800)
                 console.log("indexofvalue"+indexofvalue)
                  this.state.rotpendinglist[indexofvalue].counttotal =   this.state.rotpendinglist[indexofvalue].counttotal + 1
                  if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
                    this.state.rotpendinglist[indexofvalue].countcompleted =   this.state.rotpendinglist[indexofvalue].countcompleted + 1
                  }
                console.log(json.user[i].Rotnumber)
                console.log(this.state.rotpendinglist)
                console.log(indexofvalue)
                //console.log(filterobj)
                console.log(this.state.rotpendinglist[0].counttotal)
              // }else if(rot have in all job then){
              //   add counttotal,
               }else{
                var tempcreatedbywho = "" ; 
                var tempcreatedbywhoprofileurl="";
                if(this.state.userlist != []){
                  for ( let k=0;k<this.state.userlist.length;k++){
                    if(this.state.userlist[k].ref === json.user[i].Createdby){
                      tempcreatedbywho =  this.state.userlist[k].name 
                      tempcreatedbywhoprofileurl = this.state.userlist[k].profilepictureurl
                    }
                  }   
                }
                countrow = countrow + 1 
                this.setState({["expand" + countrow]:false})
                if(json.user[i].Activitydatetruckin-28800 == 946684800-28800){
                  this.setState({rotpendinglist:[...this.state.rotpendinglist,
                    { no:countrow,
                      rotno:json.user[i].Rotnumber,
                      shippername:json.user[i].Shipper,
                      totalvolume:json.user[i].totalvolume,
                      bookingno:json.user[i].Bookingno,
                      status:1,
                      remark:json.user[i].Remark,
                      counttotal:1,
                      countcompleted:0,      
                      closingdate:json.user[i].Closingday-28800,    
                      createdby:tempcreatedbywho,
                      createdbywhoref:tempcreatedbywhoprofileurl,
                    },            
                  ]})
                }else{
                  this.setState({rotpendinglist:[...this.state.rotpendinglist,
                    { no:countrow,
                      rotno:json.user[i].Rotnumber,
                      shippername:json.user[i].Shipper,
                      totalvolume:json.user[i].totalvolume,
                      bookingno:json.user[i].Bookingno,
                      status:1,
                      remark:json.user[i].Remark,
                      counttotal:1,
                      countcompleted:1,      
                      closingdate:json.user[i].Closingday-28800,    
                      createdby:tempcreatedbywho,
                      createdbywhoref:tempcreatedbywhoprofileurl,
                    },            
                  ]})
                }
                
               }

              //then check bill, and history, 
             // add counttotal, add countcompleted,
             
              
              
             
               //console.log(this.state.rotpendinglist)
             

             
              }
              this.searchfunctionstartrotorderlistbillinghistory(importexport,searchtext)
             //this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
    //}
  
  
   
   }

   searchfunctionstartrotorderlistbillinghistory = (importexport,searchtext) =>{
    

     this.setState({loading:true})     
     var urlencoded = new URLSearchParams();
     var countrow = 0;
     if(searchtext == "" || searchtext == null){
      urlencoded.append("searchvalue",this.state.searchtextvalue);
     }else{
      urlencoded.append("searchvalue",searchtext);
     }
     urlencoded.append("importexport",importexport);
     urlencoded.append("billtoparty", this.state.Billtoparty);
     let link = this.state.linkurl + 'mode=admin-getrotsearchlistorderbillinghistory'
    
     fetch(link, {
       method: 'POST', 
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/x-www-form-urlencoded',
       },
       body:urlencoded
     })
     .then(response => response.json())
     .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
        console.log(json)
        //this.setState({rotpendinglist:[]});   
         var lengthemployee = Object.keys(json.user).length;
         var indexofvalue = -1
         for(let i=0;i<lengthemployee;i++){
          
          //status need do
          if(this.state.rotpendinglist.some(item => item.rotno === json.user[i].Rotnumber)){             
              //update counttotal here,
              console.log("Same Rot: " + json.user[i].Rotnumber);
              var filterobj = this.state.rotpendinglist.find(function(item, j){
                if(item.rotno === json.user[i].Rotnumber){
                  indexofvalue = j;
                  //return j;
                }
              });
              //var index = this.state.rotpendinglist.indexOf(item => item.rotno === json.user[i].Rotnumber)
              this.state.rotpendinglist[indexofvalue].counttotal =   this.state.rotpendinglist[indexofvalue].counttotal + 1
              this.state.rotpendinglist[indexofvalue].countcompleted =   this.state.rotpendinglist[indexofvalue].countcompleted + 1
            // console.log(json.user[i].Rotnumber)
            // console.log(this.state.rotpendinglist)
            // console.log(indexofvalue)
            // console.log(filterobj)
          //  console.log(this.state.rotpendinglist[0].counttotal)
           // console.log(this.state.rotpendinglist[0].countcompleted)
          // }else if(rot have in all job then){
          //   add counttotal,
           }

          //then check bill, and history, 
         // add counttotal, add countcompleted,
         
          
          
         
           //console.log(this.state.rotpendinglist)
         

         
          }
          this.setState({loading:false})  
        }        
           
     else{
      this.setState({loading:false}) 
    console.log(json)
  }
     })
    
  
  
   
   }

   searchfunctionstartorderpending = (importexport) =>{
    // if(this.state.searchtextvalue.length < 3){
    //  window.confirm('Please insert more than 3 word in Search box Order')
    // }else{
   
     this.setState({loading:true})
     this.setState({rotpendinglist:[]})
     var urlencoded = new URLSearchParams();
     var countrow = 0;
     urlencoded.append("searchvalue",this.state.searchtextvalue);
     urlencoded.append("importexport",importexport);
     urlencoded.append("billtoparty", this.state.Billtoparty);
     let link = this.state.linkurl + 'mode=admin-getrotsearchlistorderpending'
    
     fetch(link, {
       method: 'POST', 
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/x-www-form-urlencoded',
       },
       body:urlencoded
     })
     .then(response => response.json())
     .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({rotpendinglist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              var tempcreatedbywho = "" ; 
              var tempcreatedbywhoprofileurl="";
              if(this.state.userlist != []){
                for ( let k=0;k<this.state.userlist.length;k++){
                  if(this.state.userlist[k].ref === json.user[i].Createdby){
                    tempcreatedbywho =  this.state.userlist[k].name 
                    tempcreatedbywhoprofileurl = this.state.userlist[k].profilepictureurl
                  }
                }   
              }
              countrow = countrow + 1 
              this.setState({["expandpendingimport" + countrow]:false})
              //status need do
               this.setState({rotpendinglist:[...this.state.rotpendinglist,
                { no:countrow,
                  rotno:json.user[i].Rotnumber,
                  shippername:json.user[i].Shipper,
                  totalvolume:json.user[i].totalvolume,
                  bookingno:json.user[i].Bookingno,
                  status:"Pending",
                  remark:json.user[i].Remark,
                  importexport:json.user[i].importexport,
                  createdby:tempcreatedbywho,
                  createdbywhoref:tempcreatedbywhoprofileurl,
                },            
              ]})
              
              
             
               console.log(this.state.rotpendinglist)
             

             
              }
              
             // this.setState({loading:false})  
             this.searchfunctionstartordercancelled(importexport)
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
        this.searchfunctionstartordercancelled(importexport)
      }
    
     })
   // }
  
  
   
   }

   searchfunctionstartordercancelled = (importexport) =>{
   
     this.setState({loading:true})   
     var urlencoded = new URLSearchParams();
     var countrow = 0;
     if(this.state.rotpendinglist != null){
       countrow = this.state.rotpendinglist.length
     }
     urlencoded.append("searchvalue",this.state.searchtextvalue);
     urlencoded.append("importexport",importexport);
     urlencoded.append("billtoparty", this.state.Billtoparty);
     let link = this.state.linkurl + 'mode=admin-getrotsearchlistordercancelled'
    
     fetch(link, {
       method: 'POST', 
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/x-www-form-urlencoded',
       },
       body:urlencoded
     })
     .then(response => response.json())
     .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            //this.setState({rotpendinglist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              var tempcreatedbywho = "" ; 
              var tempcreatedbywhoprofileurl = "";
              if(this.state.userlist != []){
                for ( let k=0;k<this.state.userlist.length;k++){
                  if(this.state.userlist[k].ref === json.user[i].Createdby){
                    tempcreatedbywho =  this.state.userlist[k].name 
                    tempcreatedbywhoprofileurl = this.state.userlist[k].profilepictureurl
                  }
                }   
              }
              countrow = countrow + 1 
              this.setState({["expandpendingimport" + countrow]:false})
              //status need do
               this.setState({rotpendinglist:[...this.state.rotpendinglist,
                { no:countrow,
                  rotno:json.user[i].Rotnumber,
                  shippername:json.user[i].Shipper,
                  totalvolume:json.user[i].totalvolume,
                  bookingno:json.user[i].Bookingno,
                  status:"Cancelled",
                  remark:json.user[i].Remark,
                  importexport:json.user[i].importexport,
                  createdby:tempcreatedbywho,
                  createdbywhoref:tempcreatedbywhoprofileurl,
                },            
              ]})
              
              
             
               console.log(this.state.rotpendinglist)
             

             
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
    
  
  
   
   }

   searchfunctionstartrotcompletedlist = (importexport) =>{
    // if(this.state.searchtextvalue.length < 3){
    //  window.confirm('Please insert more than 3 word in Search box Order')
    // }else{
      this.setState({loading:true})
      var countrow = 0;
      var urlencoded = new URLSearchParams();
      urlencoded.append("searchvalue", this.state.searchtextvalue);
      urlencoded.append("billtoparty", this.state.Billtoparty);
      urlencoded.append("importexport", importexport);
      let link = this.state.linkurl + 'mode=admin-getrotsearchlistordercompleted'
      fetch(link, {
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body:urlencoded
      })
      .then(response => response.json())
      .then(json => {
        console.log(json.success)
        if(json.success == 1){
          
              console.log(json)
              this.setState({completelist:[]});   
               var lengthemployee = Object.keys(json.user).length;
               for(let i=0;i<lengthemployee;i++){
                var tempcreatedbywho = "" ; 
                var tempcreatedbywhoprofileurl ="";
                if(this.state.userlist != []){
                  for ( let k=0;k<this.state.userlist.length;k++){
                    if(this.state.userlist[k].ref === json.user[i].Createdby){
                      tempcreatedbywho =  this.state.userlist[k].name 
                      tempcreatedbywhoprofileurl = this.state.userlist[k].profilepictureurl
                    }
                  }   
                }
                countrow = countrow + 1 
                this.setState({["expandcompletedimport" + countrow]:false})
                //status need doasdf
                 this.setState({completelist:[...this.state.completelist,
                  { no:countrow,
                    rotno:json.user[i].Rotnumber,
                    shippername:json.user[i].Shipper,
                    totalvolume:json.user[i].totalvolume,
                    bookingno:json.user[i].Bookingno,
                    status:"Completed",
                    remark:json.user[i].Remark,
                    createdby:tempcreatedbywho,
                    createdbywhoref:tempcreatedbywhoprofileurl,
                    nocolumn:1,
                  },            
                ]})
                
                
               
                 console.log(this.state.completelist)
               
  
               
                }
                //this.setState({loading:false})  111
               this.searchfunctionstartrotcompletedbill(importexport);
              }        
                 
           else{
            this.setState({loading:false}) 
          console.log(json)
          this.searchfunctionstartrotcompletedbill(importexport);
        }
      
       })
   // }
  
  
   
   }

   searchfunctionstartrotcompletedbill = (importexport) =>{
    this.setState({loading:true})
    var countrow = this.state.completelist.length;
    var urlencoded = new URLSearchParams();
    urlencoded.append("searchvalue", this.state.searchtextvalue);
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("importexport", importexport);
    let link = this.state.linkurl + 'mode=admin-getrotsearchlistordercompletedbilling'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            //this.setState({completelist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              var tempcreatedbywho = "" ; 
              var tempcreatedbywhoprofileurl ="";
              if(this.state.userlist != []){
                for ( let k=0;k<this.state.userlist.length;k++){
                  if(this.state.userlist[k].ref === json.user[i].Createdby){
                    tempcreatedbywho =  this.state.userlist[k].name 
                    tempcreatedbywhoprofileurl=this.state.userlist[k].profilepictureurl
                  }
                }   
              }
              countrow = countrow + 1 
              this.setState({["expandcompletedimport" + countrow]:false})
              //status need do
               this.setState({completelist:[...this.state.completelist,
                { no:countrow,
                  rotno:json.user[i].Rotnumber,
                  shippername:json.user[i].Shipper,
                  totalvolume:json.user[i].totalvolume,
                  bookingno:json.user[i].Bookingno,
                  status:"Billing",
                  remark:json.user[i].Remark,
                  createdby:tempcreatedbywho,
                  createdbywhoref:tempcreatedbywhoprofileurl,
                  nocolumn:1,
                },            
              ]})              
              
              

             
              }
              
              console.log(this.state.completelist.sort((a,b)=>b.rotno -a.rotno))
              //after sort reinput number
              for(let x=0;x<this.state.completelist.length;x++){
                this.state.completelist[x].nocolumn = x + 1;
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
   }

   searchfunctionstart=()=>{
    this.setState({currentcompletedpagenumber:1})
    this.setState({startDate:'-'})
    this.setState({endDate:'-'})
    if(this.state.tabs == 1){
      if(this.state.tabsimportexport == 1){
        this.searchfunctionstartorderpending("Import")
      }else if(this.state.tabsimportexport == 2){
        this.searchfunctionstartorderpending("Export")
      }
      
    }else if(this.state.tabs == 2){
      console.log("tabsimportexport: "+this.state.tabsimportexport)
      if(this.state.tabsimportexport == 1){
        this.searchfunctionstartrotorderlist("Import")
      }else if(this.state.tabsimportexport == 2){
        this.searchfunctionstartrotorderlist("Export")
      }
   
    }else if(this.state.tabs == 3){
      if(this.state.tabsimportexport == 1){
        this.searchfunctionstartrotcompletedlist("Import")
      }else if(this.state.tabsimportexport == 2){
        this.searchfunctionstartrotcompletedlist("Export")
      }
    
    }
  }

  searchdatecompletedate = (importexport) =>{
    if(this.state.startDate == '-' ||  this.state.endDate == '-' ){
      window.confirm('Invalid date for search completed job')
    }else if(this.state.startDate > this.state.endDate){
      window.confirm('From Date must before To Date')
    }
      else{
        
      this.getcompletedorderapiwithdaterange(importexport);
    } 
  }

  cancelledsearchbuttonclick = () =>{
    if(this.state.tabs == 1){
     this.setState({searchtextvalue:""})
     this.setState({currentcompletedpagenumber:1})
     if(this.state.tabsimportexport == 1){
      this.getpendingorderlistapi("Import");
     }else{
      this.getpendingorderlistapi("Export");
     }
     
    }else if( this.state.tabs ==2){
     this.setState({searchtextvalue:""})
     if(this.state.tabsimportexport == 1){
      this.getorderlistapi("Import");
     }else{
      this.getorderlistapi("Export");
     }
    }else if( this.state.tabs ==3){
      this.setState({searchtextvalue:""})
      if(this.state.tabsimportexport == 1){
        this.getcompletedorderapiwithdaterange("Import");
       }else{
        this.getcompletedorderapiwithdaterange("Export");
       }
     }      
  }

  nextpaginationfunction = (e) =>{
    if(this.state.completelist.length/100 <= this.state.currentcompletedpagenumber){
      
    }else{
     this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber +1})
    }
  }
 
  previouspaginationfunction = (e) =>{
   if(this.state.currentcompletedpagenumber== 1){
     
   }else{
    this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber - 1})
   }
  }

  renderTablePagination() {
         
      { if( this.state.completelist.length/100 < 1){
        return( null )
      }else if(this.state.completelist.length/100 >= 1 && this.state.completelist.length/100 < 2 ){
       return (
         
            
          <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                           <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                            <PaginationLink href="#pablo" onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>      
                 <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
                  
                 )
      }else if(this.state.completelist.length/100 >= 2 && this.state.completelist.length/100 < 3 ){
       return (
          
               
             <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>      
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               
             
            


                 )
      }else if(this.state.completelist.length/100 >= 3 && this.state.completelist.length/100 < 4 ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                       <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber ==2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>          
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      }else if(this.state.completelist.length/100 >= 4 && this.state.completelist.length/100 < 5 ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>   
                          <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                              5
                            </PaginationLink>
                          </PaginationItem>           
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      }else if(this.state.completelist.length/100 >= 5  ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                    <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>   
                          <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                              5
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 6 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:6})}>
                              ...
                            </PaginationLink>
                          </PaginationItem>           
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      } 
      
     }
  }

  expandbuttonclick = (no,rot,rotlength) =>{
    console.log("rotnumbershow:" + rot)

    for(let i =0 ; i<rotlength ;i++){
      this.setState({["expand" + i]:false})
    }
    if(this.state["expand" + no] == false){
      this.showmodalapifunctioncalldetailordertab(rot,no)
    }
   
    this.setState({["expand" + no]:!this.state["expand"+no]})
    //this.divclicked
  }
  
  expandbuttonclickpending = (no,rot,status,rotlength) =>{
    console.log("rotnumbershow:" + rot)

    for(let i =0 ; i<rotlength ;i++){
      this.setState({["expandpendingimport" + i]:false})
    }
    if(this.state["expandpendingimport" + no] == false){
      if(status == "Pending"){
        this.showmodalapifunctioncalldetail(rot,no)
      }else if(status =="Cancelled"){
        this.showmodalapifunctioncalldetailcancelled(rot,no)
      }
     
    }
   
    this.setState({["expandpendingimport" + no]:!this.state["expandpendingimport"+no]})
    //this.divclicked
  }
  
  expandbuttonclickcompleted= (no,rot,status,rotlength) =>{
    console.log("rotnumbershow:" + rot)

    for(let i =0 ; i<rotlength ;i++){
      this.setState({["expandcompletedimport" + i]:false})
    }
    if(this.state["expandcompletedimport" + no] == false){
      if(status == "Billing"){
        this.showmodalapifunctioncalldetailbillingtab(rot,no)
      }else if(status =="Completed"){
        this.showmodalapifunctioncalldetailcompletetab(rot,no)
      }
     
    }
   
    this.setState({["expandcompletedimport" + no]:!this.state["expandcompletedimport"+no]})
    //this.divclicked
  }

  deleteorcancelpendingrot = (rotnumber) =>{
    
    console.log(rotnumber)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("cancelpendingrotnumber",rotnumber)
     let link = this.state.linkurl + 'mode=admin-deletependingrotnumber'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
        window.confirm('Your Rot had cancelled success.')
        console.log(json)
       }        
                   
         else{
          window.confirm('Something wrong please try again later.')
        console.log(json)
      }
    
     })


     if(this.state.tabsimportexport == 1){
      this.pendingorderbuttonclicked("Import");
     }else{
      this.pendingorderbuttonclicked("Export");
     }
    
  }

  renderPendingOrderData() {
      
    return this.state.rotpendinglist.map((data, index) => {
       const {createdby,createdbywhoref,importexport, no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
       var rotlength = Object.keys(this.state.rotpendinglist).length;
       var volumeshow = totalvolume.slice(0,35)
       return (
    
            
            <tbody>
              
             <tr>
   
            <td>{no})</td>
              <td >{rotno}</td>
              <td >{importexport}</td>
                  <td >
                
                        <span className="mb-0 text-sm">
                          {shippername}
                        </span>
                    
                  </td>
                  <td>{volumeshow}</td>
                  <td>{bookingno}</td>    
                  <td>
                    <Badge color="" className="badge-dot mr-4">
                        {status =="Pending"                
                        ?
                        <i className="bg-info" />
                        :
                        <i className="bg-warning" />
                      }
                      
                        {status}
                      </Badge>
                  </td>  
              
                  <td>
                          <i  onClick={(e)=>this.expandbuttonclickpending(no,rotno,status,rotlength)} className="ni ni-bold-down" />          
                        </td>
                  </tr>    
                  {this.state["expandpendingimport"+no] == false?
                  null:
                  <tr>
                  <td colspan="8">   
               
                    {this.renderinsideexpandpending(no,createdby,createdbywhoref,rotno,status)}
                
                </td>
                </tr>
                }
                </tbody>

       
       
   
       )
    })
  }

  renderPendingOrderDataphone2() {
      
    return this.state.rotpendinglist.map((data, index) => {
       const {createdby,createdbywhoref,importexport, no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
       var rotlength = Object.keys(this.state.rotpendinglist).length;
       var volumeshow = totalvolume.slice(0,35)
       return (
             <div className="mt-1 cardviewbox"> 
              <div className="horizontaldirection">
                <h4 className="ml-4 mr-10 phoneviewcontent">{shippername}</h4>
                <p className="ml-auto mr-20 phoneviewcontent">
                <i  onClick={(e)=>this.expandbuttonclickpending(no,rotno,status,rotlength)} className="ni ni-bold-down" />                   
                          </p>
                </div> 
                <p className="ml-4 mr-10 phoneviewcontent mt-2">{rotno}</p>                  
                <p className="ml-4 mr-10 phoneviewcontent mt-2">{importexport}</p>    
                <p className="ml-4 mr-10 phoneviewcontent mt-2">{volumeshow}</p>
                <p className="ml-4 mr-10 phoneviewcontent mt-2">{bookingno}</p>
                <p className="ml-4 mr-10 phoneviewcontent mt-2">
                    <Badge color="" className="badge-dot mr-4">
                                  {status =="Pending"                
                                  ?
                                  <i className="bg-info" />
                                  :
                                  <i className="bg-warning" />
                                }
                                
                                  {status}
                      </Badge>
                  </p>                             
                        {this.state["expandpendingimport"+no] == false?
                        null:
                                <div>
                            {this.renderinsideexpandpending(no,createdby,createdbywhoref,rotno,status)}
                      </div>                   
                        
                }
                </div>

       
       
   
       )
    })
  }

  rendercompletedOrderData() {

    if(this.state.currentcompletedpagenumber ==1){
      return this.state.completelist.slice(0,100).map((data, index) => {
        const {nocolumn, createdby, createdbywhoref,no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
        var volumeshow = totalvolume.slice(0,35)
        var rotlength = Object.keys(this.state.completelist).length;
        return ( 
           <tbody>
             
           <tr>
 
          <td >{nocolumn})</td>
            <td >{rotno}</td>
                <td  >
              
                      <span className="mb-0 text-sm">
                        {shippername}
                      </span>
                  
                </td>
                <td >{volumeshow}</td>
                <td>{bookingno}</td>    
                <td>
                      <Badge color="" className="badge-dot mr-4">
                        {status =="Completed"                
                        ?
                        <i className="bg-success" />
                        :
                        <i className="bg-info" />
                      }
                      
                        {status}
                      </Badge>
                </td>  
            
                <td>
                        <i  onClick={(e)=>this.expandbuttonclickcompleted(no,rotno,status,rotlength)} className="ni ni-bold-down" />          
                      </td>
                </tr>    
  
             <tr>

             </tr>

            
              {this.state["expandcompletedimport"+no] == false?
                null:
                <tr>
                    <td colspan="7">  
                     { this.renderinsideexpandcompleted(no,createdby,createdbywhoref,rotno,status)}
                   </td>
                </tr>
                 
              }
              
              </tbody>
        )
     })


    }else if(this.state.currentcompletedpagenumber ==2){
      return this.state.completelist.slice(100,200).map((data, index) => {
        const {nocolumn, createdby, createdbywhoref,no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
        var volumeshow = totalvolume.slice(0,35)
        var rotlength = Object.keys(this.state.completelist).length;
        return (
         
          
         <tbody>
           
         <tr>

        <td >{nocolumn})</td>
          <td >{rotno}</td>
              <td  >
            
                    <span className="mb-0 text-sm">
                      {shippername}
                    </span>
                
              </td>
              <td >{volumeshow}</td>
              <td>{bookingno}</td>    
              <td>
                    <Badge color="" className="badge-dot mr-4">
                      {status =="Completed"                
                      ?
                      <i className="bg-success" />
                      :
                      <i className="bg-info" />
                    }
                    
                      {status}
                    </Badge>
              </td>  
          
              <td>
                      <i  onClick={(e)=>this.expandbuttonclickcompleted(no,rotno,status,rotlength)} className="ni ni-bold-down" />          
                    </td>
              </tr>    

           <tr>

           </tr>

          
            {this.state["expandcompletedimport"+no] == false?
              null:
              <tr>
                  <td colspan="7">  
                   { this.renderinsideexpandcompleted(no,createdby,createdbywhoref,rotno,status)}
                 </td>
              </tr>
               
            }
            
            </tbody>
   
         
        )
     })
    }else if(this.state.currentcompletedpagenumber ==3){
      return this.state.completelist.slice(200,300).map((data, index) => {
        const {nocolumn, createdby, createdbywhoref,no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
        var volumeshow = totalvolume.slice(0,35)
        var rotlength = Object.keys(this.state.completelist).length;
        return (
 
          
         <tbody>
           
         <tr>

        <td >{nocolumn})</td>
          <td >{rotno}</td>
              <td  >
            
                    <span className="mb-0 text-sm">
                      {shippername}
                    </span>
                
              </td>
              <td >{volumeshow}</td>
              <td>{bookingno}</td>    
              <td>
                    <Badge color="" className="badge-dot mr-4">
                      {status =="Completed"                
                      ?
                      <i className="bg-success" />
                      :
                      <i className="bg-info" />
                    }
                    
                      {status}
                    </Badge>
              </td>  
          
              <td>
                      <i  onClick={(e)=>this.expandbuttonclickcompleted(no,rotno,status,rotlength)} className="ni ni-bold-down" />          
                    </td>
              </tr>    

           <tr>

           </tr>

          
            {this.state["expandcompletedimport"+no] == false?
              null:
              <tr>
                  <td colspan="7">  
                   { this.renderinsideexpandcompleted(no,createdby,createdbywhoref,rotno,status)}
                 </td>
              </tr>
               
            }
            
            </tbody>
   
    
        )
     })
    }else if(this.state.currentcompletedpagenumber ==4){
      return this.state.completelist.slice(300,400).map((data, index) => {
        const { nocolumn,createdby, createdbywhoref,no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
        var volumeshow = totalvolume.slice(0,35)
        var rotlength = Object.keys(this.state.completelist).length;
        return (
        
           <tbody>
             
           <tr>
 
          <td >{nocolumn})</td>
            <td >{rotno}</td>
                <td  >
              
                      <span className="mb-0 text-sm">
                        {shippername}
                      </span>
                  
                </td>
                <td >{volumeshow}</td>
                <td>{bookingno}</td>    
                <td>
                      <Badge color="" className="badge-dot mr-4">
                        {status =="Completed"                
                        ?
                        <i className="bg-success" />
                        :
                        <i className="bg-info" />
                      }
                      
                        {status}
                      </Badge>
                </td>  
            
                <td>
                        <i  onClick={(e)=>this.expandbuttonclickcompleted(no,rotno,status,rotlength)} className="ni ni-bold-down" />          
                      </td>
                </tr>    
  
             <tr>

             </tr>

            
              {this.state["expandcompletedimport"+no] == false?
                null:
                <tr>
                    <td colspan="7">  
                     { this.renderinsideexpandcompleted(no,createdby,createdbywhoref,rotno,status)}
                   </td>
                </tr>
                 
              }
              
              </tbody>
     
    
        )
     })
    }else if(this.state.currentcompletedpagenumber ==5){
      return this.state.completelist.slice(400,500).map((data, index) => {
        const {nocolumn, createdby, createdbywhoref,no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
        var volumeshow = totalvolume.slice(0,35)
        var rotlength = Object.keys(this.state.completelist).length;
        return (
         
            
           <tbody>
             
           <tr>
 
          <td >{nocolumn})</td>
            <td >{rotno}</td>
                <td  >
              
                      <span className="mb-0 text-sm">
                        {shippername}
                      </span>
                  
                </td>
                <td >{volumeshow}</td>
                <td>{bookingno}</td>    
                <td>
                      <Badge color="" className="badge-dot mr-4">
                        {status =="Completed"                
                        ?
                        <i className="bg-success" />
                        :
                        <i className="bg-info" />
                      }
                      
                        {status}
                      </Badge>
                </td>  
            
                <td>
                        <i  onClick={(e)=>this.expandbuttonclickcompleted(no,rotno,status,rotlength)} className="ni ni-bold-down" />          
                      </td>
                </tr>    
  
             <tr>

             </tr>

            
              {this.state["expandcompletedimport"+no] == false?
                null:
                <tr>
                    <td colspan="7">  
                     { this.renderinsideexpandcompleted(no,createdby,createdbywhoref,rotno,status)}
                   </td>
                </tr>
                 
              }
              
              </tbody>
     
    
        )
     })
    }else if(this.state.currentcompletedpagenumber ==6){
      return this.state.completelist.slice(500,this.state.completelist.length).map((data, index) => {
        const {nocolumn, createdby, createdbywhoref,no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
        var volumeshow = totalvolume.slice(0,35)
        var rotlength = Object.keys(this.state.completelist).length;
        return (
         
         <tbody>
           
         <tr>

        <td >{nocolumn})</td>
          <td >{rotno}</td>
              <td  >
            
                    <span className="mb-0 text-sm">
                      {shippername}
                    </span>
                
              </td>
              <td >{volumeshow}</td>
              <td>{bookingno}</td>    
              <td>
                    <Badge color="" className="badge-dot mr-4">
                      {status =="Completed"                
                      ?
                      <i className="bg-success" />
                      :
                      <i className="bg-info" />
                    }
                    
                      {status}
                    </Badge>
              </td>  
          
              <td>
                      <i  onClick={(e)=>this.expandbuttonclickcompleted(no,rotno,status,rotlength)} className="ni ni-bold-down" />          
                    </td>
              </tr>    

           <tr>

           </tr>

          
            {this.state["expandcompletedimport"+no] == false?
              null:
              <tr>
                  <td colspan="7">  
                   { this.renderinsideexpandcompleted(no,createdby,createdbywhoref,rotno,status)}
                 </td>
              </tr>
               
            }
            
            </tbody>
   
         
    
        )
     })
    }
   
   
  }

  rendercompletedOrderDataphone2() {
    
    if(this.state.currentcompletedpagenumber ==1){
      return this.state.completelist.slice(0,100).map((data, index) => {
        const {nocolumn, createdby, createdbywhoref,no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
        var volumeshow = totalvolume.slice(0,35)
        var rotlength = Object.keys(this.state.completelist).length;
        return (
 
         
              <div className="mt-1 cardviewbox">
              <div className="horizontaldirection">
              <h4 className="ml-4 mr-10 phoneviewcontent">{shippername}</h4>
              <p className="ml-auto mr-20 phoneviewcontent">
              <i  onClick={(e)=>this.expandbuttonclickcompleted(no,rotno,status,rotlength)} className="ni ni-bold-down" />                   
                        </p>
              </div> 
              <p className="ml-4 mr-10 phoneviewcontent mt-2">{rotno}</p>             
              <p className="ml-4 mr-10 phoneviewcontent mt-2">{volumeshow}</p>
              <p className="ml-4 mr-10 phoneviewcontent mt-2">{bookingno}</p>
              <p className="ml-4 mr-10 phoneviewcontent mt-2">
                 <Badge color="" className="badge-dot mr-4">
                        {status =="Completed"                
                        ?
                        <i className="bg-success" />
                        :
                        <i className="bg-info" />
                      }
                      
                        {status}
                      </Badge>
                </p>                             
                    
                    
                      {this.state["expandcompletedimport"+no] == false?
                      null:
                              <div>
                          {this.renderinsideexpandcompletedphone(no,createdby,createdbywhoref,rotno,status)}
                    </div>
                      }
     
                  </div>
      

    
        )
     })
    }else if(this.state.currentcompletedpagenumber ==2){
      return this.state.completelist.slice(100,200).map((data, index) => {
        const {nocolumn, createdby, createdbywhoref,no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
        var volumeshow = totalvolume.slice(0,35)
        var rotlength = Object.keys(this.state.completelist).length;
        return (
        
            <div className="mt-1 cardviewbox">

            <div className="horizontaldirection">
            <h4 className="ml-4 mr-10 phoneviewcontent">{shippername}</h4>
            <p className="ml-auto mr-20 phoneviewcontent">
            <i  onClick={(e)=>this.expandbuttonclickcompleted(no,rotno,status,rotlength)} className="ni ni-bold-down" />                   
                      </p>
            </div> 
            <p className="ml-4 mr-10 phoneviewcontent mt-2">{rotno}</p>             
            <p className="ml-4 mr-10 phoneviewcontent mt-2">{volumeshow}</p>
            <p className="ml-4 mr-10 phoneviewcontent mt-2">{bookingno}</p>
            <p className="ml-4 mr-10 phoneviewcontent mt-2">
               <Badge color="" className="badge-dot mr-4">
                      {status =="Completed"                
                      ?
                      <i className="bg-success" />
                      :
                      <i className="bg-info" />
                    }
                    
                      {status}
                    </Badge>
              </p>                             
                  
                  
                    {this.state["expandcompletedimport"+no] == false?
                    null:
                            <div>
                        {this.renderinsideexpandcompletedphone(no,createdby,createdbywhoref,rotno,status)}
                  </div>
                    }
   
                </div>
    

        )
     })
    }else if(this.state.currentcompletedpagenumber ==3){
      return this.state.completelist.slice(200,300).map((data, index) => {
        const {nocolumn, createdby, createdbywhoref,no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
        var volumeshow = totalvolume.slice(0,35)
        var rotlength = Object.keys(this.state.completelist).length;
        return (
 
         
            <div className="mt-1 cardviewbox">

            <div className="horizontaldirection">
            <h4 className="ml-4 mr-10 phoneviewcontent">{shippername}</h4>
            <p className="ml-auto mr-20 phoneviewcontent">
            <i  onClick={(e)=>this.expandbuttonclickcompleted(no,rotno,status,rotlength)} className="ni ni-bold-down" />                   
                      </p>
            </div> 
            <p className="ml-4 mr-10 phoneviewcontent mt-2">{rotno}</p>             
            <p className="ml-4 mr-10 phoneviewcontent mt-2">{volumeshow}</p>
            <p className="ml-4 mr-10 phoneviewcontent mt-2">{bookingno}</p>
            <p className="ml-4 mr-10 phoneviewcontent mt-2">
               <Badge color="" className="badge-dot mr-4">
                      {status =="Completed"                
                      ?
                      <i className="bg-success" />
                      :
                      <i className="bg-info" />
                    }
                    
                      {status}
                    </Badge>
              </p>                             
                  
                  
                    {this.state["expandcompletedimport"+no] == false?
                    null:
                            <div>
                        {this.renderinsideexpandcompletedphone(no,createdby,createdbywhoref,rotno,status)}
                  </div>
                    }
   
                </div>
    

          
    
        )
     })
    }else if(this.state.currentcompletedpagenumber ==4){
      return this.state.completelist.slice(300,400).map((data, index) => {
        const { nocolumn,createdby, createdbywhoref,no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
        var volumeshow = totalvolume.slice(0,35)
        var rotlength = Object.keys(this.state.completelist).length;
        return (
        
           
              <div className="mt-1 cardviewbox">

              <div className="horizontaldirection">
              <h4 className="ml-4 mr-10 phoneviewcontent">{shippername}</h4>
              <p className="ml-auto mr-20 phoneviewcontent">
              <i  onClick={(e)=>this.expandbuttonclickcompleted(no,rotno,status,rotlength)} className="ni ni-bold-down" />                   
                        </p>
              </div> 
              <p className="ml-4 mr-10 phoneviewcontent mt-2">{rotno}</p>             
              <p className="ml-4 mr-10 phoneviewcontent mt-2">{volumeshow}</p>
              <p className="ml-4 mr-10 phoneviewcontent mt-2">{bookingno}</p>
              <p className="ml-4 mr-10 phoneviewcontent mt-2">
                 <Badge color="" className="badge-dot mr-4">
                        {status =="Completed"                
                        ?
                        <i className="bg-success" />
                        :
                        <i className="bg-info" />
                      }
                      
                        {status}
                      </Badge>
                </p>                             
                    
                    
                      {this.state["expandcompletedimport"+no] == false?
                      null:
                              <div>
                          {this.renderinsideexpandcompletedphone(no,createdby,createdbywhoref,rotno,status)}
                    </div>
                      }
     
                  </div>
      
    
        )
     })
    }else if(this.state.currentcompletedpagenumber ==5){
      return this.state.completelist.slice(400,500).map((data, index) => {
        const {nocolumn, createdby, createdbywhoref,no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
        var volumeshow = totalvolume.slice(0,35)
        var rotlength = Object.keys(this.state.completelist).length;
        return (
         
              <div className="mt-1 cardviewbox">

              <div className="horizontaldirection">
              <h4 className="ml-4 mr-10 phoneviewcontent">{shippername}</h4>
              <p className="ml-auto mr-20 phoneviewcontent">
              <i  onClick={(e)=>this.expandbuttonclickcompleted(no,rotno,status,rotlength)} className="ni ni-bold-down" />                   
                        </p>
              </div> 
              <p className="ml-4 mr-10 phoneviewcontent mt-2">{rotno}</p>             
              <p className="ml-4 mr-10 phoneviewcontent mt-2">{volumeshow}</p>
              <p className="ml-4 mr-10 phoneviewcontent mt-2">{bookingno}</p>
              <p className="ml-4 mr-10 phoneviewcontent mt-2">
                 <Badge color="" className="badge-dot mr-4">
                        {status =="Completed"                
                        ?
                        <i className="bg-success" />
                        :
                        <i className="bg-info" />
                      }
                      
                        {status}
                      </Badge>
                </p>                             
                    
                    
                      {this.state["expandcompletedimport"+no] == false?
                      null:
                              <div>
                          {this.renderinsideexpandcompletedphone(no,createdby,createdbywhoref,rotno,status)}
                    </div>
                      }
     
                  </div>
      
        )
     })
    }else if(this.state.currentcompletedpagenumber ==6){
      return this.state.completelist.slice(500,this.state.completelist.length).map((data, index) => {
        const {nocolumn, createdby, createdbywhoref,no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
        var volumeshow = totalvolume.slice(0,35)
        var rotlength = Object.keys(this.state.completelist).length;
        return (
        
            <div className="mt-1 cardviewbox">

            <div className="horizontaldirection">
            <h4 className="ml-4 mr-10 phoneviewcontent">{shippername}</h4>
            <p className="ml-auto mr-20 phoneviewcontent">
            <i  onClick={(e)=>this.expandbuttonclickcompleted(no,rotno,status,rotlength)} className="ni ni-bold-down" />                   
                      </p>
            </div> 
            <p className="ml-4 mr-10 phoneviewcontent mt-2">{rotno}</p>             
            <p className="ml-4 mr-10 phoneviewcontent mt-2">{volumeshow}</p>
            <p className="ml-4 mr-10 phoneviewcontent mt-2">{bookingno}</p>
            <p className="ml-4 mr-10 phoneviewcontent mt-2">
               <Badge color="" className="badge-dot mr-4">
                      {status =="Completed"                
                      ?
                      <i className="bg-success" />
                      :
                      <i className="bg-info" />
                    }
                    
                      {status}
                    </Badge>
              </p>                             
                  
                  
                    {this.state["expandcompletedimport"+no] == false?
                    null:
                            <div>
                        {this.renderinsideexpandcompletedphone(no,createdby,createdbywhoref,rotno,status)}
                  </div>
                    }
   
                </div>
    

    
        )
     })
    }
   
   
  }

  renderOrderListData() {
      

  return this.state.rotpendinglist.map((data, index) => {
     const {createdbywhoref,createdby, no, rotno, shippername, totalvolume ,bookingno,status,remark,counttotal,countcompleted,closingdate} = data //destructuring
     const closedateshowday = new Date(closingdate*1000).getDate()
     const closedateshowmonth = new Date(closingdate*1000).getMonth() +1 
     const closedateshowyear = new Date(closingdate*1000).getFullYear()
     const closedateshow = closedateshowday +'/' + closedateshowmonth + '/' + closedateshowyear
     var volumeshow = totalvolume.slice(0,35)
     var rotlength = Object.keys(this.state.rotpendinglist).length;
     return (
      <tbody>   
                <tr>   
                  <td>{no})</td>
                  <td>{rotno}</td>
                  <td  >
                    
                            <span className="mb-0 text-sm">
                              {shippername}
                            </span>
                        
                      </td>
                  <td>{bookingno}</td>    
                  <td>{volumeshow}</td>
                  <td >{closedateshow}</td>
                  <td>{countcompleted}/{counttotal} Delivered</td>   
                  <td >
                      <i  onClick={(e)=>this.expandbuttonclick(no,rotno,rotlength)} className="ni ni-bold-down" />          
                  </td>
              </tr> 
              {this.state["expand"+no] == false?
                  null:
               <tr>
                  <td colspan="8">   
                    {this.renderinsideexpand(no,createdby,createdbywhoref,rotno)}
                </td>
               </tr>  
                }
              </tbody>   
            
        
    
     )
  })
  }

  renderOrderListDataphone() {
      

    return this.state.rotpendinglist.map((data, index) => {
       const {createdbywhoref,createdby, no, rotno, shippername, totalvolume ,bookingno,status,remark,counttotal,countcompleted,closingdate} = data //destructuring
       const closedateshowday = new Date(closingdate*1000).getDate()
       const closedateshowmonth = new Date(closingdate*1000).getMonth() +1 
       const closedateshowyear = new Date(closingdate*1000).getFullYear()
       const closedateshow = closedateshowday +'/' + closedateshowmonth + '/' + closedateshowyear
       var volumeshow = totalvolume.slice(0,35)
       var rotlength = Object.keys(this.state.rotpendinglist).length;
       return (
        <div className="mt-1 cardviewbox"> 
          <div className="horizontaldirection">
          <h4 className="ml-4 mr-10 phoneviewcontent">{shippername}</h4>
          <p className="ml-auto mr-20 phoneviewcontent">
                        <i  onClick={(e)=>this.expandbuttonclick(no,rotno,rotlength)} className="ni ni-bold-down" />          
                    </p>
          </div>   
                   <p className="ml-4 mr-10 phoneviewcontent mt-2">{rotno}</p>                  
          <p className="ml-4 mr-10 phoneviewcontent mt-2">{bookingno}</p>    
          <p className="ml-4 mr-10 phoneviewcontent mt-2">{volumeshow}</p>
          <p className="ml-4 mr-10 phoneviewcontent mt-2">{closedateshow}</p>
          <p className="ml-4 mr-10 phoneviewcontent mt-2">{countcompleted}/{counttotal} Delivered</p>   
                   
                {this.state["expand"+no] == false?
                    null:
                    <div>
                      {this.renderinsideexpand(no,createdby,createdbywhoref,rotno)}
                    </div>
                  }
                </div>   
              
          
      
       )
    })
  }

  renderinsideexpandcompleted(no,createdby,createdbywhoref,rotnumber,status){
    return(
      <div className="insideexpandbackground">
      {this.state["loadingmodalcompleted" + no]?
        <Row >
        <Col md="12" >
      <img 
      className="img-center"
      width="120"
      height="120"
      alt="..."
      src={require("./../../assets/gif/lorrygif3.gif")}
    />
    </Col>
      </Row>:
       <div>    
          
       
          <Table  className="tableinsidecss" responsive>
               <thead className="thead-light-blue">
                 <tr>
                   <th scope="col">No.</th>
                   <th scope="col">Consignment No.</th>
                   <th scope="col">Container No.</th>      
                   <th scope="col">Require Date</th>                  
                   <th scope="col">Closing Date</th>
                   <th scope="col">Type</th>
                 </tr>
               </thead>
      {this.renderdetaillistDatacompleted(no)}
      </Table>     
     
      <Media className="align-items-center">
                <span>
                 Created By:  
                  </span>
                  <span className="ml-3 avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      width="40"
                      height="40"
                      src = {this.state.imageurllink + createdbywhoref }
                    />
                  </span>
                  
                    <span className="mb-0 text-sm font-weight-bold">
                      {createdby}
                    </span>
                 
                </Media>     
   
             
    </div>
}</div>
  
    )
  }

  renderinsideexpandcompletedphone(no,createdby,createdbywhoref,rotnumber,status){
    return(
      <div>
              {window.innerWidth < 760 ?
                <div className="insideexpandbackgroundphone">
                 {this.state["loadingmodalcompleted" + no]?
                  <Row >
                  <Col md="12" >
                <img 
                className="img-center"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/gif/lorrygif3.gif")}
              />
              </Col>
                </Row>:
                <div> 
                      
                          
                {this.renderdetaillistDatacompletedphone(no)}


                <Media className="align-items-center ml-3 mt-3">
                <span>
                 Created By:  
                  </span>
                  <span className="ml-3 avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      width="40"
                      height="40"
                      src = {this.state.imageurllink + createdbywhoref }
                    />
                  </span>
                  
                    <span className="mb-0 text-sm font-weight-bold">
                      {createdby}
                    </span>
                 
                </Media>     

                    
                </div>}
                </div>:
                <div>    
          
       
                <Table  className="tableinsidecss" responsive>
                     <thead className="thead-light-blue">
                       <tr>
                         <th scope="col">No.</th>
                         <th scope="col">Consignment No.</th>
                         <th scope="col">Container No.</th>      
                         <th scope="col">Require Date</th>                  
                         <th scope="col">Closing Date</th>
                         <th scope="col">Type</th>
                       </tr>
                     </thead>
            {this.renderdetaillistDatacompleted(no)}
            </Table>     
           
            <Media className="align-items-center ">
                      <span>
                       Created By:  
                        </span>
                        <span className="ml-3 avatar avatar-sm rounded-circle">
                          <img
                            alt="..."
                            width="40"
                            height="40"
                            src = {this.state.imageurllink + createdbywhoref }
                          />
                        </span>
                        
                          <span className="mb-0 text-sm font-weight-bold">
                            {createdby}
                          </span>
                       
                      </Media>     
         
                   
          </div>

          }
          </div>
    )
  }

  renderinsideexpandpending(no,createdby,createdbywhoref,rotnumber,status){
      return(
        <div>
        {window.innerWidth < 760 ?
         <div className="insideexpandbackgroundphone">
            {this.state["loadingmodalorder" + no]?
              <Row >
                  <Col md="12" >
                <img 
                className="img-center"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/gif/lorrygif3.gif")}
              />
              </Col>
            </Row>
            :
              <div>        
                {this.renderdetaillistDataphone2(no)}
              
                <Media className="align-items-center mt-2">
                <span>
                  Created By:  
                  </span>
                  <span className="ml-3 avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      width="40"
                      height="40"
                      src = {this.state.imageurllink + createdbywhoref }
                    />
                  </span>
                  
                    <span className="mb-0 text-sm font-weight-bold">
                      {createdby}
                    </span>
                  
                </Media>

                {status == "Pending"?
                <div className="displayflex">          
                <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                className="alignrightbutton"
                onClick={() => this.toggleModalwitheditorderpopout(rotnumber,true)}
                >
                Edit Rot
                </Button> 
                <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                className="alignrightbutton"
                onClick={() => {if (window.confirm('Are you sure you wish to cancel this Rot?')) this.deleteorcancelpendingrot(rotnumber);}}
                >
                Cancel This Rot
                </Button>           
                </div>  
                :
                null
                }

            </div>
            }

        </div>
        :
        <div className="insideexpandbackground">
              {this.state["loadingmodalorder" + no]?
                  <Row >
                  <Col md="12" >
                <img 
                className="img-center"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/gif/lorrygif3.gif")}
              />
              </Col>
                </Row>:
                <div>
            <Table  className="tableinsidecss" responsive>
                 <thead className="thead-light-blue">
                   <tr>
                     <th scope="col">No.</th>
                     <th scope="col">Consignment No.</th>
                     <th scope="col">Container No.</th>      
                     <th scope="col">Require Date</th>                  
                     <th scope="col">Closing Date</th>
                     <th scope="col">Type</th>
                   </tr>
                 </thead>
        {this.renderdetaillistData(no)}
        </Table>     
       
        <Media className="align-items-center">
                  <span>
                   Created By:  
                    </span>
                    <span className="ml-3 avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        width="40"
                        height="40"
                        src = {this.state.imageurllink + createdbywhoref }
                      />
                    </span>
                    
                      <span className="mb-0 text-sm font-weight-bold">
                        {createdby}
                      </span>
                   
                  </Media>
       <div>

       {status == "Pending"?
          <div className="displayflex">          
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="alignrightbutton"
              onClick={() => this.toggleModalwitheditorderpopout(rotnumber,true)}
            >
              Edit Rot
            </Button> 
          <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="alignrightbutton"
              onClick={() => {if (window.confirm('Are you sure you wish to cancel this Rot?')) this.deleteorcancelpendingrot(rotnumber);}}
            >
              Cancel This Rot
            </Button>           
            </div>  
                :
          null
       }
       </div>
        
        </div>
      }
               
      </div>
  
  }
  </div>
    
      )
  }

  renderinsideexpand(no,createdby,createdbywhoref,rotno){
   
          return (
            <div>
              {window.innerWidth < 760 ?
                <div className="insideexpandbackgroundphone">
                 {this.state["loadingmodalorder" + no]?
                  <Row >
                  <Col md="12" >
                <img 
                className="img-center"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/gif/lorrygif3.gif")}
              />
              </Col>
                </Row>:
                <div> 
                      
                          
                {this.renderdetaillistDataOrderphone(no)}


                <Media className="align-items-center mt-2">
                <span>
                Created By:  
                </span>
                <span className="ml-3 avatar avatar-sm rounded-circle">
                <img
                  alt="..."
                  width="40"
                  height="40"
                  src = {this.state.imageurllink + createdbywhoref }
                />
                </span>

                <span className="mb-0 text-sm font-weight-bold">
                  {createdby}
                </span>

                </Media>

                <div className="displayflex ">
                <div   className="alignrightbutton">

                  <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  className="alignrightbutton"
                  onClick={(e) => this.toggleModalwithfunctionpopoutemail(true)}
                  >

                  Edit/Cancel
                  </Button> 


                  <ReactToPrint
                  className="alignrightbutton"
                  trigger={() =>    <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  className="alignrightbutton"
                  onClick={(e) => console.log("Print clicked")}
                  >

                  Print ROT
                  </Button>   }
                  content={() => this.state["componentRef"+no]}
                  />

                </div>          

                </div>       
                </div>}
                </div>
              :

              <div className="insideexpandbackground">
              {this.state["loadingmodalorder" + no]?
                  <Row >
                  <Col md="12" >
                <img 
                className="img-center"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/gif/lorrygif3.gif")}
              />
              </Col>
                </Row>:
                  <div>  
                  <Table  className="tableinsidecss" responsive>
                  <thead className="thead-light-blue">
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Consignment No.</th>
                      <th scope="col">Container No.</th>      
                      <th scope="col">Require Date</th>                  
                      <th scope="col">Closing Date</th>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Detail</th>
                    </tr>
                  </thead>
                  
                    {this.renderdetaillistDataOrder(no)}
                   
                 
                </Table>
                  <Media className="align-items-center">
                <span>
                 Created By:  
                  </span>
                  <span className="ml-3 avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      width="40"
                      height="40"
                      src = {this.state.imageurllink + createdbywhoref }
                    />
                  </span>
                  
                    <span className="mb-0 text-sm font-weight-bold">
                      {createdby}
                    </span>
                 
                </Media>
        
                <div className="displayflex">
         <div   className="alignrightbutton">
        
         <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                className="alignrightbutton"
                onClick={(e) => this.toggleModalwithfunctiondetailorderpopoutattachment(rotno,true)}
               >
              
                Attachment
              </Button> 


         <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                className="alignrightbutton"
                onClick={(e) => this.toggleModalwithfunctionpopoutemail(true)}
               >
              
                Edit/Cancel
              </Button> 
        
        
            <ReactToPrint
              className="alignrightbutton"
              trigger={() =>    <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                className="alignrightbutton"
                onClick={(e) => console.log("Print clicked")}
               >
              
                Print ROT
              </Button>   }
              content={() => this.state["componentRef"+no]}
            />
            
          </div>          
              
           </div>       
                </div>
              }


            </div>

            }

                </div> 
        
      )
  }

  renderdetaillistData(rownumber) {

    if(this.state["detailpendinglist"+rownumber] != null){
      return this.state["detailpendinglist"+rownumber].map((data, index) => {
        const { no, shipper, bookingno, pol ,pod,consignmentno,containerno,requiredate,closedate,type} = data //destructuringwew
        var requiredateshow =''
        
        if(requiredate == 946684800-28800){
           requiredateshow = 'TBC'
        }else{
         const requiredateshowday = new Date(requiredate*1000).getDate()
         const requiredateshowmonth = new Date(requiredate*1000).getMonth() +1 
         const requiredateshowyear = new Date(requiredate*1000).getFullYear()
          requiredateshow = requiredateshowday +'/' + requiredateshowmonth + '/' + requiredateshowyear
  
      
        }
        const closedateshowday = new Date(closedate*1000).getDate()
        const closedateshowmonth = new Date(closedate*1000).getMonth() +1 
        const closedateshowyear = new Date(closedate*1000).getFullYear()
        const closedateshow = closedateshowday +'/' + closedateshowmonth + '/' + closedateshowyear
 
        return (
       
                   <tbody>
                       <tr className="rowbackgroundcolorinsideexpand">
                       <td>{no})</td>
                       <td>{consignmentno}</td>
                       <td>{containerno}</td>
                       <td>{requiredateshow}</td>
                       <td>{closedateshow}</td>
                       <td>{type}</td>
                     </tr>                   
                    
                   </tbody>
                
        )
     })
    }else{
      return null
    }
    
  }

  renderdetaillistDataphone2(rownumber) {

    if(this.state["detailpendinglist"+rownumber] != null){
      return this.state["detailpendinglist"+rownumber].map((data, index) => {
        const { no, shipper, bookingno, pol ,pod,consignmentno,containerno,requiredate,closedate,type} = data //destructuringwew
        var requiredateshow =''
        if(requiredate == 946684800-28800){
           requiredateshow = 'TBC'
        }else{
         const requiredateshowday = new Date(requiredate*1000).getDate()
         const requiredateshowmonth = new Date(requiredate*1000).getMonth() +1 
         const requiredateshowyear = new Date(requiredate*1000).getFullYear()
          requiredateshow = requiredateshowday +'/' + requiredateshowmonth + '/' + requiredateshowyear
  
      
        }
        const closedateshowday = new Date(closedate*1000).getDate()
        const closedateshowmonth = new Date(closedate*1000).getMonth() +1 
        const closedateshowyear = new Date(closedate*1000).getFullYear()
        const closedateshow = closedateshowday +'/' + closedateshowmonth + '/' + closedateshowyear
 
        return (
                  <div className="mt-1 cardviewboxphone">
                  <div className="horizontaldirection">             
                    <h4 className="ml-4 mr-10 phoneviewcontent">{consignmentno}</h4>                 
                    </div>
                    <p className="ml-4 mr-10 phoneviewcontent">{containerno}</p>
                    <p className="ml-4 mr-10 phoneviewcontent">{requiredateshow}</p>
                    <p className="ml-4 mr-10 phoneviewcontent">{closedateshow}</p>
                    <p className="ml-4 mr-10 phoneviewcontent">{type}</p>
                   
              </div>
        )
     })
    }else{
      return null
    }
    
  }

  
  renderdetaillistDatacompleted(rownumber) {

    if(this.state["detailcompletedlist"+rownumber] != null){
      return this.state["detailcompletedlist"+rownumber].map((data, index) => {
        const { no, shipper, bookingno, pol ,pod,consignmentno,containerno,requiredate,closedate,type} = data //destructuringwew
        var requiredateshow =''
        if(requiredate == 946684800-28800){
           requiredateshow = 'TBC'
        }else{
         const requiredateshowday = new Date(requiredate*1000).getDate()
         const requiredateshowmonth = new Date(requiredate*1000).getMonth() +1 
         const requiredateshowyear = new Date(requiredate*1000).getFullYear()
          requiredateshow = requiredateshowday +'/' + requiredateshowmonth + '/' + requiredateshowyear
  
      
        }
        const closedateshowday = new Date(closedate*1000).getDate()
        const closedateshowmonth = new Date(closedate*1000).getMonth() +1 
        const closedateshowyear = new Date(closedate*1000).getFullYear()
        const closedateshow = closedateshowday +'/' + closedateshowmonth + '/' + closedateshowyear
 
        return (
       
                   <tbody>
                       <tr className="rowbackgroundcolorinsideexpand">
                       <td>{no})</td>
                       <td>{consignmentno}</td>
                       <td>{containerno}</td>
                       <td>{requiredateshow}</td>
                       <td>{closedateshow}</td>
                       <td>{type}</td>
                     </tr>                   
                    
                   </tbody>
                
        )
     })
    }else{
      return null
    }
    
  }

  renderdetaillistDatacompletedphone(rownumber) {

    if(this.state["detailcompletedlist"+rownumber] != null){
      return this.state["detailcompletedlist"+rownumber].map((data, index) => {
        const { no, shipper, bookingno, pol ,pod,consignmentno,containerno,requiredate,closedate,type} = data //destructuringwew
        var requiredateshow =''
        if(requiredate == 946684800-28800){
           requiredateshow = 'TBC'
        }else{
         const requiredateshowday = new Date(requiredate*1000).getDate()
         const requiredateshowmonth = new Date(requiredate*1000).getMonth() +1 
         const requiredateshowyear = new Date(requiredate*1000).getFullYear()
          requiredateshow = requiredateshowday +'/' + requiredateshowmonth + '/' + requiredateshowyear
  
      
        }
        const closedateshowday = new Date(closedate*1000).getDate()
        const closedateshowmonth = new Date(closedate*1000).getMonth() +1 
        const closedateshowyear = new Date(closedate*1000).getFullYear()
        const closedateshow = closedateshowday +'/' + closedateshowmonth + '/' + closedateshowyear
 
        return (
       
                
                 <div className="mt-1 cardviewboxphone">
                      
                   <h4 className="ml-4 mr-10 phoneviewcontent">{consignmentno}</h4>
            
                   <p className="ml-4 mr-10 phoneviewcontent">{containerno}</p>
                   <p className="ml-4 mr-10 phoneviewcontent">{requiredateshow}</p>
                   <p className="ml-4 mr-10 phoneviewcontent">{closedateshow}</p>
                   <p className="ml-4 mr-10 phoneviewcontent">{type}</p>
              
             </div>
        )
     })
    }else{
      return null
    }
    
  }

  handleKeyPress = (event) => {
    if(event.key === 'Escape'){
      console.log('Escape press here! ')
    }
    if(event.keyCode === 27){
      event.preventDefault();
    }
    console.log(event.key)
    console.log(event.keyCode)
  }


  renderdetaillistDataOrder(rownumber) {
    
    // if(rownumber == 26){
    //   console.log("volume1: "+ this.state["volume"+rownumber+"1"])
    //   console.log("volume2: "+ this.state["volume"+rownumber+"2"])
    //   console.log("volume3: "+ this.state["volume"+rownumber+"3"])
    //   console.log("volume4: "+ this.state["volume"+rownumber+"4"])
    // }
   
    if(this.state["detailorderlist"+rownumber] != null){
      return this.state["detailorderlist"+rownumber].map((data, index) => {
        const {voyagename,contactpic,bookingno,commodity,billingto,shippperaddress,pic,pickupyard,eta,vesselvoyage,forwardingagent,shippingline,remark,importexport, no, shipper, pol ,pod,consignmentno,containerno,requiredate,closedate,type,activitystagingindate,activtiytruckindate,activitystagingoutdate,activitytruckoutdate,activitytransferbilldate,activitytransferhistory,activitydatecreatenewtime} = data //destructuring
        var requiredateshow =''
        var status=''
       //  console.log(consignmentno)
        if(requiredate == 946684800-28800){
           requiredateshow = 'TBC'
        }else{
         const requiredateshowday = new Date(requiredate*1000).getDate()
         const requiredateshowmonth = new Date(requiredate*1000).getMonth() +1 
         const requiredateshowyear = new Date(requiredate*1000).getFullYear()
          requiredateshow = requiredateshowday +'/' + requiredateshowmonth + '/' + requiredateshowyear
  
      
        }

        const closedateshowday = new Date(closedate*1000).getDate()
        const closedateshowmonth = new Date(closedate*1000).getMonth() +1 
        const closedateshowyear = new Date(closedate*1000).getFullYear()
        const closedateshow = closedateshowday +'/' + closedateshowmonth + '/' + closedateshowyear


        const etadateshowday = new Date(eta*1000).getDate()
        const etadateshowmonth = new Date(eta*1000).getMonth() +1 
        const etadateshowyear = new Date(eta*1000).getFullYear()
        const etashow = etadateshowday +'/' + etadateshowmonth + '/' + etadateshowyear
        var statuscolor ="bg-warning"
        
        if(activitytransferhistory != 946684800-28800){
         status ='Completed'
         statuscolor='bg-success'
        }
        else if(activitytransferbilldate != 946684800-28800){
         status ='Billing'
         statuscolor='bg-success'
        }else if(activitytruckoutdate != 946684800-28800){
         status ='Trucked Out'
         statuscolor='bg-success'
        }else if(activitystagingoutdate != 946684800-28800){
         status ='Staging Out'
         statuscolor='bg-success'
        }else if(activtiytruckindate != 946684800-28800){
         status ='Trucked In'
         statuscolor='bg-success'
        }else if(activitystagingindate != 946684800-28800){
         status ='Staging In'
         statuscolor='bg-yellow'
        }else{
         status ='Order Created'
         statuscolor='bg-warning'
        }


        var activitydatecreatenewtimetoshow = 0;
        var activitystagingindatetoshow = 0;
        var activtiytruckindatetoshow = 0;
        var activitystagingoutdatetoshow = 0;
        var activitytruckoutdatetoshow = 0;
        var activitytransferbilldatetoshow = 0;
        var tempdate=0;
        var tempmonth=0;
        var tempyear =0;
        var temphour=0;
        var tempmin=0;
        var tempsec =0;
        //createnew
        if(activitydatecreatenewtime == 946684800-28800){
          activitydatecreatenewtimetoshow ="No Detail"
        }else{
          tempdate = new Date(activitydatecreatenewtime*1000).getDate()
          tempmonth = new Date(activitydatecreatenewtime*1000).getMonth() +1 
          tempyear = new Date(activitydatecreatenewtime*1000).getFullYear()
          temphour = new Date(activitydatecreatenewtime*1000).getHours()
          tempmin = new Date(activitydatecreatenewtime*1000).getMinutes()
          tempsec = new Date(activitydatecreatenewtime*1000).getSeconds()
          
          activitydatecreatenewtimetoshow = tempdate +'/' + tempmonth + '/' + tempyear + ' ' + temphour + ':' + tempmin + ":" + tempsec
          
        }
        

         //staging
         if(activitystagingindate == 946684800-28800){
          activitystagingindatetoshow ="No Detail"
        }else{
         tempdate = new Date(activitystagingindate*1000).getDate()
         tempmonth = new Date(activitystagingindate*1000).getMonth() +1 
         tempyear = new Date(activitystagingindate*1000).getFullYear()
         temphour = new Date(activitystagingindate*1000).getHours()
         tempmin = new Date(activitystagingindate*1000).getMinutes()
         tempsec = new Date(activitystagingindate*1000).getSeconds()
         activitystagingindatetoshow = tempdate +'/' + tempmonth + '/' + tempyear + ' ' + temphour + ':' + tempmin + ":" + tempsec
        }

         //truck in
         if(activtiytruckindate == 946684800-28800){
          activtiytruckindatetoshow ="No Detail"
        }else{
         tempdate = new Date(activtiytruckindate*1000).getDate()
         tempmonth = new Date(activtiytruckindate*1000).getMonth() +1 
         tempyear = new Date(activtiytruckindate*1000).getFullYear()
         temphour = new Date(activtiytruckindate*1000).getHours()
         tempmin = new Date(activtiytruckindate*1000).getMinutes()
         tempsec = new Date(activtiytruckindate*1000).getSeconds()
         activtiytruckindatetoshow = tempdate +'/' + tempmonth + '/' + tempyear + ' ' + temphour + ':' + tempmin + ":" + tempsec
        }

         //staging out
         if(activitystagingoutdate == 946684800-28800){
          activitystagingoutdatetoshow ="No Detail"
        }else{
         tempdate = new Date(activitystagingoutdate*1000).getDate()
         tempmonth = new Date(activitystagingoutdate*1000).getMonth() +1 
         tempyear = new Date(activitystagingoutdate*1000).getFullYear()
         temphour = new Date(activitystagingoutdate*1000).getHours()
         tempmin = new Date(activitystagingoutdate*1000).getMinutes()
         tempsec = new Date(activitystagingoutdate*1000).getSeconds()
         activitystagingoutdatetoshow = tempdate +'/' + tempmonth + '/' + tempyear + ' ' + temphour + ':' + tempmin + ":" + tempsec
        }
          //truck out
          if(activitytruckoutdate == 946684800-28800){
            activitytruckoutdatetoshow ="No Detail"
          }else{
          tempdate = new Date(activitytruckoutdate*1000).getDate()
          tempmonth = new Date(activitytruckoutdate*1000).getMonth() +1 
          tempyear = new Date(activitytruckoutdate*1000).getFullYear()
          temphour = new Date(activitytruckoutdate*1000).getHours()
          tempmin = new Date(activitytruckoutdate*1000).getMinutes()
          tempsec = new Date(activitytruckoutdate*1000).getSeconds()
          activitytruckoutdatetoshow = tempdate +'/' + tempmonth + '/' + tempyear + ' ' + temphour + ':' + tempmin + ":" + tempsec
          }
          
          //billing
          if(activitytransferbilldate == 946684800-28800){
            activitytransferbilldatetoshow ="No Detail"
          }else{
          tempdate = new Date(activitytransferbilldate*1000).getDate()
          tempmonth = new Date(activitytransferbilldate*1000).getMonth() +1 
          tempyear = new Date(activitytransferbilldate*1000).getFullYear()
          temphour = new Date(activitytransferbilldate*1000).getHours()
          tempmin = new Date(activitytransferbilldate*1000).getMinutes()
          tempsec = new Date(activitytransferbilldate*1000).getSeconds()
          activitytransferbilldatetoshow = tempdate +'/' + tempmonth + '/' + tempyear + ' ' + temphour + ':' + tempmin + ":" + tempsec
          }

         
        return (
       
         <tbody>
             <tr className="rowbackgroundcolorinsideexpand">
               <td>{no})</td>
               <td>{consignmentno}</td>
               <td>{containerno}</td>
               <td>{requiredateshow}</td>
               <td>{closedateshow}</td>
               <td>{type}</td>
               <td>
                     <Badge color="" className="badge-dot mr-4">
                       <i className={statuscolor} />
                       {status}
                     </Badge>
               </td>
               <td className="text-right">
               <UncontrolledDropdown>
                 <DropdownToggle
                   className="btn-icon-only text-light"
                   href="#pablo"
                   role="button"
                   size="sm"
                   color=""
                   onClick={() => this.toggleModalwithfunctiondetailprocesspopout(10,true,consignmentno,status,containerno,requiredateshow,closedateshow,type,activitydatecreatenewtimetoshow,activitystagingindatetoshow,activtiytruckindatetoshow,activitystagingoutdatetoshow,activitytruckoutdatetoshow,activitytransferbilldatetoshow)}
                 >
                   <i className="fas fa-ellipsis-v" />
                 </DropdownToggle>
                 {/* <DropdownMenu className="dropdown-menu-arrow" right>
                   <DropdownItem
                     href="#pablo"
                     onClick={() => this.toggleModalwithfunctiondetailprocesspopout(10,true,consignmentno,status,containerno,requiredateshow,closedateshow,type)}
                   >
                     Detail
                   </DropdownItem>
                  
                 </DropdownMenu> */}
               </UncontrolledDropdown>
             </td>
            
               </tr>

               
               <div style={{ display: "none" }}><ComponentToPrint 
                  volume1={this.state["volume"+rownumber+"1"]}
                  volume2={this.state["volume"+rownumber+"2"]}
                  volume3={this.state["volume"+rownumber+"3"]}
                  volume4={this.state["volume"+rownumber+"4"]}
                  size1=""
                  size2=""
                  size3=""
                  size4=""
                  type1=""
                  type2=""
                  type3=""
                  type4=""
                  container1={this.state["checksideloader"+rownumber+"0"] == true ?  this.state["container"+rownumber+"0"] +"(SL)" : this.state["container"+rownumber+"0"]}
                  container2={this.state["checksideloader"+rownumber+"1"] == true ?  this.state["container"+rownumber+"1"] +"(SL)" : this.state["container"+rownumber+"1"]}
                  container3={this.state["checksideloader"+rownumber+"2"] == true ?  this.state["container"+rownumber+"2"] +"(SL)" : this.state["container"+rownumber+"2"]}
                  container4={this.state["checksideloader"+rownumber+"3"] == true ?  this.state["container"+rownumber+"3"] +"(SL)" : this.state["container"+rownumber+"3"]}
                  container5={this.state["checksideloader"+rownumber+"4"] == true ?  this.state["container"+rownumber+"4"] +"(SL)" : this.state["container"+rownumber+"4"]}
                  container6={this.state["checksideloader"+rownumber+"5"] == true ?  this.state["container"+rownumber+"5"] +"(SL)" : this.state["container"+rownumber+"5"]}
                  container7={this.state["checksideloader"+rownumber+"6"] == true ?  this.state["container"+rownumber+"6"] +"(SL)" : this.state["container"+rownumber+"6"]}
                  container8={this.state["checksideloader"+rownumber+"7"] == true ?  this.state["container"+rownumber+"7"] +"(SL)" : this.state["container"+rownumber+"7"]}
                  remark={remark}
                  importexport={importexport}
                  shippingline={shippingline}
                  forwardingagent={forwardingagent}
                  commodity={commodity}
                  pod={pod}
                  pol={pol}
                  vesselvoyage={vesselvoyage}
                  voyagename={voyagename}
                  vesseleta={etashow}
                  closingdate={closedateshow}
                  containerrequiredate={requiredateshow}
                  containerrequiretime=""
                  Bookingno={bookingno}
                  pickupyard={pickupyard}
                  shippername={shipper}
                  pic={pic}
                  shipperaddress={shippperaddress}
                  billingto={billingto}
                  contactpic={contactpic}
                    ref={el => (this.state["componentRef"+rownumber] = el)} /></div> 
         </tbody>
     
      )
  })}else{
      return null;
    }
    
  }

  renderdetaillistDataOrderphone(rownumber) {
    
   
    if(this.state["detailorderlist"+rownumber] != null){
      return this.state["detailorderlist"+rownumber].map((data, index) => {
        const {voyagename,contactpic,bookingno,commodity,billingto,shippperaddress,pic,pickupyard,eta,vesselvoyage,forwardingagent,shippingline,remark,importexport, no, shipper, pol ,pod,consignmentno,containerno,requiredate,closedate,type,activitystagingindate,activtiytruckindate,activitystagingoutdate,activitytruckoutdate,activitytransferbilldate,activitytransferhistory,activitydatecreatenewtime} = data //destructuring
        var requiredateshow =''
        var status=''
       //  console.log(consignmentno)
        if(requiredate == 946684800-28800){
           requiredateshow = 'TBC'
        }else{
         const requiredateshowday = new Date(requiredate*1000).getDate()
         const requiredateshowmonth = new Date(requiredate*1000).getMonth() +1 
         const requiredateshowyear = new Date(requiredate*1000).getFullYear()
          requiredateshow = requiredateshowday +'/' + requiredateshowmonth + '/' + requiredateshowyear
  
      
        }

        const closedateshowday = new Date(closedate*1000).getDate()
        const closedateshowmonth = new Date(closedate*1000).getMonth() +1 
        const closedateshowyear = new Date(closedate*1000).getFullYear()
        const closedateshow = closedateshowday +'/' + closedateshowmonth + '/' + closedateshowyear


        const etadateshowday = new Date(eta*1000).getDate()
        const etadateshowmonth = new Date(eta*1000).getMonth() +1 
        const etadateshowyear = new Date(eta*1000).getFullYear()
        const etashow = etadateshowday +'/' + etadateshowmonth + '/' + etadateshowyear
        var statuscolor ="bg-warning"
        
        if(activitytransferhistory != 946684800-28800){
         status ='Completed'
         statuscolor='bg-success'
        }
        else if(activitytransferbilldate != 946684800-28800){
         status ='Billing'
         statuscolor='bg-success'
        }else if(activitytruckoutdate != 946684800-28800){
         status ='Trucked Out'
         statuscolor='bg-success'
        }else if(activitystagingoutdate != 946684800-28800){
         status ='Staging Out'
         statuscolor='bg-success'
        }else if(activtiytruckindate != 946684800-28800){
         status ='Trucked In'
         statuscolor='bg-success'
        }else if(activitystagingindate != 946684800-28800){
         status ='Staging In'
         statuscolor='bg-yellow'
        }else{
         status ='Order Created'
         statuscolor='bg-warning'
        }


        var activitydatecreatenewtimetoshow = 0;
        var activitystagingindatetoshow = 0;
        var activtiytruckindatetoshow = 0;
        var activitystagingoutdatetoshow = 0;
        var activitytruckoutdatetoshow = 0;
        var activitytransferbilldatetoshow = 0;
        var tempdate=0;
        var tempmonth=0;
        var tempyear =0;
        var temphour=0;
        var tempmin=0;
        var tempsec =0;
        //createnew
        if(activitydatecreatenewtime == 946684800-28800){
          activitydatecreatenewtimetoshow ="No Detail"
        }else{
          tempdate = new Date(activitydatecreatenewtime*1000).getDate()
          tempmonth = new Date(activitydatecreatenewtime*1000).getMonth() +1 
          tempyear = new Date(activitydatecreatenewtime*1000).getFullYear()
          temphour = new Date(activitydatecreatenewtime*1000).getHours()
          tempmin = new Date(activitydatecreatenewtime*1000).getMinutes()
          tempsec = new Date(activitydatecreatenewtime*1000).getSeconds()
          
          activitydatecreatenewtimetoshow = tempdate +'/' + tempmonth + '/' + tempyear + ' ' + temphour + ':' + tempmin + ":" + tempsec
          
        }
        

         //staging
         if(activitystagingindate == 946684800-28800){
          activitystagingindatetoshow ="No Detail"
        }else{
         tempdate = new Date(activitystagingindate*1000).getDate()
         tempmonth = new Date(activitystagingindate*1000).getMonth() +1 
         tempyear = new Date(activitystagingindate*1000).getFullYear()
         temphour = new Date(activitystagingindate*1000).getHours()
         tempmin = new Date(activitystagingindate*1000).getMinutes()
         tempsec = new Date(activitystagingindate*1000).getSeconds()
         activitystagingindatetoshow = tempdate +'/' + tempmonth + '/' + tempyear + ' ' + temphour + ':' + tempmin + ":" + tempsec
        }

         //truck in
         if(activtiytruckindate == 946684800-28800){
          activtiytruckindatetoshow ="No Detail"
        }else{
         tempdate = new Date(activtiytruckindate*1000).getDate()
         tempmonth = new Date(activtiytruckindate*1000).getMonth() +1 
         tempyear = new Date(activtiytruckindate*1000).getFullYear()
         temphour = new Date(activtiytruckindate*1000).getHours()
         tempmin = new Date(activtiytruckindate*1000).getMinutes()
         tempsec = new Date(activtiytruckindate*1000).getSeconds()
         activtiytruckindatetoshow = tempdate +'/' + tempmonth + '/' + tempyear + ' ' + temphour + ':' + tempmin + ":" + tempsec
        }

         //staging out
         if(activitystagingoutdate == 946684800-28800){
          activitystagingoutdatetoshow ="No Detail"
        }else{
         tempdate = new Date(activitystagingoutdate*1000).getDate()
         tempmonth = new Date(activitystagingoutdate*1000).getMonth() +1 
         tempyear = new Date(activitystagingoutdate*1000).getFullYear()
         temphour = new Date(activitystagingoutdate*1000).getHours()
         tempmin = new Date(activitystagingoutdate*1000).getMinutes()
         tempsec = new Date(activitystagingoutdate*1000).getSeconds()
         activitystagingoutdatetoshow = tempdate +'/' + tempmonth + '/' + tempyear + ' ' + temphour + ':' + tempmin + ":" + tempsec
        }
          //truck out
          if(activitytruckoutdate == 946684800-28800){
            activitytruckoutdatetoshow ="No Detail"
          }else{
          tempdate = new Date(activitytruckoutdate*1000).getDate()
          tempmonth = new Date(activitytruckoutdate*1000).getMonth() +1 
          tempyear = new Date(activitytruckoutdate*1000).getFullYear()
          temphour = new Date(activitytruckoutdate*1000).getHours()
          tempmin = new Date(activitytruckoutdate*1000).getMinutes()
          tempsec = new Date(activitytruckoutdate*1000).getSeconds()
          activitytruckoutdatetoshow = tempdate +'/' + tempmonth + '/' + tempyear + ' ' + temphour + ':' + tempmin + ":" + tempsec
          }
          
          //billing
          if(activitytransferbilldate == 946684800-28800){
            activitytransferbilldatetoshow ="No Detail"
          }else{
          tempdate = new Date(activitytransferbilldate*1000).getDate()
          tempmonth = new Date(activitytransferbilldate*1000).getMonth() +1 
          tempyear = new Date(activitytransferbilldate*1000).getFullYear()
          temphour = new Date(activitytransferbilldate*1000).getHours()
          tempmin = new Date(activitytransferbilldate*1000).getMinutes()
          tempsec = new Date(activitytransferbilldate*1000).getSeconds()
          activitytransferbilldatetoshow = tempdate +'/' + tempmonth + '/' + tempyear + ' ' + temphour + ':' + tempmin + ":" + tempsec
          }

         
        return (
       
         <div className="mt-1 cardviewboxphone">
             <div className="horizontaldirection">             
               <h4 className="ml-4 mr-10 phoneviewcontent">{consignmentno}</h4>
               
               <p className="ml-auto mr-10 phoneviewcontent">
               <UncontrolledDropdown>
                 <DropdownToggle
                   className="btn-icon-only  "
                   href="#pablo"
                   role="button"
                   color="black"
                   onClick={() => this.toggleModalwithfunctiondetailprocesspopout(10,true,consignmentno,status,containerno,requiredateshow,closedateshow,type,activitydatecreatenewtimetoshow,activitystagingindatetoshow,activtiytruckindatetoshow,activitystagingoutdatetoshow,activitytruckoutdatetoshow,activitytransferbilldatetoshow)}
                 >
                   <i className="fas fa-ellipsis-v" />
                 </DropdownToggle>
                 {/* <DropdownMenu className="dropdown-menu-arrow" right>
                   <DropdownItem
                     href="#pablo"
                     onClick={() => this.toggleModalwithfunctiondetailprocesspopout(10,true,consignmentno,status,containerno,requiredateshow,closedateshow,type)}
                   >
                     Detail
                   </DropdownItem>
                  
                 </DropdownMenu> */}
               </UncontrolledDropdown>
             </p>
            
               </div>
               <p className="ml-4 mr-10 phoneviewcontent">{containerno}</p>
               <p className="ml-4 mr-10 phoneviewcontent">{requiredateshow}</p>
               <p className="ml-4 mr-10 phoneviewcontent">{closedateshow}</p>
               <p className="ml-4 mr-10 phoneviewcontent">{type}</p>
               <p className="ml-4 mr-10 phoneviewcontent">
                     <Badge color="" className="badge-dot mr-4">
                       <i className={statuscolor} />
                       {status}
                     </Badge>
               </p>

               <div style={{ display: "none" }}><ComponentToPrint 
                  volume1={this.state["volume"+rownumber+"1"]}
                  volume2={this.state["volume"+rownumber+"2"]}
                  volume3={this.state["volume"+rownumber+"3"]}
                  volume4={this.state["volume"+rownumber+"4"]}
                  size1=""
                  size2=""
                  size3=""
                  size4=""
                  type1=""
                  type2=""
                  type3=""
                  type4=""
                  container1={this.state["checksideloader"+rownumber+"0"] == true ?  this.state["container"+rownumber+"0"] +"(SL)" : this.state["container"+rownumber+"0"]}
                  container2={this.state["checksideloader"+rownumber+"1"] == true ?  this.state["container"+rownumber+"1"] +"(SL)" : this.state["container"+rownumber+"1"]}
                  container3={this.state["checksideloader"+rownumber+"2"] == true ?  this.state["container"+rownumber+"2"] +"(SL)" : this.state["container"+rownumber+"2"]}
                  container4={this.state["checksideloader"+rownumber+"3"] == true ?  this.state["container"+rownumber+"3"] +"(SL)" : this.state["container"+rownumber+"3"]}
                  container5={this.state["checksideloader"+rownumber+"4"] == true ?  this.state["container"+rownumber+"4"] +"(SL)" : this.state["container"+rownumber+"4"]}
                  container6={this.state["checksideloader"+rownumber+"5"] == true ?  this.state["container"+rownumber+"5"] +"(SL)" : this.state["container"+rownumber+"5"]}
                  container7={this.state["checksideloader"+rownumber+"6"] == true ?  this.state["container"+rownumber+"6"] +"(SL)" : this.state["container"+rownumber+"6"]}
                  container8={this.state["checksideloader"+rownumber+"7"] == true ?  this.state["container"+rownumber+"7"] +"(SL)" : this.state["container"+rownumber+"7"]}
                  // container1={this.state["container"+rownumber+"0"]}
                  // container2={this.state["container"+rownumber+"1"]}
                  // container3={this.state["container"+rownumber+"2"]}
                  // container4={this.state["container"+rownumber+"3"]}
                  // container5={this.state["container"+rownumber+"4"]}
                  // container6={this.state["container"+rownumber+"5"]}
                  // container7={this.state["container"+rownumber+"6"]}
                  // container8={this.state["container"+rownumber+"7"]}
                  remark={remark}
                  importexport={importexport}
                  shippingline={shippingline}
                  forwardingagent={forwardingagent}
                  commodity={commodity}
                  pod={pod}
                  pol={pol}
                  vesselvoyage={vesselvoyage}
                  voyagename={voyagename}
                  vesseleta={etashow}
                  closingdate={closedateshow}
                  containerrequiredate={requiredateshow}
                  containerrequiretime=""
                  Bookingno={bookingno}
                  pickupyard={pickupyard}
                  shippername={shipper}
                  pic={pic}
                  shipperaddress={shippperaddress}
                  billingto={billingto}
                  contactpic={contactpic}
                    ref={el => (this.state["componentRef"+rownumber] = el)} /></div> 
         </div>
     
      )
  })}else{
      return null;
    }
    
  }


  // ordertablepaginationcount = () =>{
  //   this.setState({loading:true})
  //   var urlencoded = new URLSearchParams();
  //   urlencoded.append("billtoparty", this.state.Billtoparty);
  //   urlencoded.append("importexport", importexport);
  //   let link = 'https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getorderpaginationcounttotal'
  //   fetch(link, {
  //     method: 'POST', 
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body:urlencoded
  //   })
  //   .then(response => response.json())
  //   .then(json => {
  //     console.log(json.success)
  //     if(json.success == 1){
        
  //           console.log(json)
  //           this.setState({rotpendinglist:[]});   
  //            var lengthemployee = Object.keys(json.user).length;
  //            var indexofvalue = -1
  //            for(let i=0;i<lengthemployee;i++){
              
  //             //status need do
  //             if(this.state.rotpendinglist.some(item => item.rotno === json.user[i].Rotnumber)){             
  //                 //update counttotal here,
  //                 var filterobj = this.state.rotpendinglist.find(function(item, j){
  //                   if(item.rotno === json.user[i].Rotnumber){
  //                     indexofvalue = j;
  //                     //return j;
  //                   }
  //                 });
  //                 //var index = this.state.rotpendinglist.indexOf(item => item.rotno === json.user[i].Rotnumber)
  //                 this.state.rotpendinglist[indexofvalue].counttotal =   this.state.rotpendinglist[indexofvalue].counttotal + 1
  //                 if(json.user[i].Activitydatetruckin != 946684800){
  //                   this.state.rotpendinglist[indexofvalue].countcompleted =   this.state.rotpendinglist[indexofvalue].countcompleted + 1
  //                 }
  //               console.log(json.user[i].Rotnumber)
  //               console.log(this.state.rotpendinglist)
  //               console.log(indexofvalue)
  //               //console.log(filterobj)
  //               console.log(this.state.rotpendinglist[0].counttotal)
  //             // }else if(rot have in all job then){
  //             //   add counttotal,
  //              }else{
  //               this.setState({rotpendinglist:[...this.state.rotpendinglist,
  //                 { no:i+1,
  //                   rotno:json.user[i].Rotnumber,
  //                   shippername:json.user[i].Shipper,
  //                   totalvolume:json.user[i].totalvolume,
  //                   bookingno:json.user[i].Bookingno,
  //                   status:1,
  //                   remark:json.user[i].Remark,
  //                   counttotal:1,
  //                   countcompleted:0,      
  //                   closingdate:json.user[i].Closingday,            
  //                 },            
  //               ]})
  //              }

  //             //then check bill, and history, 
  //            // add counttotal, add countcompleted,
             
              
              
             
  //              console.log(this.state.rotpendinglist)
             

             
  //             }
  //             this.getorderlistfrombillandhistory(importexport)
  //            // this.setState({loading:false})  
  //           }        
               
  //        else{
  //         this.setState({loading:false}) 
  //       console.log(json)
  //     }
    
  //    })
  // }
 
  getoperatorlistapi =() =>{
    //this.setState({loadingmodaleditorder:true})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-getoperatorlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({operatorlistdata:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({operatorlistdata:[...this.state.operatorlistdata,
                { no:i+1,
                  operator:json.user[i].Operator,
                  title:json.user[i].Operator,
                  value:json.user[i].Operator,
                },            
              ]})
               console.log(this.state.operatorlistdata)
              }
             // this.setState({loadingmodaleditorder:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }
  
  getpickupyardreturnyardlist =()=>{
   // this.setState({loadingmodaleditorder:true})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-getpickupyardreturnyardlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json)
      if(json.success == 1){
        
            console.log(json)
            this.setState({yardlist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({yardlist:[...this.state.yardlist,
                { no:i+1,
                  area:json.user[i].Area,
                  title:json.user[i].Area,
                },            
              ]})
               console.log(this.state.yardlist)
              }
              //this.setState({loadingmodaleditorder:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  getcontainertypelist =(pguorptp,shippername,shipmenttype)=>{
   // this.setState({loadingmodaleditorder:true})
    this.setState({typelist:[]});  
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("shipper", shippername);

   // console.log("PGUPTPhere: " + pguorptp)
    //console.log("shipmenttype: " + shipmenttype)
    if(shipmenttype == 'Export'){
      if(pguorptp == 'Pasir Gudang'){
        console.log("PGUPTPhere: " + pguorptp)
        urlencoded.append("Pguorptp", 'PGU');
      }else if(pguorptp == 'Tanjung Pelepas'){
        urlencoded.append("Pguorptp", 'PTP');
      }
    }else if(shipmenttype == 'Import'){
      if(pguorptp == 'Pasir Gudang'){
        urlencoded.append("Pguorptp", 'PGU');
      }else if(pguorptp == 'Tanjung Pelepas'){
        urlencoded.append("Pguorptp", 'PTP');
      }
    }
    console.log(this.state.Billtoparty)
    console.log(shippername)
    console.log(pguorptp)

    let link = this.state.linkurl +'mode=admin-getcontainertypelist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json)
      if(json.success == 1){
        
            console.log(json)
            this.setState({typelist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({typelist:[...this.state.typelist,
                { no:i+1,
                  size:json.user[i].Size,
                  type:json.user[i].Type,
                  title:json.user[i].Size +" "+json.user[i].Type,
                },            
              ]})

              this.setState({typelistfirstone:[...this.state.typelistfirstone,
                { no:i+1,
                  size:json.user[i].Size,
                  type:json.user[i].Type,
                  title:json.user[i].Size +" "+json.user[i].Type,
                },            
              ]})
               //console.log(this.state.typelist)
              }
             // this.setState({loadingmodaleditorder:false})  
             this.setState({typelist:[...this.state.typelist,
              { no:0,
                size:"None",
                type:"None",
                title:"None",
              },            
            ]}) 
            }        
           
         else{
        //  this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  getforwardingagentlist = ()=>{
    //this.setState({loadingmodaleditorder:true})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-getforwardingagentlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({forwardingagentlist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({forwardingagentlist:[...this.state.forwardingagentlist,
                { no:i+1,
                  forwardingagent:json.user[i].companyname,
                  title:json.user[i].companyname,
                },            
              ]})
               console.log(this.state.forwardingagentlist)
              }
             // this.setState({loadingmodaleditorder:false})  
            }        
               
         else{
          //this.setState({loadingmodaleditorder:false}) 
        console.log(json)
      }
    
     })
  }

  addvolumebuttonclicked = () =>{
    console.log("operatorlist: "+this.state.operatoreadropdowntext)
    if(this.state.volumeshowrow >= 8){
      
    }else{
      this.setState({volumeshowrow:this.state.volumeshowrow+1})
    }
    
    var numbershowtemp = this.state.volumeshowrow+1
    console.log(numbershowtemp)
        if(numbershowtemp == 0){
          this.setState({
            rowvolume1:false,
            rowvolume2:false,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:false,
          })
        } else if(numbershowtemp == 1){
          this.setState({
            rowvolume1:true,
            rowvolume2:false,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        } else if(numbershowtemp == 2){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        } else if(numbershowtemp == 3){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        } else if(numbershowtemp == 4){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        }else if(numbershowtemp == 5){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        }else if(numbershowtemp ==6){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        }else if(numbershowtemp == 7){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:true,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        }else if(numbershowtemp == 8){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:true,
            rowvolume8:true,
            minusbuttonshow:true,
          })
        }
  }
  
  minusvolumebuttonclicked = () =>{
    if(this.state.volumeshowrow <= 0){
    }else{
      this.setState({volumeshowrow:this.state.volumeshowrow-1})
    }
  
    var numbershowtemp = this.state.volumeshowrow-1
    console.log(numbershowtemp)
        if(numbershowtemp == 0){
          this.setState({
            rowvolume1:false,
            rowvolume2:false,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:false,
            volumenumberinput2:0,
          })
        } else if(numbershowtemp == 1){
          this.setState({
            rowvolume1:true,
            rowvolume2:false,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput3:0,
          })
        } else if(numbershowtemp == 2){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput4:0,
          })
        } else if(numbershowtemp == 3){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput5:0,
          })
        } else if(numbershowtemp == 4){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput6:0,
          })
        }else if(numbershowtemp == 5){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput7:0,
          })
        }else if(numbershowtemp ==6){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput8:0,
          })
        }else if(numbershowtemp == 7){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:true,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput9:0,
          })
        }else if(numbershowtemp == 8){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:true,
            rowvolume8:true,
            minusbuttonshow:true,
            
          })
        }
  }

  callgettypelistfunctionpol = (pguorptp) =>{
    this.setState({poltext:pguorptp})
    this.getcontainertypelist(pguorptp,this.state.areadropdowntext,this.state.shipmenttypeadropdowntext);
  }
  
  callgettypelistfunctionshipmenttype= (value) =>{
    this.setState({shipmenttypeadropdowntext:value})
    //this.setState({poltext:''})
    //this.setState({podtext:''})
    console.log("pod: " + this.state.podtext)
    console.log("pol: " + this.state.poltext)
    this.setState({typelist:[]})
  
    if(value =='Export'){
      if(this.state.poltext !=''){
        if(this.state.areadropdowntext != ''){
          console.log("pol2: " + this.state.poltext)
          this.getcontainertypelist(this.state.poltext,this.state.areadropdowntext,value)
        }
       
      }   
     } else if(value =='Import'){
       if(this.state.podtext != ''){
        if(this.state.areadropdowntext != ''){
          this.getcontainertypelist(this.state.podtext,this.state.areadropdowntext,value )
        }
       } 
     } else{
      console.log("no able to get container type list")
     }
    // if(value =='Export'){
    //   this.getcontainertypelist(this.state.poltext,this.state.areadropdowntext)
    //  } else if(value =='Import'){
    //   this.getcontainertypelist(this.state.podtext,this.state.areadropdowntext)
    //  } else{
  
    //  }
  }
  
  callgettypelistfunctionpod = (pguorptp) =>{
    this.setState({podtext:pguorptp})
    this.getcontainertypelist(pguorptp,this.state.areadropdowntext,this.state.shipmenttypeadropdowntext);
  }
  
  checktbefunction = (number) =>{
    
    this.setState({["requestdate" + number]:946684800})
    this.setState({ ["checktbe" + number]: !this.state["checktbe" + number]})
    this.setState({["requestdatedisable" + number]:!this.state["checktbe" + number]})
   
    console.log("checktbecheck: " + this.state["checktbe" + number])
    if(this.state["checktbe" + number] == false){
      this.setState({["requestdate" + number]:946684800})
      //console.log("emptycheck")
    }else if(this.state["checktbe" + number]){
     this.setState({["requestdate" + number]:new Date().getTime()})
      //console.log("bplacecheck1")
    }else{
      this.setState({["requestdate" + number]:946684800})
    }
    
    // console.log("requestdatedisable: "+this.state["requestdatedisable" + number])
    // console.log("requestdate: "+this.state["requestdate" + number])
    // console.log("checktbe: "+this.state["checktbe" + number])
  }
  
  checksideloaderfunction = (number) =>{
    
    this.setState({["checksideloader" + number]:!this.state["checksideloader" + number]})
   
   
    console.log("checksideloadercheck: " + this.state["checksideloader" + number])
   
    
    // console.log("requestdatedisable: "+this.state["requestdatedisable" + number])
    // console.log("requestdate: "+this.state["requestdate" + number])
    // console.log("checktbe: "+this.state["checktbe" + number])
  }

  sameallthedate = () =>{
    console.log("requestdate0:" + this.state.requestdate0)
    this.setState({ samedate: !this.state.samedate})
    let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
    Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
    Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
    Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
    Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 
  
    if(this.state.checktbe0 == true){
      //disable all below
      for(let i=0;i<numberrow;i++){
        this.setState({ ["requestdatedisable" + i]: true })
        this.setState({["requestdate" + i]:946684800})
        this.setState({ ["checktbe" + i]: true})
      }
    }else{
      for(let i=0;i<numberrow;i++){
        this.setState({ ["requestdate" + i]: this.state.requestdate0 })
      }
    }
   
  }
  
  buttonaddattachmentclick = () =>{
    
    this.setState({attachmentquantity:this.state.attachmentquantity + 1}) 
    if(this.state.attachmentquantity == 1){
      this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:false})
      this.setState({fileuploadnumber4:false})
      this.setState({fileuploadnumber5:false})
      this.setState({fileuploadnumber6:false})
      this.setState({fileuploadnumber7:false})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
      }else if(this.state.attachmentquantity == 2){
        this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:true})
      this.setState({fileuploadnumber4:false})
      this.setState({fileuploadnumber5:false})
      this.setState({fileuploadnumber6:false})
      this.setState({fileuploadnumber7:false})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
      }else if(this.state.attachmentquantity == 3){
        this.setState({fileuploadnumber2:true})
        this.setState({fileuploadnumber3:true})
        this.setState({fileuploadnumber4:true})
        this.setState({fileuploadnumber5:false})
        this.setState({fileuploadnumber6:false})
        this.setState({fileuploadnumber7:false})
        this.setState({fileuploadnumber8:false})
        this.setState({fileuploadnumber9:false})
        this.setState({fileuploadnumber10:false})
      }else if(this.state.attachmentquantity == 4){
        this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:true})
      this.setState({fileuploadnumber4:true})
      this.setState({fileuploadnumber5:true})
      this.setState({fileuploadnumber6:false})
      this.setState({fileuploadnumber7:false})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
      }else if(this.state.attachmentquantity == 5){
        this.setState({fileuploadnumber2:true})
        this.setState({fileuploadnumber3:true})
        this.setState({fileuploadnumber4:true})
        this.setState({fileuploadnumber5:true})
        this.setState({fileuploadnumber6:true})
        this.setState({fileuploadnumber7:false})
        this.setState({fileuploadnumber8:false})
        this.setState({fileuploadnumber9:false})
        this.setState({fileuploadnumber10:false})
      }else if(this.state.attachmentquantity == 6){
        this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:true})
      this.setState({fileuploadnumber4:true})
      this.setState({fileuploadnumber5:true})
      this.setState({fileuploadnumber6:true})
      this.setState({fileuploadnumber7:true})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
      }else if(this.state.attachmentquantity == 7){
        this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:true})
      this.setState({fileuploadnumber4:true})
      this.setState({fileuploadnumber5:true})
      this.setState({fileuploadnumber6:true})
      this.setState({fileuploadnumber7:true})
      this.setState({fileuploadnumber8:true})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
      }else if(this.state.attachmentquantity == 8){
        this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:true})
      this.setState({fileuploadnumber4:true})
      this.setState({fileuploadnumber5:true})
      this.setState({fileuploadnumber6:true})
      this.setState({fileuploadnumber7:true})
      this.setState({fileuploadnumber8:true})
      this.setState({fileuploadnumber9:true})
      this.setState({fileuploadnumber10:false})
      }else if(this.state.attachmentquantity == 9){
        this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:true})
      this.setState({fileuploadnumber4:true})
      this.setState({fileuploadnumber5:true})
      this.setState({fileuploadnumber6:true})
      this.setState({fileuploadnumber7:true})
      this.setState({fileuploadnumber8:true})
      this.setState({fileuploadnumber9:true})
      this.setState({fileuploadnumber10:true})
      }else if(this.state.attachmentquantity == 10){
        this.setState({attachmentquantity:10})
      }
  
      console.log("attachmentquantity: " + this.state.attachmentquantity)
  }
  
  //this.uploadfilefrom1to10()
  buttoncancelattachmentclick = () =>{
    this.setState({attachmentquantity:this.state.attachmentquantity - 1}) 
    
    if(this.state.attachmentquantity == 1){  
    this.setState({attachmentquantity:1})
    }else if(this.state.attachmentquantity == 2){
      this.setState({fileuploadnumber2:false})
    this.setState({fileuploadnumber3:false})
    this.setState({fileuploadnumber4:false})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 3){
      this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:false})
      this.setState({fileuploadnumber4:false})
      this.setState({fileuploadnumber5:false})
      this.setState({fileuploadnumber6:false})
      this.setState({fileuploadnumber7:false})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 4){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:false})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 5){
      this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:true})
      this.setState({fileuploadnumber4:true})
      this.setState({fileuploadnumber5:false})
      this.setState({fileuploadnumber6:false})
      this.setState({fileuploadnumber7:false})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 6){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 7){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 8){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 9){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:true})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 10){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:true})
    this.setState({fileuploadnumber9:true})
    this.setState({fileuploadnumber10:false})
    }
    console.log("attachmentquantity: " + this.state.attachmentquantity)
  }
  
  uploadfilefrom1to10edit = (rotnumber) =>{ 
       
      // Create an object of formData 
      const formData = new FormData(); 
      // formData.append( 
      //   'myFile1', 
      //   this.state.selectedFile1,
      // );
     
      console.log("formData: " + formData)
      console.log("selectedFile1: " + this.state.selectedFile1)
      // Update the formData object 
      if(this.state.attachmentquantity == 1){
        if(this.state.selectedFile1 !=null){
          formData.append( 
            "myFile1", 
            this.state.selectedFile1,
          );
        }
        
      }else if(this.state.attachmentquantity == 2){
        if(this.state.selectedFile1 !=null){
          formData.append( 
            "myFile1", 
            this.state.selectedFile1,
          );
        }
        if(this.state.selectedFile2 !=null){
        formData.append( 
          "myFile2", 
          this.state.selectedFile2,
        );  
        }
      }else if(this.state.attachmentquantity == 3){
        if(this.state.selectedFile3 !=null){
        formData.append( 
          "myFile1", 
          this.state.selectedFile1,
        );
        }
        if(this.state.selectedFile2 !=null){
        formData.append( 
          "myFile2", 
          this.state.selectedFile2, 
        ); 
        }
        if(this.state.selectedFile3!=null){
        formData.append( 
          "myFile3", 
          this.state.selectedFile3, 
        ); 
        }
      }else if(this.state.attachmentquantity == 4){
        if(this.state.selectedFile1 !=null){
        formData.append( 
          "myFile1", 
          this.state.selectedFile1,  
        );
        }
        if(this.state.selectedFile2 !=null){
        formData.append( 
          "myFile2", 
          this.state.selectedFile2, 
        ); 
        }
        if(this.state.selectedFile3 !=null){
        formData.append( 
          "myFile3", 
          this.state.selectedFile3, 
        ); 
        }
        if(this.state.selectedFile4 !=null){
        formData.append( 
          "myFile4", 
          this.state.selectedFile4, 
        ); 
        }
      }else if(this.state.attachmentquantity == 5){
        if(this.state.selectedFile1 !=null){
        formData.append( 
          "myFile1", 
          this.state.selectedFile1, 
        );
        }
        if(this.state.selectedFile2 !=null){
        formData.append( 
          "myFile2", 
          this.state.selectedFile2,
        ); 
        }
        if(this.state.selectedFile3 !=null){
        formData.append( 
          "myFile3", 
          this.state.selectedFile3,
        ); 
        }
        if(this.state.selectedFile4 !=null){
        formData.append( 
          "myFile4", 
          this.state.selectedFile4,
        ); 
        }
        if(this.state.selectedFile5 !=null){
        formData.append( 
          "myFile5", 
          this.state.selectedFile5,
        ); 
        }
  
      }else if(this.state.attachmentquantity == 6){
        if(this.state.selectedFile1 !=null){
        formData.append( 
          "myFile1", 
          this.state.selectedFile1,  
        );
        }
        if(this.state.selectedFile2 !=null){
        formData.append( 
          "myFile2", 
          this.state.selectedFile2, 
        ); 
        }
        if(this.state.selectedFile3 !=null){
        formData.append( 
          "myFile3", 
          this.state.selectedFile3, 
        ); 
        }
        if(this.state.selectedFile4 !=null){
        formData.append( 
          "myFile4", 
          this.state.selectedFile4, 
        ); 
        }
        if(this.state.selectedFile5 !=null){
        formData.append( 
          "myFile5", 
          this.state.selectedFile5,
        ); 
        }
        if(this.state.selectedFile6 !=null){        
        formData.append( 
          "myFile6", 
          this.state.selectedFile6, 
        ); 
        }
      }else if(this.state.attachmentquantity == 7){
        if(this.state.selectedFile1 !=null){
        formData.append( 
          "myFile1", 
          this.state.selectedFile1, 
        );
        }
        if(this.state.selectedFile2 !=null){
        formData.append( 
          "myFile2", 
          this.state.selectedFile2, 
        ); 
        }
        if(this.state.selectedFile3 !=null){
        formData.append( 
          "myFile3", 
          this.state.selectedFile3, 
        ); 
        }
        if(this.state.selectedFile4 !=null){
        formData.append( 
          "myFile4", 
          this.state.selectedFile4,
        ); 
        }
        if(this.state.selectedFile5 !=null){
        formData.append( 
          "myFile5", 
          this.state.selectedFile5, 
        ); 
        }
        if(this.state.selectedFile6 !=null){
        formData.append( 
          "myFile6", 
          this.state.selectedFile6,  
        ); 
        }
        if(this.state.selectedFile7 !=null){
        formData.append( 
          "myFile7", 
          this.state.selectedFile7, 
        );
        }
      }else if(this.state.attachmentquantity ==8){
        if(this.state.selectedFile1 !=null){
        formData.append( 
          "myFile1", 
          this.state.selectedFile1,  
        );
        }
        if(this.state.selectedFile2 !=null){
        formData.append( 
          "myFile2", 
          this.state.selectedFile2,  
        ); 
        }
        if(this.state.selectedFile3 !=null){
        formData.append( 
          "myFile3", 
          this.state.selectedFile3,  
        ); 
        }
        if(this.state.selectedFile4 !=null){
        formData.append( 
          "myFile4", 
          this.state.selectedFile4,  
        ); 
        }
        if(this.state.selectedFile5 !=null){
        formData.append( 
          "myFile5", 
          this.state.selectedFile5,  
        ); 
        }
        if(this.state.selectedFile6 !=null){
        formData.append( 
          "myFile6", 
          this.state.selectedFile6,  
        ); 
        }
        if(this.state.selectedFile7 !=null){
        formData.append( 
          "myFile7", 
          this.state.selectedFile7, 
        );
        }
        if(this.state.selectedFile8 !=null){
        formData.append( 
          "myFile8", 
          this.state.selectedFile8,  
        );
        }
      }else if(this.state.attachmentquantity == 9){
        if(this.state.selectedFile1 !=null){
        formData.append( 
          "myFile1", 
          this.state.selectedFile1,  
        );
        }
        if(this.state.selectedFile2 !=null){
        formData.append( 
          "myFile2", 
          this.state.selectedFile2,   
        ); 
        }
        if(this.state.selectedFile3 !=null){
        formData.append( 
          "myFile3", 
          this.state.selectedFile3,  
        ); 
        }
        if(this.state.selectedFile4 !=null){
        formData.append( 
          "myFile4", 
          this.state.selectedFile4,   
        ); 
        }
        if(this.state.selectedFile5 !=null){
        formData.append( 
          "myFile5", 
          this.state.selectedFile5,  
        ); 
        }
        if(this.state.selectedFile6 !=null){
        formData.append( 
          "myFile6", 
          this.state.selectedFile6,  
        ); 
        }
        if(this.state.selectedFile7 !=null){
        formData.append( 
          "myFile7", 
          this.state.selectedFile7,  
        );
        }
        if(this.state.selectedFile8 !=null){
        formData.append( 
          "myFile8", 
          this.state.selectedFile8, 
        );
        }
        if(this.state.selectedFile9 !=null){
        formData.append( 
          "myFile9", 
          this.state.selectedFile9,   
        );
        }
      }else if(this.state.attachmentquantity == 10){
        if(this.state.selectedFile1 !=null){
        formData.append( 
          "myFile1", 
          this.state.selectedFile1, 
        );
        }
        if(this.state.selectedFile2 !=null){
        formData.append( 
          "myFile2", 
          this.state.selectedFile2,  
        ); 
        }
        if(this.state.selectedFile3 !=null){
        formData.append( 
          "myFile3", 
          this.state.selectedFile3, 
        ); 
        }
        if(this.state.selectedFile4 !=null){
        formData.append( 
          "myFile4", 
          this.state.selectedFile4, 
        ); 
        }
        if(this.state.selectedFile5 !=null){
        formData.append( 
          "myFile5", 
          this.state.selectedFile5, 
        ); 
        }
        if(this.state.selectedFile6 !=null){
        formData.append( 
          "myFile6", 
          this.state.selectedFile6,  
        ); 
        }
        if(this.state.selectedFile7 !=null){
        formData.append( 
          "myFile7", 
          this.state.selectedFile7,  
        );
        }
        if(this.state.selectedFile8 !=null){
        formData.append( 
          "myFile8", 
          this.state.selectedFile8,  
        );
        }
        if(this.state.selectedFile9 !=null){
        formData.append( 
          "myFile9", 
          this.state.selectedFile9,   
        );
        }
        if(this.state.selectedFile10 !=null){
        formData.append( 
          "myFile10", 
          this.state.selectedFile10, 
        );
        }
      }
      console.log("")
      formData.append( 
        "Rotnumber", 
        rotnumber
      );
     
      console.log("cancelattachemnt1: " + this.state.cancelattachmentbutton1)
      if(this.state.cancelattachmentbutton1 == true){
        formData.append( 
          "cancelattachmentbutton1", 
          1
        );
      }
     
      console.log("cancelattachemnt2: " + this.state.cancelattachmentbutton2)
      if(this.state.cancelattachmentbutton2 == true){
        formData.append( 
          "cancelattachmentbutton2", 
          1
        );
      }

 
      if(this.state.cancelattachmentbutton3 == true){
        formData.append( 
          "cancelattachmentbutton3", 
          1
        );
      }

     
      if(this.state.cancelattachmentbutton4 == true){
        formData.append( 
          "cancelattachmentbutton4", 
          1
        );
      }

      
      if(this.state.cancelattachmentbutton5 == true){
        formData.append( 
          "cancelattachmentbutton5", 
          1
        );
      }

      
      if(this.state.cancelattachmentbutton6 == true){
        formData.append( 
          "cancelattachmentbutton6", 
          1
        );
      }

       
      if(this.state.cancelattachmentbutton7 == true){
        formData.append( 
          "cancelattachmentbutton7", 
          1
        );
      }

      
      if(this.state.cancelattachmentbutton8 == true){
        formData.append( 
          "cancelattachmentbutton8", 
          1
        );
      }

      
      if(this.state.cancelattachmentbutton9 == true){
        formData.append( 
          "cancelattachmentbutton9", 
          1
        );
      }

      if(this.state.cancelattachmentbutton10 == true){
        formData.append( 
          "cancelattachmentbutton10", 
          1
        );
      }
      // Details of the uploaded file 
      console.log(this.state.selectedFile1); 
      console.log(this.state.selectedFile2); 
      console.log(this.state.selectedFile3); 
      console.log(this.state.selectedFile4); 
      console.log(this.state.selectedFile5); 
      console.log(this.state.selectedFile6); 
      console.log(this.state.selectedFile7); 
      console.log(this.state.selectedFile8); 
      console.log(this.state.selectedFile9);
      console.log(this.state.selectedFile10);  
      // Request made to the backend api 
      // Send formData object 
      const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
  
  
      return post(this.state.linkurl+"mode=admin-uploadfileattachmentrotedit", formData,config )
      .then(res => {
        console.log(res);
        console.log(res.data);
      }); 
    
  }
  
uploadfile1 = ({target:{files}}) =>{ 
  this.setState({percentupload1:0})
  this.setState({selectedFilename1:""}) 
  let attachmentnumber = 1
 
  const formData = new FormData();
 
    formData.append( 
      "myFile", 
      files[0] ,
    );

  
 
  formData.append( 
    "attachementsetID", 
    this.state.attachmentsetID,
  );
 
  formData.append( 
    "attachmentnumber", 
    attachmentnumber,
  );


  const config = {
    headers: {
        'content-type': 'multipart/form-data'
    }
}

const options = {
  onUploadProgress:(progressEvent) =>{
    const {loaded,total} = progressEvent;
    let percent = Math.floor(loaded*100/total)
    console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
    this.setState({percentupload1:percent})
  }
}

//upload go then feed back link, 
//the link update go current database

  axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
  .then(res => {
    //console.log(res);
    console.log(res.data);
    this.setState({selectedFilename1:res.data})
    console.log("selectedfilename1:" + this.state.selectedFilename1)

    this.syncattachmentlist();
   
    
  }); 

}


syncattachmentlist = () =>{
  this.setState({attachmentlist:[]})
  console.log("selectedFileexistname1full"+this.state.selectedFileexistname1full)
  if(this.state.selectedFileexistname1full != null){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFileexistname1full]})
   
  }
  if(this.state.selectedFileexistname2full != null){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFileexistname2full]})
  
  }
  if(this.state.selectedFileexistname3full != null){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFileexistname3full]})

  }
  if(this.state.selectedFileexistname4full != null){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFileexistname4full]})
  }
  if(this.state.selectedFileexistname5full != null){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFileexistname5full]})
  }
  if(this.state.selectedFileexistname6full != null){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFileexistname6full]})
  }
  if(this.state.selectedFileexistname7full != null){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFileexistname7full]})
  }
  if(this.state.selectedFileexistname8full != null){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFileexistname8full]})
  }
  if(this.state.selectedFileexistname9full != null){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFileexistname9full]})
  }
  if(this.state.selectedFileexistname10full != null){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFileexistname10full]})
  }
  if(this.state.selectedFilename1 != ""){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFilename1]})
  }
  if(this.state.selectedFilename2 != ""){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFilename2]})
  }
  if(this.state.selectedFilename3 != ""){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFilename3]})
  }
  if(this.state.selectedFilename4 != ""){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFilename4]})
  }
  if(this.state.selectedFilename5 != ""){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFilename5]})
  }
  if(this.state.selectedFilename6 != ""){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFilename6]})
  }
  if(this.state.selectedFilename7 != ""){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFilename7]})
  }
  if(this.state.selectedFilename8 != ""){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFilename8]})
  }
  if(this.state.selectedFilename9 != ""){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFilename9]})
  }
  if(this.state.selectedFilename10 != ""){
    this.setState({attachmentlist:[...this.state.attachmentlist,this.state.selectedFilename10]})
  }

  console.log("File:" + this.state.attachmentlist)
}


uploadfile2 = ({target:{files}}) =>{ 
     this.setState({percentupload2:0})
     this.setState({selectedFilename2:""})   
  let attachmentnumber = 2
 
  const formData = new FormData();
 
    formData.append( 
      "myFile", 
      files[0] ,
    );

  
 
  formData.append( 
    "attachementsetID", 
    this.state.attachmentsetID,
  );
 
  formData.append( 
    "attachmentnumber", 
    attachmentnumber,
  );


  const config = {
    headers: {
        'content-type': 'multipart/form-data'
    }
}

const options = {
  onUploadProgress:(progressEvent) =>{
    const {loaded,total} = progressEvent;
    let percent = Math.floor(loaded*100/total)
    console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
    this.setState({percentupload2:percent})
  }
}

//upload go then feed back link, 
//the link update go current database

  axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
  .then(res => {
    //console.log(res);
    console.log(res.data);
    this.setState({selectedFilename2:res.data})    
    this.syncattachmentlist();

  }); 

}

uploadfile3 = ({target:{files}}) =>{ 
  this.setState({percentupload3:0})
  this.setState({selectedFilename3:""})   
let attachmentnumber = 3

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload3:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename3:res.data})    
 this.syncattachmentlist();
}); 

}

uploadfile4 = ({target:{files}}) =>{ 
  this.setState({percentupload4:0})
  this.setState({selectedFilename4:""})   
let attachmentnumber = 4

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload4:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename4:res.data})    
 this.syncattachmentlist();
}); 

}

uploadfile5 = ({target:{files}}) =>{ 
  this.setState({percentupload5:0})
  this.setState({selectedFilename5:""})   
let attachmentnumber = 5

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload5:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename5:res.data})    
 this.syncattachmentlist();
}); 

}

uploadfile6 = ({target:{files}}) =>{ 
  this.setState({percentupload6:0})
  this.setState({selectedFilename6:""})   
let attachmentnumber = 6

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload6:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename6:res.data})    
 this.syncattachmentlist();
}); 

}

uploadfile7 = ({target:{files}}) =>{ 
  this.setState({percentupload7:0})
  this.setState({selectedFilename7:""})   
let attachmentnumber = 7

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload7:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename7:res.data})    
 this.syncattachmentlist();
}); 

}

uploadfile8 = ({target:{files}}) =>{ 
  this.setState({percentupload8:0})
  this.setState({selectedFilename8:""})   
let attachmentnumber = 8

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload8:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename8:res.data})    
 this.syncattachmentlist();
}); 

}

uploadfile9 = ({target:{files}}) =>{ 
  this.setState({percentupload9:0})
  this.setState({selectedFilename9:""})   
let attachmentnumber = 9

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload9:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename9:res.data})    
 this.syncattachmentlist();
}); 

}

uploadfile10 = ({target:{files}}) =>{ 
  this.setState({percentupload10:0})
  this.setState({selectedFilename10:""})   
let attachmentnumber = 10

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload10:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename10:res.data})    
 this.syncattachmentlist();
}); 

}

//export , upload, 
//ask for update, take video show how to update and what update


  closeandrefreshaftersubmit = () =>{
    this.setState({editpendingrotpopout:false});
    if(this.state.tabsimportexport == 1){
      this.getpendingorderlistapi("Import");
    }else{
      this.getpendingorderlistapi("Export");
    }

    this.setState({
      attachmentlist:[],
      selectedFile1: null,
      selectedFile2: null,
      selectedFile3: null,
      selectedFile4: null,
      selectedFile5: null,
      selectedFile6: null,
      selectedFile7: null,
      selectedFile8: null,
      selectedFile9: null,
      selectedFile10: null,
      selectedFileexistname1: null,
      selectedFileexistname2: null,
      selectedFileexistname3: null,
      selectedFileexistname4: null,
      selectedFileexistname5: null,
      selectedFileexistname6: null,
      selectedFileexistname7: null,
      selectedFileexistname8: null,
      selectedFileexistname9: null,
      selectedFileexistname10: null,
      selectedFilename1:"",
      selectedFilename2:"",
      selectedFilename3:"",
      selectedFilename4:"",
      selectedFilename5:"",
      selectedFilename6:"",
      selectedFilename7:"",
      selectedFilename8:"",
      selectedFilename9:"",
      selectedFilename10:"",
      selectedFileexistname1full:null,
      selectedFileexistname2full:null,
      selectedFileexistname3full:null,
      selectedFileexistname4full:null,
      selectedFileexistname5full:null,
      selectedFileexistname6full:null,
      selectedFileexistname7full:null,
      selectedFileexistname8full:null,
      selectedFileexistname9full:null,
      selectedFileexistname10full:null,
    })
    
  }
  
  vsletachangedchoose = (e) =>{
    if(this.state.shipmenttypeadropdowntext =="Export"){
      this.setState({ Vsleta: e})
      var timestamp = e.unix()
      var daylate5r = timestamp - 86400 * 1 
      var closingdaymoment = moment.unix(daylate5r)
      this.setState({closingday:closingdaymoment})
    }else{
      this.setState({ Vsleta: e})
      var timestamp = e.unix()
      var daylate5r = timestamp + 86400 * 5 
      var closingdaymoment = moment.unix(daylate5r)
      this.setState({closingday:closingdaymoment})
    }
  }
    

  cancelattachment= (number)=>{

    if(window.confirm("Are you sure you want remove this attachment?")){
      if(number ==1){
        this.setState({cancelattachmentbutton1:true});
        console.log("cancelattachment1: "+this.state.cancelattachmentbutton1);
        this.setState({selectedFileexistname1:null})
        this.setState({selectedFileexistname1full:null})
        

      }else if(number ==2){
        this.setState({cancelattachmentbutton2:true});
        console.log("cancelattachment2: "+this.state.cancelattachmentbutton2);
        this.setState({selectedFileexistname2:null})
        this.setState({selectedFileexistname2full:null})
      }else if(number ==3){
        this.setState({cancelattachmentbutton3:true});
        console.log("cancelattachment3: "+this.state.cancelattachmentbutton3);
        this.setState({selectedFileexistname3:null})
        this.setState({selectedFileexistname3full:null})
      }else if(number ==4){
        this.setState({cancelattachmentbutton4:true});
        console.log("cancelattachment4: "+this.state.cancelattachmentbutton4);
        this.setState({selectedFileexistname4:null})
        this.setState({selectedFileexistname4full:null})
      }else if(number ==5){
        this.setState({cancelattachmentbutton5:true});
        console.log("cancelattachment5: "+this.state.cancelattachmentbutton5);
        this.setState({selectedFileexistname5:null})
        this.setState({selectedFileexistname5full:null})
      }else if(number ==6){
        this.setState({cancelattachmentbutton6:true});
        console.log("cancelattachment6: "+this.state.cancelattachmentbutton6);
        this.setState({selectedFileexistname6:null})
        this.setState({selectedFileexistname6full:null})
      }else if(number ==7){
        this.setState({cancelattachmentbutton7:true});
        console.log("cancelattachment7: "+this.state.cancelattachmentbutton7);
        this.setState({selectedFileexistname7:null})
        this.setState({selectedFileexistname7full:null})
      }else if(number ==8){
        this.setState({cancelattachmentbutton8:true});
        console.log("cancelattachment8: "+this.state.cancelattachmentbutton8);
        this.setState({selectedFileexistname8:null})
        this.setState({selectedFileexistname8full:null})
      }else if(number ==9){
        this.setState({cancelattachmentbutton9:true});
        console.log("cancelattachment9: "+this.state.cancelattachmentbutton9);
        this.setState({selectedFileexistname9:null})
        this.setState({selectedFileexistname9full:null})
      }else if(number ==10){
        this.setState({cancelattachmentbutton10:true});
        console.log("cancelattachment10: "+this.state.cancelattachmentbutton10);
        this.setState({selectedFileexistname10:null})
        this.setState({selectedFileexistname10full:null})
      }
      //console.log("selectedFileexistname1fullfront"+this.state.selectedFileexistname1full)
      this.syncattachmentlist();
      
    }  
   // console.log("cancelattachment1test: "+this.state.cancelattachmentbutton1);
  }
  
  render() {
    return (
      <>
        <Header />
        {/* Page contentas */}
        <Container className=" mt--10" fluid>
          {/* Table */}
          <Row>
            <div className=" col-12 ">
            {/* <div className="nav-wrapper">
          <Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={this.state.tabs === 1}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.tabs === 1
                })}
                onClick={e => 
                (this.buttonclicktoswitchtab(e,1,1))
              }
                href="#pablo"
                role="tab"
              >
                <i className="ni ni-planet mr-2" />
                Pending Approval
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={this.state.tabs === 2}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.tabs === 2
                })}
                onClick={e => 
                  (this.buttonclicktoswitchtab(e,2,1))
                }
                href="#pablo"
                role="tab"
              >
                <i className="ni ni-collection  mr-2" />
                Orders
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={this.state.tabs === 3}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.tabs === 3
                })}
                onClick={e =>
                  (this.buttonclicktoswitchtab(e,3,1))}
                href="#pablo"
                role="tab"
              >
                <i className="ni ni-check-bold  mr-2" />
                Completed
              </NavLink>
            </NavItem>
          </Nav>
        </div> */}
        
        <Col md="12  mt-3">
        {window.innerWidth <= 760 ?
                <div className="d-md-inline-block">
                <NavLink className="nav-link-icon d-md-inline-block col-12" to="/admin/addnewrot" tag={Link}>
              <Button className="col-12" color="default" type="button" >
                + Add New ROT
              </Button>
              </NavLink>
                <NavLink className="nav-link-icon d-md-inline-block col-12 mt-3" >
              <Button  className={classnames("col-12")} color={this.state.buttoncolor1} type="button"  onClick={(e)=>this.pendingorderbuttonclicked("Import")}>
              <i className="ni ni-planet mr-2" />
                 Pending Approval
              </Button>
              </NavLink>
                <NavLink className="nav-link-icon d-md-inline-block col-12" >
              <Button  className={classnames("col-12")} color={this.state.buttoncolor3} type="button"  onClick={(e)=> this.completedbuttonclicked() }>
              <i className="ni ni-check-bold mr-2" />
                 Completed
              </Button>
              </NavLink>
                <NavLink className="nav-link-icon d-md-inline-block col-12">
              <Button  className={classnames("col-12")} color={this.state.buttoncolor2} type="button"  onClick={(e)=>this.orderbuttonclicked()}>
               <i className="ni ni-collection  mr-2" />
                 Orders
              </Button>
              </NavLink>
              
                </div>
         :
<div>
      <NavLink className="nav-link-icon" to="/admin/addnewrot" tag={Link}>
       <Button className="my-4" color="default" type="button" style={{marginLeft:-10}}>
         + Add New ROT
       </Button>
       </NavLink>
         <div className="d-md-inline-block">
         <NavLink className="nav-link-icon d-md-inline-block ">
       <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor2} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.orderbuttonclicked()}>
        <i className="ni ni-collection  mr-2" />
          Orders
       </Button>
       </NavLink>
       <NavLink className="nav-link-icon d-md-inline-block" >
       <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor1} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.pendingorderbuttonclicked("Import")}>
       <i className="ni ni-planet mr-2" />
          Pending Approval
       </Button>
       </NavLink>
       <NavLink className="nav-link-icon d-md-inline-block" >
       <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor3} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=> this.completedbuttonclicked() }>
       <i className="ni ni-check-bold mr-2" />
          Completed
       </Button>
       </NavLink>
         </div>
  
        
       </div>
        }

                
        </Col>
            </div>
            <div className=" col-12 ">
              <Card className=" shadow  ">
              
              <Row>
                <Col >

                <div className=" col-12 d-md-inline-block ">
                <div className="nav-wrapper ">
              
            {window.innerWidth < 760 ?
            <Nav
            className="nav-fill  flex-md-row  "
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={this.state.tabsimportexport === 1}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.tabsimportexport === 1
                })}
                onClick={e => (this.buttonclicktoswitchtab(e,this.state.tabs,1))}
                href="#pablo"
                role="tab"
              >
                <i className="ni ni-world mr-2" />
                Import
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={this.state.tabsimportexport === 2}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.tabsimportexport === 2
                })}
                onClick={e =>(this.buttonclicktoswitchtab(e,this.state.tabs,2))}
                href="#pablo"
                role="tab"
              >
                <i className="ni ni-world-2  mr-2" />
                Export
              </NavLink>
            </NavItem>
            
          </Nav>
          :
          <Nav
                className="nav-fill flex-column flex-md-row  "
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabsimportexport === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabsimportexport === 1
                    })}
                    onClick={e => (this.buttonclicktoswitchtab(e,this.state.tabs,1))}
                    href="#pablo"
                    role="tab"
                  >
                    <i className="ni ni-world mr-2" />
                    Import
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabsimportexport === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabsimportexport === 2
                    })}
                    onClick={e =>(this.buttonclicktoswitchtab(e,this.state.tabs,2))}
                    href="#pablo"
                    role="tab"
                  >
                    <i className="ni ni-world-2  mr-2" />
                    Export
                  </NavLink>
                </NavItem>
                
              </Nav>}
            
            
            </div>


                </div>
                
               
                </Col>

                <Col md="8 form-inline  d-md-flex">
             
              
                 {/* Searchbar is here */}
                   {/* till here */}
                   {window.innerWidth <= 760 ?
                <InputGroup className="col-11 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                   
                      <i className="fas fa-search" />
                     
                    </InputGroupText>
                  </InputGroupAddon>
                
                    <Input
                      placeholder="Search" 
                      value={this.state.searchtextvalue}
                      onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                      type="text"
                      onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                    />
                  
                 {this.state.searchtextvalue == "" ?
                 null:
                 <InputGroupAddon addonType="prepend">
                 <InputGroupText>
                 <Button onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</Button>
                                  
                <Button className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</Button >
                   
                 </InputGroupText>
               </InputGroupAddon>
                 }
                    
                     
                  
                </InputGroup>
             :
             <InputGroup className="col-6 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
             <InputGroupAddon addonType="prepend">
                 <InputGroupText>
                
                   <i className="fas fa-search" />
                  
                 </InputGroupText>
               </InputGroupAddon>
             
                 <Input
                   placeholder="Search" 
                   value={this.state.searchtextvalue}
                   onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                   type="text"
                   onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                 />
               
              {this.state.searchtextvalue == "" ?
              null:
              <InputGroupAddon addonType="prepend">
              <InputGroupText>
              <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                               
             <span className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</span >
                
              </InputGroupText>
            </InputGroupAddon>
              }
                 
                  
               
             </InputGroup>
             }



                </Col>
              </Row>
             
             
             {this.state.tabs==3?
                  <Row className="marginleftcss">                
                    <Col md="0.5" className="margintopcss">
                    <span className="margintopcss">From: </span>
                    </Col>
                    <Col md={3}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "From Date"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ startDate: e })  }
                  value={this.state.startDate}
                />
              </InputGroup>
            </FormGroup>
          </Col>
                    <Col md="0.5" className="margintopcss">
                    <span className="margintopcss">To: </span>
                    </Col>
                    <Col md={3}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "To Date"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ endDate: e })}
                  value={this.state.endDate}
                />
                {/* , this.setState({ endDate: e }) */}
              </InputGroup>
            </FormGroup>
          </Col>
                   
                   
                    <Col md={2}>
                    <Button  className={classnames("mb-sm-3 mb-md-0")} color="blue" type="button" style={{marginLeft:-10}} onClick={(e)=>{if(this.state.tabsimportexport == 1){this.searchdatecompletedate("Import")}else{this.searchdatecompletedate("Export")}}}>
                         Search
                      </Button>
                    </Col>
                    
                    <Col md="3">
                   {this.renderTablePagination()}
                 </Col>
                 </Row>
             :
             null
             }
         
            
              {this.state.loading
              ?
              <Row >
                <Col md="12" >
              <img 
              className="img-center mt-5"
              width="120"
              height="120"
              alt="..."
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
            </Col>
              </Row>
              :
              <CardBody >
                
            
                <TabContent  activeTab={"tabs" + this.state.tabs + "andtabsimportexport"+ this.state.tabsimportexport}>
                  <TabPane tabId="tabs1andtabsimportexport1">                    
                  {window.innerWidth < 760 ?
                     <div className="align-items-center " responsive>     
                     <div className="bg-gray pt-2 pb-2 ">
                     <h3 scope="col" className="ml-4">    Job</h3> 
                       </div>   
                        {this.renderPendingOrderDataphone2()}                       
                        </div>
                  :
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                 <tr>
                   <th>No.</th>
                   <th >ROT No.</th>
                   <th  >IM/EX</th>    
                   <th  >Shipper/Consignee</th>  
                   
                   <th>Volume</th>
                   <th >BL./DO.</th> 
                   <th >Status</th>
                   {/* <th scope="col">Remark</th> */}
                   <th >Action</th>
                   <th scope="col" />
                 </tr>
               </thead>
                   
                   {this.renderPendingOrderData()}                       
                   </Table>
                  }
             
                  </TabPane>
                    
                  <TabPane tabId="tabs2andtabsimportexport1">
                  {window.innerWidth < 760 ?
                 <div className="align-items-center " responsive>     
                 <div className="bg-gray pt-2 pb-2 ">
                 <h3 scope="col" className="ml-4">    Job</h3> 
                   </div> 
                
                                       
                      {this.renderOrderListDataphone()} 
                      </div>
                  :
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th >No.</th>
                      <th >ROT No.</th>
                      <th  >Shipper/Consignee</th>    
                      <th >BL./DO.</th>  
                      <th >Volume</th>               
                      <th >Last Day</th>
                      <th >Status</th>
                      {/* <th scope="col">Remark</th> */}
                      <th >Action</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                     
                    
                    </tbody>  
                             
                      {this.renderOrderListData()} 
                      
                      </Table>
                  }
                  
                           
        
                  </TabPane>

                  <TabPane tabId="tabs3andtabsimportexport1">
                  {window.innerWidth < 760 ?
                  <div className="align-items-center " responsive>     
                  <div className="bg-gray pt-2 pb-2 ">
                  <h3 scope="col" className="ml-4">    Job</h3> 
                    </div>  
                    {this.rendercompletedOrderDataphone2()}  
                    </div>
                  :
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th >No.</th>
                      <th >ROT No.</th>
                      <th   >Shipper/Consignee</th>
                      
                      <th >Volume</th>
                      <th >BL./DO.</th> 
                      <th>Status</th>
                      {/* <th scope="col">Remark</th> */}
                      <th >Action</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                      
                      {this.rendercompletedOrderData()}  
                      </Table>
                  }
                
                      {this.renderTablePagination()}
                
                      
                  </TabPane>

                  <TabPane tabId="tabs1andtabsimportexport2">
                  {window.innerWidth < 760 ?
                     <div className="align-items-center " responsive>     
                     <div className="bg-gray pt-2 pb-2 ">
                     <h3 scope="col" className="ml-4">    Job</h3> 
                       </div>   
                        {this.renderPendingOrderDataphone2()}                       
                        </div>
                  :
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                 <tr>
                   <th>No.</th>
                   <th >ROT No.</th>
                   <th  >IM/EX</th>    
                   <th  >Shipper/Consignee</th>  
                   
                   <th>Volume</th>
                   <th >BL./DO.</th> 
                   <th >Status</th>
                   {/* <th scope="col">Remark</th> */}
                   <th >Action</th>
                   <th scope="col" />
                 </tr>
               </thead>
                   
                   {this.renderPendingOrderData()}                       
                   </Table>
                  }  
                  </TabPane>


                  <TabPane tabId="tabs2andtabsimportexport2">
                  {window.innerWidth < 760 ?
                 <div className="align-items-center " responsive>     
                 <div className="bg-gray pt-2 pb-2 ">
                 <h3 scope="col" className="ml-4">    Job</h3> 
                   </div> 
                
                                       
                      {this.renderOrderListDataphone()} 
                      </div>
                  :
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th >No.</th>
                      <th >ROT No.</th>
                      <th  >Shipper/Consignee</th>    
                      <th >BL./DO.</th>  
                      <th >Volume</th>               
                      <th >Last Day</th>
                      <th >Status</th>
                      {/* <th scope="col">Remark</th> */}
                      <th >Action</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                                       
                      {this.renderOrderListData()} 
                      
                      </Table>
                  }
                  </TabPane>


                  <TabPane tabId="tabs3andtabsimportexport2">
                  {window.innerWidth < 760 ?
                   
                      <div className="align-items-center " responsive>     
                      <div className="bg-gray pt-2 pb-2 ">
                      <h3 scope="col" className="ml-4">    Job</h3> 
                        </div>   
                        {this.rendercompletedOrderDataphone2()}
                    </div>

                  :
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>No.</th>
                      <th >ROT No.</th>
                      <th   >Shipper/Consignee</th>
                      
                      <th >Volume</th>
                      <th >BL./DO.</th> 
                      <th>Status</th>
                      {/* <th scope="col">Remark</th> */}
                      <th>Action</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                      
                  
                  {this.rendercompletedOrderData()}  
                      </Table>
                  }
                

                      {this.renderTablePagination()}
                  </TabPane>


                </TabContent>
              
               
              </CardBody>
                  }

                  
             
                
              </Card>
            </div>
          </Row>
          
        </Container>
       
        {/* detailpopout */}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.detailpopout}
          toggle={() => this.setState({detailpopout:false})}
        >
          {this.state.loadingmodal?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>:
          <div>
          <div className="modal-header">
          <h1 className="modal-title" id="detailpopoutLabel">
              ROT No: {this.state.rotnumbermodalpending}
            </h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailpopout:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10">
        <Form>
        <Row>
          <Col md="3">
          <h5 className="modal-title" id="detailpopoutLabel">
            Shipper/Consignee
          </h5>
          </Col>
          <Col md="9">
          <h5 className="modal-title" id="detailpopoutLabel">
            : {this.state.shipper}
          </h5>
          </Col>
        </Row>
        <Row>
          <Col md="3">
          <h5 className="modal-title" id="detailpopoutLabel">
          Booking No
          </h5>
          </Col>
          <Col md="9">
          <h5 className="modal-title" id="detailpopoutLabel">
          : {this.state.bookingno}
          </h5>
          </Col>
        </Row>
        <Row>
          <Col md="3">
          <h5 className="modal-title" id="detailpopoutLabel">
          POL
          </h5>
          </Col>
          <Col md="9">
          <h5 className="modal-title" id="detailpopoutLabel">
          : {this.state.pol}
          </h5>
          </Col>
        </Row>
        <Row>
          <Col md="3">
          <h5 className="modal-title" id="detailpopoutLabel">
          POD
          </h5>
          </Col>
          <Col md="9">
          <h5 className="modal-title" id="detailpopoutLabel">
          : {this.state.pod}
          </h5>
          </Col>
        </Row>
        </Form>
     
        
          
        <Table style={{marginTop:20}} className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Consignment No.</th>
                      <th scope="col">Container No.</th>      
                      <th scope="col">Require Date</th>                  
                      <th scope="col">Closing Date</th>
                      <th scope="col">Type</th>
                      <th scope="col" />
                    </tr>
                  </thead>
         {this.renderdetaillistData()}
         </Table>
       
        
       </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailpopout:false})}
            >
              Close
            </Button>
         
          </div>
          </div>}</Modal>  

          {/* detailorderpopout */}
         <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.detailorderpopout}
          toggle={() => this.setState({detailorderpopout:false})}
        >
           {this.state.loadingmodalorder?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>:
         <div>
          <div className="modal-header">
          <h1 className="modal-title" id="detailorderpopoutLabel">
              ROT No: {this.state.rotnumbermodalordertab}
            </h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailorderpopout:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10">
          <Form>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
              Shipper/Consignee
            </h5>
            </Col>
            <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
              : {this.state.shipperorder}
            </h5>
            </Col>
          </Row>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
            Booking No
            </h5>
            </Col>
            <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
            : {this.state.bookingnoorder}
            </h5>
            </Col>
          </Row>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
            POL
            </h5>
            </Col>
            <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
            : {this.state.polorder}
            </h5>
            </Col>
          </Row>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
            POD
            </h5>
            </Col>
            <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
            : {this.state.podorder}
            </h5>
            </Col>
          </Row>
         
          </Form>

          <Table style={{marginTop:20}} className="align-items-center" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Consignment No.</th>
                        <th scope="col">Container No.</th>      
                        <th scope="col">Require Date</th>                  
                        <th scope="col">Closing Date</th>
                        <th scope="col">Type</th>
                        <th scope="col">Status</th>
                        <th scope="col">Detail</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    
                      {this.renderdetaillistDataOrder()}
                      
                   
                  </Table>
          
          
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailorderpopout:false})}
            >
              Close
            </Button>
         
          </div>
          </div>
  }
        </Modal> 

          {/* detailprocess */}
         <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.detailprocesspopout}
          toggle={() => this.setState({detailprocesspopout:false})}
        >
          <div className="modal-header">
          <h3 className="modal-title" id="detailprocesspopoutlabel">
              Consignment No: {this.state.consignmentnoprocesstab} 
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailprocesspopout:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10">
          <Form>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailprocesspopoutLabel">
              Container No  : {this.state.containernoprocesstab}
            </h5>
            </Col>
            {/* <Col md="1">
            <h5 className="modal-title" id="detailprocesspopoutLabel">
             
            </h5>
            </Col> */}
          </Row>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
            Require Date : {this.state.requiredateprocesstab}
            </h5>
            </Col>
            {/* <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
            
            </h5>
            </Col> */}
          </Row>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
            Closing Date  : {this.state.closingdateprocesstab}
            </h5>
            </Col>
            {/* <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
            : {this.state.closingdateprocesstab}
            </h5>
            </Col> */}
          </Row>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
             Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {this.state.typeprocesstab}
            </h5>
            </Col>
            {/* <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
            : {this.state.typeprocesstab}
            </h5>
            </Col> */}
          </Row>
          </Form>
       
          <Container style={{marginTop:40}}>
                {this.renderprocesslist()}
            </Container>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailprocesspopout:false})}
            >
              Close
            </Button>
         
          </div>
        </Modal>             
     
        {/*editpendingorder */}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.editpendingrotpopout}
          toggle={() => this.setState({editpendingrotpopout:false})}
        >
          {this.state.loadingmodaleditorder?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>:
          <div>
          <div className="modal-header">
          <h1 className="modal-title" id="detailpopoutLabel">
              ROT No: {this.state.editorderrotnumber}
            </h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({editpendingrotpopout:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10">
        {/*Body start here */}
        {/*Body start here */}
        {/*Body start here */}

        <div>
          {/* Table */}
          <Row>
            <div className=" col">           
              {
              /* Success Status*/
              this.state.aftersubmitstatus == 1
              ?             
              <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodoneneweditrot"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Thanks for choosing Mewahtrans Logistics,</p>
              <p className="word-quotationaddnewfinish">Your Rot Number is {this.state.rotnumbercomeback}.</p>
              <ReactToPrint
          trigger={() =>   <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            to="/admin/orders"
            >
            Print Rot
            </Button>}
          content={() => this.componentRef}
        />
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => {this.closeandrefreshaftersubmit()}}
              >
              Close
            </Button>
          
            </Col>
            
          </Row>
          </Form>
         </div>
              :
              /* Fail Status */
              this.state.aftersubmitstatus == 2
              ?              
              <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">Submit Failed, Something Wrong, Please Try Again.</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={() => this.setState({aftersubmitstatus:0})}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
              :
              /* Loading Status */
              this.state.aftersubmitstatus == 3
              ?
              <Row >
                <Col md="12" >
                  <img 
                  className="img-center"
                  width="120"
                  height="120"
                  alt="..."
                  src={require("./../../assets/gif/lorrygif3.gif")}
                />
                </Col>
            </Row>
              :             
              <Form>
           

                  {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
               

                  {/*Bill to Party*/}
                  <Row>
                    <Col md="12">
                      <p className="h5">Bill To Party:</p>
                      <FormGroup>
                        <Input
                          id="Billtopartyinput1"
                          placeholder="Bill To Party"
                          value={this.state.Billtoparty}
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    
                  </Row>
                  {/*Shipper*/}
                  <Row>
                  <Col md="12">
                      <p className="h5">Shipper/Consignee:</p>
                      <FormGroup >
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.quotationtabledatafinal}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.functionselectshipmenttype(value)}
                        renderInput={(params) => <TextField {...params} label={this.state.areadropdowntext} variant="outlined" error={this.state.shippervalidation} helperText={this.state.shippervalidationmessage} />}
                        disableClearable={true}
                      />
                      {/* <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownarea} toggle={() => this.toggledropdownarea("isopendropdownarea")}>
                        <DropdownToggle className = "btn-block" block={true} caret>
                          {this.state.areadropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                        {this.rendershipperdropdownmenu()}
                        </DropdownMenu>
                      </Dropdown> */}
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  {/*Address*/}
                  <Row>
                  <Col md="12">
                      <p className="h5">Address:</p>
                      <FormGroup>
                      <Input
                          id="Addressinput1"
                          placeholder="Address"
                          type="text"
                          value={this.state.deliveryaddress}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  {/*Area,PIC,Contact*/}
                  <Row>
                  <Col md="4">
                      <p className="h5">Area:</p>
                      <FormGroup>
                      <Input
                          id="areainput1"
                          placeholder="Area"
                          type="text"
                          value={this.state.areatextbox}
                          disabled
                        />
                      </FormGroup>
                      </Col>
                      <Col md="4">
                      <p className="h5">PIC:</p>
                      <FormGroup>
                      {/* {this.state.submitbuttonclick == 0?
                        
                        :
                        
                        } */}
                      <Input
                          id="Picinput1"
                          placeholder="PIC"
                          type="text"
                          disabled={true}
                          value={this.state.picvalue}
                          onChange={(e) => this.handleChange(e.target.value,"picvalue")}
                          invalid={this.state.picvalidation}
                        />
                          <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <p className="h5">Contact:</p>
                      <FormGroup>
                      <Input
                          id="Contactinput1"
                          placeholder="Contact"
                          type="text"
                          disabled={true}
                          onChange={(e) => this.handleChange(e.target.value,"contactvalue")}
                          value={this.state.contactvalue}
                          invalid={this.state.contactvalidation}
                        />
                         <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                
                  </Row>
                  {/*ShipmentType,POL,POD*/}
                  <Row>
                  <Col md="4">
                      <p className="h5">Shipment Type:</p>
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={[{title:"Export"},{title:"Import"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.callgettypelistfunctionshipmenttype(value)}
                        renderInput={(params) => <TextField {...params} label={this.state.shipmenttypeadropdowntext} variant="outlined"  error={this.state.shipmenttypevalidation} helperText={this.state.shipmenttypevalidationmessage} />}
                        disableClearable={true}
                     />
                      {/* <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownshipmenttype} toggle={() => this.toggledropdownarea("isopendropdownshipmenttype")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.shipmenttypeadropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>

                          <DropdownItem onClick={() => this.setState({shipmenttypeadropdowntext:'Export',
                        disableddropdownpol:false,
                        disableddropdownpod:false,})}>Export</DropdownItem>
                          <DropdownItem onClick={() => this.setState({shipmenttypeadropdowntext:'Import',
                        disableddropdownpol:false,
                        disableddropdownpod:false,})}>Import</DropdownItem>
                
                        </DropdownMenu>
                      </Dropdown> */}
                      </FormGroup>
                    </Col>

                    {this.state.shipmenttypeadropdowntext=='Import'?
                    
                      <Col md="4">
                      <p className="h5">POL:</p>
                      <FormGroup>
                      <Input
                          id="Pol1"
                          placeholder="POL"
                          type="text"
                          className="textcolorblack"
                          value={this.state.poltext}
                          onChange={(e) => this.handleChange(e.target.value,"poltext")}    
                          invalid={this.state.poltextvalidation}                     
                        />
                         <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
         
                    :
                    <Col md="4">
                    <p className="h5">POL:</p>
                    <FormGroup>
                    <Autocomplete
                        id="combo-box-demo2"
                        options={[{title:"Pasir Gudang"},{title:"Tanjung Pelepas"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.callgettypelistfunctionpol(value)}
                        renderInput={(params) => <TextField {...params} label={this.state.poltext} variant="outlined"  error={this.state.poltextvalidation} helperText={this.state.poltextvalidationmessage} />}
                        disableClearable={true}
                     />
                    </FormGroup>
                  </Col>
                
                    }
              {this.state.shipmenttypeadropdowntext=='Import'?
                    
                  <Col md="4">
                     <p className="h5">POD:</p>
                     <FormGroup>
                     <Autocomplete
                        id="combo-box-demo2"
                        options={[{title:"Pasir Gudang"},{title:"Tanjung Pelepas"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.callgettypelistfunctionpod(value)}
                        renderInput={(params) => <TextField {...params} label={this.state.podtext} variant="outlined" error={this.state.podtextvalidation} helperText={this.state.podtextvalidationmessage}  />}
                        disableClearable={true}
                      />
                     </FormGroup>
                   </Col>
                 
                  :
                   <Col md="4">
                   <p className="h5">POD:</p>
                   <FormGroup>
                   <Input
                       id="Podinput1"
                       placeholder="POD"
                       type="text"
                       className="textcolorblack"
                       value={this.state.podtext}                      
                       onChange={(e) => this.handleChange(e.target.value,"podtext")}
                       invalid={this.state.podtextvalidation}
                     />
                       <FormFeedback invalid>Please fill in the detail</FormFeedback>
                   </FormGroup>
                   </Col>
                  }
                  </Row>
                  {/*Bookingno,VslETA,ClosingDay*/}
                  <Row>
                  <Col md="4">
                      <p className="h5">Booking/BL No:</p>
                      <FormGroup>
                      <Input
                       id="bookingno"
                       placeholder="Booking No."
                       type="text"
                       className="textcolorblack"
                       value={this.state.bookingno}
                       onChange={(e) => this.handleChange(e.target.value,"bookingno")}
                       invalid={this.state.bookingnovalidation}
                     />
                     <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <p className="h5">Vsl Eta:</p>
                      <FormGroup>
                      <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Vsl Eta",                  
                      }}    
                      
                    closeOnSelect={true}                  
                      value={this.state.Vsleta}
                      onChange={(e) => this.vsletachangedchoose(e)}
                      timeFormat={false}
                      dateFormat="DD/MM/YYYY"
                    />
                  </InputGroup>
                    <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <p className="h5">Closing/Last Day:</p>
                      <FormGroup>
                      <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Closing / Last Day",                    
                      }}
                      
                    closeOnSelect={true}
                      timeFormat={false}
                      value={this.state.closingday}
                      dateFormat="DD/MM/YYYY"
                      onChange={e => this.setState({ closingday: e })}
                      
                    />
                  </InputGroup>
                        <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                      </Col>
                  </Row>
                  {/*Operator,Pickup*/}
                  {this.state.shipmenttypeadropdowntext=='Import'?
                  
                  
                 <Row>
                    
                 <Col md="4">
                     <p className="h5">Shipping Liner:</p>
                     <FormGroup>
                     <Autocomplete
                       id="combo-box-demo2"
                       options={this.state.operatorlistdata}
                       getOptionLabel={(option) => option.title}                       
                       style={{ flex:1,display:"block" }}
                       onInputChange={(event,value) =>this.setState({operatoreadropdowntext:value})}
                       renderInput={(params) => <TextField {...params} label={this.state.operatoreadropdowntext} variant="outlined"  error={this.state.operationvalidation} helperText={this.state.operationvalidationmessage} />}
                       disableClearable={true}
                     />
                     </FormGroup>
                   </Col>
                   <Col md="4">
                     <p className="h5">Pick Up</p>
                     <FormGroup>
                     <Autocomplete
                       id="combo-box-demo2"
                       options={this.state.yardlist}
                       getOptionLabel={(option) => option.title}                       
                       style={{ flex:1,display:"block" }}
                       onInputChange={(event,value) =>this.setState({pickupdropdowntext:value})}
                       renderInput={(params) => <TextField {...params} label={this.state.pickupdropdowntext} variant="outlined"  error={this.state.pickupvalidation} helperText={this.state.pickupvalidationmessage} />}
                       disableClearable={true}
                     />
                     </FormGroup>
                   </Col>
                   <Col md="4">
                     <p className="h5">Return Yard</p>
                     <FormGroup>
                     <Autocomplete
                       id="combo-box-demo2"
                       options={this.state.yardlist}
                       getOptionLabel={(option) => option.title}                       
                       style={{ flex:1,display:"block" }}
                       onInputChange={(event,value) =>this.setState({returnyarddropdowntext:value})}
                       renderInput={(params) => <TextField {...params} label={this.state.returnyarddropdowntext} variant="outlined"  error={this.state.returnvalidation} helperText={this.state.returnvalidationmessage} />}
                       disableClearable={true}
                    />
                     {/* <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownreturnyard} toggle={() => this.toggledropdownarea("isopendropdownreturnyard")}>
                       <DropdownToggle caret className = "btn-block" block={true}>
                         {this.state.returnyarddropdowntext}
                         </DropdownToggle>
                       <DropdownMenu>
                       {this.renderreturnyardlistdropdownmenu()}
                       </DropdownMenu>
                     </Dropdown> */}
                     </FormGroup>
                   </Col>

                 </Row>
                 
                    :
                    <Row>
                  <Col md="6">
                      <p className="h5">Shipping Liner:</p>
                      
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.operatorlistdata}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({operatoreadropdowntext:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.operatoreadropdowntext} variant="outlined"  error={this.state.operationvalidation} helperText={this.state.operationvalidationmessage} />}
                        disableClearable={true}
                      />
                      {/* <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownoperator} toggle={() => this.toggledropdownarea("isopendropdownoperator")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.operatoreadropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                        {this.renderoperatordropdownmenu()}
                        </DropdownMenu>
                      </Dropdown>
                    */}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <p className="h5">Pick Up</p>
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.yardlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({pickupdropdowntext:value})}
                        renderInput={(params) => <TextField {...params}  label={this.state.pickupdropdowntext}  variant="outlined"  error={this.state.pickupvalidation} helperText={this.state.pickupvalidationmessage} />}
                        disableClearable={true}
                      />
                      {/* <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownpickup} toggle={() => this.toggledropdownarea("isopendropdownpickup")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.pickupdropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                         {this.renderyardlistdropdownmenu()}
                        </DropdownMenu>
                      </Dropdown> */}
                      </FormGroup>
                    </Col>
                  </Row>
                 
                  }
 
                  {/*Volume*/}
                  <Row>
                    <Col md="12">
                    <p className="h5">Volume: (If the volume type not contain, please add at Quotation)</p>
                    </Col>
                  </Row>
                 

                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                          name="volumenumberinput"
                          id="volumenumberinput"
                          placeholder="Volume"
                          type="number"
                          min="0"
                          className="textcolorblack"
                          value={this.state.volumenumberinput}
                          onChange={(e) => this.handleChange(e.target.value,"volumenumberinput")}
                          invalid={this.state.volumenumber1validation}
                          />
                        <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelistfirstone}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.type1dropdowntext} variant="outlined" error={this.state.type1validation} helperText={this.state.type1validationmessage}/>}
                        disableClearable={true}
                        noOptionsText={"Please complete above fill first"}
                     />
                      {/* <Dropdown size="lg" className = "btn-block" block={true}  isOpen={this.state.isopendropdowntype} toggle={() => this.toggledropdownarea("isopendropdowntype")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.type1dropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                          {this.rendertypelistdropdownmenu()}
                        </DropdownMenu>
                      </Dropdown> */}
                      </FormGroup>
                    </Col>


                    
                  </Row>               
                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                           name="volumenumberinput1"
                           id="volumenumberinput1"
                           placeholder="Volume"
                           type="number"
                           className="textcolorblack"
                           min="0"
                           value={this.state.volumenumberinput1}
                           invalid={this.state.volumenumber2validation}
                           onChange={(e) => this.handleChange(e.target.value,"volumenumberinput1")}/>
                          <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext1:value})}
                        disableClearable={true}
                        renderInput={(params) => <TextField {...params} label={this.state.type1dropdowntext1} variant="outlined"  error={this.state.type2validation} helperText={this.state.type2validationmessage} />}
                      />
                      {/* <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdowntype1} toggle={() => this.toggledropdownarea("isopendropdowntype1")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                        {this.state.type1dropdowntext1}
                          </DropdownToggle>
                        <DropdownMenu>
                            {this.rendertypelistdropdownmenu1()}
                        </DropdownMenu>
                      </Dropdown> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.state.rowvolume1?
                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                          name="volumenumberinput2"
                          id="volumenumberinput2"
                          placeholder="Volume"
                          type="number"
                          className="textcolorblack"
                          min="0"
                          value={this.state.volumenumberinput2}
                          invalid={this.state.volumenumber3validation}
                          onChange={(e) => this.handleChange(e.target.value,"volumenumberinput2")}/>
                        <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext2:value})}
                        noOptionsText={"Please complete above fill first"}
                        renderInput={(params) => <TextField {...params} label={this.state.type1dropdowntext2} variant="outlined" error={this.state.type3validation} helperText={this.state.type3validationmessage} />}
                      />
                      </FormGroup>
                    </Col>


                    
                  </Row>               
                  :
                  null
                  }

                  {this.state.rowvolume2?
                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                            name="volumenumberinput3"
                            id="volumenumberinput3"
                            placeholder="Volume"
                            type="number"
                            className="textcolorblack"
                            min="0"
                            value={this.state.volumenumberinput3}
                            invalid={this.state.volumenumber4validation}
                            onChange={(e) => this.handleChange(e.target.value,"volumenumberinput3")}/>
                            <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                      
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext3:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.type1dropdowntext3} variant="outlined"  error={this.state.type4validation} helperText={this.state.type4validationmessage} />}
                        disableClearable={true}
                      />
                      </FormGroup>
                    </Col>
                  </Row>
                  :
                  null
                    }

                  {this.state.rowvolume3?
                        <Row>
                        <Col md="3">
                            <FormGroup>
                            <Input
                            className="textcolorblack"
                                name="volumenumberinput4"
                                id="volumenumberinput4"
                                placeholder="Volume"
                                type="number"
                                min="0"
                                value={this.state.volumenumberinput4}
                                invalid={this.state.volumenumber5validation}
                                onChange={(e) => this.handleChange(e.target.value,"volumenumberinput4")}/>
                              <FormFeedback invalid>Please fill in the detail</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md="9">
                            <FormGroup>
                            <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext4:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.type1dropdowntext4} variant="outlined"  error={this.state.type5validation} helperText={this.state.type5validationmessage} />}
                        disableClearable={true}
                     />
                            </FormGroup>
                          </Col>


                          
                        </Row>               
                  :
                  null
                    }

                  {this.state.rowvolume4?
                  <Row>
           <Col md="3">
               <FormGroup>
               <Input
                    name="volumenumberinput5"
                    className="textcolorblack"
                    id="volumenumberinput5"
                    placeholder="Volume"
                    type="number"
                    min="0"
                    value={this.state.volumenumberinput5}
                    invalid={this.state.volumenumber6validation}
                    onChange={(e) => this.handleChange(e.target.value,"volumenumberinput5")}/>
                     <FormFeedback invalid>Please fill in the detail</FormFeedback>
               </FormGroup>
             </Col>
             <Col md="9">
               <FormGroup>
               <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext5:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.type1dropdowntext5} variant="outlined"  error={this.state.type6validation} helperText={this.state.type6validationmessage} />}
                        disableClearable={true}
                     />
               </FormGroup>
             </Col>
           </Row>
                  :
                  null
                    }

                {this.state.rowvolume5?
                  <Row>
                                  <Col md="3">
                                      <FormGroup>
                                      <Input
                                          name="volumenumberinput6"
                                          className="textcolorblack"
                                          id="volumenumberinput6"
                                          placeholder="Volume"
                                          type="number"
                                          min="0"
                                          value={this.state.volumenumberinput6}
                                          invalid={this.state.volumenumber7validation}
                                          onChange={(e) => this.handleChange(e.target.value,"volumenumberinput6")}/>
                                         <FormFeedback invalid>Please fill in the detail</FormFeedback>
                                      </FormGroup>
                                    </Col>
                                    <Col md="9">
                                      <FormGroup>
                                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext6:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.type1dropdowntext6} variant="outlined"  error={this.state.type7validation} helperText={this.state.type7validationmessage} />}
                        disableClearable={true}
                      />
                                      </FormGroup>
                                    </Col>


                                    
                                  </Row>                         
                :
                null
                  }

                {this.state.rowvolume6?
                <Row>
                <Col md="3">
                    <FormGroup>
                    <Input
                         name="volumenumberinput7"
                         id="volumenumberinput7"
                         placeholder="Volume"
                         type="number"
                         min="0"
                         className="textcolorblack"
                         value={this.state.volumenumberinput7}
                         invalid={this.state.volumenumber8validation}
                         onChange={(e) => this.handleChange(e.target.value,"volumenumberinput7")}/>
                          <FormFeedback invalid>Please fill in the detail</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="9">
                    <FormGroup>
                    <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext7:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.type1dropdowntext7} variant="outlined"  error={this.state.type8validation} helperText={this.state.type8validationmessage}/>}
                        disableClearable={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                :
                null
                }
                {this.state.rowvolume7?
                <Row>
                <Col md="3">
                    <FormGroup>
                    <Input
                        name="volumenumberinput8"
                        className="textcolorblack"
                        id="volumenumberinput8"
                        placeholder="Volume"
                        type="number"
                        min="0"
                        value={this.state.volumenumberinput8}
                        invalid={this.state.volumenumber9validation}
                        onChange={(e) => this.handleChange(e.target.value,"volumenumberinput8")}/>
                       <FormFeedback invalid>Please fill in the detail</FormFeedback>
                    </FormGroup>

                  </Col>
                  <Col md="9">
                    <FormGroup>
                    <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext8:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.type1dropdowntext8} variant="outlined"  error={this.state.type9validation} helperText={this.state.type9validationmessage} />}
                        disableClearable={true}
                      />
                    </FormGroup>
                  </Col>


                  
                </Row>               
                :
                null
                }
                {this.state.rowvolume8?
                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                           name="volumenumberinput9"
                           id="volumenumberinput9"
                           placeholder="Volume"
                           type="number"
                           min="0"
                           className="textcolorblack"
                           value={this.state.volumenumberinput9}
                           invalid={this.state.volumenumber10validation}
                           onChange={(e) => this.handleChange(e.target.value,"volumenumberinput9")}/>
                            <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext9:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.type1dropdowntext9} variant="outlined"  error={this.state.type10validation} helperText={this.state.type10validationmessage}/>}
                        disableClearable={true}
                      />
                      </FormGroup>
                    </Col>
                  </Row>
                :
                null
                }


                  <Row>
                    <Col md="12">
                      {this.state.volumeshowrow < 8?   <Button color="primary" type="button" onClick={() => this.addvolumebuttonclicked()}>
                          +
                      </Button>:null}
                      {this.state.volumeshowrow ==0 ?null:    <Button color="primary" type="button" onClick={() => this.minusvolumebuttonclicked()}>
                          -
                      </Button>}
                  
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  {/*ConsignmentnoRow*/}
                  
                  <Row>
                    <Col md="2">
                        <p className="h5">Consignment No:</p>
                        </Col>
                      <Col md="3">
                        <p className="h5">Container No:</p>
                          </Col>
                      <Col md="3">
                        <p className="h5">Seal No:</p>
                          </Col>
                        <Col md="2">
                        <p className="h5">Request Date:</p>
                        {this.state.requestdateerrorinvalid ?
                         <p className="h5 invalid-requestdate ">(Please fill in all Request Date)</p>
                        :
                        null
                        }
                       
                          </Col>
                          <Col md="1">
                        <FormGroup>
                          
                          <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id={"customChec"}
                              type="checkbox"
                              checked={this.state.samedate}
                              onChange={(e)=>this.sameallthedate()}
                            />
                            <label className="custom-control-label" htmlFor={"customChec"}>
                              Same Date
                            </label>
                          </div>
                        </FormGroup>
                        </Col>
                        <Col md="1">
                        <FormGroup>
                          
                        <p className="h5">SL:</p>
                        </FormGroup>
                        </Col>
                    </Row>
                    {this.renderaddinvolumerow()}

                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                   
                  
                  {/*Vessel*/}
                  <Row>
                  <Col md="6">
                      <p className="h5">Vessel Name:</p>
                      <FormGroup>
                      <Input
                          id="Vesselnameinput1"
                          className="textcolorblack"
                          placeholder="Vessel Name"
                          type="text"
                          value={this.state.vesselname}
                          onChange={(e)=>this.setState({vesselname:e.target.value})}
                          invalid={this.state.vesselnamevalidation}
                        />
                        <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <p className="h5">Vessel Voyage:</p>
                      <FormGroup>
                      <Input
                          id="Vesselvoyageinput1"
                          className="textcolorblack"
                          placeholder="Vessel Voyage"
                          type="text"
                          value={this.state.vesselvoyage}
                          onChange={(e)=>this.setState({vesselvoyage:e.target.value})}
                          invalid={this.state.vesselvoyagevalidation}
                        />
                        <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>



            </Row>
                  
                  {/*Commodity*/}
                  <Row>
            <Col md="6">
                <p className="h5">Commodity:</p>
                <FormGroup>
                <Input
                    id="Commodityinput1"
                    className="textcolorblack"
                    placeholder="Commodity"
                    type="text"
                    value={this.state.commodity}
                    onChange={(e)=>this.setState({commodity:e.target.value})}
                    invalid={this.state.commodityvalidation}
                  />
                  <FormFeedback invalid>Please fill in the detail</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <p className="h5">Forwarding Agent:</p>
                <FormGroup>
                <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.forwardingagentlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({forwardingagentdropdowntext:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.forwardingagentdropdowntext} variant="outlined"   error={this.state.forwardingvalidation} helperText={this.state.forwardingvalidationmessage}/>}
                        disableClearable={true}
                     />
                {/* <Dropdown className = "btn-block" block={true} isOpen={this.state.isopendropdownforwardingagent} toggle={() => this.toggledropdownarea("isopendropdownforwardingagent")}>
                  <DropdownToggle  caret   className = "btn-block" block={true}>
                    {this.state.forwardingagentdropdowntext}
                    </DropdownToggle>
                  <DropdownMenu >
                    {this.renderforwardingagentrow()}
                  </DropdownMenu>
                </Dropdown> */}
                </FormGroup>
              </Col>



            </Row>
                  
                  {/*Remark*/}
                  <Row>
            <Col md="12">
                <p className="h5">Remark:</p>
                <FormGroup>
                <Input
                    id="Remarkinput1"
                    placeholder="Remark"
                    rows="5"
                    className="textcolorblack"
                    type="textarea"
                    value={this.state.remark}
                    onChange={(e)=>this.setState({remark:e.target.value})}
                  />
                </FormGroup>
              </Col>
            
            </Row>
                  
                  {/*Attached button*/}
                 <Row>
                   <Col md="12">
                   <p className="h5">Attachment:</p> 
                   </Col>
                 </Row>
                {this.state.selectedFileexistname1 != null ?
                 <Row>
                 <Col md="5">          
                 <p className="h5">1) {this.state.selectedFileexistname1}</p>          
                                   
                 </Col>
                 <Col md="3">
                             {this.state.selectedFileexistname1 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFileexistname1full, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                 <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={() => this.cancelattachment(1)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
             </Row>
                :
                null
                }
                {this.state.selectedFileexistname2 != null ?
                <Row>
                    <Col md="5">          
                    <p className="h5">2) {this.state.selectedFileexistname2}</p>          
                                      
                    </Col>
                    <Col md="3">
                             {this.state.selectedFileexistname2 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFileexistname2full, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                    <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={() =>this.cancelattachment(2)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                </Row>
                :
                null}
                {this.state.selectedFileexistname3 != null ?
                <Row>
                    <Col md="5">          
                    <p className="h5">3) {this.state.selectedFileexistname3}</p>          
                                      
                    </Col>
                    <Col md="3">
                             {this.state.selectedFileexistname3 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFileexistname3full, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                    <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={() => this.cancelattachment(3)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                </Row>
                :
                null}

                {this.state.selectedFileexistname4 != null ?
                
                <Row>
                    <Col md="5">          
                    <p className="h5">4) {this.state.selectedFileexistname4}</p>          
                                      
                    </Col>
                    <Col md="3">
                             {this.state.selectedFileexistname4 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFileexistname4full, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                    <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={() => this.cancelattachment(4)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                </Row>
                :
                null}
                {this.state.selectedFileexistname5 != null ?
                <Row>
                    <Col md="5">          
                    <p className="h5">5) {this.state.selectedFileexistname5}</p>          
                                      
                    </Col>
                    <Col md="3">
                             {this.state.selectedFileexistname5 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFileexistname5full, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                    <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={() => this.cancelattachment(5)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                </Row>
                :
                null}
                {this.state.selectedFileexistname6 != null ?
                <Row>
                    <Col md="5">          
                    <p className="h5">6) {this.state.selectedFileexistname6}</p>          
                                      
                    </Col>
                    <Col md="3">
                             {this.state.selectedFileexistname6 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFileexistname6full, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                    <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={() => this.cancelattachment(6)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                </Row>
                :
                null}

                {this.state.selectedFileexistname7 != null ?
                <Row>
                    <Col md="5">          
                    <p className="h5">7) {this.state.selectedFileexistname7}</p>          
                                      
                    </Col>
                    <Col md="3">
                             {this.state.selectedFileexistname7 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFileexistname7full, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                    <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={() => this.cancelattachment(7)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                   
                </Row>
                :
                null}

                {this.state.selectedFileexistname8 != null ?
                <Row>
                    <Col md="5">          
                    <p className="h5">8) {this.state.selectedFileexistname8}</p>          
                                      
                    </Col>
                    <Col md="3">
                             {this.state.selectedFileexistname8 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFileexistname8full, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                    <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={() => this.cancelattachment(8)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                </Row>
                :
                null}

                {this.state.selectedFileexistname9 != null ?
                <Row>
                    <Col md="5">          
                    <p className="h5">9) {this.state.selectedFileexistname9}</p>          
                                      
                    </Col>
                    <Col md="3">
                             {this.state.selectedFileexistname9 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFileexistname9full, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                    <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={() => this.cancelattachment(9)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                </Row>
                :null}

                {this.state.selectedFileexistname10 != null ?
                <Row>
                    <Col md="5">          
                    <p className="h5">10) {this.state.selectedFileexistname10}</p>          
                                      
                    </Col>
                    <Col md="3">
                             {this.state.selectedFileexistname10 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFileexistname10full, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                    <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={() =>this.cancelattachment(10)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                </Row>
                :null}


                  <Row>                 
                
                  <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile1} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload1}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename1 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename1, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                    
                 </Row>
                 {this.state.fileuploadnumber2 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile2} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload2}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename2 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename2, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber3 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile3} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload3}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename3 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename3, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber4 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile4} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload4}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename4 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename4, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber5 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile5} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload5}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename5 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename5, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber6 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile6} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload6}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename6 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename6, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber7 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile7} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload7}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename7 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename7, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber8 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile8} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload8}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename8 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename8, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber9 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile9} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload9}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename9 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename9, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                         <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber10 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile10} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload10}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename10 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename10, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                 <Row className="mt-2">
                 <Col md="12">
                 
                  <button className="buttonwidth" color="primary" type="button" onClick={(e) => this.buttonaddattachmentclick()}>
                             <span className="mb-0 text-sm">
                          +
                     </span>
                      </button>
                      <span className="mb-0 text-sm">
                          Add More Attachment
                     </span>
                     
                    </Col>  
                 </Row>
                 
        </Form>
                 
              }

                     
         <div style={{ display: "none" }}><ComponentToPrint2 
                    volume1={this.state.volumenumberinput + "x " + this.state.type1dropdowntext}
                    volume2={this.state.volumenumberinput1 == 0? "": this.state.volumenumberinput1 + "x " + this.state.type1dropdowntext1}
                    volume3={this.state.volumenumberinput2 == 0? "": this.state.volumenumberinput2 + "x " + this.state.type1dropdowntext2}
                    volume4={this.state.volumenumberinput3 == 0? "": this.state.volumenumberinput3 + "x " + this.state.type1dropdowntext3}
                    size1=""
                    size2=""
                    size3=""
                    size4=""
                    type1=""
                    type2=""
                    type3=""
                    type4=""
                    container1={this.state["checksideloader0"] == true ?  this.state["container0"] +"(SL)" : this.state["container0"]}
                    container2={this.state["checksideloader1"] == true ?  this.state["container1"] +"(SL)" : this.state["container1"]}
                    container3={this.state["checksideloader2"] == true ?  this.state["container2"] +"(SL)" : this.state["container2"]}
                    container4={this.state["checksideloader3"] == true ?  this.state["container3"] +"(SL)" : this.state["container3"]}
                    container5={this.state["checksideloader4"] == true ?  this.state["container4"] +"(SL)" : this.state["container4"]}
                    container6={this.state["checksideloader5"] == true ?  this.state["container5"] +"(SL)" : this.state["container5"]}
                    container7={this.state["checksideloader6"] == true ?  this.state["container6"] +"(SL)" : this.state["container6"]}
                    container8={this.state["checksideloader7"] == true ?  this.state["container7"] +"(SL)" : this.state["container7"]}
                    remark={this.state.remark}
                    importexport={this.state.shipmenttypeadropdowntext}
                    shippingline={this.state.operatoreadropdowntext}
                    forwardingagent={this.state.forwardingagentdropdowntext}
                    commodity={this.state.commodity}
                    pod={this.state.podtext}
                    pol={this.state.poltext}
                    vesselvoyage={this.state.vesselvoyage}
                    vesselname={this.state.vesselname}
                    vesseleta={this.state.vsltoshow}
                    closingdate={this.state.closingdateshow}
                    containerrequiredate={this.state.requestdatetoshow0}
                    containerrequiretime=""
                    Bookingno={this.state.bookingno}
                    pickupyard={this.state.pickupdropdowntext}
                    shippername={this.state.areadropdowntext}
                    pic={this.state.picvalue}
                    shipperaddress={this.state.deliveryaddress}
                    billingto={this.state.Billtoparty}
                    contactpic={this.state.contactvalue}
                    ref={el => (this.componentRef = el)} /></div> 


            </div>
          </Row>
        </div>
     

        {/*Body  end here */}
        {/*Body  end here */}
        {/*Body  end here */}
        {/*Body  end here */}
       </div>
        
        <div className="modal-footer">
      {this.state.aftersubmitstatus == 0 ?
      <div>

        <Button color="primary" type="button" onClick={(e)=>this.submitnewrotforpending(this.state.editorderrotnumber)}>
          Submit
        </Button>

        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={() => this.setState({editpendingrotpopout:false})}
        >
          Close
        </Button>
        </div>
      :
      null
      }
        
        {/* <Button color="primary" type="button" onClick={(e)=>this.uploadfilefrom1to10()}>
          testingbutton
        </Button> */}
      </div>
       
          </div>}</Modal>  

        {/* popout ask for email */}
        <Modal
          className="modal-dialog-centered modal-primary"
          contentClassName="bg-gradient-primary"
          size="lg"
          isOpen={this.state.popoutemail}
          toggle={() => this.setState({popoutemail:false})}
        >
          {this.state.loadingmodal?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>:
          <div>
          <div className="modal-header">
         
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({popoutemail:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10 ">
        <Form>
        <Row>
          <Col md="12">
            <div className="alignmiddletbuttonemailpopout">
            <i className="ni ni-email-83 ni-3x " />
            </div>
         
          <h3 className="modal-title alignmiddletbuttonemailpopout" >
            Please contact Mewahtrans Admin to edit or cancel this job.
          </h3>
          <h2 className="modal-title alignmiddletbuttonemailpopout mt-1" >
           Email: operation@mewahtrans.com.my
          </h2>
          </Col>
         
        </Row>
        </Form>
        
       </div>
       <div className="modal-footer d-md-inline-block alignmiddletbuttonemailpopout col-12">
            <Button
              color="secondary"
              data-dismiss="modal"
              className="d-md-inline-block alignmiddletbuttonemailpopout"
              type="button"
              onClick={() => window.open("mailto:operation@mewahtrans.com.my?cc=fernyong@mewahtrans.com.my&subject=Edit%20or%20Cancel%20ROT%20request", "_blank") }
            >
              Go to Email
            </Button>
            <Button
              color="secondary"
              data-dismiss="modal"
              className="d-md-inline-block alignmiddletbuttonemailpopout"
              type="button"
              onClick={() => this.setState({popoutemail:false})}
            >
              Close
            </Button>
          </div>
          
         
          </div>}
          </Modal>  


        
            {/* Attachment table */}
            <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.detailpopoutattachment}
          toggle={() => this.setState({detailpopoutattachment:false})}
        >
          {this.state.loadingmodal?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>:
          <div>
          <div className="modal-header">
          <h1 className="modal-title" id="detailpopoutLabel">
              ROT No: {this.state.attachmentrotshowtitlw}
            </h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailpopout:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10">
       
        
          
        <Table style={{marginTop:20}} className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Attachment Files.</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                       <tr >
                       <td>1.</td>
                       <td>Picture 1</td>
                       <td>
                        <Button color="primary">
                          Download
                        </Button>
                       </td>                     
                     </tr>                   
                     <tr >
                       <td>2.</td>
                       <td>Picture 1</td>
                       <td>
                        <Button color="primary">
                          Download
                        </Button>
                       </td>                     
                     </tr>   
                     <tr >
                       <td>3.</td>
                       <td>Picture 1</td>
                       <td>
                        <Button color="primary">
                          Download
                        </Button>
                       </td>                     
                     </tr>             
                   </tbody>
         </Table>
       
        
       </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailpopoutattachment:false})}
            >
              Close
            </Button>
         
          </div>
          </div>}</Modal>  


        
      </>
    );
  }
}



class ComponentToPrint extends React.Component {
  
  state= {  
    
    printbilltoparty:this.props.billingto,
    printbookingno:this.props.Bookingno,
    printclosing:this.props.closingdate,
    printcommodity:this.props.commodity,
    printcontainer1:this.props.container1,
    printcontainer2:this.props.container2,
    printcontainer3:this.props.container3,
    printcontainer4:this.props.container4,
    printcontainer5:this.props.container5,
    printcontainer6:this.props.container6,
    printcontainer7:this.props.container7,
    printcontainer8:this.props.container8,
    printeta:this.props.vesseleta,
    printforwardingagent:this.props.forwardingagent,
    printpic:this.props.pic,
    printpickup:this.props.pickupyard,
    printpod:this.props.pod,
    printpol:this.props.pol,
    printremark:this.props.remark,
    printrequiretime:this.props.containerrequiretime,
    printrequiredate:this.props.containerrequiredate,
    printshipper:this.props.shippername,
    printshippingaddresss:this.props.shipperaddress,
    printshippingliner:this.props.shippingline,
    printsize1:this.props.size1,
    printsize2:this.props.size2,
    printsize3:this.props.size3,
    printsize4:this.props.size4,
    printtype1:this.props.type1,
    printtype2:this.props.type2,
    printtype3:this.props.type3,
    printtype4:this.props.type4,
    printvolume1:this.props.volume1,
    printvolume2:this.props.volume2,
    printvolume3:this.props.volume3,
    printvolume4:this.props.volume4,
    printvoyage:this.props.vesselvoyage,
    printvoyagename:this.props.voyagename,
    printexport:this.props.importexport,
    printcontactpic:this.props.contactpic,
  }

  
  render() {
   // console.log(this.props.importexport);
    return (
      <div class="containerprint">
      <img 
      width="1000px"
      height="1442px"
      alt="..."
      class
      src={require("./../../assets/gif/Rottemplate3.jpg")}
      />
      <span class="importexportprintcss">{this.state.printexport}</span>
      <span class="linerprintcss">{this.state.printbookingno}</span>
       <span class="shippinglinerprintcss">{this.state.printshippingliner}</span>
       <span class="Forwardinglinerprintcss">{this.state.printforwardingagent}</span>
       <span class="Commoditylinerprintcss">{this.state.printcommodity}</span>
       <span class="POLlinerprintcss">{this.state.printpol}</span>
       <span class="PODlinerprintcss">{this.state.printpod}</span>
    <span class="Voyagelinerprintcss">{this.state.printvoyage}/{this.state.printvoyagename}</span>
       <span class="ETAlinerprintcss">{this.state.printeta}</span>
       <span class="Closinglinerprintcss">{this.state.printclosing}</span>
       <span class="Requirelinerprintcss">{this.state.printrequiredate}</span>
       <span class="Requiretimelinerprintcss">{this.state.printrequiretime}</span>
       <span class="Picklinerprintcss">{this.state.printpickup}</span>
       <span class="Shipperprintcss">{this.state.printshipper}</span>
       <span class="PICprintcss">{this.state.printpic}</span>
       <span class="PICcontactcss">{this.state.printcontactpic}</span>
       <span class="shippingaddressprintcss">{this.state.printshippingaddresss}</span>
       <span class="Billprintcss">{this.state.printbilltoparty}</span>
       <span class="volume1printcss">{this.state.printvolume1}</span>
       <span class="volume2printcss">{this.state.printvolume2}</span>
       <span class="volume3printcss">{this.state.printvolume3}</span>
       <span class="volume4printcss">{this.state.printvolume4}</span>
       <span class="size1printcss">{this.state.printsize1}</span>
       <span class="size2printcss">{this.state.printsize2}</span>
       <span class="size3printcss">{this.state.printsize3}</span>
       <span class="size4printcss">{this.state.printsize4}</span>
       <span class="type1printcss">{this.state.printtype1}</span>
       <span class="type2printcss">{this.state.printtype2}</span>
       <span class="type3printcss">{this.state.printtype3}</span>
       <span class="type4printcss">{this.state.printtype4}</span>
       <span class="container1printcss">{this.state.printcontainer1}</span>
       <span class="container2printcss">{this.state.printcontainer2}</span>
       <span class="container3printcss">{this.state.printcontainer3}</span>
       <span class="container4printcss">{this.state.printcontainer4}</span>
       <span class="container5printcss">{this.state.printcontainer5}</span>
       <span class="container6printcss">{this.state.printcontainer6}</span>
       <span class="container7printcss">{this.state.printcontainer7}</span>
       <span class="container8printcss">{this.state.printcontainer8}</span>
       <span class="remarkprintcss">{this.state.printremark}</span>
      </div>
     
       
    );
  }
}

class ComponentToPrint2 extends React.Component {
  
  state= {  
    
    printbilltoparty2:this.props.billingto,
    printbookingno2:this.props.Bookingno,
    printclosing2:this.props.closingdate,
    printcommodity2:this.props.commodity,
    printcontainer12:this.props.container1,
    printcontainer22:this.props.container2,
    printcontainer32:this.props.container3,
    printcontainer42:this.props.container4,
    printcontainer52:this.props.container5,
    printcontainer62:this.props.container6,
    printcontainer72:this.props.container7,
    printcontainer82:this.props.container8,
    printeta2:this.props.vesseleta,
    printforwardingagent2:this.props.forwardingagent,
    printpic2:this.props.pic,
    printpickup2:this.props.pickupyard,
    printpod2:this.props.pod,
    printpol2:this.props.pol,
    printremark2:this.props.remark,
    printrequiretime2:this.props.containerrequiretime,
    printrequiredate2:this.props.containerrequiredate,
    printshipper2:this.props.shippername,
    printshippingaddresss2:this.props.shipperaddress,
    printshippingliner2:this.props.shippingline,
    printsize12:this.props.size1,
    printsize22:this.props.size2,
    printsize32:this.props.size3,
    printsize42:this.props.size4,
    printtype12:this.props.type1,
    printtype22:this.props.type2,
    printtype32:this.props.type3,
    printtype42:this.props.type4,
    printvolume12:this.props.volume1,
    printvolume22:this.props.volume2,
    printvolume32:this.props.volume3,
    printvolume42:this.props.volume4,
    printvoyage2:this.props.vesselvoyage,
    printvesselname:this.props.vesselname,
    printexport2:this.props.importexport,
    printcontactpic2:this.props.contactpic,
  }

  
  render() {
    return (
      <div class="containerprint">
      <img 
      width="1000px"
      height="1442px"
      alt="..."
      class
      src={require("./../../assets/gif/Rottemplate3.jpg")}
      />
      <span class="importexportprintcss">{this.props.importexport}</span>
      <span class="linerprintcss">{this.props.Bookingno}</span>
       <span class="shippinglinerprintcss">{this.props.shippingline}</span>
       <span class="Forwardinglinerprintcss">{this.props.forwardingagent}</span>
       <span class="Commoditylinerprintcss">{this.props.commodity}</span>
       <span class="POLlinerprintcss">{this.props.pol}</span>
       <span class="PODlinerprintcss">{this.props.pod}</span>
       <span class="Voyagelinerprintcss">{this.props.vesselname}/{this.props.vesselvoyage}</span>
       <span class="ETAlinerprintcss">{this.props.vesseleta}</span>
       <span class="Closinglinerprintcss">{this.props.closingdate}</span>
       <span class="Requirelinerprintcss">{this.props.containerrequiredate}</span>
       <span class="Requiretimelinerprintcss">{this.state.printrequiretime2}</span>
       <span class="Picklinerprintcss">{this.props.pickupyard}</span>
       <span class="Shipperprintcss">{this.props.shippername}</span>
       <span class="PICprintcss">{this.props.pic}</span>
       <span class="PICcontactcss">{this.props.contactpic}</span>
       <span class="shippingaddressprintcss">{this.props.shipperaddress}</span>
       <span class="Billprintcss">{this.props.billingto}</span>
       <span class="volume1printcss">{this.props.volume1}</span>
       <span class="volume2printcss">{this.props.volume2}</span>
       <span class="volume3printcss">{this.props.volume3}</span>
       <span class="volume4printcss">{this.props.volume4}</span>
       <span class="size1printcss">{this.state.printsize12}</span>
       <span class="size2printcss">{this.state.printsize22}</span>
       <span class="size3printcss">{this.state.printsize32}</span>
       <span class="size4printcss">{this.state.printsize42}</span>
       <span class="type1printcss">{this.state.printtype12}</span>
       <span class="type2printcss">{this.state.printtype22}</span>
       <span class="type3printcss">{this.state.printtype32}</span>
       <span class="type4printcss">{this.state.printtype42}</span>
       <span class="container1printcss">{this.props.container1}</span>
       <span class="container2printcss">{this.props.container2}</span>
       <span class="container3printcss">{this.props.container3}</span>
       <span class="container4printcss">{this.props.container4}</span>
       <span class="container5printcss">{this.props.container5}</span>
       <span class="container6printcss">{this.props.container6}</span>
       <span class="container7printcss">{this.props.container7}</span>
       <span class="container8printcss">{this.props.container8}</span>
       <span class="remarkprintcss">{this.state.printremark2}</span>
      </div>
     
       
    );
  }
}

export default Orders;
