/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.jsx";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Alert,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormFeedback,
  FormText,
  Label,
  Pagination, 
  PaginationItem, 
  PaginationLink ,
} from "reactstrap";
// core components
import ReactToPrint from 'react-to-print';
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import ReactDatetime from "react-datetime";
import { textSpanIntersectsWith } from "typescript";
import axios ,{post} from 'axios'; 
import Moment from 'moment';
import { controllers } from "chart.js";
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar,Doughnut,Pie  } from "react-chartjs-2";


//Validation date?
//export or import, also check type
//change shipmenttype then empty type


class Report extends React.Component {
  
  state = {
    attachmentsetID:0,
    tabs: 1,
    exampleModal: false,
    editpopuout:false,
    isopendropdownshipper:false,
    isopendropdownshipmenttype:false,
    isopendropdownpod:false,
    isopendropdownpol:false,
    shipmenttypeadropdowntext:'',
    isopendropdownoperator:false,
    operatoreadropdowntext:'',
    isopendropdownpickup:false,
    isopendropdownreturnyard:false,
    pickupdropdowntext:'',
    returnyarddropdowntext:'',
    type2dropdowntext:'',
    isopendropdownforwardingagent:false,
    forwardingagentdropdowntext:'',
    quotationtabledatafinal:[],
    operatorlistdata:[],
    yardlist:[],
    returnyardlist:[],
    typelist:[],
    typelistfirstone:[],
    Billtoparty:sessionStorage.getItem('Billtoparty'),
    Createdbyref:sessionStorage.getItem('Ref'),
    deliveryaddress:'',
    areatextbox:'',
    poltext:'',
    podtext:'',
    disableddropdownpol:true,
    disableddropdownpod:true,
    volumenumberinput:0,
    volumenumberinput1:0,
    volumenumberinput2:0,
    volumenumberinput3:0,
    volumenumberinput4:0,
    volumenumberinput5:0,
    volumenumberinput6:0,
    volumenumberinput7:0,
    volumenumberinput8:0,
    volumenumberinput9:0,
    type1dropdowntext:'',
    type1dropdowntext1:'',
    type1dropdowntext2:'',
    type1dropdowntext3:'',
    type1dropdowntext4:'',
    type1dropdowntext5:'',
    type1dropdowntext6:'',
    type1dropdowntext7:'',
    type1dropdowntext8:'',
    type1dropdowntext9:'',
    isopendropdowntype:false,
    isopendropdowntype1:false,
    isopendropdowntype2:false,
    isopendropdowntype3:false,
    isopendropdowntype4:false,
    isopendropdowntype5:false,
    isopendropdowntype6:false,
    isopendropdowntype7:false,
    isopendropdowntype8:false,
    isopendropdowntype9:false,
    volumeshowrow:0,
    rowvolume1:false,
    rowvolume2:false,
    rowvolume3:false,
    rowvolume4:false,
    rowvolume5:false,
    rowvolume6:false,
    rowvolume7:false,
    rowvolume8:false,
    minusbuttonshow:false,
    forwardingagentlist:[],
    aftersubmitstatus:0,
    picvalue:'',
    contactvalue:'',
    bookingno:'',
    Vsleta:'',
    closingday:'',
    emptytest:[],
    submitbuttonclick:0,
    picvalidation:false,
    contactvalidation:false,
    podtextvalidation:false,
    poltextvalidation:false,
    bookingnovalidation:false,
    vsletavalidation:false,
    closingvalidation:false,
    volumenumber1validation:false,
    volumenumber2validation:false,
    volumenumber3validation:false,
    volumenumber4validation:false,
    volumenumber5validation:false,
    volumenumber6validation:false,
    volumenumber7validation:false,
    volumenumber8validation:false,
    volumenumber9validation:false,
    volumenumber10validation:false,
    containervalidation:false,
    requestdatevalidation:false,
    vesselnamevalidation:false,
    vesselvoyagevalidation:false,
    commodityvalidation:false,
    areadropdowntext:'',
    commodity:'',
    vesselname:'',
    vesselvoyage:'',
    errorsubmit:false,
    samedate:false,
    alerterrormessage:'',
    attachmentquantity:1,
    selectedFile1: null,
    selectedFile2: null,
    selectedFile3: null,
    selectedFile4: null,
    selectedFile5: null,
    selectedFile6: null,
    selectedFile7: null,
    selectedFile8: null,
    selectedFile9: null,
    selectedFile10: null,
    container0:'',
    container1:'',
    container2:'',
    container3:'',
    container4:'',
    container5:'',
    container6:'',
    container7:'',
    requestdate0:'',
    vsltoshow:'',
    closingdateshow:'',
    numberrowaftersubmit:0,
    Createdbyaccount:sessionStorage.getItem('Ref'),
    requestdateerrorinvalid:false,
    percentupload1:'',
    podrecentlist:[],
    polrecentlist:[],
    bookingrecentlist:[],
    vesselnamerecentlist:[],
    vesselvoyagerecentlist:[],
    commodityrecentlist:[],
    bookingalllist:[],
    selectedFilename1:"",
    selectedFilename2:"",
    selectedFilename3:"",
    selectedFilename4:"",
    selectedFilename5:"",
    selectedFilename6:"",
    selectedFilename7:"",
    selectedFilename8:"",
    selectedFilename9:"",
    selectedFilename10:"",
    percentupload1:0,
    percentupload2:0,
    percentupload3:0,
    percentupload4:0,
    percentupload5:0,
    percentupload6:0,
    percentupload7:0,
    percentupload8:0,
    percentupload9:0,
    percentupload10:0,
    //linkurl:"https://crm.mewahtrans.com.my/api/mewahapicode.php?",
    linkurl:"https://demostaging.mewahtrans.com.my/api/mewahapicode.php?",
    imageurllink:"https://crm.mewahtrans.com.my/api/img/profile_img/",
    completelist:[],
    alljobschedulelistimport:[],
    alljobschedulelistexport:[],
    loadingdashboard:false,
    currentquotationpagenumber:1,
    sixmonthtotalcontainersize:[0,0,0,0,0,0],
    sixmonthtotalimport:[0,0,0,0,0,0],
    sixmonthtotalexport:[0,0,0,0,0,0],
    labelsixmonth:["Jan","Feb","Mar","Apr","May","Jun"],
    datacontainertotalbymonth: {
      labels: ["Jan","Feb","Mar","Apr","May","Jun"],
      datasets: [
        {
          label: "Container",
          data: [0, 0, 0, 0, 0, 0]
        }
      ]
    },
    datacontainertotalbymonthimportexport: {
      labels: ["Jan","Feb","Mar","Apr","May","Jun"],
      datasets: [{
        label: 'Import ',
        yAxisID: 'Import ',
        data: [0, 0, 0, 0, 0,0],
        backgroundColor: '#77b5fe',
      }, {
        label: 'Export ',
        yAxisID: 'Export ',
        backgroundColor: '#000080',
        data: [0, 0, 0, 0, 0,0]
      }]
    },
    datatopshipperlist: {
      labels: ["Shipper1","Shipper2","Shipper3","Shipper4","Shipper5","Shipper6"],
      datasets: [
        {
          backgroundColor: 'rgb(212,175,55)',
          label: "Shipper",
          data: [0, 0, 0, 0, 0, 0]
        }
      ]
    },
    datalistontimekpi:{    
        labels: ["Fail", "OnTime"],
        datasets: [
          {
            backgroundColor: ['rgb(0, 255, 255)','rgb(0, 128, 128)'],
          
            label: "KPI",
            data: [0, 0]
          }
        ]    
    },

    topshipperlist:[
      { quotationnumber:0,
        shippername:"", 
        containercount:0,                 
      },            
    ],
    ontimekpilistfortable:[],
    failontime:0,
    successontime:0,
    tabsimportexportalljob:1,
    tabsimportexportschedule:1,
    selectedreporttypevalue:"",
    personalschedulelistimport:[],
    personalschedulelistexport:[],
    alljobschedulelistimport:[],
    alljobschedulelistexport:[],
    todaydeliver:0,
    todaypending:0,
    totalinpipeline:0,
    todaydeliveryesterday:0,
    todaypendingyesterday:0,
    totalinpipelineyesterday:0,
    percentdeliver:0,
    percentpending:0,
    tabs:1,
    detailpopout:false,
    ref:window.sessionStorage.getItem('Ref'),
    personalschedulelistimport:[],
    personalschedulelistexport:[],
    alljobschedulelistimport:[],
    alljobschedulelistexport:[],
    loadingdashboard:false,
    currentquotationpagenumber:1,
    sixmonthtotalcontainersize:[0,0,0,0,0,0],
    sixmonthtotalimport:[0,0,0,0,0,0],
    sixmonthtotalexport:[0,0,0,0,0,0],
    labelsixmonth:["Jan","Feb","Mar","Apr","May","Jun"],
    datacontainertotalbymonth: {
      labels: ["Jan","Feb","Mar","Apr","May","Jun"],
      datasets: [
        {
          label: "Container",
          data: [0, 0, 0, 0, 0, 0]
        }
      ]
    },
    datacontainertotalbymonthimportexport: {
      labels: ["Jan","Feb","Mar","Apr","May","Jun"],
      datasets: [{
        label: 'Import ',
        yAxisID: 'Import ',
        data: [0, 0, 0, 0, 0,0],
        backgroundColor: '#77b5fe',
      }, {
        label: 'Export ',
        yAxisID: 'Export ',
        backgroundColor: '#000080',
        data: [0, 0, 0, 0, 0,0]
      }]
    },
    datatopshipperlist: {
      labels: ["Shipper1","Shipper2","Shipper3","Shipper4","Shipper5","Shipper6"],
      datasets: [
        {
          backgroundColor: 'rgb(212,175,55)',
          label: "Shipper",
          data: [0, 0, 0, 0, 0, 0]
        }
      ]
    },
    datalistontimekpi:{    
        labels: ["Fail", "OnTime"],
        datasets: [
          {
            backgroundColor: ['rgb(0, 255, 255)','rgb(0, 128, 128)'],
          
            label: "KPI",
            data: [0, 0]
          }
        ]    
    },

    topshipperlist:[
      { quotationnumber:0,
        shippername:"", 
        containercount:0,                 
      },            
    ],
    ontimekpilistfortable:[],
    failontime:0,
    successontime:0,
    tabsimportexportalljob:1,
    tabsimportexportschedule:1,
    
    //imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
   // linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",

    
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
 
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  toggledropdownarea =  state => {
    this.setState({
      [state]:!this.state[state]
    });
   
  };

  handleChange=(event,stateid)=> {
    if(stateid === "picvalue"){
      this.setState({picvalue: event});
    } else if(stateid == "contactvalue"){
      this.setState({contactvalue: event});
    }else if(stateid == "poltext"){
      this.setState({poltext: event});
    }else if(stateid == "podtext"){
      this.setState({podtext: event});
    }else if(stateid == "bookingno"){
      this.setState({bookingno: event});
    }else if(stateid == "volumenumberinput"){
      this.setState({volumenumberinput: event});
      
    }else if(stateid == "volumenumberinput1"){
      this.setState({volumenumberinput1: event});
      console.log("volumenumberinput1: "+ this.state.volumenumberinput1)
    }else if(stateid == "volumenumberinput2"){
      this.setState({volumenumberinput2: event});
      console.log("volumenumber2value2: "+ this.state.volumenumberinput2)
    }else if(stateid == "volumenumberinput3"){
      this.setState({volumenumberinput3: event});
    }else if(stateid == "volumenumberinput4"){
      this.setState({volumenumberinput4: event});
    }else if(stateid == "volumenumberinput5"){
      this.setState({volumenumberinput5: event});
    }else if(stateid == "volumenumberinput6"){
      this.setState({volumenumberinput6: event});
    }else if(stateid == "volumenumberinput7"){
      this.setState({volumenumberinput7: event});
    }else if(stateid == "volumenumberinput8"){
      this.setState({volumenumberinput8: event});
    }else if(stateid == "volumenumberinput9"){
      this.setState({volumenumberinput9: event});
    }
    
    
  }

  componentDidMount(){
   // this.setState({aftersubmitstatus:3})
   // this.getdetailquotationtableapi();
   // this.getoperatorlistapi();
 //   this.getpickupyardreturnyardlist();
    //this.getforwardingagentlist();
   // this.getrecentautocomplete();
   // this.getbookingnoprevious();
   if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
   this.setState({loading:false})
    this.setState({attachmentsetID:Moment().unix()}) ;   
    this.getshipperlist();   
  }

  gettodaypendingandalljobscheduleandschedule = () =>{
    //before request date count as pending  
    var todaytimestamp = new Date().getTime()/1000
    var status="Order Created"
    var statuscolor = "bg-warning"
    var estimatetimetoshowword = "-"


    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty",this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-gettodaypendingbilltoparty'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      var timestampnow  = new Date().getTime()/1000
      var timestampnowtouse  = new Date().getTime()
      var datestart = new Date(timestampnowtouse).getDate()
      var twodigitdate = ("0" + datestart).slice(-2);
      var monthstart = new Date(timestampnowtouse).getMonth() + 1
      var twodigitmonth = ("0" + monthstart).slice(-2);
      var yearstart = new Date(timestampnowtouse).getFullYear()
      var totaldateend = yearstart + "-" + twodigitmonth + "-" + twodigitdate + "T23:59:59+00:00"
      var totaldatestart = yearstart + "-" + twodigitmonth + "-" + twodigitdate + "T00:00:00+00:00"
      var timestamptodayend = new Date(totaldateend).getTime() / 1000
      var timestamptodaystart = new Date(totaldatestart).getTime() / 1000      

      var timestamptodayendyesterday = timestamptodayend - 86400
      var timestamptodaystartyesterday = timestamptodaystart - 86400
    
      //console.log(json.success)      
      console.log(json)
      if(json.success == 1){

        //today pending ---------------------------------------------------------------
        this.setState({todaypending:0})   
        this.setState({todaydeliver:0}) 
        this.setState({totalinpipeline:0})
        this.setState({todaypendingyesterday:0})   
        this.setState({todaydeliveryesterday:0}) 
        this.setState({totalinpipelineyesterday:0}) 
        var lengthemployee = Object.keys(json.user).length;
        for(let i=0;i<lengthemployee;i++){

          var timestamptruckin = new Date(json.user[i].Activitydatetruckin-28800).getTime()/1000
          var timestamptruckintouse  = new Date(json.user[i].Activitydatetruckin-28800).getTime()
          var datestarttruckin = new Date(timestamptruckintouse).getDate()
          var twodigitdatetruckin = ("0" + datestarttruckin).slice(-2);
          var monthstarttruckin = new Date(timestamptruckintouse).getMonth() + 1
          var twodigitmonthtruckin = ("0" + monthstarttruckin).slice(-2);
          var yearstarttruckin = new Date(timestamptruckintouse).getFullYear()
          var totaldateendtruckin = yearstarttruckin + "-" + twodigitmonthtruckin + "-" + twodigitdatetruckin + "T23:59:59+00:00"
          var totaldatestarttruckin = yearstarttruckin + "-" + twodigitmonthtruckin + "-" + twodigitdatetruckin + "T00:00:00+00:00"
          var timestamptodayendtruckin = new Date(totaldateendtruckin).getTime() / 1000
          var timestamptodaystarttruckin = new Date(totaldatestarttruckin).getTime() / 1000
          //today
          if(json.user[i].Requestdate-28800 == 946684800-28800){
           // console.log("requestdate: " + Moment.unix(json.user[i].Requestdate).format("DD/MM/YYYY HH:mm"))
           // console.log("consignmentno: " + json.user[i].Consignmentno)
          }else if(json.user[i].Requestdate-28800 < timestamptodayend ){
            if(json.user[i].Activitydatetruckin-28800 ==946684800-28800 ){
              //console.log("requestdate: " + Moment.unix(json.user[i].Requestdate).format("DD/MM/YYYY HH:mm"))
             // console.log("Activitydatetruckin: " + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY HH:mm"))
              //console.log("consignmentno: " + json.user[i].Consignmentno)
              this.setState({todaypending:this.state.todaypending + 1})
            }
           
          }
         // console.log("timestamptodayend: " + timestamptodayend)
          //console.log("timestamptodaystart: " + timestamptodaystart)

          if(json.user[i].Activitydatetruckin-28800 <= timestamptodayend && json.user[i].Activitydatetruckin-28800 >= timestamptodaystart ){
           // console.log("activitytruckin: " + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY HH:mm"))
           // console.log("consignmentno: " + json.user[i].Consignmentno)
            this.setState({todaydeliver:this.state.todaydeliver + 1})
          }

          //yesterday
          if(json.user[i].Requestdate-28800 == 946684800-28800){
            // console.log("requestdate: " + Moment.unix(json.user[i].Requestdate).format("DD/MM/YYYY HH:mm"))
            // console.log("consignmentno: " + json.user[i].Consignmentno)
           }else if(json.user[i].Requestdate-28800 < timestamptodayendyesterday ){
             if(json.user[i].Activitydatetruckin-28800 ==946684800-28800 || (json.user[i].Activitydatetruckin-28800 <= timestamptodayendyesterday && json.user[i].Activitydatetruckin-28800 >= timestamptodaystartyesterday)){
              // console.log("requestdate: " + Moment.unix(json.user[i].Requestdate).format("DD/MM/YYYY HH:mm"))
              // console.log("Activitydatetruckin: " + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY HH:mm"))
              // console.log("consignmentno: " + json.user[i].Consignmentno)
               this.setState({todaypendingyesterday:this.state.todaypendingyesterday + 1})
             }
            
           }
          // console.log("timestamptodayendyesterday: " + timestamptodayendyesterday)
         // console.log("timestamptodaystartyesterday: " + timestamptodaystartyesterday)
           if(json.user[i].Activitydatetruckin-28800 <= timestamptodayendyesterday && json.user[i].Activitydatetruckin-28800 >= timestamptodaystartyesterday ){
             // console.log("activitytruckin: " + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY HH:mm"))
             // console.log("consignmentno: " + json.user[i].Consignmentno)
             this.setState({todaydeliveryesterday:this.state.todaydeliveryesterday + 1})
           }


          this.setState({totalinpipeline:this.state.totalinpipeline + 1})      



          //personal schedule
          if(json.user[i].Createdby == this.state.ref){
            if(json.user[i].Requestdate-28800 != 946684800-28800 ){
              if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY') ){
                //console.log("EstimateTimeRange: " + json.user[i].EstimateTimeRange)
                console.log("Requestdate: " + Moment((json.user[i].Requestdate-28800)*1000).format('DD/MM/YYYY'))
                //console.log("todaytimestamp: " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
                //if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY')){
                  //console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'))
                  //console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
                  if(json.user[i].transferhistorydate-28800 != 946684800-28800){
                    status ='Completed'
                    statuscolor='bg-success'
                  }
                  else if(json.user[i].transferbilldate-28800 != 946684800-28800){
                    status ='Billing'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatetruckout-28800 != 946684800-28800){
                    status ='Trucked Out'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatestagingout-28800 != 946684800-28800){
                    status ='Staging Out'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
                    status ='Trucked In'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatestaging-28800 != 946684800-28800){
                    status ='Staging In'
                    statuscolor='bg-yellow'
                  }else{
                    status ='Order Created'
                    statuscolor='bg-warning'
                  }
  
                  if(json.user[i].EstimateTimeRange == 1){
                    estimatetimetoshowword = "8am - 12pm"
                  }else if(json.user[i].EstimateTimeRange == 2){
                    estimatetimetoshowword = "12pm - 4pm"
                  }else if(json.user[i].EstimateTimeRange == 3){
                    estimatetimetoshowword = "4pm - 9pm"
                  }else if(json.user[i].EstimateTimeRange == 0){
                    estimatetimetoshowword = "-"
                  }
                  
                  if(json.user[i].importexport=="Import"){
                    this.setState({personalschedulelistimport:[...this.state.personalschedulelistimport,
                      { no:i,
                        rotno:json.user[i].Rotnumber,
                        consignmentno:json.user[i].Consignmentno,
                        shippername:json.user[i].Shipper.slice(0,28),
                        bookingno:json.user[i].Bookingno,
                        containerno:json.user[i].Containerno,
                        activitystagingindate:json.user[i].Activitydatestaging-28800,
                        activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                        activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                        activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                        activitytransferbilldate:json.user[i].transferbilldate-28800,
                        activitytransferhistory:json.user[i].transferhistorydate-28800,
                        activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                        requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                        estimatetime:estimatetimetoshowword,
                        status:status,
                        statuscolor:statuscolor,
                      },            
                    ]})                    
                  }else{
                    this.setState({personalschedulelistexport:[...this.state.personalschedulelistexport,
                      { no:i,
                        rotno:json.user[i].Rotnumber,
                        consignmentno:json.user[i].Consignmentno,
                        shippername:json.user[i].Shipper.slice(0,28),
                        bookingno:json.user[i].Bookingno,
                        containerno:json.user[i].Containerno,
                        activitystagingindate:json.user[i].Activitydatestaging-28800,
                        activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                        activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                        activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                        activitytransferbilldate:json.user[i].transferbilldate-28800,
                        activitytransferhistory:json.user[i].transferhistorydate-28800,
                        activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                        requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                        estimatetime:estimatetimetoshowword,
                        status:status,
                        statuscolor:statuscolor,
                      },            
                    ]})
                    
                  }
                 
               // }
              
              }else if(json.user[i].Requestdate-28800 < todaytimestamp){
                //console.log("EstimateTimeRange: " + json.user[i].EstimateTimeRange)
                if((json.user[i].Activitydatetruckin-28800 == 946684800-28800 && json.user[i].Activitydatestaging-28800 ==946684800-28800)||(json.user[i].Activitydatetruckin-28800 == 946684800-28800 && json.user[i].Activitydatestaging-28800 !=946684800-28800)){
                  console.log("Requestdate: " + Moment((json.user[i].Requestdate-28800)*1000).format('DD/MM/YYYY'))
                  //console.log("todaytimestamp: " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
                  //if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY')){
                  //console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'))
                  //console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
                  if(json.user[i].transferhistorydate-28800 != 946684800-28800){
                    status ='Completed'
                    statuscolor='bg-success'
                  }
                  else if(json.user[i].transferbilldate-28800 != 946684800-28800){
                    status ='Billing'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatetruckout-28800 != 946684800-28800){
                    status ='Trucked Out'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatestagingout-28800 != 946684800-28800){
                    status ='Staging Out'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
                    status ='Trucked In'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatestaging-28800 != 946684800-28800){
                    status ='Staging In'
                    statuscolor='bg-yellow'
                  }else{
                    status ='Order Created'
                    statuscolor='bg-warning'
                  }
  
                  if(json.user[i].EstimateTimeRange == 1){
                    estimatetimetoshowword = "8am - 12pm"
                  }else if(json.user[i].EstimateTimeRange == 2){
                    estimatetimetoshowword = "12pm - 4pm"
                  }else if(json.user[i].EstimateTimeRange == 3){
                    estimatetimetoshowword = "4pm - 9pm"
                  }else if(json.user[i].EstimateTimeRange == 0){
                    estimatetimetoshowword = "-"
                  }
                  if(json.user[i].importexport=="Import"){
                    this.setState({personalschedulelistimport:[...this.state.personalschedulelistimport,
                      { no:i,
                        rotno:json.user[i].Rotnumber,
                        consignmentno:json.user[i].Consignmentno,
                        shippername:json.user[i].Shipper.slice(0,28),
                        bookingno:json.user[i].Bookingno,
                        containerno:json.user[i].Containerno,
                        activitystagingindate:json.user[i].Activitydatestaging-28800,
                        activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                        activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                        activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                        activitytransferbilldate:json.user[i].transferbilldate-28800,
                        activitytransferhistory:json.user[i].transferhistorydate-28800,
                        activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                        requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                        estimatetime:estimatetimetoshowword,
                        status:status,
                        statuscolor:statuscolor,
                      },            
                    ]})
                  }else{
                    this.setState({personalschedulelistexport:[...this.state.personalschedulelistexport,
                      { no:i,
                        rotno:json.user[i].Rotnumber,
                        consignmentno:json.user[i].Consignmentno,
                        shippername:json.user[i].Shipper.slice(0,28),
                        bookingno:json.user[i].Bookingno,
                        containerno:json.user[i].Containerno,
                        activitystagingindate:json.user[i].Activitydatestaging-28800,
                        activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                        activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                        activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                        activitytransferbilldate:json.user[i].transferbilldate-28800,
                        activitytransferhistory:json.user[i].transferhistorydate-28800,
                        activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                        requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                        estimatetime:estimatetimetoshowword,
                        status:status,
                        statuscolor:statuscolor,
                      },            
                    ]})
                  }
             
                }
              }

            }
                  
              
          }


          //alljob schedule
          if(json.user[i].Requestdate-28800 != 946684800-28800 ){
          if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY') ){
            //console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000).format('DD/MM/YYYY'))
          // console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
           // if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY')){
            // console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000).format('DD/MM/YYYY'))
            // console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
              if(json.user[i].transferhistorydate-28800 != 946684800-28800){
                status ='Completed'
                statuscolor='bg-success'
              }
              else if(json.user[i].transferbilldate-28800 != 946684800-28800){
                status ='Billing'
                statuscolor='bg-success'
              }else if(json.user[i].Activitydatetruckout-28800 != 946684800-28800){
                status ='Trucked Out'
                statuscolor='bg-success'
              }else if(json.user[i].Activitydatestagingout-28800 != 946684800-28800){
                status ='Staging Out'
                statuscolor='bg-success'
              }else if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
                status ='Trucked In'
                statuscolor='bg-success'
              }else if(json.user[i].Activitydatestaging-28800 != 946684800-28800){
                status ='Staging In'
                statuscolor='bg-yellow'
              }else{
                status ='Order Created'
                statuscolor='bg-warning'
              }

              if(json.user[i].EstimateTimeRange == 1){
                estimatetimetoshowword = "8am - 12pm"
              }else if(json.user[i].EstimateTimeRange == 2){
                estimatetimetoshowword = "12pm - 4pm"
              }else if(json.user[i].EstimateTimeRange == 3){
                estimatetimetoshowword = "4pm - 9pm"
              }else if(json.user[i].EstimateTimeRange == 0){
                estimatetimetoshowword = "-"
              }
              
              if(json.user[i].importexport == "Import"){
                this.setState({alljobschedulelistimport:[...this.state.alljobschedulelistimport,
                  { no:i,
                    rotno:json.user[i].Rotnumber,
                    consignmentno:json.user[i].Consignmentno,
                    shippername:json.user[i].Shipper.slice(0,28),
                    bookingno:json.user[i].Bookingno,
                    containerno:json.user[i].Containerno,
                    activitystagingindate:json.user[i].Activitydatestaging-28800,
                    activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                    activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                    activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                    activitytransferbilldate:json.user[i].transferbilldate-28800,
                    activitytransferhistory:json.user[i].transferhistorydate-28800,
                    activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                    requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                    estimatetime:estimatetimetoshowword,
                    status:status,
                    statuscolor:statuscolor,
                  },            
                ]})
              }else{
                this.setState({alljobschedulelistexport:[...this.state.alljobschedulelistexport,
                  { no:i,
                    rotno:json.user[i].Rotnumber,
                    consignmentno:json.user[i].Consignmentno,
                    shippername:json.user[i].Shipper.slice(0,28),
                    bookingno:json.user[i].Bookingno,
                    containerno:json.user[i].Containerno,
                    activitystagingindate:json.user[i].Activitydatestaging-28800,
                    activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                    activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                    activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                    activitytransferbilldate:json.user[i].transferbilldate-28800,
                    activitytransferhistory:json.user[i].transferhistorydate-28800,
                    activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                    requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                    estimatetime:estimatetimetoshowword,
                    status:status,
                    statuscolor:statuscolor,
                  },            
                ]})
              }
             
              //console.log("personalschedulelist: " + this.state.personalschedulelist)
            //}
          
         }else if(json.user[i].Requestdate-28800 < todaytimestamp){
          if((json.user[i].Activitydatetruckin-28800 == 946684800-28800 && json.user[i].Activitydatestaging-28800 ==946684800-28800)||(json.user[i].Activitydatetruckin-28800 == 946684800-28800 && json.user[i].Activitydatestaging-28800 !=946684800-28800)){
            if(json.user[i].transferhistorydate-28800 != 946684800-28800){
              status ='Completed'
              statuscolor='bg-success'
            }
            else if(json.user[i].transferbilldate-28800 != 946684800-28800){
              status ='Billing'
              statuscolor='bg-success'
            }else if(json.user[i].Activitydatetruckout-28800 != 946684800-28800){
              status ='Trucked Out'
              statuscolor='bg-success'
            }else if(json.user[i].Activitydatestagingout-28800 != 946684800-28800){
              status ='Staging Out'
              statuscolor='bg-success'
            }else if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
              status ='Trucked In'
              statuscolor='bg-success'
            }else if(json.user[i].Activitydatestaging-28800 != 946684800-28800){
              status ='Staging In'
              statuscolor='bg-yellow'
            }else{
              status ='Order Created'
              statuscolor='bg-warning'
            }

            if(json.user[i].EstimateTimeRange == 1){
              estimatetimetoshowword = "8am - 12pm"
            }else if(json.user[i].EstimateTimeRange == 2){
              estimatetimetoshowword = "12pm - 4pm"
            }else if(json.user[i].EstimateTimeRange == 3){
              estimatetimetoshowword = "4pm - 9pm"
            }else if(json.user[i].EstimateTimeRange == 0){
              estimatetimetoshowword = "-"
            }
            
            if(json.user[i].importexport =="Import"){
              this.setState({alljobschedulelistimport:[...this.state.alljobschedulelistimport,
                { no:i,
                  rotno:json.user[i].Rotnumber,
                  consignmentno:json.user[i].Consignmentno,
                  shippername:json.user[i].Shipper.slice(0,28),
                  bookingno:json.user[i].Bookingno,
                  containerno:json.user[i].Containerno,
                  activitystagingindate:json.user[i].Activitydatestaging-28800,
                  activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                  activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                  activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                  activitytransferbilldate:json.user[i].transferbilldate-28800,
                  activitytransferhistory:json.user[i].transferhistorydate-28800,
                  activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                  requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                  estimatetime:estimatetimetoshowword,
                  status:status,
                  statuscolor:statuscolor,
                },            
              ]})
            }else{
              this.setState({alljobschedulelistexport:[...this.state.alljobschedulelistexport,
                { no:i,
                  rotno:json.user[i].Rotnumber,
                  consignmentno:json.user[i].Consignmentno,
                  shippername:json.user[i].Shipper.slice(0,28),
                  bookingno:json.user[i].Bookingno,
                  containerno:json.user[i].Containerno,
                  activitystagingindate:json.user[i].Activitydatestaging-28800,
                  activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                  activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                  activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                  activitytransferbilldate:json.user[i].transferbilldate-28800,
                  activitytransferhistory:json.user[i].transferhistorydate-28800,
                  activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                  requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                  estimatetime:estimatetimetoshowword,
                  status:status,
                  statuscolor:statuscolor,
                },            
              ]})
            }
          
          }
         }       
        
        }
        this.setState({loadingdashboard:false})

          //
        }

        this.state.personalschedulelistimport.sort((a,b) => (a.requestdatetime > b.requestdatetime) ?1:-1)
        this.state.personalschedulelistexport.sort((a,b) => (a.requestdatetime > b.requestdatetime) ?1:-1)
        this.state.alljobschedulelistimport.sort((a,b) => (a.requestdatetime > b.requestdatetime) ?1:-1)
        this.state.alljobschedulelistexport.sort((a,b) => (a.requestdatetime > b.requestdatetime) ?1:-1)
      }
      else{
          //this.setState({loading:false}) 
        console.log(json)
        this.setState({loadingdashboard:false})
      }
      //console.log("todaydeliver: " + this.state.todaydeliver)
      //console.log("todaydeliveryesterday: " + this.state.todaydeliveryesterday)

      //console.log("todaypending: " + this.state.todaypending)
      //console.log("todaypendingyesterday: " + this.state.todaypendingyesterday)
      if(this.state.todaydeliver == 0){
        this.setState({percentdeliver:0})
      }else{
        this.setState({percentdeliver:(this.state.todaydeliver - this.state.todaydeliveryesterday)/(this.state.todaydeliver) *100})
      }
      
      if(this.state.todaypending == 0){
        this.setState({percentpending:0})
      }else{
        this.setState({percentpending:(this.state.todaypending - this.state.todaypendingyesterday)/(this.state.todaypending)*100})
      }
      
     

  })
  }

  gettotalcontainerbymonthontimekpi = () =>{

    
    var urlencoded = new URLSearchParams();
    var timestampnow = new Date().getTime()/1000;
    //last 6 month
    var timestamp6monthbefore = Moment().subtract(6,"months")
    var datestart = new Date(timestamp6monthbefore).getDate()
    var twodigitdate = ("0" + datestart).slice(-2);
    var monthstart = new Date(timestamp6monthbefore).getMonth() +1
    var twodigitmonth = ("0" + monthstart).slice(-2);
    var yearstart = new Date(timestamp6monthbefore).getFullYear()
    var totaldatestart = yearstart + "-" + twodigitmonth + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass = new Date(totaldatestart).getTime()
    var starttimetopassnotmillilast6month = starttimetopass/1000

    //last 5 month
    var timestamp5monthbefore5 = Moment().subtract(5,"months")
    var datestart5 = new Date(timestamp5monthbefore5).getDate()
    var twodigitdate = ("0" + datestart5).slice(-2);
    var monthstart5 = new Date(timestamp5monthbefore5).getMonth() +1
    var twodigitmonth5 = ("0" + monthstart5).slice(-2);
    var yearstart5 = new Date(timestamp5monthbefore5).getFullYear()
    var totaldatestart5 = yearstart5 + "-" + twodigitmonth5 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass5 = new Date(totaldatestart5).getTime()
    var starttimetopassnotmillilast5month = starttimetopass5/1000

    //last 4 month
    var timestamp5monthbefore4 = Moment().subtract(4,"months")
    var datestart4 = new Date(timestamp5monthbefore4).getDate()
    var twodigitdate4 = ("0" + datestart4).slice(-2);
    var monthstart4 = new Date(timestamp5monthbefore4).getMonth() +1
    var twodigitmonth4 = ("0" + monthstart4).slice(-2);
    var yearstart4 = new Date(timestamp5monthbefore4).getFullYear()
    var totaldatestart4 = yearstart4 + "-" + twodigitmonth4 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass4 = new Date(totaldatestart4).getTime()
    var starttimetopassnotmillilast4month = starttimetopass4/1000


    //last 3 month
    var timestamp5monthbefore3 = Moment().subtract(3,"months")
    var datestart3 = new Date(timestamp5monthbefore3).getDate()
    var twodigitdate3 = ("0" + datestart3).slice(-2);
    var monthstart3 = new Date(timestamp5monthbefore3).getMonth() +1
    var twodigitmonth3 = ("0" + monthstart3).slice(-2);
    var yearstart3 = new Date(timestamp5monthbefore3).getFullYear()
    var totaldatestart3 = yearstart3 + "-" + twodigitmonth3 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass3 = new Date(totaldatestart3).getTime()
    var starttimetopassnotmillilast3month = starttimetopass3/1000


    //last 2 month
    var timestamp5monthbefore2 = Moment().subtract(2,"months")
    var datestart2 = new Date(timestamp5monthbefore2).getDate()
    var twodigitdate2 = ("0" + datestart5).slice(-2);
    var monthstart2 = new Date(timestamp5monthbefore2).getMonth() +1
    var twodigitmonth2 = ("0" + monthstart2).slice(-2);
    var yearstart2 = new Date(timestamp5monthbefore2).getFullYear()
    var totaldatestart2 = yearstart2 + "-" + twodigitmonth2 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass2 = new Date(totaldatestart2).getTime()
    var starttimetopassnotmillilast2month = starttimetopass2/1000


    //last 1 month
    var timestamp1monthbefore = Moment().subtract(1,"months")
    var datestart1 = new Date(timestamp1monthbefore).getDate()
    var twodigitdate1 = ("0" + datestart1).slice(-2);
    var monthstart1 = new Date(timestamp1monthbefore).getMonth() +1
    var twodigitmonth1 = ("0" + monthstart1).slice(-2);
    var yearstart1 = new Date(timestamp1monthbefore).getFullYear()
    var totaldatestart1 = yearstart1 + "-" + twodigitmonth1 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass1 = new Date(totaldatestart1).getTime()
    var starttimetopassnotmillilast1month = starttimetopass1/1000

    //last this month head
    var timestamp1monthbefore0 = Moment().subtract(0,"months")
    var datestart0 = new Date(timestamp1monthbefore0).getDate()
    var twodigitdate0 = ("0" + datestart0).slice(-2);
    var monthstart0 = new Date(timestamp1monthbefore0).getMonth() +1
    var twodigitmonth0 = ("0" + monthstart0).slice(-2);
    var yearstart0 = new Date(timestamp1monthbefore0).getFullYear()
    var totaldatestart0 = yearstart0 + "-" + twodigitmonth0 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass0 = new Date(totaldatestart0).getTime()
    var starttimetopassnotmillilast1month0 = starttimetopass0/1000
    if( monthstart0 == 1){
      this.state.labelsixmonth[5] = "Jan"
      this.state.labelsixmonth[4] = "Dec"
      this.state.labelsixmonth[3] = "Nov"
      this.state.labelsixmonth[2] = "Oct"
      this.state.labelsixmonth[1] = "Sep"
      this.state.labelsixmonth[0] = "Aug"
    }else if( monthstart0 == 2){
      this.state.labelsixmonth[5] = "Feb"
      this.state.labelsixmonth[4] = "Jan"
      this.state.labelsixmonth[3] = "Dec"
      this.state.labelsixmonth[2] = "Nov"
      this.state.labelsixmonth[1] = "Oct"
      this.state.labelsixmonth[0] = "Sep"
    }else if( monthstart0 == 3){
      this.state.labelsixmonth[5] = "Mar"
      this.state.labelsixmonth[4] = "Feb"
      this.state.labelsixmonth[3] = "Jan"
      this.state.labelsixmonth[2] = "Dec"
      this.state.labelsixmonth[1] = "Nov"
      this.state.labelsixmonth[0] = "Oct"
    }else if( monthstart0 == 4){
      this.state.labelsixmonth[5] = "Apr"
      this.state.labelsixmonth[4] = "Mar"
      this.state.labelsixmonth[3] = "Feb"
      this.state.labelsixmonth[2] = "Jan"
      this.state.labelsixmonth[1] = "Dec"
      this.state.labelsixmonth[0] = "Nov"
    }else if( monthstart0 == 5){
      this.state.labelsixmonth[5] = "May"
      this.state.labelsixmonth[4] = "Apr"
      this.state.labelsixmonth[3] = "Mar"
      this.state.labelsixmonth[2] = "Feb"
      this.state.labelsixmonth[1] = "Jan"
      this.state.labelsixmonth[0] = "Dec"
    }else if( monthstart0 == 6){
      this.state.labelsixmonth[5] = "Jun"
      this.state.labelsixmonth[4] = "May"
      this.state.labelsixmonth[3] = "Apr"
      this.state.labelsixmonth[2] = "Mar"
      this.state.labelsixmonth[1] = "Feb"
      this.state.labelsixmonth[0] = "Jan"
    }else if( monthstart0 == 7){
      this.state.labelsixmonth[5] = "Jul"
      this.state.labelsixmonth[4] = "Jun"
      this.state.labelsixmonth[3] = "May"
      this.state.labelsixmonth[2] = "Apr"
      this.state.labelsixmonth[1] = "Mar"
      this.state.labelsixmonth[0] = "Feb"
    }else if( monthstart0 == 8){
      this.state.labelsixmonth[5] = "Aug"
      this.state.labelsixmonth[4] = "Jul"
      this.state.labelsixmonth[3] = "Jun"
      this.state.labelsixmonth[2] = "May"
      this.state.labelsixmonth[1] = "Apr"
      this.state.labelsixmonth[0] = "Mar"
    }else if( monthstart0 == 9){
      this.state.labelsixmonth[5] = "Sep"
      this.state.labelsixmonth[4] = "Aug"
      this.state.labelsixmonth[3] = "Jul"
      this.state.labelsixmonth[2] = "Jun"
      this.state.labelsixmonth[1] = "May"
      this.state.labelsixmonth[0] = "Apr"
    }else if( monthstart0 == 10){
      this.state.labelsixmonth[5] = "Oct"
      this.state.labelsixmonth[4] = "Sep"
      this.state.labelsixmonth[3] = "Aug"
      this.state.labelsixmonth[2] = "Jul"
      this.state.labelsixmonth[1] = "Jun"
      this.state.labelsixmonth[0] = "May"
    }else if( monthstart0 == 11){
      this.state.labelsixmonth[5] = "Nov"
      this.state.labelsixmonth[4] = "Oct"
      this.state.labelsixmonth[3] = "Sep"
      this.state.labelsixmonth[2] = "Aug"
      this.state.labelsixmonth[1] = "Jul"
      this.state.labelsixmonth[0] = "Jun"
    }else if( monthstart0 == 12){
      this.state.labelsixmonth[5] = "Dec"
      this.state.labelsixmonth[4] = "Nov"
      this.state.labelsixmonth[3] = "Oct"
      this.state.labelsixmonth[2] = "Sep"
      this.state.labelsixmonth[1] = "Aug"
      this.state.labelsixmonth[0] = "Jul"
    }
    


     //next month head
     var timestamp1monthbeforeadd1 = Moment().add(1,"months")
     var datestartadd1 = new Date(timestamp1monthbeforeadd1).getDate()
     var twodigitdateadd1 = ("0" + datestartadd1).slice(-2);
     var monthstartadd1 = new Date(timestamp1monthbeforeadd1).getMonth() +1
     var twodigitmonthadd1 = ("0" + monthstartadd1).slice(-2);
     var yearstartadd1 = new Date(timestamp1monthbeforeadd1).getFullYear()
     var totaldatestartadd1 = yearstartadd1 + "-" + twodigitmonthadd1 + "-" + "01" + "T00:00:00+00:00"
     var starttimetopassadd1 = new Date(totaldatestartadd1).getTime()
     var starttimetopassnotmillilast1monthadd1 = starttimetopassadd1/1000
   

    console.log("starttimetopassnotmillilast1month0: " + starttimetopassnotmillilast1month0)
    console.log("starttimetopassnotmillilast1month: " + starttimetopassnotmillilast1month)
    console.log("starttimetopassnotmillilast2month: " + starttimetopassnotmillilast2month)
    console.log("starttimetopassnotmillilast3month: " + starttimetopassnotmillilast3month)
    console.log("starttimetopassnotmillilast4month: " + starttimetopassnotmillilast4month)
    console.log("starttimetopassnotmillilast5month: " + starttimetopassnotmillilast5month)
    console.log("starttimetopassnotmillilast6month: " + starttimetopassnotmillilast6month)
    console.log("starttimetopassnotmillilast1monthadd1: " + starttimetopassnotmillilast1monthadd1)


    urlencoded.append("billtoparty",this.state.Billtoparty);
    urlencoded.append("starttimetopassnotmilli",starttimetopassnotmillilast6month);
    urlencoded.append("starttimetopassnotmillilast1monthadd1",starttimetopassnotmillilast1monthadd1);
    let link = this.state.linkurl +'mode=admin-gettotalcontainerbymonth'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
       
      if(json.success == 1){
        this.setState({failontime:0})
        this.setState({successontime:0})
        var lengthemployee = Object.keys(json.user).length;
        for(let i=0;i<lengthemployee;i++){        
         
          /////Total Container By Month, IMPORT EXPORT-----------------------------------------------------------------------------------------
          //LAST 5 MONTH
          if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast5month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast4month){
           // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
            if(json.user[i].importexport == "Export"){
              this.state.sixmonthtotalexport[0] = this.state.sixmonthtotalexport[0] + 1
            }else if(json.user[i].importexport == "Import"){
              this.state.sixmonthtotalimport[0] = this.state.sixmonthtotalimport[0] + 1
            }
            this.state.sixmonthtotalcontainersize[0] = this.state.sixmonthtotalcontainersize[0] + 1
          }

          //LAST 4 MONTH
          if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast4month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast3month){
          //  console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
            if(json.user[i].importexport == "Export"){
              this.state.sixmonthtotalexport[1] = this.state.sixmonthtotalexport[1] + 1
            }else if(json.user[i].importexport == "Import"){
              this.state.sixmonthtotalimport[1] = this.state.sixmonthtotalimport[1] + 1
            }
            this.state.sixmonthtotalcontainersize[1] = this.state.sixmonthtotalcontainersize[1] + 1
          }

           //LAST 3 MONTH
           if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast3month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast2month){
           // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
            if(json.user[i].importexport == "Export"){
              this.state.sixmonthtotalexport[2] = this.state.sixmonthtotalexport[2] + 1
            }else if(json.user[i].importexport == "Import"){
              this.state.sixmonthtotalimport[2] = this.state.sixmonthtotalimport[2] + 1
            } 
           this.state.sixmonthtotalcontainersize[2] = this.state.sixmonthtotalcontainersize[2] + 1
          }

             //LAST 2 MONTH
             if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast2month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast1month){
             // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
              if(json.user[i].importexport == "Export"){
                this.state.sixmonthtotalexport[3] = this.state.sixmonthtotalexport[3] + 1
              }else if(json.user[i].importexport == "Import"){
                this.state.sixmonthtotalimport[3] = this.state.sixmonthtotalimport[3] + 1
              }  
             this.state.sixmonthtotalcontainersize[3] = this.state.sixmonthtotalcontainersize[3] + 1
            }

            //LAST 1 MONTH
            if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast1month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast1month0){
             // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
              if(json.user[i].importexport == "Export"){
                this.state.sixmonthtotalexport[4] = this.state.sixmonthtotalexport[4] + 1
              }else if(json.user[i].importexport == "Import"){
                this.state.sixmonthtotalimport[4] = this.state.sixmonthtotalimport[4] + 1
              }   
             this.state.sixmonthtotalcontainersize[4] = this.state.sixmonthtotalcontainersize[4] + 1
            }
          
            //this MONTH
            if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast1month0 ){
                //console.log("thismonth:" + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY"))
                //console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
                if(json.user[i].importexport == "Export"){
                  this.state.sixmonthtotalexport[5] = this.state.sixmonthtotalexport[5] + 1
                }else if(json.user[i].importexport == "Import"){
                  this.state.sixmonthtotalimport[5] = this.state.sixmonthtotalimport[5] + 1
                }   
                this.state.sixmonthtotalcontainersize[5] = this.state.sixmonthtotalcontainersize[5] + 1
            }


            /////top Shipper vs container---------------------------------------------------------------------------
            var lengthoftopshipperlist = Object.keys(this.state.topshipperlist).length;
            for(let j = 0;j< lengthoftopshipperlist ; j ++){
              if(json.user[i].Shipper == this.state.topshipperlist[j].shippername){
                this.state.topshipperlist[j].containercount = this.state.topshipperlist[j].containercount + 1 
                //console.log("topshipplerlistcount: " + this.state.topshipperlist[j].containercount)
            }
          }
         
             /////on time kpi-----------------------------------------------------------
             if(json.user[i].Requestdate-28800 == 946684800-28800 || json.user[i].Activitydatetruckin-28800 == 946684800-28800 ){
             }else{
              if(json.user[i].Requestdate-28800 > json.user[i].Activitydatetruckin-28800 || Moment.unix(json.user[i].Requestdate-28800).format("DD/MM/YYYY") == Moment.unix(json.user[i].Activitydatetruckin-28800).format("DD/MM/YYYY")){
                this.state.successontime = this.state.successontime + 1
              }else{
                this.state.failontime = this.state.failontime + 1
                this.setState({ontimekpilistfortable:[...this.state.ontimekpilistfortable,
                  { no:this.state.failontime,
                    consignmentno:json.user[i].Consignmentno,
                    shipper:json.user[i].Shipper,
                    bookingno:json.user[i].Bookingno,
                    containerno:json.user[i].Containerno,
                    requestdate:Moment.unix(json.user[i].Requestdate-28800).format("DD/MM/YYYY"),
                    assigndate:Moment.unix(json.user[i].Activitydatetruckin-28800).format("DD/MM/YYYY"),
                  },            
                ]})
              }
             }
            

             

        }

          this.state.topshipperlist.sort((a,b) => (a.containercount < b.containercount) ?1:-1)
          //console.log("shipperlistsort: " + this.state.topshipperlist)
          // for(let j = 0;j< lengthoftopshipperlist ; j ++){
          //   if(this.state.topshipperlist[j].containercount != 0){
          //   console.log("topshippernamesort: " + this.state.topshipperlist[j].shippername)
          //   console.log("topshipplerlistcountsort: " + this.state.topshipperlist[j].containercount)
          //   }
          // }
        this.setState({
          datacontainertotalbymonth: {
            labels:this.state.labelsixmonth,
            datasets: [
              {
                label: "Container",
                data: this.state.sixmonthtotalcontainersize,
              }
            ]
          },
        })

        this.setState({
          datacontainertotalbymonthimportexport: {
            labels: this.state.labelsixmonth,
            datasets: [{
              label: 'Import ',
              yAxisID: 'Import ',
              data: this.state.sixmonthtotalimport,
              backgroundColor: '#77b5fe',
            }, {
              label: 'Export ',
              yAxisID: 'Export ',
              backgroundColor: '#000080',
              data: this.state.sixmonthtotalexport,
            }]
          },
        })
             
        if(this.state.topshipperlist[0].shippername == null){
          this.setState({
            datatopshipperlist: {
              labels: [0,0,0,0,0,0],
              datasets: [
                {
                  backgroundColor: 'rgb(212,175,55)',
                  label: "Shipper",
                  data: [this.state.topshipperlist[0].containercount, this.state.topshipperlist[1].containercount, this.state.topshipperlist[2].containercount, this.state.topshipperlist[3].containercount, this.state.topshipperlist[4].containercount, this.state.topshipperlist[5].containercount]
                }
              ]
            },
          })
        }else{
          this.setState({
            datatopshipperlist: {
              labels: [this.state.topshipperlist[0].shippername.slice(0,8),this.state.topshipperlist[1].shippername.slice(0,8),this.state.topshipperlist[2].shippername.slice(0,8),this.state.topshipperlist[3].shippername.slice(0,8),this.state.topshipperlist[4].shippername.slice(0,8),this.state.topshipperlist[5].shippername.slice(0,8)],
              datasets: [
                {
                  backgroundColor: 'rgb(212,175,55)',
                  label: "Shipper",
                  data: [this.state.topshipperlist[0].containercount, this.state.topshipperlist[1].containercount, this.state.topshipperlist[2].containercount, this.state.topshipperlist[3].containercount, this.state.topshipperlist[4].containercount, this.state.topshipperlist[5].containercount]
                }
              ]
            },
          })
        }
          

            this.setState({
              datalistontimekpi:{    
                labels: ["Fail", "OnTime"],
                datasets: [
                  {
                    backgroundColor: ['rgb(0, 255, 255)','rgb(0, 128, 128)'],
                  
                    label: "KPI",
                    data: [this.state.failontime, this.state.successontime]
                  }
                ]    
            },
            })



      }
      else{
          //this.setState({loading:false}) 
          

          this.setState({loadingdashboard:false})
        console.log(json)
      }
    
    })
  
  }

  getshipperlist = () =>{   
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl + 'mode=admin-getquotationshipperlistdashboard'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){        
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              this.setState({topshipperlist:[...this.state.topshipperlist,
                { quotationnumber:json.user[i].Quotationnumber,
                  shippername:json.user[i].Shipper, 
                  containercount:0,                 
                },            
              ]})
             }

             this.gettotalcontainerbymonthontimekpi();

        }        
               
         else{
          this.setState({loadingdashboard:false})
        console.log(json)
      }
    
     })
  }


  refreshallthing =(numberrow) =>{
      window.location.reload()
  //   for(let i=0;i<numberrow;i++){
          
  //       this.setState({["container" + i] :''})
  //       this.setState({["requestdatedisable"+i] :false})
  //       this.setState({["requestdate" + i] :new Date().getTime()})
        
     
  //       this.setState({["Seal" +i] :''})
       
      
  //       this.setState({["checktbe"+i] :false})
       
  //     console.log("numberrow: "+numberrow);
  //     console.log("container: "+this.state["container" + i]);
  //     console.log("requestdate: "+this.state["requestdate" + i]);
  //     console.log("checktbe: "+this.state["checktbe" + i]);
  //   }

    
  //   this.setState({tabs: 1,
  //   exampleModal: false,
  //   editpopuout:false,
  //   isopendropdownshipper:false,
  //   isopendropdownshipmenttype:false,
  //   isopendropdownpod:false,
  //   isopendropdownpol:false,
  //   shipmenttypeadropdowntext:'',
  //   isopendropdownoperator:false,
  //   operatoreadropdowntext:'',
  //   isopendropdownpickup:false,
  //   isopendropdownreturnyard:false,
  //   pickupdropdowntext:'',
  //   returnyarddropdowntext:'',
  //   type2dropdowntext:'',
  //   isopendropdownforwardingagent:false,
  //   forwardingagentdropdowntext:'',
  
  //   Billtoparty:sessionStorage.getItem('Billtoparty'),
  //   deliveryaddress:'',
  //   areatextbox:'',
  //   poltext:'',
  //   podtext:'',
  //   disableddropdownpol:true,
  //   disableddropdownpod:true,
  //   volumenumberinput:0,
  //   volumenumberinput1:0,
  //   volumenumberinput2:0,
  //   volumenumberinput3:0,
  //   volumenumberinput4:0,
  //   volumenumberinput5:0,
  //   volumenumberinput6:0,
  //   volumenumberinput7:0,
  //   volumenumberinput8:0,
  //   volumenumberinput9:0,
  //   type1dropdowntext:'',
  //   type1dropdowntext1:'',
  //   type1dropdowntext2:'',
  //   type1dropdowntext3:'',
  //   type1dropdowntext4:'',
  //   type1dropdowntext5:'',
  //   type1dropdowntext6:'',
  //   type1dropdowntext7:'',
  //   type1dropdowntext8:'',
  //   type1dropdowntext9:'',
  //   isopendropdowntype:false,
  //   isopendropdowntype1:false,
  //   isopendropdowntype2:false,
  //   isopendropdowntype3:false,
  //   isopendropdowntype4:false,
  //   isopendropdowntype5:false,
  //   isopendropdowntype6:false,
  //   isopendropdowntype7:false,
  //   isopendropdowntype8:false,
  //   isopendropdowntype9:false,
  //   volumeshowrow:0,
  //   rowvolume1:false,
  //   rowvolume2:false,
  //   rowvolume3:false,
  //   rowvolume4:false,
  //   rowvolume5:false,
  //   rowvolume6:false,
  //   rowvolume7:false,
  //   rowvolume8:false,
  //   minusbuttonshow:false,
  //   picvalue:'',
  //   contactvalue:'',
  //   bookingno:'',
  //   Vsleta:'',
  //   closingday:'',
  //   submitbuttonclick:0,
  //   picvalidation:false,
  //   contactvalidation:false,
  //   podtextvalidation:false,
  //   poltextvalidation:false,
  //   bookingnovalidation:false,
  //   vsletavalidation:false,
  //   closingvalidation:false,
  //   volumenumber1validation:false,
  //   volumenumber2validation:false,
  //   volumenumber3validation:false,
  //   volumenumber4validation:false,
  //   volumenumber5validation:false,
  //   volumenumber6validation:false,
  //   volumenumber7validation:false,
  //   volumenumber8validation:false,
  //   volumenumber9validation:false,
  //   volumenumber10validation:false,
  //   containervalidation:false,
  //   requestdatevalidation:false,
  //   vesselnamevalidation:false,
  //   vesselvoyagevalidation:false,
  //   commodityvalidation:false,
  //   areadropdowntext:'',
  //   commodity:'',
  //   vesselname:'',
  //   vesselvoyage:'',
  //   errorsubmit:false,
  //   aftersubmitstatus:0,
  // })




  // this.getrecentautocomplete()

  }
  
  submitnewrotforpending =() =>{ 
//2

if(window.confirm("Ready to submit this Rot?")){
    
   
  var errorornot = false;
   
  let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
  Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
  Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
  Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
  Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 

  

  if(this.state.percentupload1 == 100 || this.state.percentupload1 == 0){     
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})      
  }

  if(this.state.percentupload2 == 100 || this.state.percentupload2 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload3 == 100 || this.state.percentupload3 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload4 == 100 || this.state.percentupload4 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload5 == 100 || this.state.percentupload5 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload6 == 100 || this.state.percentupload6 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload7 == 100 || this.state.percentupload7 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload8 == 100 || this.state.percentupload8 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload9 == 100 || this.state.percentupload9 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload10 == 100 || this.state.percentupload10 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }





    for(let i=0;i<numberrow;i++){
      //console.log("Requestdate: " + this.state["requestdate"+i])
     // console.log("requestdatedisable: " + this.state["requestdatedisable"+i])
     // console.log("checktbe: " + this.state["checktbe"+i])
     
    }
  if(this.state.volumenumberinput == 0){
    this.setState({volumenumber1validation:true});
    errorornot=true;
  }else{
    this.setState({volumenumber1validation:false});
   
  }

  if(this.state.type1dropdowntext == ''){
    this.setState({type1validation:true});
    this.setState({type1validationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({type1validation:false});
    this.setState({type1validationmessage:""});
  }

  if(this.state.volumenumberinput1 == 0 && this.state.type1dropdowntext1 != ''){
    this.setState({volumenumber2validation:true});
    errorornot=true;
  }else{
    this.setState({volumenumber2validation:false});
  }

  if(this.state.type1dropdowntext == '' && this.state.volumenumberinput1 != 0){
    this.setState({type2validation:true});
    this.setState({type2validationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({type2validation:false});
    this.setState({type2validationmessage:""});
  }
  if(this.state.rowvolume1==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }

    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }
  }

  if(this.state.rowvolume2==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
  }

  if(this.state.rowvolume3==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput4 == 0){
      this.setState({volumenumber5validation:true});
      errorornot=true;
    }



    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
    if(this.state.type1dropdowntext4 == ''){
      this.setState({type5validation:true});
      this.setState({type5validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type5validation:false});
      this.setState({type5validationmessage:""});
    }
  }

  if(this.state.rowvolume4==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput4 == 0){
      this.setState({volumenumber5validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput5 == 0){
      this.setState({volumenumber6validation:true});
      errorornot=true;
    }



    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
    if(this.state.type1dropdowntext4 == ''){
      this.setState({type5validation:true});
      this.setState({type5validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type5validation:false});
      this.setState({type5validationmessage:""});
    }
    if(this.state.type1dropdowntext5 == ''){
      this.setState({type6validation:true});
      this.setState({type6validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type6validation:false});
      this.setState({type6validationmessage:""});
    }
  }

  if(this.state.rowvolume5==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput4 == 0){
      this.setState({volumenumber5validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput5 == 0){
      this.setState({volumenumber6validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput6 == 0){
      this.setState({volumenumber7validation:true});
      errorornot=true;
    }

    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
    if(this.state.type1dropdowntext4 == ''){
      this.setState({type5validation:true});
      this.setState({type5validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type5validation:false});
      this.setState({type5validationmessage:""});
    }
    if(this.state.type1dropdowntext5 == ''){
      this.setState({type6validation:true});
      this.setState({type6validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type6validation:false});
      this.setState({type6validationmessage:""});
    }
    if(this.state.type1dropdowntext6 == ''){
      this.setState({type7validation:true});
      this.setState({type7validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type7validation:false});
      this.setState({type7validationmessage:""});
    }
  }

  if(this.state.rowvolume6==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput4 == 0){
      this.setState({volumenumber5validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput5 == 0){
      this.setState({volumenumber6validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput6 == 0){
      this.setState({volumenumber7validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput7 == 0){
      this.setState({volumenumber8validation:true});
      errorornot=true;
    }


    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
      
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
    if(this.state.type1dropdowntext4 == ''){
      this.setState({type5validation:true});
      this.setState({type5validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type5validation:false});
      this.setState({type5validationmessage:""});
    }
    if(this.state.type1dropdowntext5 == ''){
      this.setState({type6validation:true});
      this.setState({type6validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type6validation:false});
      this.setState({type6validationmessage:""});
    }
    if(this.state.type1dropdowntext6 == ''){
      this.setState({type7validation:true});
      this.setState({type7validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type7validation:false});
      this.setState({type7validationmessage:""});
    }
    if(this.state.type1dropdowntext7 == ''){
      this.setState({type8validation:true});
      this.setState({type8validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type8validation:false});
      this.setState({type8validationmessage:""});
    }
  }

  if(this.state.rowvolume7==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput4 == 0){
      this.setState({volumenumber5validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput5 == 0){
      this.setState({volumenumber6validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput6 == 0){
      this.setState({volumenumber7validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput7 == 0){
      this.setState({volumenumber8validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput8 == 0){
      this.setState({volumenumber9validation:true});
      errorornot=true;
    }


    
    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
    if(this.state.type1dropdowntext4 == ''){
      this.setState({type5validation:true});
      this.setState({type5validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type5validation:false});
      this.setState({type5validationmessage:""});
    }
    if(this.state.type1dropdowntext5 == ''){
      this.setState({type6validation:true});
      this.setState({type6validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type6validation:false});
      this.setState({type6validationmessage:""});
    }
    if(this.state.type1dropdowntext6 == ''){
      this.setState({type7validation:true});
      this.setState({type7validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type7validation:false});
      this.setState({type7validationmessage:""});
    }
    if(this.state.type1dropdowntext7 == ''){
      this.setState({type8validation:true});
      this.setState({type8validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type8validation:false});
      this.setState({type8validationmessage:""});
    }
    if(this.state.type1dropdowntext8 == ''){
      this.setState({type9validation:true});
      this.setState({type9validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type9validation:false});
      this.setState({type9validationmessage:""});
    }
  }

  if(this.state.rowvolume8==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput4 == 0){
      this.setState({volumenumber5validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput5 == 0){
      this.setState({volumenumber6validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput6 == 0){
      this.setState({volumenumber7validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput7 == 0){
      this.setState({volumenumber8validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput8 == 0){
      this.setState({volumenumber9validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput9 == 0){
      this.setState({volumenumber10validation:true});
      errorornot=true;
    }



    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
    if(this.state.type1dropdowntext4 == ''){
      this.setState({type5validation:true});
      this.setState({type5validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type5validation:false});
      this.setState({type5validationmessage:""});
    }
    if(this.state.type1dropdowntext5 == ''){
      this.setState({type6validation:true});
      this.setState({type6validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type6validation:false});
      this.setState({type6validationmessage:""});
    }
    if(this.state.type1dropdowntext6 == ''){
      this.setState({type7validation:true});
      this.setState({type7validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type7validation:false});
      this.setState({type7validationmessage:""});
    }
    if(this.state.type1dropdowntext7 == ''){
      this.setState({type8validation:true});
      this.setState({type8validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type8validation:false});
      this.setState({type8validationmessage:""});
    }
    if(this.state.type1dropdowntext8 == ''){
      this.setState({type9validation:true});
      this.setState({type9validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type9validation:false});
      this.setState({type9validationmessage:""});
    }
    if(this.state.type1dropdowntext9 == ''){
      this.setState({type10validation:true});
      this.setState({type10validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type10validation:false});
      this.setState({type10validationmessage:""});
    }
  }


  if(this.state.areadropdowntext ==''){
    this.setState({shippervalidation:true});
    this.setState({shippervalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({shippervalidation:false});
    this.setState({shippervalidationmessage:""});
  }

  if(this.state.operatoreadropdowntext ==''){
    this.setState({operationvalidation:true});
    this.setState({operationvalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({operationvalidation:false});
    this.setState({operationvalidationmessage:""});
  }

  if(this.state.pickupdropdowntext ==''){
    this.setState({pickupvalidation:true});
    this.setState({pickupvalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({pickupvalidation:false});
    this.setState({pickupvalidationmessage:""});
  }

  if(this.state.shipmenttypeadropdowntext =="Import"){
    if(this.state.returnyarddropdowntext ==''){
      this.setState({returnvalidation:true});
      this.setState({returnvalidationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({returnvalidation:false});
      this.setState({returnvalidationmessage:""});
    }
  }

  if(this.state.forwardingagentdropdowntext ==''){
    this.setState({forwardingvalidation:true});
    this.setState({forwardingvalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({forwardingvalidation:false});
    this.setState({forwardingvalidationmessage:""});
  }

  

  if(this.state.shipmenttypeadropdowntext ==''){
    this.setState({shipmenttypevalidation:true});
    this.setState({shipmenttypevalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({shipmenttypevalidation:false});
    this.setState({shipmenttypevalidationmessage:""});
  }

  if(this.state.podtext ==''){
    this.setState({podtextvalidation:true});
    this.setState({podtextvalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({podtextvalidation:false});
    this.setState({podtextvalidationmessage:""});
  }

  if(this.state.poltext ==''){
    this.setState({poltextvalidation:true});
    this.setState({poltextvalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({poltextvalidation:false});
    this.setState({poltextvalidationmessage:""});
  }

 

  if(this.state.picvalue==''){
    this.setState({picvalidation:true});
    errorornot=true;
  }else{
    this.setState({picvalidation:false});
  }

  if(this.state.contactvalue==''){
    this.setState({contactvalidation:true});
    errorornot=true;
  }else{
    this.setState({contactvalidation:false});
  }
  
  if(this.state.bookingno==''){
    this.setState({bookingnovalidation:true});
    errorornot=true;
  }else{
    this.setState({bookingnovalidation:false});
  }
  
  if(this.state.Vsleta==''){
    this.setState({vsletavalidation:true});
    errorornot=true;
  }else{
    this.setState({vsletavalidation:false});
  }
  
  if(this.state.closingday==''){
    this.setState({closingvalidation:true});
    errorornot=true;
  }else{
    this.setState({closingvalidation:false});
  }
    


  if(this.state.vesselname==''){
    this.setState({vesselnamevalidation:true});
    this.setState({vesselnamevalidationmesssage:"Please fill in the detail"});
    errorornot=true;    
  }else{
    this.setState({vesselnamevalidation:false});
    this.setState({vesselnamevalidationmesssage:""});
  }
  
  if(this.state.vesselvoyage==''){
    this.setState({vesselvoyagevalidation:true});
    this.setState({vesselvoyagevalidationmessage:"Please fill in the detail"});
    errorornot=true;  
   
  }else{
    this.setState({vesselvoyagevalidation:false});
    this.setState({vesselvoyagevalidationmessage:""});
  }  
   

  if(errorornot == true){
    this.setState({alerterrormessage:'Something wrong, Please fill in all details'})
  }

  if(this.state.shipmenttypeadropdowntext=="Import"){
    //CONTAINER NUMBER MUST FILL
   
    for(let i=0;i<numberrow;i++){
      //console.log("container:" + this.state["container" + i])
      if(this.state["container" + i] =='' || this.state["container" + i] == null ){
        errorornot = true
        this.setState({alerterrormessage:'Please fill in Container No'})
      }  
      if(this.state["requestdate" + i] == ''|| this.state["requestdate" + i] == null){
        errorornot = true
        this.setState({alerterrormessage:'Request Date must fill in'})
        this.setState({requestdateerrorinvalid:true});
      } 
      if(this.state["requestdate" + i] != 946684800){
        if(this.state["requestdate" + i] < this.state.Vsleta){
          errorornot = true
          this.setState({alerterrormessage:'Request Date cannot before ETA Date'})
        } 
      }
     
    }
  }else if(this.state.shipmenttypeadropdowntext=="Export"){
    //REQUEST DATE CANNOT AFTER CLOSING DATE, if(requestdate > closingday)false
    for(let i=0;i<numberrow;i++){
      if(this.state["requestdate" + i] > this.state.closingday){
        errorornot = true
        this.setState({alerterrormessage:'Request Date cannot be after Closing Date'})
      }  
     
      if(this.state["requestdate" + i] > this.state.Vsleta){
        errorornot = true
        this.setState({alerterrormessage:'Request Date must before ETA Date'})
      } 
      if(this.state["requestdate" + i] == ''|| this.state["requestdate" + i] == null){
        errorornot = true
        this.setState({alerterrormessage:'Request Date must fill in'})
        this.setState({requestdateerrorinvalid:true})
      } 
       
      // if(this.state["requestdate" + i] == 946684800){
      //   errorornot = true
      //   this.setState({alerterrormessage:'Request Date cannot be TBC when export'})
      // } 
    }
  }

  if(this.state.shipmenttypeadropdowntext == 'Export' && this.state.selectedFilename1 == null){
    if(window.confirm("Are you sure submit without attachment when export?")){

    }else{
      errorornot=true;
    }

  }

  // console.log("type1: " + this.state.type1dropdowntext)
  // console.log("type2: " + this.state.type1dropdowntext1)
  // console.log("type3: " + this.state.type1dropdowntext2)
  // console.log("type4: " + this.state.type1dropdowntext3)
  // console.log("type5: " + this.state.type1dropdowntext4)
  // console.log("type6: " + this.state.type1dropdowntext5)
  // console.log("type7: " + this.state.type1dropdowntext6)
  // console.log("type8: " + this.state.type1dropdowntext7)
  // console.log("type9: " + this.state.type1dropdowntext8)

  var bookingalllistnumber = Object.keys(this.state.bookingalllist).length;
  for(let i=0;i<bookingalllistnumber;i++){
      if(this.state.bookingalllist[i].title==this.state.bookingno){
        errorornot=true;
        window.scrollTo(0, 0)
        this.setState({errorsubmit:true})
        this.setState({alerterrormessage:'Booking no is used before'})
        return;
      }else{
        
      }      
  } 

  


  if( errorornot==true){
    window.scrollTo(0, 0)
    this.setState({errorsubmit:true})
    
  }else{
    
    
      //console.log("clicked")
      this.setState({aftersubmitstatus:3})
      var urlencoded = new URLSearchParams();
      urlencoded.append("billtoparty", this.state.Billtoparty);
      urlencoded.append("shipper",this.state.areadropdowntext );
      urlencoded.append("deliveryaddress", this.state.deliveryaddress);
      urlencoded.append("areatextbox", this.state.areatextbox);
      urlencoded.append("picvalue", this.state.picvalue);
      urlencoded.append("contact", this.state.contactvalue);
  
      //shipment type problem
      urlencoded.append("shipmenttypedropdowntext", this.state.shipmenttypeadropdowntext);
      urlencoded.append("poltext", this.state.poltext);
      urlencoded.append("podtext", this.state.podtext);
      urlencoded.append("bookingno", this.state.bookingno);

      if(this.state.attachmentquantity == 1){
        urlencoded.append("Filename1", this.state.selectedFilename1);
      urlencoded.append("Filename2", "");
      urlencoded.append("Filename3", "");
      urlencoded.append("Filename4", "");
      urlencoded.append("Filename5", "");
      urlencoded.append("Filename6", "");
      urlencoded.append("Filename7", "");
      urlencoded.append("Filename8", "");
      urlencoded.append("Filename9", "");
      urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 2){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
      urlencoded.append("Filename3", "");
      urlencoded.append("Filename4", "");
      urlencoded.append("Filename5", "");
      urlencoded.append("Filename6", "");
      urlencoded.append("Filename7", "");
      urlencoded.append("Filename8", "");
      urlencoded.append("Filename9", "");
      urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 3){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", "");
        urlencoded.append("Filename5", "");
        urlencoded.append("Filename6", "");
        urlencoded.append("Filename7", "");
        urlencoded.append("Filename8", "");
        urlencoded.append("Filename9", "");
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 4){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", "");
        urlencoded.append("Filename6", "");
        urlencoded.append("Filename7", "");
        urlencoded.append("Filename8", "");
        urlencoded.append("Filename9", "");
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 5){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", this.state.selectedFilename5);
        urlencoded.append("Filename6", "");
        urlencoded.append("Filename7", "");
        urlencoded.append("Filename8", "");
        urlencoded.append("Filename9", "");
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 6){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", this.state.selectedFilename5);
        urlencoded.append("Filename6", this.state.selectedFilename6);
        urlencoded.append("Filename7", "");
        urlencoded.append("Filename8", "");
        urlencoded.append("Filename9", "");
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 7){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", this.state.selectedFilename5);
        urlencoded.append("Filename6", this.state.selectedFilename6);
        urlencoded.append("Filename7", this.state.selectedFilename7);
        urlencoded.append("Filename8", "");
        urlencoded.append("Filename9", "");
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 8){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", this.state.selectedFilename5);
        urlencoded.append("Filename6", this.state.selectedFilename6);
        urlencoded.append("Filename7", this.state.selectedFilename7);
        urlencoded.append("Filename8", this.state.selectedFilename8);
        urlencoded.append("Filename9", "");
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 9){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", this.state.selectedFilename5);
        urlencoded.append("Filename6", this.state.selectedFilename6);
        urlencoded.append("Filename7", this.state.selectedFilename7);
        urlencoded.append("Filename8", this.state.selectedFilename8);
        urlencoded.append("Filename9", this.state.selectedFilename9);
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 10){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", this.state.selectedFilename5);
        urlencoded.append("Filename6", this.state.selectedFilename6);
        urlencoded.append("Filename7", this.state.selectedFilename7);
        urlencoded.append("Filename8", this.state.selectedFilename8);
        urlencoded.append("Filename9", this.state.selectedFilename9);
        urlencoded.append("Filename10", this.state.selectedFilename10);
      }
      


      var vsletapassup = new Date(this.state.Vsleta).getTime()/1000;
      var vsldate = new Date(this.state.Vsleta).getDate();
      var vsldatemonth = new Date(this.state.Vsleta).getMonth() + 1;
      var vslfullyear = new Date(this.state.Vsleta).getFullYear();
      var vsltotal = vsldate + "/" + vsldatemonth + "/" + vslfullyear;
      // console.log("vslfullyear: " + vslfullyear)
      // console.log("vsldate: " + vsldate)
      // console.log("vsldatemonth: " + vsldatemonth)
      // console.log("vsltotal: " + vsltotal)
      this.setState({vsltoshow:vsltotal});
      urlencoded.append("Vsleta", vsletapassup);
  
      var closingdate = new Date(this.state.closingday).getDate();
      var closingmonth = new Date(this.state.closingday).getMonth() + 1;
      var closingfullyear = new Date(this.state.closingday).getFullYear();
      var closingtotal = closingdate + "/" + closingmonth + "/" + closingfullyear;
      console.log("closingfullyear: " + closingfullyear)
      console.log("closingdate: " + closingdate)
      console.log("closingmonth: " + closingmonth)
      console.log("closingtotal: " + closingtotal)
      this.setState({closingdateshow:closingtotal});
      
      var closingdaypasssup = new Date(this.state.closingday).getTime()/1000;
      urlencoded.append("closingday", closingdaypasssup);
      urlencoded.append("operatoreadropdowntext",this.state.operatoreadropdowntext);
      urlencoded.append("pickupdropdowntext", this.state.pickupdropdowntext);
      if(this.state.shipmenttypeadropdowntext =="Export"){
        urlencoded.append("returnyarddropdowntext", "");
      }else if(this.state.shipmenttypeadropdowntext =="Import"){
        urlencoded.append("returnyarddropdowntext", this.state.returnyarddropdowntext);
      }
      
      //till here, need to add volume and check volume key in problem
      if(this.state.volumenumberinput != 0) {
        if(this.state.type1dropdowntext == "None"){
        
        } else{
          urlencoded.append("volumenumberinput", this.state.volumenumberinput);
          urlencoded.append("type1dropdowntext",this.state.type1dropdowntext );
        }
      }else{
        //alert
      }
      console.log("volumenumber1value1: "+ this.state.volumenumberinput)
    
      if(this.state.volumenumberinput1 != 0) {
        if(this.state.type1dropdowntext1 == "None"){
        
        } else{
        urlencoded.append("volumenumberinput1", this.state.volumenumberinput1);
        urlencoded.append("type1dropdowntext1", this.state.type1dropdowntext1);
        }
      }else{
        urlencoded.append("volumenumberinput1", 0);
      }
      console.log("volumenumber2value2: "+ this.state.volumenumberinput1)
  
      if(this.state.volumenumberinput2 != 0 && this.state.rowvolume1 == true) {
        if(this.state.type1dropdowntext2 == "None"){
        
        } else{
        urlencoded.append("volumenumberinput2", this.state.volumenumberinput2);
        urlencoded.append("type1dropdowntext2",this.state.type1dropdowntext2 );
        }
        console.log("consoled"+ this.state.volumenumberinput2)
      }else{
        urlencoded.append("volumenumberinput2", 0);
      }
  
      if(this.state.volumenumberinput3 != 0 && this.state.rowvolume2 == true) {
        if(this.state.type1dropdowntext3 == "None"){
        
        } else{
        urlencoded.append("volumenumberinput3", this.state.volumenumberinput3);
        urlencoded.append("type1dropdowntext3",this.state.type1dropdowntext3 );
        }
      }else{
        urlencoded.append("volumenumberinput3", 0);
      }
  
      if(this.state.volumenumberinput4 != 0 && this.state.rowvolume3 == true) {
        if(this.state.type1dropdowntext4 == "None"){
        
        } else{
        urlencoded.append("volumenumberinput4", this.state.volumenumberinput4);
        urlencoded.append("type1dropdowntext4",this.state.type1dropdowntext4 );
        }
      }else{
        urlencoded.append("volumenumberinput4", 0);
      }
  
      if(this.state.volumenumberinput5 != 0 && this.state.rowvolume4 == true) {
        if(this.state.type1dropdowntext5 == "None"){
        
        } else{
        urlencoded.append("volumenumberinput5", this.state.volumenumberinput5);
        urlencoded.append("type1dropdowntext5",this.state.type1dropdowntext5 );
        }
      }else{
        urlencoded.append("volumenumberinput5", 0);
      }
  
      if(this.state.volumenumberinput6 != 0 && this.state.rowvolume5 == true) {
        if(this.state.type1dropdowntext6 == "None"){          
        } else{
          urlencoded.append("volumenumberinput6", this.state.volumenumberinput6);
          urlencoded.append("type1dropdowntext6",this.state.type1dropdowntext6 );
        }
      }else{
        urlencoded.append("volumenumberinput6", 0);
      }
  
      if(this.state.volumenumberinput7 != 0 && this.state.rowvolume6 == true) {
        if(this.state.type1dropdowntext7 == "None"){          
        } else{
          urlencoded.append("volumenumberinput7", this.state.volumenumberinput7);
          urlencoded.append("type1dropdowntext7",this.state.type1dropdowntext7 );
        }
      }else{
        urlencoded.append("volumenumberinput7", 0);
      }
  
      if(this.state.volumenumberinput8 != 0 && this.state.rowvolume7 == true) {
        if(this.state.type1dropdowntext8 == "None"){          
        } else{
          urlencoded.append("volumenumberinput8", this.state.volumenumberinput8);
          urlencoded.append("type1dropdowntext8",this.state.type1dropdowntext8 );
        }
      }else{
        urlencoded.append("volumenumberinput8", 0);
      }
  
      if(this.state.volumenumberinput9 != 0 && this.state.rowvolume8 == true) {
        if(this.state.type1dropdowntext9 == "None"){          
        } else{
          urlencoded.append("volumenumberinput9", this.state.volumenumberinput9);
          urlencoded.append("type1dropdowntext9",this.state.type1dropdowntext9 );
        }
      }else{
        urlencoded.append("volumenumberinput9", 0);
      }
    
      
      let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
      Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
      Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
      Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
      Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 
      
      urlencoded.append("totalnumberofjob", numberrow);
      for (let i=0;i<numberrow;i++){
        urlencoded.append("consigment"+i, i+1);
        urlencoded.append("container"+i, this.state["container" +i]);
        if(this.state["Seal" +i] == null){
          urlencoded.append("Seal"+i, " ");
        }else{
          urlencoded.append("Seal"+i, this.state["Seal" +i]);
        }
        
        if(this.state["container" +i] == null){
          urlencoded.append("container"+i, " ");
        }else{
          urlencoded.append("container"+i, this.state["container" +i]);
        }
        var requestdatedate = new Date(this.state["requestdate" +i]).getDate();
        var requestdatemonth = new Date(this.state["requestdate" +i]).getMonth() + 1;
        var requestdateyear = new Date(this.state["requestdate" +i]).getFullYear();
        var requestdatetotal = requestdatedate + "/" + requestdatemonth + "/" + requestdateyear;
  
        if(this.state["requestdate" +i]==946684800-28800 || this.state["requestdate" +i]==946684800){
          this.setState({["requestdatetoshow" + i]:'TBC'})
        }else{
          this.setState({["requestdatetoshow" + i]:requestdatetotal})
        }
        
        var requestdate = new Date( this.state["requestdate" +i]).getTime()/1000;
  
        console.log(i);
        console.log(this.state["container" +i]);
        console.log(this.state["Seal" +i]);
        console.log("requestdatestate: " + this.state["requestdate" +i]);
        console.log("requestdate: " + requestdate)
  
        if(this.state["requestdate" +i] == 946684800 || this.state["requestdate" +i] == 'TBC'){
          requestdatetotal ='TBC'
          urlencoded.append("requestdate"+i, this.state["requestdate" +i]);
        }else{
          urlencoded.append("requestdate"+i,requestdate);
        }

        if(this.state["checksideloader" +i] == null){
          urlencoded.append("checksideloader"+i, 0);
        }else{
          if(this.state["checksideloader" +i] == true){
            urlencoded.append("checksideloader"+i, 1);
          }else{
            urlencoded.append("checksideloader"+i, 0);
          }
          
        }
        
       
        //urlencoded.append("requestdate"+i,requestdate);
  
        
      }
      
      urlencoded.append("vesselname", this.state.vesselname);
      urlencoded.append("vesselvoyage", this.state.vesselvoyage);
      urlencoded.append("commodity", this.state.commodity);
      urlencoded.append("forwardingagentdropdowntext", this.state.forwardingagentdropdowntext);
  
      console.log("Remark: " + this.state.remark)
      urlencoded.append("createdby",this.state.Createdbyaccount)

      if(this.state.remark == null || this.state.remark == ''){
        urlencoded.append("remark", '-');
      }else{
        urlencoded.append("remark", this.state.remark);
      }
  
      //file upload2
  
      let link = this.state.linkurl +'mode=admin-addnewrotapi-version2'
      fetch(link, {
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body:urlencoded
      })
      .then(response => response.json())
      .then(json => {
        console.log(json.success)
        if(json.success == 1){
          
              console.log(json)
              //success
              console.log("rotnumberfeedback: " + json.rotnumber)
              this.setState({rotnumbercomeback:json.rotnumber})
              this.setState({aftersubmitstatus:1})
              this.setState({numberrowaftersubmit:numberrow})
              //this.uploadfilefrom1to10(json.rotnumber)
              //console.log(111json.user)
              //  var lengthemployee = Object.keys(json.user).length;
              //  for(let i=0;i<lengthemployee;i++){
  
              //   //status need do
              //    this.setState({rotpendinglist:[...this.state.rotpendinglist,
              //     { no:i+1,
              //       rotno:json.user[i].Rotnumber,
              //       shippername:json.user[i].Shipper,
              //       totalvolume:json.user[i].totalvolume,
              //       bookingno:json.user[i].Bookingno,
              //       status:1,
              //       remark:json.user[i].Remark,
              //     },            
              //   ]})
                
                
               
              //    console.log(this.state.rotpendinglist)
               
  
               
              //   }
                this.setState({loading:false})  
              }        
                 
           else{
             //fail submit
            this.setState({aftersubmitstatus:2})
            this.setState({loading:false}) 
          console.log(json)
        }
      
       })
    
  


   
  }
}else{

    errorornot=true;
}

  }

  getdetailquotationtableapi =()=>{
    this.setState({loading:true})
    //console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-getquotationlistfinal'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
     // console.log(json.success)
      if(json.success == 1){
        
           // console.log(json)
            this.setState({quotationtabledatafinal:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

          
               this.setState({quotationtabledatafinal:[...this.state.quotationtabledatafinal,
                { no:i+1,
                  shippername:json.user[i].Shipper,
                  area:json.user[i].Area,
                  address:json.user[i].Address,
                  title:json.user[i].Shipper,
                  picvalue:json.user[i].Personincharge,
                  contactvalue:json.user[i].Contact,
                },            
              ]})
              
              
              this.setState({aftersubmitstatus:0})
               //console.log(this.state.quotationtabledatafinal)
             

             
              }
              //this.setState({loading:false})  
              
            }        
               
         else{
          this.setState({loading:false}) 
          this.setState({aftersubmitstatus:0})
        console.log(json)
      }
    
     })

    
       

   
  }

  getoperatorlistapi =() =>{
    this.setState({loading:true})
   // console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-getoperatorlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
     // console.log(json.success)
      if(json.success == 1){
        
           // console.log(json)
            this.setState({operatorlistdata:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({operatorlistdata:[...this.state.operatorlistdata,
                { no:i+1,
                  operator:json.user[i].Operator,
                  title:json.user[i].Operator,
                  value:json.user[i].Operator,
                },            
              ]})
             //  console.log(this.state.operatorlistdata)
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  getrecentautocomplete = () =>{
    this.setState({loading:true})
   // console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("createdbyref", this.state.Createdbyref);

    let link = this.state.linkurl +'mode=admin-getrecentautocompletelistrot'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
     // console.log(json.success)
      if(json.success == 1){
        
           // console.log(json)
            this.setState({vesselnamerecentlist:[]});   
            this.setState({vesselvoyagerecentlist:[]});   
            this.setState({commodityrecentlist:[]});   
            this.setState({bookingrecentlist:[]});   
            this.setState({polrecentlist:[]});
            this.setState({podrecentlist:[]});

             var lengthvesselname = Object.keys(json.vesselname).length;
             for(let i=0;i<lengthvesselname;i++){

               this.setState({vesselnamerecentlist:[...this.state.vesselnamerecentlist,
                { no:i+1,
                  title:json.vesselname[i].Vesselname,
                },            
              ]})
            }

            var lengthvesselvoyage = Object.keys(json.vesselvoyage).length;
            for(let i=0;i<lengthvesselvoyage;i++){
              this.setState({vesselvoyagerecentlist:[...this.state.vesselvoyagerecentlist,
                { no:i+1,
                  title:json.vesselvoyage[i].Vesselvoyage,
                },            
              ]})
            }

            var lengthcommodity = Object.keys(json.commodity).length;
            for(let i=0;i<lengthcommodity;i++){
              this.setState({commodityrecentlist:[...this.state.commodityrecentlist,
                { no:i+1,
                  title:json.commodity[i].Commodity,
                },            
              ]})
            }

            var lengthpollist = Object.keys(json.pollist).length;
            for(let i=0;i<lengthpollist;i++){
              this.setState({polrecentlist:[...this.state.polrecentlist,
                { no:i+1,
                  title:json.pollist[i].POL,
                },            
              ]})
            }

            var lengthpodlist = Object.keys(json.podlist).length;
            for(let i=0;i<lengthpodlist;i++){
              this.setState({podrecentlist:[...this.state.podrecentlist,
                { no:i+1,
                  title:json.podlist[i].POD,
                },            
              ]})
            }

            //   console.log(this.state.yardlist)
              

            
              //this.setState({loading:false})   
              
              
            }    
              
               


         else{
          this.setState({vesselnamerecentlist:[...this.state.vesselnamerecentlist,
            { no:1,
              title:"-",
            },            
          ]})
          this.setState({vesselvoyagerecentlist:[...this.state.vesselvoyagerecentlist,
            { no:1,
              title:"-",
            },            
          ]})
          this.setState({commodityrecentlist:[...this.state.commodityrecentlist,
            { no:1,
              title:"-",
            },            
          ]})
          this.setState({bookingrecentlist:[...this.state.bookingrecentlist,
            { no:1,
              title:"-",
            },            
          ]})
          this.setState({polrecentlist:[...this.state.polrecentlist,
            { no:1,
              title:"-",
            },            
          ]})
          this.setState({podrecentlist:[...this.state.podrecentlist,
            { no:1,
              title:"-",
            },            
          ]})
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  getbookingnoprevious = () => {
    this.setState({loading:true})
   // console.log("booking console")
    var urlencoded = new URLSearchParams();

    //till here gonna go api
    let link = this.state.linkurl +'mode=admin-getbookingnoprevious'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      if(json.success == 1){
        
            //console.log("bookingjson:" + json)  
            this.setState({bookingalllist:[]});   
           
             var lengthbookinglist = Object.keys(json.bookinglist).length;
             for(let i=0;i<lengthbookinglist;i++){
              //console.log("json.bookinglist[i].Bookingno:" + json.bookinglist[i].Bookingno)    
              
               this.setState({bookingalllist:[...this.state.bookingalllist,
                { no:i+1,
                  title:json.bookinglist[i].Bookingno,
                },            
              ]})
            }     
            
            
             //console.log("bookingallist:" + this.state.bookingalllist);
              
            
            
            
            // var lengthbookinglistnumber = Object.keys(this.state.bookingalllist).length;

            // console.log("lengthbooking: " + lengthbookinglistnumber)

            //  for(let x=0;x<lengthbookinglistnumber;x++){

            //   console.log(this.state.bookingallist[x].title);
            // }   
            
            
            
            
            
            
            
            
            
            this.setState({loading:false})                
              
            }    
              
               



         else{
          this.setState({bookingalllist:[...this.state.bookingalllist,
            { no:1,
              title:"-",
            },            
          ]})
         
          this.setState({loading:false}) 
        
      }
    
     })
  }

  getpickupyardreturnyardlist =()=>{
    this.setState({loading:true})
    //console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-getpickupyardreturnyardlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
     // console.log(json)
      if(json.success == 1){
        
            //console.log(json)
            this.setState({yardlist:[]});   
            this.setState({returnyardlist:[]});
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({yardlist:[...this.state.yardlist,
                { no:i+1,
                  area:json.user[i].Area,
                  title:json.user[i].Area,
                },            
              ]})

                this.setState({returnyardlist:[...this.state.returnyardlist,
                  { no:i+1,
                    area:json.user[i].Area,
                    title:json.user[i].Area,
                  },            
                ]})
               //console.log(this.state.yardlist)
              }
             // this.setState({loading:false})  
              // this.setState({returnyardlist:[...this.state.returnyardlist,
              //   { no:lengthemployee+1,
              //     area:"**TBC (TO BE CONFIRM)",
              //     title:"**TBC (TO BE CONFIRM)",
              //   },            
              // ]})
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  getcontainertypelist =(pguorptp,shippername,shipmenttype)=>{
    this.setState({loading:true})
    this.setState({typelist:[]});  
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("shipper", shippername);

    // console.log("PGUPTPhere: " + pguorptp)
    // console.log("shipmenttype: " + shipmenttype)
    if(shipmenttype == 'Export'){
      if(pguorptp == 'Pasir Gudang'){
       // console.log("PGUPTPhere: " + pguorptp)
        urlencoded.append("Pguorptp", 'PGU');
      }else if(pguorptp == 'Tanjung Pelepas'){
        urlencoded.append("Pguorptp", 'PTP');
      }
    }else if(shipmenttype == 'Import'){
      if(pguorptp == 'Pasir Gudang'){
        urlencoded.append("Pguorptp", 'PGU');
      }else if(pguorptp == 'Tanjung Pelepas'){
        urlencoded.append("Pguorptp", 'PTP');
      }
    }
    // console.log(this.state.Billtoparty)
    // console.log(shippername)
    // console.log(pguorptp)

    let link = this.state.linkurl +'mode=admin-getcontainertypelist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json)
      if(json.success == 1){
        
          //  console.log(json)
            this.setState({typelist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({typelist:[...this.state.typelist,
                { no:i+1,
                  size:json.user[i].Size,
                  type:json.user[i].Type,
                  title:json.user[i].Size +" "+json.user[i].Type,
                },            
              ]})
              this.setState({typelistfirstone:[...this.state.typelistfirstone,
                { no:i+1,
                  size:json.user[i].Size,
                  type:json.user[i].Type,
                  title:json.user[i].Size +" "+json.user[i].Type,
                },            
              ]})
               console.log(this.state.typelist)
              }
              this.setState({typelist:[...this.state.typelist,
                { no:0,
                  size:"None",
                  type:"None",
                  title:"None",
                },            
              ]}) 
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  getforwardingagentlist = ()=>{
    this.setState({loading:true})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-getforwardingagentlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
     // console.log(json.success)
      if(json.success == 1){
        
           // console.log(json)
            this.setState({forwardingagentlist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({forwardingagentlist:[...this.state.forwardingagentlist,
                { no:i+1,
                  forwardingagent:json.user[i].companyname,
                  title:json.user[i].companyname,
                },            
              ]})
               console.log(this.state.forwardingagentlist)
              }
              //this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  addvolumebuttonclicked = () =>{
    console.log("operatorlist: "+this.state.operatoreadropdowntext)
    if(this.state.volumeshowrow >= 8){
      
    }else{
      this.setState({volumeshowrow:this.state.volumeshowrow+1})
    }
    
    var numbershowtemp = this.state.volumeshowrow+1
    console.log(numbershowtemp)
        if(numbershowtemp == 0){
          this.setState({
            rowvolume1:false,
            rowvolume2:false,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:false,
          })
        } else if(numbershowtemp == 1){
          this.setState({
            rowvolume1:true,
            rowvolume2:false,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        } else if(numbershowtemp == 2){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        } else if(numbershowtemp == 3){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        } else if(numbershowtemp == 4){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        }else if(numbershowtemp == 5){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        }else if(numbershowtemp ==6){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        }else if(numbershowtemp == 7){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:true,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        }else if(numbershowtemp == 8){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:true,
            rowvolume8:true,
            minusbuttonshow:true,
          })
        }
  }
  
  minusvolumebuttonclicked = () =>{
    if(this.state.volumeshowrow <= 0){
    }else{
      this.setState({volumeshowrow:this.state.volumeshowrow-1})
    }
  
    var numbershowtemp = this.state.volumeshowrow-1
    console.log(numbershowtemp)
        if(numbershowtemp == 0){
          this.setState({
            rowvolume1:false,
            rowvolume2:false,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:false,
            volumenumberinput2:0,
          })
        } else if(numbershowtemp == 1){
          this.setState({
            rowvolume1:true,
            rowvolume2:false,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput3:0,
          })
        } else if(numbershowtemp == 2){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput4:0,
          })
        } else if(numbershowtemp == 3){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput5:0,
          })
        } else if(numbershowtemp == 4){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput6:0,
          })
        }else if(numbershowtemp == 5){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput7:0,
          })
        }else if(numbershowtemp ==6){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput8:0,
          })
        }else if(numbershowtemp == 7){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:true,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput9:0,
          })
        }else if(numbershowtemp == 8){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:true,
            rowvolume8:true,
            minusbuttonshow:true,
            
          })
        }
  }

  functionselectshipmenttype =(shippername2)=>{
       
    var data = this.state.quotationtabledatafinal.filter(item => item.shippername === shippername2)
   
    this.setState({type1dropdowntext:''})
    this.setState({type1dropdowntext1:''})
    this.setState({type1dropdowntext2:''})
    this.setState({type1dropdowntext3:''})
    this.setState({type1dropdowntext4:''})
    this.setState({type1dropdowntext5:''})
    this.setState({type1dropdowntext6:''})
    this.setState({type1dropdowntext7:''})
    this.setState({type1dropdowntext8:''})
    this.setState({type1dropdowntext9:''})
    
    if(data[0] != null){
      
      this.setState({
        areadropdowntext:shippername2,
        deliveryaddress:data[0].address,
        areatextbox:data[0].area,
        picvalue:data[0].picvalue,
        contactvalue:data[0].contactvalue,
      })

      if(data[0].picvalue == ""){
        this.setState({picvalue:"-"});
      }
      if(data[0].contactvalue ==""){
        this.setState({contactvalue:"-"});
      }
    }

    if(shippername2 ==""){
      this.setState({
        areadropdowntext:"",
        deliveryaddress:"",
        areatextbox:"",
        picvalue:"",
        contactvalue:"",
        
      })
    }
   
    console.log(shippername2)
    console.log(this.state.poltext)
    console.log(this.state.podtext)

    if(this.state.shipmenttypeadropdowntext =='Export'){
     this.getcontainertypelist(this.state.poltext,shippername2,this.state.shipmenttypeadropdowntext)
    } else if(this.state.shipmenttypeadropdowntext =='Import'){
     this.getcontainertypelist(this.state.podtext,shippername2,this.state.shipmenttypeadropdowntext)
    } else{

    }
  }
//   rendershipperdropdownmenu() {
   

//     return this.state.quotationtabledatafinal.map((data, index) => {
//        const {address,area,shippername} = data //destructuring
      
//        return (
//         <DropdownItem onClick={this.functionselectshipmenttype.bind(this,shippername,area,address)}>{shippername}</DropdownItem>
//        )
//     })
//  }
 renderoperatordropdownmenu() {
      

  return this.state.operatorlistdata.map((data, index) => {
     const {operator} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        operatoreadropdowntext:operator,
      })}>{operator}</DropdownItem>
     )
  })
}
//  renderconsignmentnorow(totalrownumber) {

    
//      return (
//       <Row>

//       <Col md="3">
        
//           <FormGroup>
//           <Input
//               id="Consignmentnoinput2"
//               placeholder="Consignment No"
//               type="text"
//             />
//           </FormGroup>
//         </Col>
//         <Col md="3">

//           <FormGroup>
//           <Input
//               id="Containernoinput2"
//               placeholder="Container No"
//               type="text"
//             />
//           </FormGroup>
//         </Col>
//         <Col md="3">
//           <FormGroup>
//           <Input
//               id="Sealnoinput2"
//               placeholder="Seal No"
//               type="text"
//             />
//           </FormGroup>
//           </Col>
//           <Col md="2">
//           <FormGroup>
//           <InputGroup className="input-group-alternative">
//                   <InputGroupAddon addonType="prepend">
//                     <InputGroupText>
//                       <i className="ni ni-calendar-grid-58" />
//                     </InputGroupText>
//                   </InputGroupAddon>
//                   <ReactDatetime
//                     inputProps={{
//                       placeholder: "Request Date"
//                     }}
//                     timeFormat={false}
//                   />
//                 </InputGroup>
//           </FormGroup>
//           </Col>
//           <Col md="1">
//           <FormGroup>
//               <div className="custom-control custom-checkbox mb-3">
//               <input
//                 className="custom-control-input"
//                 id="customCheck1"
//                 type="checkbox"
//                 onChange={(e)=>console.log("checkboxchecked:" + e.target.value)}
//               />
//               <label className="custom-control-label" htmlFor="customCheck1">
//                 TBE
//               </label>
//             </div>
//           </FormGroup>
//           </Col>
//       </Row>
//      )
  
// }
renderforwardingagentrow() {
      

  return this.state.forwardingagentlist.map((data, index) => {
     const {forwardingagent} = data //destructuring
     return (

      <DropdownItem onClick={() => this.setState({
        forwardingagentdropdowntext:forwardingagent,
      })}>{forwardingagent}</DropdownItem>
     )
  })
}

renderaddinvolumerow(){
  // console.log("checktbevalue0: " + this.state.checktbe0)
  // console.log("checktbevalue1: " + this.state.checktbe1)
  // console.log("checktbevalue2: " + this.state.checktbe2)
  // console.log("checktbevalue3: " + this.state.checktbe3)
  // console.log("checktbevalue4: " + this.state.checktbe4)
  // console.log("checktbevalue5: " + this.state.checktbe5)
  // console.log("reque122stdate11: " + this.state.requestdate0)
  // console.log("requestdatedisable: " + this.state.requestdatedisable0)
  let consignmentnorow=[]
  let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
                  Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
                  Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
                  Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
                  Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 

                 


  for(let i=0;i<numberrow;i++){
    var numbershow = i+1
    
    consignmentnorow.push(
      <Row>

      <Col md="2">
        
          <FormGroup>
          <Input
              id="Consignmentnoinput2"
              placeholder="Consignment No"
              type="text"
              disabled
              value= {"No. " + numbershow}
              
            />
          </FormGroup>
        </Col>
        <Col md="3">

          <FormGroup>
          <Input
              id="Containernoinput2"
              placeholder="Container No"
              className="textcolorblack"
              value={this.state["container" + i]}
              onChange = {(event) => this.setState({ ["container" + i]: event.target.value })}
              type="text"
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
          <Input
              id="Sealnoinput2"
              placeholder="Seal No"
              className="textcolorblack"
              type="text"
              value={this.state["Seal" + i]}
              onChange = {(e) => this.setState({ ["Seal" + i]: e.target.value })}
            />
          </FormGroup>
          </Col>
          <Col md="2">
          <FormGroup>
            {this.state["requestdatedisable"+i]==true?
                <Input
                id="requestdatetbe"
                placeholder="TBC"
                type="text"
                disabled={true}
                value={"TBC"}
              />
            :
                  <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Request Date"
                    }}
                    closeOnSelect={true}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    value={this.state["requestdate" + i]}
                    onChange = {(e) => this.setState({ ["requestdate" + i]: e })}
                    
                  />
                </InputGroup> 
            }
        
     
          </FormGroup>
          </Col>
          <Col md="1">
          <FormGroup>
            
            <div className="custom-control custom-control-alternative custom-checkbox mb-3">
              <input
                className="custom-control-input"
                id={"customCheck"+i}
                type="checkbox"
                checked={this.state["checktbe" + i]}
                onChange={(e)=>this.checktbefunction(i)}
              />
              <label className="custom-control-label" htmlFor={"customCheck"+i}>
                TBC
              </label>
            </div>
          </FormGroup>
          </Col>
          <Col md="1">
          <FormGroup>
            
            <div className="custom-control custom-control-alternative custom-checkbox mb-3">
              <input
                className="custom-control-input"
                id={"customChecksideloader"+i}
                type="checkbox"
                checked={this.state["checksideloader" + i]}
                onChange={(e)=>this.checksidefunction(i)}
              />
              <label className="custom-control-label" htmlFor={"customChecksideloader"+i}>
                SL
              </label>             
            </div>
          </FormGroup>
          </Col>
         
      </Row>
    )  
  }
  
    return consignmentnorow
    
}
  
renderyardlistdropdownmenu() {
  return this.state.yardlist.map((data, index) => {
     const {area} = data //destructuring
     //console.log(area)
     return (
      <DropdownItem onClick={() => this.setState({
        pickupdropdowntext:area,
      })}>{area}</DropdownItem>
     )
  })
}

// rendertypelistdropdownmenu() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }

// rendertypelistdropdownmenu1() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext1:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu2() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext2:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu3() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext3:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu4() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext4:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu5() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext5:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu6() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext6:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu7() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext7:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu8() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext8:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu9() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext9:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }

// renderreturnyardlistdropdownmenu() {
//   return this.state.yardlist.map((data, index) => {
//      const {area} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         returnyarddropdowntext:area,
//       })}>{area}</DropdownItem>
//      )
//   })
// }

callgettypelistfunctionpol = (pguorptp) =>{
  this.setState({poltext:pguorptp})
  this.getcontainertypelist(pguorptp,this.state.areadropdowntext,this.state.shipmenttypeadropdowntext);
}

callgettypelistfunctionshipmenttype= (value) =>{
  this.setState({shipmenttypeadropdowntext:value})
  //this.setState({poltext:''})
  //this.setState({podtext:''})
  console.log("pod: " + this.state.podtext)
  console.log("pol: " + this.state.poltext)
  this.setState({typelist:[]})

  if(value =='Export'){
    if(this.state.poltext !=''){
      if(this.state.areadropdowntext != ''){
        console.log("pol2: " + this.state.poltext)
        this.getcontainertypelist(this.state.poltext,this.state.areadropdowntext,value)
      }
     
    }   
   } else if(value =='Import'){
     if(this.state.podtext != ''){
      if(this.state.areadropdowntext != ''){
        this.getcontainertypelist(this.state.podtext,this.state.areadropdowntext,value )
      }
     } 
   } else{
    console.log("no able to get container type list")
   }
  // if(value =='Export'){
  //   this.getcontainertypelist(this.state.poltext,this.state.areadropdowntext)
  //  } else if(value =='Import'){
  //   this.getcontainertypelist(this.state.podtext,this.state.areadropdowntext)
  //  } else{

  //  }
}

callgettypelistfunctionpod = (pguorptp) =>{
  this.setState({podtext:pguorptp})
  this.getcontainertypelist(pguorptp,this.state.areadropdowntext,this.state.shipmenttypeadropdowntext);
}


checktbefunction = (number) =>{
  
  this.setState({["requestdate" + number]:946684800})
  this.setState({ ["checktbe" + number]: !this.state["checktbe" + number]})
  this.setState({["requestdatedisable" + number]:!this.state["checktbe" + number]})
 
  console.log("checktbecheck: " + this.state["checktbe" + number])
  if(this.state["checktbe" + number] == false){
    this.setState({["requestdate" + number]:946684800})
    //console.log("emptycheck")
  }else if(this.state["checktbe" + number]){
   this.setState({["requestdate" + number]:new Date().getTime()})
    //console.log("bplacecheck1")
  }else{
    this.setState({["requestdate" + number]:946684800})
  }
  
  // console.log("requestdatedisable: "+this.state["requestdatedisable" + number])
  // console.log("requestdate: "+this.state["requestdate" + number])
  // console.log("checktbe: "+this.state["checktbe" + number])
}


checksidefunction = (number) =>{
  

  this.setState({ ["checksideloader" + number]: !this.state["checksideloader" + number]}) 
  console.log("checksideloader: " + this.state["checksideloader" + number])

}




sameallthedate = () =>{
  console.log("requestdate0:" + this.state.requestdate0)
  this.setState({ samedate: !this.state.samedate})
  let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
  Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
  Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
  Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
  Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 

  if(this.state.checktbe0 == true){
    //disable all below
    for(let i=0;i<numberrow;i++){
      this.setState({ ["requestdatedisable" + i]: true })
      this.setState({["requestdate" + i]:946684800})
      this.setState({ ["checktbe" + i]: true})
    }
  }else{
    for(let i=0;i<numberrow;i++){
      this.setState({ ["requestdate" + i]: this.state.requestdate0 })
    }
  }
 
}

buttonaddattachmentclick = () =>{
  
  this.setState({attachmentquantity:this.state.attachmentquantity + 1}) 
  if(this.state.attachmentquantity == 1){
    this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:false})
    this.setState({fileuploadnumber4:false})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 2){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:false})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 3){
      this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:true})
      this.setState({fileuploadnumber4:true})
      this.setState({fileuploadnumber5:false})
      this.setState({fileuploadnumber6:false})
      this.setState({fileuploadnumber7:false})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 4){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 5){
      this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:true})
      this.setState({fileuploadnumber4:true})
      this.setState({fileuploadnumber5:true})
      this.setState({fileuploadnumber6:true})
      this.setState({fileuploadnumber7:false})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 6){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 7){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:true})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 8){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:true})
    this.setState({fileuploadnumber9:true})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 9){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:true})
    this.setState({fileuploadnumber9:true})
    this.setState({fileuploadnumber10:true})
    }else if(this.state.attachmentquantity == 10){
      this.setState({attachmentquantity:10})
    }

    console.log("attachmentquantity: " + this.state.attachmentquantity)
}

//this.uploadfilefrom1to10()
buttoncancelattachmentclick = () =>{
  this.setState({attachmentquantity:this.state.attachmentquantity - 1}) 
  
  if(this.state.attachmentquantity == 1){  
  this.setState({attachmentquantity:1})
  }else if(this.state.attachmentquantity == 2){
    this.setState({fileuploadnumber2:false})
  this.setState({fileuploadnumber3:false})
  this.setState({fileuploadnumber4:false})
  this.setState({fileuploadnumber5:false})
  this.setState({fileuploadnumber6:false})
  this.setState({fileuploadnumber7:false})
  this.setState({fileuploadnumber8:false})
  this.setState({fileuploadnumber9:false})
  this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 3){
    this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:false})
    this.setState({fileuploadnumber4:false})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 4){
    this.setState({fileuploadnumber2:true})
  this.setState({fileuploadnumber3:true})
  this.setState({fileuploadnumber4:false})
  this.setState({fileuploadnumber5:false})
  this.setState({fileuploadnumber6:false})
  this.setState({fileuploadnumber7:false})
  this.setState({fileuploadnumber8:false})
  this.setState({fileuploadnumber9:false})
  this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 5){
    this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 6){
    this.setState({fileuploadnumber2:true})
  this.setState({fileuploadnumber3:true})
  this.setState({fileuploadnumber4:true})
  this.setState({fileuploadnumber5:true})
  this.setState({fileuploadnumber6:false})
  this.setState({fileuploadnumber7:false})
  this.setState({fileuploadnumber8:false})
  this.setState({fileuploadnumber9:false})
  this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 7){
    this.setState({fileuploadnumber2:true})
  this.setState({fileuploadnumber3:true})
  this.setState({fileuploadnumber4:true})
  this.setState({fileuploadnumber5:true})
  this.setState({fileuploadnumber6:true})
  this.setState({fileuploadnumber7:false})
  this.setState({fileuploadnumber8:false})
  this.setState({fileuploadnumber9:false})
  this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 8){
    this.setState({fileuploadnumber2:true})
  this.setState({fileuploadnumber3:true})
  this.setState({fileuploadnumber4:true})
  this.setState({fileuploadnumber5:true})
  this.setState({fileuploadnumber6:true})
  this.setState({fileuploadnumber7:true})
  this.setState({fileuploadnumber8:false})
  this.setState({fileuploadnumber9:false})
  this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 9){
    this.setState({fileuploadnumber2:true})
  this.setState({fileuploadnumber3:true})
  this.setState({fileuploadnumber4:true})
  this.setState({fileuploadnumber5:true})
  this.setState({fileuploadnumber6:true})
  this.setState({fileuploadnumber7:true})
  this.setState({fileuploadnumber8:true})
  this.setState({fileuploadnumber9:false})
  this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 10){
    this.setState({fileuploadnumber2:true})
  this.setState({fileuploadnumber3:true})
  this.setState({fileuploadnumber4:true})
  this.setState({fileuploadnumber5:true})
  this.setState({fileuploadnumber6:true})
  this.setState({fileuploadnumber7:true})
  this.setState({fileuploadnumber8:true})
  this.setState({fileuploadnumber9:true})
  this.setState({fileuploadnumber10:false})
  }
  console.log("attachmentquantity: " + this.state.attachmentquantity)
}

uploadfile1 = ({target:{files}}) =>{ 
  this.setState({percentupload1:0})
  this.setState({selectedFilename1:""}) 
  let attachmentnumber = 1
 
  const formData = new FormData();
 
    formData.append( 
      "myFile", 
      files[0] ,
    );

  
 
  formData.append( 
    "attachementsetID", 
    this.state.attachmentsetID,
  );
 
  formData.append( 
    "attachmentnumber", 
    attachmentnumber,
  );


  const config = {
    headers: {
        'content-type': 'multipart/form-data'
    }
}

const options = {
  onUploadProgress:(progressEvent) =>{
    const {loaded,total} = progressEvent;
    let percent = Math.floor(loaded*100/total)
    console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
    this.setState({percentupload1:percent})
  }
}

//upload go then feed back link, 
//the link update go current database

  axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
  .then(res => {
    //console.log(res);
    console.log(res.data);
    this.setState({selectedFilename1:res.data})
    console.log("selectedfilename1:" + this.state.selectedFilename1)
  }); 

}

uploadfile2 = ({target:{files}}) =>{ 
     this.setState({percentupload2:0})
     this.setState({selectedFilename2:""})   
  let attachmentnumber = 2
 
  const formData = new FormData();
 
    formData.append( 
      "myFile", 
      files[0] ,
    );

  
 
  formData.append( 
    "attachementsetID", 
    this.state.attachmentsetID,
  );
 
  formData.append( 
    "attachmentnumber", 
    attachmentnumber,
  );


  const config = {
    headers: {
        'content-type': 'multipart/form-data'
    }
}

const options = {
  onUploadProgress:(progressEvent) =>{
    const {loaded,total} = progressEvent;
    let percent = Math.floor(loaded*100/total)
    console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
    this.setState({percentupload2:percent})
  }
}

//upload go then feed back link, 
//the link update go current database

  axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
  .then(res => {
    //console.log(res);
    console.log(res.data);
    this.setState({selectedFilename2:res.data})    
    console.log("selectedfilename1:" + this.state.selectedFilename2)
  }); 

}

uploadfile3 = ({target:{files}}) =>{ 
  this.setState({percentupload3:0})
  this.setState({selectedFilename3:""})   
let attachmentnumber = 3

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload3:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename3:res.data})    
 console.log("selectedfilename1:" + this.state.selectedFilename3)
}); 

}

uploadfile4 = ({target:{files}}) =>{ 
  this.setState({percentupload4:0})
  this.setState({selectedFilename4:""})   
let attachmentnumber = 4

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload4:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename4:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}

uploadfile5 = ({target:{files}}) =>{ 
  this.setState({percentupload5:0})
  this.setState({selectedFilename5:""})   
let attachmentnumber = 5

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload5:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename5:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}

uploadfile6 = ({target:{files}}) =>{ 
  this.setState({percentupload6:0})
  this.setState({selectedFilename6:""})   
let attachmentnumber = 6

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload6:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename6:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}

uploadfile7 = ({target:{files}}) =>{ 
  this.setState({percentupload7:0})
  this.setState({selectedFilename7:""})   
let attachmentnumber = 7

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload7:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename7:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}

uploadfile8 = ({target:{files}}) =>{ 
  this.setState({percentupload8:0})
  this.setState({selectedFilename8:""})   
let attachmentnumber = 8

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload8:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename8:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}

uploadfile9 = ({target:{files}}) =>{ 
  this.setState({percentupload9:0})
  this.setState({selectedFilename9:""})   
let attachmentnumber = 9

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload9:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename9:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}

uploadfile10 = ({target:{files}}) =>{ 
  this.setState({percentupload10:0})
  this.setState({selectedFilename10:""})   
let attachmentnumber = 10

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload10:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename10:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}


renderontimekpimodallist() {
      
  if(this.state.currentquotationpagenumber ==1){
    return this.state.ontimekpilistfortable.slice(0,50).map((data, index) => {
      const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
      var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
      return (
       <tbody>
       <tr>
         <th scope="row">{consignmentno}</th>
         <td>{shipper}</td>
         <td>{bookingno}</td>
         <td>{containerno}</td>
         <td>{requestdate}</td>
         <td>{assigndate}</td>
         <td> <Badge color="" className="badge-dot mr-4">
         
         <i className="bg-warning" />                        
         
         Fail
         </Badge></td>
       </tr>  
     </tbody>
      )
   })         
  }else if(this.state.currentquotationpagenumber ==2){
    return this.state.ontimekpilistfortable.slice(50,100).map((data, index) => {
      const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
      var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
      return (
       <tbody>
       <tr>
         <th scope="row">{consignmentno}</th>
         <td>{shipper}</td>
         <td>{bookingno}</td>
         <td>{containerno}</td>
         <td>{requestdate}</td>
         <td>{assigndate}</td>
         <td> <Badge color="" className="badge-dot mr-4">
         
         <i className="bg-warning" />                        
         
         Fail
         </Badge></td>
       </tr>  
     </tbody>
      )
   })     
  }else if(this.state.currentquotationpagenumber ==3){
    return this.state.ontimekpilistfortable.slice(100,150).map((data, index) => {
      const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
      var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
      return (
       <tbody>
       <tr>
         <th scope="row">{consignmentno}</th>
         <td>{shipper}</td>
         <td>{bookingno}</td>
         <td>{containerno}</td>
         <td>{requestdate}</td>
         <td>{assigndate}</td>
         <td> <Badge color="" className="badge-dot mr-4">
         
         <i className="bg-warning" />                        
         
         Fail
         </Badge></td>
       </tr>  
     </tbody>
      )
   })     
  }else if(this.state.currentquotationpagenumber ==4){
    return this.state.ontimekpilistfortable.slice(150,200).map((data, index) => {
      const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
      var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
      return (
       <tbody>
       <tr>
         <th scope="row">{consignmentno}</th>
         <td>{shipper}</td>
         <td>{bookingno}</td>
         <td>{containerno}</td>
         <td>{requestdate}</td>
         <td>{assigndate}</td>
         <td> <Badge color="" className="badge-dot mr-4">
         
         <i className="bg-warning" />                        
         
         Fail
         </Badge></td>
       </tr>  
     </tbody>
      )
   })     
  }else if(this.state.currentquotationpagenumber ==5){
    return this.state.ontimekpilistfortable.slice(250,300).map((data, index) => {
      const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
      var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
      return (
       <tbody>
       <tr>
         <th scope="row">{consignmentno}</th>
         <td>{shipper}</td>
         <td>{bookingno}</td>
         <td>{containerno}</td>
         <td>{requestdate}</td>
         <td>{assigndate}</td>
         <td> <Badge color="" className="badge-dot mr-4">
         
         <i className="bg-warning" />                        
         
         Fail
         </Badge></td>
       </tr>  
     </tbody>
      )
   })     
  }else if(this.state.currentquotationpagenumber >= 6){
    return this.state.ontimekpilistfortable.slice(300,1000).map((data, index) => {
      const {containerno,consignmentno,no,shipper,bookingno,requestdate,assigndate,} = data //destructuring
      var rotlength = Object.keys(this.state.ontimekpilistfortable).length;
      return (
       <tbody>
       <tr>
         <th scope="row">{consignmentno}</th>
         <td>{shipper}</td>
         <td>{bookingno}</td>
         <td>{containerno}</td>
         <td>{requestdate}</td>
         <td>{assigndate}</td>
         <td> <Badge color="" className="badge-dot mr-4">
         
         <i className="bg-warning" />                        
         
         Fail
         </Badge></td>
       </tr>  
     </tbody>
      )
   })     
  }
 
}

uploadfilefrom1to10 = (rotnumber) =>{ 
     
    // Create an object of formData 
    const formData = new FormData(); 
    // formData.append( 
    //   'myFile1', 
    //   this.state.selectedFile1,
    // );
   
    console.log("formData: " + formData)
    console.log("selectedFile1: " + this.state.selectedFile1)
    // Update the formData object 
    if(this.state.attachmentquantity == 1){
      if(this.state.selectedFile1 !=null){
        formData.append( 
          "myFile1", 
          this.state.selectedFile1,
        );
      }
      
    }else if(this.state.attachmentquantity == 2){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1,
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2,
      );  
      }
    }else if(this.state.attachmentquantity == 3){
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1,
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2, 
      ); 
      }
      if(this.state.selectedFile3!=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3, 
      ); 
      }
    }else if(this.state.attachmentquantity == 4){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1,  
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2, 
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3, 
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4, 
      ); 
      }
    }else if(this.state.attachmentquantity == 5){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1, 
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2,
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3,
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4,
      ); 
      }
      if(this.state.selectedFile5 !=null){
      formData.append( 
        "myFile5", 
        this.state.selectedFile5,
      ); 
      }

    }else if(this.state.attachmentquantity == 6){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1,  
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2, 
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3, 
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4, 
      ); 
      }
      if(this.state.selectedFile5 !=null){
      formData.append( 
        "myFile5", 
        this.state.selectedFile5,
      ); 
      }
      if(this.state.selectedFile6 !=null){        
      formData.append( 
        "myFile6", 
        this.state.selectedFile6, 
      ); 
      }
    }else if(this.state.attachmentquantity == 7){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1, 
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2, 
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3, 
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4,
      ); 
      }
      if(this.state.selectedFile5 !=null){
      formData.append( 
        "myFile5", 
        this.state.selectedFile5, 
      ); 
      }
      if(this.state.selectedFile6 !=null){
      formData.append( 
        "myFile6", 
        this.state.selectedFile6,  
      ); 
      }
      if(this.state.selectedFile7 !=null){
      formData.append( 
        "myFile7", 
        this.state.selectedFile7, 
      );
      }
    }else if(this.state.attachmentquantity ==8){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1,  
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2,  
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3,  
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4,  
      ); 
      }
      if(this.state.selectedFile5 !=null){
      formData.append( 
        "myFile5", 
        this.state.selectedFile5,  
      ); 
      }
      if(this.state.selectedFile6 !=null){
      formData.append( 
        "myFile6", 
        this.state.selectedFile6,  
      ); 
      }
      if(this.state.selectedFile7 !=null){
      formData.append( 
        "myFile7", 
        this.state.selectedFile7, 
      );
      }
      if(this.state.selectedFile8 !=null){
      formData.append( 
        "myFile8", 
        this.state.selectedFile8,  
      );
      }
    }else if(this.state.attachmentquantity == 9){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1,  
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2,   
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3,  
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4,   
      ); 
      }
      if(this.state.selectedFile5 !=null){
      formData.append( 
        "myFile5", 
        this.state.selectedFile5,  
      ); 
      }
      if(this.state.selectedFile6 !=null){
      formData.append( 
        "myFile6", 
        this.state.selectedFile6,  
      ); 
      }
      if(this.state.selectedFile7 !=null){
      formData.append( 
        "myFile7", 
        this.state.selectedFile7,  
      );
      }
      if(this.state.selectedFile8 !=null){
      formData.append( 
        "myFile8", 
        this.state.selectedFile8, 
      );
      }
      if(this.state.selectedFile9 !=null){
      formData.append( 
        "myFile9", 
        this.state.selectedFile9,   
      );
      }
    }else if(this.state.attachmentquantity == 10){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1, 
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2,  
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3, 
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4, 
      ); 
      }
      if(this.state.selectedFile5 !=null){
      formData.append( 
        "myFile5", 
        this.state.selectedFile5, 
      ); 
      }
      if(this.state.selectedFile6 !=null){
      formData.append( 
        "myFile6", 
        this.state.selectedFile6,  
      ); 
      }
      if(this.state.selectedFile7 !=null){
      formData.append( 
        "myFile7", 
        this.state.selectedFile7,  
      );
      }
      if(this.state.selectedFile8 !=null){
      formData.append( 
        "myFile8", 
        this.state.selectedFile8,  
      );
      }
      if(this.state.selectedFile9 !=null){
      formData.append( 
        "myFile9", 
        this.state.selectedFile9,   
      );
      }
      if(this.state.selectedFile10 !=null){
      formData.append( 
        "myFile10", 
        this.state.selectedFile10, 
      );
      }
    }
   
    formData.append( 
      "Rotnumber", 
      rotnumber
    );
   
    // Details of the uploaded file 
    console.log(this.state.selectedFile1); 
    console.log(this.state.selectedFile2); 
    console.log(this.state.selectedFile3); 
    console.log(this.state.selectedFile4); 
    console.log(this.state.selectedFile5); 
    console.log(this.state.selectedFile6); 
    console.log(this.state.selectedFile7); 
    console.log(this.state.selectedFile8); 
    console.log(this.state.selectedFile9);
    console.log(this.state.selectedFile10);  
    // Request made to the backend api 
    // Send formData object 
    const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
  }


    return post(this.state.linkurl+"mode=admin-uploadfileattachmentrot", formData,config )
    .then(res => {
      console.log(res);
      console.log(res.data);
    }); 
  
}

vsletachangedchoose = (e) =>{
  if(this.state.shipmenttypeadropdowntext =="Export"){
    this.setState({ Vsleta: e})
    var timestamp = e.unix()
    var daylate5r = timestamp - 86400 * 1 
    var closingdaymoment = Moment.unix(daylate5r)
    this.setState({closingday:closingdaymoment})
  }else{
    this.setState({ Vsleta: e})
    var timestamp = e.unix()
    var daylate5r = timestamp + 86400 * 5 
    var closingdaymoment = Moment.unix(daylate5r)
    this.setState({closingday:closingdaymoment})
  }
  
}

renderTablePagination() {
         
  { if( this.state.completelist.length/100 < 1){
    return( null )
  }else if(this.state.completelist.length/100 >= 1 && this.state.completelist.length/100 < 2 ){
   return (
     
        
      <nav aria-label="Page navigation example">
             <Pagination
               className="pagination justify-content-end"
               listClassName="justify-content-end"
             >
                 <PaginationItem   >
                   <PaginationLink
                     href="#pablo"
                     onClick={(e) => this.previouspaginationfunction()}
                     tabIndex="-1"
                   >
                     <i className="fa fa-angle-left" />
                     <span className="sr-only">Previous</span>
                   </PaginationLink>
                 </PaginationItem>
                 <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                     1
                   </PaginationLink>
                 </PaginationItem>               
                       <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                        <PaginationLink href="#pablo" onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                          2
                        </PaginationLink>
                      </PaginationItem>      
             <PaginationItem>
                     <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                       <i className="fa fa-angle-right" />
                       <span className="sr-only">Next</span>
                     </PaginationLink>
                   </PaginationItem>
                 </Pagination>
               </nav>                                    
              
             )
  }else if(this.state.completelist.length/100 >= 2 && this.state.completelist.length/100 < 3 ){
   return (
      
           
         <nav aria-label="Page navigation example">
             <Pagination
               className="pagination justify-content-end"
               listClassName="justify-content-end"
             >
                 <PaginationItem   >
                   <PaginationLink
                     href="#pablo"
                     onClick={(e) => this.previouspaginationfunction()}
                     tabIndex="-1"
                   >
                     <i className="fa fa-angle-left" />
                     <span className="sr-only">Previous</span>
                   </PaginationLink>
                 </PaginationItem>
                 <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                     1
                   </PaginationLink>
                 </PaginationItem>               
                 <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                          2
                        </PaginationLink>
                      </PaginationItem>    
                      <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                          3
                        </PaginationLink>
                      </PaginationItem>      
                      <PaginationItem>
                     <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                       <i className="fa fa-angle-right" />
                       <span className="sr-only">Next</span>
                     </PaginationLink>
                   </PaginationItem>
                 </Pagination>
               </nav>                                    
           
         
        


             )
  }else if(this.state.completelist.length/100 >= 3 && this.state.completelist.length/100 < 4 ){
   return (
             <nav aria-label="Page navigation example">
             <Pagination
               className="pagination justify-content-end"
               listClassName="justify-content-end"
             >
                   <PaginationItem   >
                   <PaginationLink
                     href="#pablo"
                     onClick={(e) => this.previouspaginationfunction()}
                     tabIndex="-1"
                   >
                     <i className="fa fa-angle-left" />
                     <span className="sr-only">Previous</span>
                   </PaginationLink>
                 </PaginationItem>
                 <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                     1
                   </PaginationLink>
                 </PaginationItem>               
                 <PaginationItem className={this.state.currentcompletedpagenumber ==2 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                          2
                        </PaginationLink>
                      </PaginationItem>    
                      <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                          3
                        </PaginationLink>
                      </PaginationItem>  
                      <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                          4
                        </PaginationLink>
                      </PaginationItem>          
                      <PaginationItem>
                     <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                       <i className="fa fa-angle-right" />
                       <span className="sr-only">Next</span>
                     </PaginationLink>
                   </PaginationItem>
                 </Pagination>
               </nav>                                    
           )
  }else if(this.state.completelist.length/100 >= 4 && this.state.completelist.length/100 < 5 ){
   return (
             <nav aria-label="Page navigation example">
             <Pagination
               className="pagination justify-content-end"
               listClassName="justify-content-end"
             >
                 <PaginationItem   >
                   <PaginationLink
                     href="#pablo"
                     onClick={(e) => this.previouspaginationfunction()}
                     tabIndex="-1"
                   >
                     <i className="fa fa-angle-left" />
                     <span className="sr-only">Previous</span>
                   </PaginationLink>
                 </PaginationItem>
                 <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                     1
                   </PaginationLink>
                 </PaginationItem>               
                 <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                          2
                        </PaginationLink>
                      </PaginationItem>    
                      <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                          3
                        </PaginationLink>
                      </PaginationItem>  
                      <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                          4
                        </PaginationLink>
                      </PaginationItem>   
                      <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                          5
                        </PaginationLink>
                      </PaginationItem>           
                      <PaginationItem>
                     <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                       <i className="fa fa-angle-right" />
                       <span className="sr-only">Next</span>
                     </PaginationLink>
                   </PaginationItem>
                 </Pagination>
               </nav>                                    
           )
  }else if(this.state.completelist.length/100 >= 5  ){
   return (
             <nav aria-label="Page navigation example">
             <Pagination
               className="pagination justify-content-end"
               listClassName="justify-content-end"
             >
                <PaginationItem   >
                   <PaginationLink
                     href="#pablo"
                     onClick={(e) => this.previouspaginationfunction()}
                     tabIndex="-1"
                   >
                     <i className="fa fa-angle-left" />
                     <span className="sr-only">Previous</span>
                   </PaginationLink>
                 </PaginationItem>
                 <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                     1
                   </PaginationLink>
                 </PaginationItem>               
                 <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                          2
                        </PaginationLink>
                      </PaginationItem>    
                      <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                          3
                        </PaginationLink>
                      </PaginationItem>  
                      <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                          4
                        </PaginationLink>
                      </PaginationItem>   
                      <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                          5
                        </PaginationLink>
                      </PaginationItem>  
                      <PaginationItem className={this.state.currentcompletedpagenumber == 6 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:6})}>
                          ...
                        </PaginationLink>
                      </PaginationItem>           
                      <PaginationItem>
                     <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                       <i className="fa fa-angle-right" />
                       <span className="sr-only">Next</span>
                     </PaginationLink>
                   </PaginationItem>
                 </Pagination>
               </nav>                                    
           )
  } 
  
 }
}

render() {
  
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
             


              {
              /* Success Status*/
              this.state.aftersubmitstatus == 1
              ?             
              <CardBody>

              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodonenew"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Thanks for choose our services,</p>
              <p className="word-quotationaddnewfinish">Your Rot Number is {this.state.rotnumbercomeback}.</p>
              <ReactToPrint
          trigger={() =>   <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            to="/admin/orders"
            >
            Print Rot
            </Button>}
          content={() => this.componentRef}
        />
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              to="/admin/orders"
              tag={Link}
              >
              Go To Pending Approval
            </Button>
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.refreshallthing(this.state.numberrowaftersubmit)}
              >
              Create Another Rot
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Fail Status */
              this.state.aftersubmitstatus == 2
              ?
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">Submit Failed, Something Wrong, Please Try Again.</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={(e) => this.refreshallthing(this.state.numberrowaftersubmit)}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Loading Status */
              this.state.aftersubmitstatus == 3
              ?
              <Row >
                <Col md="12" >
                  <img 
                  className="img-center"
                  width="120"
                  height="120"
                  alt="..."
                  src={require("./../../assets/gif/lorrygif3.gif")}
                />
                </Col>
            </Row>
              :
              <CardBody>
                <div className="modal-body p-10">
                <Form>
           

                  {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
               

                  {/*Report Type*/}                
                  <Row>
                  <Col md="12">
                      <p className="h5">Report Type:</p>
                      <FormGroup >
                      <Autocomplete
                        id="combo-box-demo2"
                        options={[{title:"Total Container Last 6 Month"},{title:"Import/Export Vs Container Volume Last 6 Month"},{title:"Top Shipper vs Container last 6 months"},{title:"On Time KPI Last 6 Month"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({selectedreporttypevalue:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.shippervalidation} helperText={this.state.shippervalidationmessage} />}
                        disableClearable={true}
                      />                  
                      </FormGroup>
                    </Col>
                  
                  </Row>

                  {/*Choose Date*/}
                  {/* <Row>
                  <Col md="5">                 
                      <p className="h5">From month:</p>
                                         
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "From Date"
                      }}
                      dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        if (
                          this.state.startDate &&
                          this.state.endDate &&
                          this.state.startDate._d + "" === currentDate._d + ""
                        ) {
                          classes += " start-date";
                        } else if (
                          this.state.startDate &&
                          this.state.endDate &&
                          new Date(this.state.startDate._d + "") <
                            new Date(currentDate._d + "") &&
                          new Date(this.state.endDate._d + "") >
                            new Date(currentDate._d + "")
                        ) {
                          classes += " middle-date";
                        } else if (
                          this.state.endDate &&
                          this.state.endDate._d + "" === currentDate._d + ""
                        ) {
                          classes += " end-date";
                        }
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      
                      closeOnSelect={true}
                      onChange={e =>this.setState({ startDate: e })  }
                      value={this.state.startDate}
                    />
                  </InputGroup>
                </FormGroup>
           
                  </Col>

                  <Col md="5">
                    <p className="h5">To month:</p>
                  
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                          inputProps={{
                            placeholder: "To Date"
                          }}
                          dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            if (
                              this.state.startDate &&
                              this.state.endDate &&
                              this.state.startDate._d + "" === currentDate._d + ""
                            ) {
                              classes += " start-date";
                            } else if (
                              this.state.startDate &&
                              this.state.endDate &&
                              new Date(this.state.startDate._d + "") <
                                new Date(currentDate._d + "") &&
                              new Date(this.state.endDate._d + "") >
                                new Date(currentDate._d + "")
                            ) {
                              classes += " middle-date";
                            } else if (
                              this.state.endDate &&
                              this.state.endDate._d + "" === currentDate._d + ""
                            ) {
                              classes += " end-date";
                            }
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                          
                          closeOnSelect={true}
                          onChange={e =>this.setState({ endDate: e })}
                          value={this.state.endDate}
                        />
                      </InputGroup>
                    </FormGroup>
                
                   
                      </Col>

                      <Col md="2">
                        <Col md={2}>
                        <Button className="mt-4" color="primary" type="button" >
                            Show Report
                          </Button>
                        </Col>
                  </Col>
                  </Row>
                 */}
                 
         
                  {this.state.selectedreporttypevalue == "Total Container Last 6 Month" ?
                  <Row className="mt-5">
                  {/* Top Shipper vs container */}
                   <Col className="mb-5 mb-xl-0" xl="8">
                     <Card className="bg-gradient-default shadow">
                       <CardHeader className="bg-transparent">
                         <Row className="align-items-center">
                           <div className="col">                     
                             <h2 className="text-white mb-0">Total Container Last 6 Month</h2>
                           </div>
                          
                         </Row>
                       </CardHeader>
                       <CardBody>
                         {/* Chart */}
                         <div className="chart">
                           <Line
                             data={this.state.datacontainertotalbymonth}
                             options={chartExample1.options}                      
                             getDatasetAtEvent={e => console.log(e)}
                           />
                         </div>
                       </CardBody>
                     </Card>
                   </Col>
                     </Row>
                  :
                    null
                  }
                 
                 {this.state.selectedreporttypevalue == "Import/Export Vs Container Volume Last 6 Month" ?
                  <Row className="mt-5">
                  <Col xl="12">
                    <Card className="shadow">
                      <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                          <div className="col">                     
                            <h2 className="mb-0">Import/Export Vs Container Volume Last 6 Month</h2>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {/* Chart */}
                        <div className="chart">
                          <Bar
                            data={this.state.datacontainertotalbymonthimportexport}
                            options={chartExample2.options}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  </Row>
                 :
                  null                 
                  }

                {this.state.selectedreporttypevalue == "Top Shipper vs Container last 6 months" ?
              <Row className="mt-5">
                {/* Total Container by month */}
                <Col xl="12">
                    <Card className="shadow">
                      <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                          <div className="col">                     
                            <h2 className="mb-0">Top Shipper vs Container last 6 months</h2>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {/* Chart */}
                        <div className="chart">
                          <Bar
                            data={this.state.datatopshipperlist}
                            options={chartExample3.options}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  </Row>
                  :
                  null
                  }
                  
                  {this.state.selectedreporttypevalue == "On Time KPI Last 6 Month" ?
                  <Row className="mt-5">
                {/* On Real Time KPI */}
                <Col xl="12">
                    <Card className="shadow">
                      <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                          <div className="col">
                          
                            <h2 className="mb-0">On Time KPI</h2>
                          </div>
                          <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            className="alignrightbutton"
                            onClick={(e) => this.setState({detailpopout:true})}
                          >
                            See Detail
                          </Button> 
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {/* Chart */}
                        <div className="chart" >
                          <Pie
                            data={this.state.datalistontimekpi}
                            options={chartExample4.options}                   
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                :
                null}
    
                 
        </Form>
          </div>

        
           
          </CardBody>
              }

                     
         <div style={{ display: "none" }}><ComponentToPrint2 
                    volume1={this.state.volumenumberinput + "x " + this.state.type1dropdowntext}
                    volume2={this.state.volumenumberinput1 == 0? "": this.state.volumenumberinput1 + "x " + this.state.type1dropdowntext1}
                    volume3={this.state.volumenumberinput2 == 0? "": this.state.volumenumberinput2 + "x " + this.state.type1dropdowntext2}
                    volume4={this.state.volumenumberinput3 == 0? "": this.state.volumenumberinput3 + "x " + this.state.type1dropdowntext3}
                    size1=""
                    size2=""
                    size3=""
                    size4=""
                    type1=""
                    type2=""
                    type3=""
                    type4=""
                    container1={this.state["checksideloader0"] == true ?  this.state["container0"] +"(SL)" : this.state["container0"]}
                    container2={this.state["checksideloader1"] == true ?  this.state["container1"] +"(SL)" : this.state["container1"]}
                    container3={this.state["checksideloader2"] == true ?  this.state["container2"] +"(SL)" : this.state["container2"]}
                    container4={this.state["checksideloader3"] == true ?  this.state["container3"] +"(SL)" : this.state["container3"]}
                    container5={this.state["checksideloader4"] == true ?  this.state["container4"] +"(SL)" : this.state["container4"]}
                    container6={this.state["checksideloader5"] == true ?  this.state["container5"] +"(SL)" : this.state["container5"]}
                    container7={this.state["checksideloader6"] == true ?  this.state["container6"] +"(SL)" : this.state["container6"]}
                    container8={this.state["checksideloader7"] == true ?  this.state["container7"] +"(SL)" : this.state["container7"]}
                    remark={this.state.remark}
                    importexport={this.state.shipmenttypeadropdowntext}
                    shippingline={this.state.operatoreadropdowntext}
                    forwardingagent={this.state.forwardingagentdropdowntext}
                    commodity={this.state.commodity}
                    pod={this.state.podtext}
                    pol={this.state.poltext}
                    vesselvoyage={this.state.vesselvoyage}
                    vesselname={this.state.vesselname}
                    vesseleta={this.state.vsltoshow}
                    closingdate={this.state.closingdateshow}
                    containerrequiredate={this.state.requestdatetoshow0}
                    containerrequiretime=""
                    Bookingno={this.state.bookingno}
                    pickupyard={this.state.pickupdropdowntext}
                    shippername={this.state.areadropdowntext}
                    pic={this.state.picvalue}
                    shipperaddress={this.state.deliveryaddress}
                    billingto={this.state.Billtoparty}
                    contactpic={this.state.contactvalue}
                    ref={el => (this.componentRef = el)} /></div> 


              </Card>
            </div>
          </Row>
        </Container>
     {/* on real time kpi pop out */}
     <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.detailpopout}
          toggle={() => this.setState({detailpopout:false})}
        >
          {this.state.loadingmodal?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>
         :
          <div>
          <div className="modal-header">
          <h1 className="modal-title" id="detailpopoutLabel">
          On Time KPI
            </h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailpopout:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10">
          <Row className="mt-1">
          
         {/* Personal Table */}
       <Col className="mb-5 mb-xl-0" xl="12">
           <Card className="shadow">
           <CardBody >
           {this.renderTablePagination()}
             <Table className="align-items-center table-flush" responsive>
               <thead className="thead-light">
                 <tr>
                   <th scope="col">Consignment No.</th>
                   <th scope="col">Shipper</th>
                   <th scope="col">Booking No.</th>
                   <th scope="col">Container No.</th>
                   <th scope="col">Request Date</th>
                   <th scope="col">Assign Date</th>
                   <th scope="col">Status</th>
                 </tr>
               
               </thead>
               
               {this.renderontimekpimodallist()}
              
             </Table>
             </CardBody>
           </Card>
         </Col>
        
       </Row>
      
       
        
       </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailpopout:false})}
            >
              Close
            </Button>
         
          </div>
          </div>}
          
          </Modal>  
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />

     
       
      </>
    );
  }

}

class ComponentToPrint2 extends React.Component {
  
  state= {  
    
    printbilltoparty2:this.props.billingto,
    printbookingno2:this.props.Bookingno,
    printclosing2:this.props.closingdate,
    printcommodity2:this.props.commodity,
    printcontainer12:this.props.container1,
    printcontainer22:this.props.container2,
    printcontainer32:this.props.container3,
    printcontainer42:this.props.container4,
    printcontainer52:this.props.container5,
    printcontainer62:this.props.container6,
    printcontainer72:this.props.container7,
    printcontainer82:this.props.container8,
    printeta2:this.props.vesseleta,
    printforwardingagent2:this.props.forwardingagent,
    printpic2:this.props.pic,
    printpickup2:this.props.pickupyard,
    printpod2:this.props.pod,
    printpol2:this.props.pol,
    printremark2:this.props.remark,
    printrequiretime2:this.props.containerrequiretime,
    printrequiredate2:this.props.containerrequiredate,
    printshipper2:this.props.shippername,
    printshippingaddresss2:this.props.shipperaddress,
    printshippingliner2:this.props.shippingline,
    printsize12:this.props.size1,
    printsize22:this.props.size2,
    printsize32:this.props.size3,
    printsize42:this.props.size4,
    printtype12:this.props.type1,
    printtype22:this.props.type2,
    printtype32:this.props.type3,
    printtype42:this.props.type4,
    printvolume12:this.props.volume1,
    printvolume22:this.props.volume2,
    printvolume32:this.props.volume3,
    printvolume42:this.props.volume4,
    printvoyage2:this.props.vesselvoyage,
    printvesselname:this.props.vesselname,
    printexport2:this.props.importexport,
    printcontactpic2:this.props.contactpic,
  }

  
  render() {
    return (
      <div class="containerprint">
      <img 
      width="1000px"
      height="1442px"
      alt="..."
      class
      src={require("./../../assets/gif/Rottemplate3.jpg")}
      />
      <span class="importexportprintcss">{this.props.importexport}</span>
      <span class="linerprintcss">{this.props.Bookingno}</span>
       <span class="shippinglinerprintcss">{this.props.shippingline}</span>
       <span class="Forwardinglinerprintcss">{this.props.forwardingagent}</span>
       <span class="Commoditylinerprintcss">{this.props.commodity}</span>
       <span class="POLlinerprintcss">{this.props.pol}</span>
       <span class="PODlinerprintcss">{this.props.pod}</span>
       <span class="Voyagelinerprintcss">{this.props.vesselname}/{this.props.vesselvoyage}</span>
       <span class="ETAlinerprintcss">{this.props.vesseleta}</span>
       <span class="Closinglinerprintcss">{this.props.closingdate}</span>
       <span class="Requirelinerprintcss">{this.props.containerrequiredate}</span>
       <span class="Requiretimelinerprintcss">{this.state.printrequiretime2}</span>
       <span class="Picklinerprintcss">{this.props.pickupyard}</span>
       <span class="Shipperprintcss">{this.props.shippername}</span>
       <span class="PICprintcss">{this.props.pic}</span>
       <span class="PICcontactcss">{this.props.contactpic}</span>
       <span class="shippingaddressprintcss">{this.props.shipperaddress}</span>
       <span class="Billprintcss">{this.props.billingto}</span>
       <span class="volume1printcss">{this.props.volume1}</span>
       <span class="volume2printcss">{this.props.volume2}</span>
       <span class="volume3printcss">{this.props.volume3}</span>
       <span class="volume4printcss">{this.props.volume4}</span>
       <span class="size1printcss">{this.state.printsize12}</span>
       <span class="size2printcss">{this.state.printsize22}</span>
       <span class="size3printcss">{this.state.printsize32}</span>
       <span class="size4printcss">{this.state.printsize42}</span>
       <span class="type1printcss">{this.state.printtype12}</span>
       <span class="type2printcss">{this.state.printtype22}</span>
       <span class="type3printcss">{this.state.printtype32}</span>
       <span class="type4printcss">{this.state.printtype42}</span>
       <span class="container1printcss">{this.props.container1}</span>
       <span class="container2printcss">{this.props.container2}</span>
       <span class="container3printcss">{this.props.container3}</span>
       <span class="container4printcss">{this.props.container4}</span>
       <span class="container5printcss">{this.props.container5}</span>
       <span class="container6printcss">{this.props.container6}</span>
       <span class="container7printcss">{this.props.container7}</span>
       <span class="container8printcss">{this.props.container8}</span>
       <span class="remarkprintcss">{this.props.remark}</span>
      </div>
     
       
    );
  }
}

export default Report;


